/*-----Contact----*/

@media (min-width: 768px) and (max-width: 991.98px) {
	.app {
	  .main-content-left-contacts {
		.main-img-user {
		  margin: 0 auto;
		}
	  }
	}
  }
  
  @media (max-width: 1200px) {
	.main-contact-info-header {  
	  .media-body {
		padding-block-start: 20px;
	  }
	}
  }
  
  @media (max-width: 400px) {
	.main-contact-action a span {
	  display: none;
	}
  }
  
  @media (max-width: 576px) {
	.main-contact-info-header {
	  padding-block-start: 60px !important;
	}
  
	.main-contact-action {
	  inset-inline-start: 21px;
	  inset-inline-end: 0 !important;
	}
  
	.main-contact-info-header .media-body .nav-link {
	  padding-block-start: 5px;
	}
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
	.main-contact-info-header {
	  .media {
		display: block !important;
	  }
  
	  .media-body {
		padding-block-start: 20px;
		margin-inline-start: 0 !important;
	  }
	}
  }
  
  .main-content-left-contacts {
	width: 100%;
	display: block;
	padding: 0;
  }
  
  .main-contacts-list {
	position: relative;
	height: 700px;
  }
  
  .main-contact-label {
	padding-block-start: 20px;
	padding-block-end: 5px;
	padding-inline-start: 20px;
	font-weight: 500;
	font-size: 12px;
	position: relative;
  
	&::after {
	  content: '';
	  position: absolute;
	  inset-block-end: -1px;
	  inset-inline-start: 0;
	  inset-inline-end: 0;
	  border-block-end: 1px solid #e9edf4;
	  z-index: 1;
	}
  }
  
  .main-contact-item {
	padding: 10px 20px;
	border: 1px solid transparent;
	border-width: 0;
	display: flex;
	position: relative;
	cursor: pointer;
  
	+ .main-contact-item {
	  margin-block-start: -1px;
	  border-block-start-color: transparent;
  
	  &::before {
		content: '';
		position: absolute;
		inset-block-start: -1px;
		inset-inline-start: 0;
		inset-inline-end: 0;
		border-block-start: 1px solid #e9edf4;
	  }
	}
  
	&:hover, &:focus {
	  background-color: #f3f4f7;
	  border-block-start-color: $border;
	  border-block-end-color: $border;
	}
  
	&.selected {
	  z-index: 0;
	  background-color: #f3f4f7;
	  border-block-start-color: $border;
	  border-block-end-color: $border;
	  border-width: 2px;
	}
  }
  
  .main-contact-body {
	flex: 1;
	margin-inline-start: 15px;
  
	h6 {
	  color: $color;
	  font-weight: 600;
	  margin-block-end: 2px;
	}
  
	span {
	  font-size: 13px;
	  color: #728096;
	}
  }
  
  @media (min-width: 768px) {
	.main-content-body-contacts {
	  display: block;
	  overflow-y: auto;
	}
  
	.main-content-left-contacts {
	  width: 100%;
	}
  }
  
  .main-contact-info-header {
	padding-block-start: 40px;
	padding-inline-start: 20px;
	padding-block-end: 20px;
	position: relative;
  
	.media {
	  display: block;
	}
  
	.main-img-user {
	  width: 100px;
	  height: 100px;
  
	  &::after {
		display: none;
	  }
  
	  a {
		position: absolute;
		inset-block-end: 2px;
		inset-inline-end: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		background-color: $primary;
    	color: white;
		font-size: 17px;
		line-height: .9;
		box-shadow: 0 0 0 2px $white;
		border-radius: 100%;
  
		&:hover, &:focus {
		  fill: $white-5;
		  color: $white;
		}
	  }
	}
  
	.media-body {
	  margin-block-start: 20px;
  
	  h4 {
		color: $color;
		font-size: 22px;
		font-weight: 500;
		margin-block-end: 4px;
		line-height: 1;
	  }
  
	  p {
		margin-block-end: 15px;
	  }
  
	  .nav-link {
		padding: 0;
		display: flex;
		align-items: center;
		color: #728096;
		font-size: 13px;
  
		i {
		  font-size: 14px;
		  line-height: 0;
		  margin-inline-end: 5px;
  
		  &.typcn {
			line-height: .9;
  
			&::before {
			  width: auto;
			}
		  }
		}
  
		+ .nav-link {
		  margin-inline-start: 10px;
		}
	  }
	}
  }
  
  @media (min-width: 576px) {
	.main-contact-info-header {
	  .media-body .nav-link {
		+ .nav-link {
		  margin-inline-start: 10px;
		}
  
		i {
		  margin-inline-end: 7px;
		}
  
		font-size: 0.875rem;
	  }
  
	  padding-block-start: 20px;
	}
  }
  
  .main-contact-action {
	position: absolute;
	inset-block-start: 20px;
	inset-inline-end: 20px;
	display: flex;
  
	a {
	  display: flex;
	  align-items: center;
  
	  i {
		font-size: 17px;
		line-height: .9;
		margin-inline-end: 5px;
	  }
  
	  + a {
		margin-inline-start: 15px;
	  }
	}
  }
  
  .main-contact-info-body {
	padding-inline-start: 0px;
  
	.media-list {
	  padding: 20px 0;
	}
  
	.media {
	  align-items: center;
	  position: relative;
  
	  + .media {
		margin-block-start: 30px;
  
		&::before {
		  content: '';
		  position: absolute;
		  inset-block-start: -15px;
		  inset-inline-start: 0;
		  inset-inline-end: 0;
		  border-block-start: 1px solid #e9edf4;
		}
	  }
  
	  &:last-child {
		margin-block-end: 15px;
	  }
	}
  
	.media-body {
	  margin-inline-start: 30px;
  
	  > div + div {
		margin-block-start: 15px;
	  }
  
	  label {
		color: #728096;
		margin-block-end: 0;
		display: block;
		font-size: 14px;
	  }
  
	  span {
		display: block;
		color: $color;
		font-weight: 500;
	  }
	}
  
	.media::before {
	  content: '';
	  position: absolute;
	  inset-block-start: -15px;
	  inset-inline-start: 0;
	  inset-inline-end: 0;
	  border-block-start: 1px solid #e9edf4;
	}
  }
  
  @media (min-width: 992px) {
	.main-contact-info-body {
	  .media-body {
		> div {
		  flex: 1;
		}
  
		display: flex;
	  }
  
	  .media-list {
		padding: 25px 0;
	  }
  
	  height: calc(100% - 126px);
	  position: relative;
	}
  
	.main-contact-action {
	  inset-inline-end: 20px;
	}
  
	.main-contact-info-header {
	  .media-body {
		margin-block-start: 0;
		margin-inline-start: 30px;
	  }
  
	  .media {
		display: flex;
		align-items: center;
	  }
  
	  padding-inline-start: 30px;
	  padding-block-end: 25px;
	}
  
	.main-content-body-contacts {
	  overflow: visible;
	}
  
	.main-contact-item {
	  padding: 10px 15px;
	}
  
	.main-contact-label {
	  padding-inline-start: 10px;
	}
  
	.main-contact-info-body .media-body > div + div {
	  margin-block-start: 0;
	}
  }
  
  .main-contact-info-header .main-img-user {
	position: relative;
  
	a {
	  position: absolute;
	  margin-block-end: 2px;
	}
  }
  .main-contact-info-header.bg-contacthead {
	border-radius: 0 8px 0 0;
  }

  .contact-icons {
	margin-inline-start: 1px;
  }

  /*-----Contact End----*/