/*--------Panel----------*/

.expanel-default {
	border: #ddd !important;
  }
  
  .expanel-success {
	border: #2dce89 !important;
  }
  
  .expanel-danger {
	border: #dc0441 !important;
  }
  
  .expanel-success > .expanel-heading {
	color: $white !important;
	background-color: #2dce89 !important;
	border-color: #2dce89 !important;
  }
  
  .expanel-danger > .expanel-heading {
	color: $white !important;
	background-color: #dc0441 !important;
	border-color: #dc0441 !important;
  }
  
  .expanel-title {
	margin-block-start: 0 !important;
	margin-block-end: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
  }
  
  .expanel {
	margin-block-end: 20px !important;
	background-color: $white !important;
	border: 1px solid $border !important;
	border-radius: 4px !important;
  }
  
  .expanel-default > .expanel-heading {
	background-color: #f0f0f2 !important;
	border: 1px solid $border !important;
  }
  
  .expanel-heading {
	padding: 10px 15px !important;
	border-block-end: 1px solid $border;
	border-start-end-radius: 3px !important;
	border-start-end-radius: 3px !important;
    border: 1px solid #ebecf1;
  }
  
  .expanel-footer {
	padding: 10px 15px !important;
	background-color: #f0f0f2 !important;
	border-block-start: 1px solid $border !important;
	border-end-end-radius: 3px !important;
	border-end-start-radius: 3px !important;
  }
  
  .expanel-body {
	padding: 15px !important;
  }
  /*--------Panel End----------*/