/*-----Alerts-----*/

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-block-end: 1rem;
	border: 1px solid transparent;
	border-radius: 3px;
	font-size: 0.9375rem;
  
	&:first-child {
	  margin-block-end: 1rem !important;
	}
  
	&:last-child {
	  margin-block-end: 0;
	}
  }
  
  .alert-link {
	font-weight: 500;
  
	&:hover, a:hover {
	  text-decoration: underline;
	}
  }
  
  hr.message-inner-separator {
	clear: both;
	margin-block-start: 10px;
	margin-block-end: 13px;
	border: 0;
	height: 1px;
	width:100%;;
	background-image: -webkit-linear-gradient(left, $black, rgba($black, 0.15), $black);
	background-image: -moz-linear-gradient(left, $black, rgba($black, 0.15), $black);
	background-image: -ms-linear-gradient(left, $black, rgba($black, 0.15), $black);
	background-image: -o-linear-gradient(left, $black, rgba($black, 0.15), $black);
  }
  
  .alert-success {
	color: $white;
	background-color: $success;
	border-color: $success;
  
	hr {
	  border-block-start: 1px solid#47d394;
	}
  
	.alert-link {
	  color: #172e00;
	}
  }
  
  .alert-info {
	color: $white;
	background-color: $info;
	border-color: $info;
  
	hr {
	  border-block-start: 1px solid #6e9fed;
	}
  
	.alert-link {
	  color: #193c56;
	}
  }
  
  .alert-warning {
	color: $white;
	background-color: #f3a403;
	border-color: #f3a403;
  
	hr {
	  border-block-start: 1px solid #f3c464;
	}
  
	.alert-link {
	  color: #4d3f05;
	}
  }
  
  .alert-danger {
	color: $white;
	background-color: $danger;
	border-color: $danger;
  
	hr {
	  border-block-start: 1px solid #ef7272;
	}
  
	.alert-link {
	  color: #3f0a09;
	}
  }
  
  .alert-light {
	color: #818182;
	background-color: #f2f2f3;
	border-color: #f2f2f3;
  
	hr {
	  border-block-start: 1px solid #ececf6;
	}
  
	.alert-link {
	  color: #686868;
	}
  }
  
  .alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
  
	hr {
	  border-block-start: 1px solid#b9bbbe;
	}
  
	.alert-link {
	  color: #040505;
	}
  }
  
  .btn-close {
	font-size: 1rem;
	line-height: 1.5;
	transition: .3s color;
  }
  
  .alert-light-success {
	color: $success;
	background-color: #cbf6e2;
	border-color: #cbf6e2;
  }
  
  .alert-light-danger {
	color: $danger;
	background-color: #fac8c8;
	border-color: #fac8c8;
  }
  
  .alert-light-warning {
	color: $warning;
	background-color: #fee8bb;
	border-color: #fee8bb;
  }
  
  .alert-light-info {
	color: $info;
	background-color: #ccd6f8;
	border-color: #ccd6f8;
  }

  
.alert .btn-close{
	color:$white-6;
	padding-inline: 0;
  }

.alert-light .alert-link {
	color: #686868;
  }
  /*-----Alerts-----*/