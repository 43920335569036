/*---- Custom-styles------*/

body * {
	&::-webkit-scrollbar {
		width: 1px;
		height: 1px;
		transition: 0.3s background;
	}

	&::-webkit-scrollbar-thumb,
	&:hover::-webkit-scrollbar-thumb {
		background: #c7cfdf;
	}

	&::-webkit-scrollbar-track {
		background: $white;
	}
}

.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 1.5rem 0 1.5rem;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	position: relative;
}

.page-title {
	margin: 0;
	font-size: 20px;
	font-weight: 700;
	line-height: 2.5rem;
	position: relative;
}

.page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.header {
	background: $white;
}

body.fixed-header .header {
	position: fixed;
	inset-block-start: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	z-index: 1030;
}

.header {
	.dropdown-menu {
		margin-block-start: 0;
		border-radius: 0px 0 4px 4px !important;
	}
}

.nav-link.icon {
	margin: 5px;
	text-align: center;
	height: 2.5rem;
	font-size: 1.2rem;
	position: relative;
}

.app-header.header .nav-link.icon {
	padding: 5px;
}

.header-brand {
	color: inherit;
	margin-inline-end: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: 0.3s opacity;
	line-height: 2.9rem;
}

.header-brand-img {
	margin-inline-end: 0;
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-brand-img {
	height: 3rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-inline-end: 0;
	width: auto;
}

.footer {
	background: $white;
	border-block-start: 1px solid $border;
	font-size: 0.875rem;
	padding: 1.25rem 0;
}

footer.footer {
	background: $white;
	font-size: 0.875rem;
	border-block-start: 0;
	padding: 1.25rem 0;
	box-shadow: 16px 0px 16px rgba(24, 23, 38, 0.06);
	border-block-start: 1px solid $border;
	display: block;
	margin-block-start: auto;
}

.footer {
	.social {
		ul li {
			float:$float-left;
			padding: 7px;

			a {
				color: #abb8c3;
			}
		}

		margin: 0 auto;
	}

	a:not(.btn) {
		font-weight: 400;
	}
}

@media print {
	.footer,
	f {
		display: none;
	}
}

.br-3 {
	border-radius: 3px !important;
}

.br-5 {
	border-radius: 5px !important;
}

.br-7 {
	border-radius: 7px !important;
}

.br-ts-7 {
	border-start-start-radius: 7px !important;
}

.br-bs-7 {
	border-end-start-radius: 7px !important;
}

.br-te-7 {
	border-start-end-radius: 7px !important;
}

.br-be-7 {
	border-end-end-radius: 7px !important;
}

.br-ts-5 {
	border-start-start-radius: 5px !important;
}

.br-bs-5 {
	border-end-start-radius: 5px !important;
}

.br-te-5 {
	border-start-end-radius: 5px !important;
}

.br-be-5 {
	border-end-end-radius: 5px !important;
}

.br-0 {
	border-radius: 0px;
}

.br-ts-0 {
	border-start-start-radius: 0px !important;
}

.br-bs-0 {
	border-end-start-radius: 0px !important;
}

.br-te-0 {
	border-start-end-radius: 0px !important;
}

.br-be-0 {
	border-end-end-radius: 0px !important;
}

.list.d-flex img {
	width: 60px;
}

.chartsh {
	height: 16rem;
	overflow: hidden;
}

.countdown span:first-child {
	font-size: 30px;
	font-weight: 500;
}

.social {
	i {
		font-size: 40px;
	}

	.social-icon i {
		font-size: 14px;
	}
}

.twitter {
	background: #1da1f2;
}

.facebook {
	background: #3b5998;
}

.login-img {
	background: url("../images/login.jpg");
	background-size: cover;
}

.z-index-10 {
	z-index: 10 !important;
}

.card-blog-overlay .card-header {
	border-block-end: 1px solid $white-2;
}

.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
	border-block-start: 1px solid $white-2;
}

.card-blog-overlay {
	background-size: cover;
	position: relative;

	&:before {
		content: "";
		display: block;
		position: absolute;
		background: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		inset-inline-end: 0;
		inset-block-start: 0;
		border-radius: 7px;
	}
}

.card-blog-overlay1:before,
.card-blog-overlay2:before {
	content: "";
	display: block;
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	inset-inline-end: 0;
	inset-block-start: 0;
}

.card-blog-overlay1 {
	background: url("../images/media/33.jpg") !important;
	background-size: cover;
}

.card-blog-overlay2 {
	background: url("../images/media/22.jpg") !important;
	background-size: cover;
}

@media (max-width: 320px) {
	.fc-toolbar .fc-right {
		float:$float-right;
		width: 100%;
		text-align: center;
		margin: 10px 0;
	}

	.material-switch.pull-right {
		margin-inline-start: 7px;
		margin-block-start: 5px;
	}
}

.search-element {
	position: relative;
}

.header .form-inline {
	.form-control {
		border: 0px solid #f6f6f6;
		padding-inline-start: 55px;
		padding-inline-end: 40px;
		background: $white;
		border-radius: 4px;

		&:focus {
			box-shadow: none;
		}

		&::-webkit-input-placeholder {
			font-size: 0.9375rem;
			opacity: 0.5;
		}
	}

	.btn {
		border-radius: 0;
		padding: 0px 15px 0px 15px;
		border: 1px solid transparent;
		box-shadow: none;
		border-start-end-radius: 4px;
		border-end-end-radius: 4px;
		background: transparent;
		color: $white;
		font-size: 15px;
		position: absolute;
		inset-inline-start: 0;
		opacity: 0.5;
		inset-block-start: 4px;

		i {
			color: #818c9b;
		}
	}
}

@media (max-width: 991px) {
	.header .form-inline .search-element {
		position: absolute;
		inset-block-start: -4px !important;
		inset-inline-start: 10px;
		inset-inline-end: 10px;
		z-index: 892;
		display: none;
		margin-inline-start: 0;
	}

	body.search-show .header .form-inline .search-element {
		display: block;
	}

	.header {
		.form-inline .form-control::-webkit-input-placeholder {
			color: $color;
		}
	}
}

@media (max-width: 320px) {
	.desktop-lgo {
		display: inline-block !important;
		margin-block-start: 3px !important;
	}
}
@media (max-width: 360px) {
	.richText .richText-form input[type=text], .richText .richText-form select {
		min-width: 180px !important;
	}
	.btn-group .btn-close.btn-lg {
		padding: 11px 5px;
	}

	#checkoutsteps>.steps ul {
		display: block;
	}

	#checkoutsteps .checkoutline {
		display: none;
	}

	#checkoutsteps>.steps ul .number {
		margin: 2px auto;
	}

	#checkoutsteps>.steps ul .number {
		border-radius: 0.25rem !important;
		width: 100%;
	}

	#checkoutsteps>.steps a {
		width: 50% !important;
	}
}

@media (max-width: 1024px) {
	.app.sidebar-gone.search-show .header.top-header {
		z-index: 1036;
	}

	body {
		&.sidebar-show {
			overflow: hidden;
		}

		&.search-show:before,
		&.sidebar-show:before {
			content: "";
			position: fixed;
			inset-inline-start: 0;
			inset-inline-end: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
			opacity: 0.6;
			z-index: 891;
		}
	}
}

@media (min-width: 575.98px) and (max-width: 1200px) {
	.header .form-inline {
		.form-control {
			width: 100% !important;
			min-width: 100% !important;
		}
	}
}

@media (min-width: 991.98px) {
	.responsive-search {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.hor-header {
		box-shadow: 16px 0px 16px rgba(24, 23, 38, 0.06);
		border-block-end: 1px solid transparent !important;
	}
}

@media (max-width: 575.98px) {
	body.search-show .header .form-inline .search-element {
		display: block;
	}

	div.dataTables_wrapper div.dataTables_paginate .pagination .page-item .page-link {
		padding: 0.3rem 0.45rem;
	}

	div.dataTables_wrapper div.dataTables_paginate ul.pagination {
		justify-content: center !important;
	}

	.header .form-inline .search-element .form-control {
		float:$float-left;
		width: 100% !important;
		height: 40px;
		margin: 0 auto;
		background: $white;
		border-radius: 4px;
	}

	.form-control.header-search {
		color: $black;
	}

	.login-page .demo-icon {
		inset-inline-end: 0 !important;
		inset-block-start: 25px !important;
	}

	.header {
		.form-inline {
			.search-element {
				position: absolute;
				z-index: 892;
				display: none;
			}

			.btn {
				color: #46494a !important;
			}

			.form-control::-webkit-input-placeholder {
				color: $color !important;
			}
		}
	}

	.hor-header .header-brand-img {
		&.desktop-lgo {
			display: none;
		}
	}
}

.icons-list {
	list-style: none;
	margin: 0 -1px -1px 0;
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	> li {
		-ms-flex: 0 0 2.5rem;
		flex: 0 0 2.5rem;
	}
}

.icons-list-wrap {
	overflow: hidden;
}

.flags-icons .icons-list-item {
	line-height: inherit;
}

.payment-icons .icons-list-item {
	line-height: 2rem;
}

.icons-list-item {
	display: inline-block;
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	text-align: center;
	border-radius: 10px !important;
	font-size: 18px;
	border-radius: 50px;
	box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	border: 1px solid $border;
	vertical-align: middle;
	color: #747fa7;
	margin: 5px;
}

.link-overlay {
	position: relative;

	&:hover .link-overlay-bg {
		opacity: 1;
	}
}

.link-overlay-bg {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	inset-block-end: 0;
	background: rgba(70, 127, 207, 0.8);
	display: -ms-flexbox;
	display: flex;
	color: $white;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: 0.3s opacity;
}

.media-icon {
	width: 2.2rem;
	height: 2.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	font-size: 18px;
}

.media-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

textarea[cols] {
	height: auto;
}

.richText {
	.richText-toolbar {
		ul li a {
			border-inline-end: #e2e3e7 solid 1px;
		}

		border-block-end: $border solid 1px;
		border-block-start: 1px solid $border;
	}

	border: $border solid 1px;
	background-color: #f0f0f2;
	border-block-start: 0;
	border-block-end: 0;
}

.fc button {
	background: #f5f6f8;
	color: #efefef;
	text-transform: uppercase;
	font-size: 13px !important;
	font-weight: 500;
	padding: 5px;
}

.fc-event {
	line-height: 1.4;
	border-radius: 3px;
}

.fc-toolbar {
	.fc-state-active,
	.ui-state-active {
		color: $white;
		outline: 0;
		box-shadow: none;
	}
}

#sidebar ul li.active > a:hover {
	background: #6d4dbb;
	color: $white;
}

.overflow-hidden {
	overflow: hidden;
}

#sidebar {
	ul li {
		a {
			color: #384056;
			font-weight: 400;
		}

		position: relative;

		ul li:last-child {
			border-block-end: 0;
		}
	}

	li a[aria-expanded="true"]:hover {
		color: $white !important;
	}
}

.tab_wrapper {
	> ul {
		border-block-end: 1px solid $border;
	}

	&.right_side {
		.content_wrapper {
			border: 1px solid $border;
		}

		> ul {
			li {
				&.active {
					border-color: 1px solid $border;
				}

				&:after {
					background: 1px solid $border;
				}
			}

			border-block-end: 1px solid $border;
		}
	}
}

.text-end {
	text-align: end;
}

.isActive {
	svg,
	&.full svg {
		stroke-dashoffset: -65;
		stroke-dasharray: 141.305 65;
		transform: translateX(0);
	}
}

.full {
	svg {
		stroke-dashoffset: 0;
		stroke-dasharray: 64.6 206.305;
		transform: translateX(0);
	}
}

.searching i {
	font-size: 18px;
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.pulse {
	display: block;
	position: absolute;
	inset-block-start: 23px;
	inset-inline-end: 23px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #f72d66;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(247, 45, 102, 0.9);
	animation: pulse 2s infinite;

	&:hover {
		animation: none;
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
	}

	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
		box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.7);
	}

	70% {
		-moz-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
		box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
		box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
	}
}

@media (max-width: 991px) {
	.header.top-header {
		.header-setting {
			display: none;
		}
	}

	.hor-header.header {
		position: fixed;
		width: 100%;
		z-index: 999;
	}

	.hor-content.main-content {
		margin-block-start: 50px;
	}

	.horizontal-main.hor-menu {
		border-block-end: 0;
	}
	
	.header.top-header .container,
	.hor-content.main-content .container {
		max-width: none !important;
	}

	.active .header.top-header {
		z-index: 999;
	}
}

.header.top-header {
	.search-element input {
		border-radius: 4px;
	}
}

@media (max-width: 991px) {
	.media.profile-footer .media-user {
		display: none;
	}

	.register1 .register-1 {
		display: none;
	}
}

@media (max-width: 991px) {
	.header-brand-img {
		display: none;
	}

	.header .form-inline .search-element {
		.form-control {
			padding-block-start: 10px;
		}

		.btn {
			padding: 0;
			inset-inline-start: 25px;
			inset-block-start: 22px;
			position: absolute !important;
			margin-block-start: 0;
		}
	}

	.theme-layout {
		border-inline-start: 0px !important;
	}

	.dark-mode .theme-layout {
		border-inline-start: 0px !important;
	}

	.header-message .nav-link {
		margin-inline-start: 0px !important;
		padding-inline-start: 0px !important;
	}
}
.activity {
	position: relative;
	border-inline-start: 3px solid $border;
	margin-inline-start: 16px;

	.img-activity {
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 44px;
		border-radius: 50%;
		position: absolute;
		inset-inline-start: -20px;
		border: 3px solid;
	}

	.item-activity {
		margin-inline-start: 40px;
		margin-block-end: 26px;
	}
}

#CryptoChart,
#CryptoChart1,
#CryptoChart2,
#CryptoChart3 {
	width: 100% !important;
	height: 85px !important;
	inset-block-end: -15px;
	position: relative;
	inset-inline-start: -7px;
}

[class*="js-conveyor-"] ul li {
	padding: 9px 15px;
	border: 1px solid #efefef;
	margin-inline-end: 5px;
	background: $white;
	margin: 15px 6px;
	border-radius: 5px;
	box-shadow: 0 0px 10px -5px rgba(68, 84, 195, 0.5);
}

.latest-timeline {
	height: 355px;

	ul.timeline {
		list-style-type: none;
		position: relative;

		&:before {
			content: " ";
			display: inline-block;
			position: absolute;
			inset-inline-start: 10px;
			height: 100%;
			z-index: 1;
			border-inline-start: 1px double $border;
		}

		> li {
			margin: 20px 0;
			padding-inline-start: 2.5rem;

			&:before {
				content: " ";
				background: white;
				display: inline-block;
				position: absolute;
				border-radius: 50%;
				border: 3px solid #1753fc;
				inset-inline-start: 5px;
				width: 13px;
				height: 13px;
				z-index: 1;
			}

			&:first-child:before {
				border: 2px double #0dd157;
			}

			&:nth-child(2):before {
				border: 2px solid #2d9eef;
			}

			&:nth-child(3):before {
				border: 2px solid #f35e90;
			}

			&:nth-child(4):before {
				border: 2px solid $orange;
			}

			&:nth-child(5):before {
				border: 2px solid #05a5ba;
			}

			&:nth-child(6):before {
				border: 2px solid #920bfa;
			}

			&:last-child:before {
				border: 2px solid #ec2d38;
			}
		}
	}
}

div {
	&[data-acc-content] {
		display: none;
	}

	&[data-acc-step]:not(.open) {
		background: #f0f0f2;

		h5 {
			color: #8e9cad;
		}

		.bg-primary {
			background: #afb4d8;
		}
	}
}

#form .list-group-item:hover {
	background: none;
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
	background: transparent;
}

.apexcharts-toolbar {
	z-index: 0 !important;
	display: none;
}

@media (min-width: 992px) {
	.top-header.hor-top-header {
		.header-brand-img {
			display: none;
		}

		.header-brand.center-logo .desktop-lgo {
			inset-inline-start: 0;
			display: block;
			position: absolute;
			margin: 0 auto;
			inset-inline-end: 0px;
		}

		.form-inline .form-control {
			width: 175px;
		}

		.header-brand {
			margin-inline-end: 0rem;
		}
	}

	.search-element {
		margin-block-start: 0px;
		margin-inline-start: 40px;
	}
}

@media (max-width: 991px) {
	.top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
		display: none;
	}

	.header.top-header.hor-top-header {
		border-block-end: 0px;
	}
}

.icon.icon-shape {
	width: 2.5rem;
	height: 2.2rem;
	line-height: 2.2rem;
	text-align: center;
	font-size: 12px;
}

.apexcharts-tooltip.light {
	border: 1px solid #e3e3e3;
	background: rgba(255, 255, 255, 0.96);
	box-shadow: 0 0px 17px -5px rgba(68, 84, 195, 0.5);
}

.chart-dropshadow-primary {
	filter: drop-shadow((-6px) 12px 4px rgba(68, 84, 195, 0.2));
}

.icon-dropshadow {
	filter: drop-shadow(0px 10px 4px rgb(229, 233, 242));
}

.icon-dropshadow-info {
	filter: drop-shadow(0px 4px 4px rgba(69, 170, 242, 0.3));
}

.icon-dropshadow-success {
	filter: drop-shadow(0px 4px 4px rgba(45, 206, 137, 0.3));
}

.icon-dropshadow-danger {
	filter: drop-shadow(0px 4px 4px rgba(255, 91, 81, 0.3));
}

.icon-dropshadow-warning {
	filter: drop-shadow(0px 4px 4px rgba(236, 180, 3, 0.3));
}

table.table-bordered.dataTable td:first-child {
	border-inline-start: 0px solid $border !important;
}

.svg-icon {
	color: #6c757e;
	fill: #6c757e;
}

.header-icon {
	color: #555b6d;
	fill: #555b6d;
}

.search-element {
	.search-icon {
		fill: #555b6d !important;
	}
}

.profile-dropdown .header-icon {
	width: 18px;
	height: 18px;
}

.card-custom-icon.text-success {
	fill: #2dce89;
}

.page-rightheader .breadcrumb {
	padding: 0;
	background: transparent;
}

.under-countdown .countdown {
	color: $white;
	padding: 20px;
	border-radius: 5px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > {
	td:first-child:before,
	th:first-child:before {
		inset-block-start: 12px;
		inset-inline-start: 4px;
		height: 14px;
		width: 14px;
		display: block;
		position: absolute;
		color: white;
		border: 2px solid #4757c4;
		border-radius: 14px;
		box-shadow: none;
		box-sizing: content-box;
		text-align: center;
		text-indent: 0 !important;
		line-height: 14px;
		content: "+";
	}
}

.cover-image {
	background-size: cover !important;
}

.timelineleft {
	position: relative;
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none;

	&:before {
		content: "";
		position: absolute;
		inset-block-start: 0;
		inset-block-end: 0;
		width: 2px;
		background: rgba(152, 166, 173, 0.3);
		inset-inline-start: 31px;
		margin: 0;
		border-radius: 2px;
	}

	> li {
		position: relative;
		margin-inline-end: 0;
		margin-block-end: 15px;
	}
}

.timelineleft > li:before {
	content: " ";
	display: table;
}

.timelineleft > {
	li:after {
		content: " ";
		display: table;
	}

	.timeleft-label > span {
		font-weight: 600;
		padding: 5px 10px;
		display: inline-block;
		background-color: $white;
		color: $white;
		border-radius: 4px;
	}

	li {
		&:after {
			clear: both;
		}

		> .timelineleft-item {
			-webkit-box-shadow: 0 1px 1px $black-1;
			box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
			border-radius: 7px;
			margin-block-start: 0;
			background: $white;
			color: #444;
			margin-inline-start: 60px;
			margin-inline-end: 0;
			padding: 0;
			position: relative;
			border: 1px solid #ebeaf1;

			> {
				.time {
					color: #999;
					float:$float-right;
					padding: 12px;
					font-size: 12px;
				}

				.timelineleft-header {
					margin: 0;
					color: #555;
					border-block-end: 1px solid #ebeaf1;
					padding: 0.75rem 1.25rem;
					font-size: 16px;
					line-height: 1.1;

					&.no-border {
						border-block-end: 0px solid #ebeaf1;
					}

					> a {
						font-weight: 500;
						font-size: 14px;
					}
				}
			}
		}
	}
}

.timelineleft-body img {
	width: 150px;
	height: 100px;
	border-radius: 3px;
}

.timelineleft > li > .timelineleft-item > {
	.timelineleft-body,
	.timelineleft-footer {
		padding: 0.75rem 1.25rem;
	}
}

.timelineleft-body {
	font-size: 14px;
}

.timelineleft > li > {
	.fa,
	.glyphicon,
	.ion {
		width: 30px;
		height: 30px;
		font-size: 12px;
		line-height: 30px;
		position: absolute;
		color: $white;
		background: #d2d6de;
		border-radius: 50%;
		text-align: center;
		inset-inline-start: 18px;
		inset-block-start: 0;
	}
}

.acc-step-number {
	padding: 0;
	width: 25px;
	height: 25px;
	line-height: 22px;
}

#form .list-group-item {
	padding: 25px;
}

.custom-switch-indicator-lg {
	display: inline-block;
	height: 1.5rem;
	width: 3rem;
	background: #f5f6fb;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: 1px solid #ebeaf1;
	transition: 0.3s border-color, 0.3s background-color;

	&:before {
		content: "";
		position: absolute;
		height: calc(1.5rem - 4px);
		width: calc(1.5rem - 4px);
		inset-block-start: 1px;
		inset-inline-start: 1px;
		background: $white;
		border-radius: 50%;
		transition: 0.3s left;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
	}
}

.custom-switch-input:checked ~ .custom-switch-indicator-lg:before {
	inset-inline-start: calc(1.46rem + 1px);
}

.custom-switch-indicator-xl {
	display: inline-block;
	height: 2rem;
	width: 4rem;
	background: #f5f6fb;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: 1px solid #ebeaf1;
	transition: 0.3s border-color, 0.3s background-color;

	&:before {
		content: "";
		position: absolute;
		height: calc(2rem - 4px);
		width: calc(2rem - 4px);
		inset-block-start: 1px;
		inset-inline-start: 1px;
		background: $white;
		border-radius: 50%;
		transition: 0.3s left;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
	}
}

.custom-switch-input:checked ~ .custom-switch-indicator-xl:before {
	inset-inline-start: calc(1.96rem + 1px) !important;
}

.main-proifle {
	position: relative;
	background: $white;
	padding: 25px;
	padding-block-end: 0;
	margin-block-end: 30px;
	border-block-start: 1px solid $border;
	border-radius: 8px;
	overflow: hidden;

	.tab-menu-heading {
		border-inline-start: 0;
		border-inline-end: 0;
		margin: 0 -30px;
		margin-block-start: 25px;
	}
}

.profile-edit {
	position: relative;
}

.profile-share {
	width: 100%;
	padding: 10px;
	background: $white;
	border-radius: 0 0 5px 5px;

	a {
		background: $white;
		color: #5c678f;
		width: 2.5rem;
		height: 2.5rem;
		line-height: 2.5rem;
		text-align: center;
		border-radius: 100%;
		font-size: 18px;
		border-radius: 50px;
		box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
		border: 1px solid $border;
		vertical-align: middle;
		margin-inline-end: 5px;
		display: inline-block;
	}
}

.main-proifle .profile-icon,
.profile-footer .new,
.pro-option {
	background: $white;
	color: #5c678f;
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	text-align: center;
	border-radius: 100%;
	font-size: 18px;
	border-radius: 50px;
	box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	border: 1px solid $border;
	vertical-align: middle;
	margin-inline-end: 10px;
	display: inline-block;
}

.pro-details li {
	width: 50%;
	float:$float-left;
	margin-block-end: 1rem;
}

.profile-edit textarea {
	background: $white;
	border-radius: 5px 5px 0 0;

	&:focus {
		background: $border;
		border: 1px solid $border;
	}
}

.fill-white {
	fill: transparent;
}

.page-header {
	.breadcrumb-icon {
		margin-block-start: 3px;
		margin-inline-start: 4px;
	}

	.breadcrumb-item {
		.svg-icon {
			width: 20px;
		}

		&:first-child {
			margin-block-start: 0;
		}
	}
}

.form-wizard-title {
	line-height: 25px;
	margin-inline-start: 10px;
}

.footer .social .social-icon {
	width: 40px;
	height: 40px;
	line-height: 40px;
	display: block;
	border: 1px solid $border;
	border-radius: 50%;
	background: $white;
	box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	border: 1px solid $border;
}

.richText {
	.richText-undo,
	.richText-redo {
		border-inline-end: #e2e3e7 solid 1px !important;
	}
}

.modal-open .select2-dropdown {
	z-index: 99999999 !important;
}

.page-leftheader {
	ol {
		padding-inline-start: 0;
		background: none;
		padding: 0;
	}

	.breadcrumb-item {
		+ .breadcrumb-item::before {
			content: "";
			font-family: Material Design Icons;
			color: $primary;
			opacity: inherit;
			padding-inline-start: 0;
		}

		margin-block-start: 0;

		a {
			color: $color;
		}
	}
}

@media (max-width: 991px) {
	.page-header {
		display: block;

		.page-leftheader ol {
			padding-block-start: 0;
			margin-block-end: 12px;
		}
	}

	.page-rightheader h6 {
		font-size: 10px;
	}
}

.dash1-card {
	p {
		font-size: 14px;
	}

	position: absolute;

	.ratio {
		position: absolute;
		inset-inline-end: -10px;
		inset-block-start: 20px;
		padding: 5px 15px;
		background-color: #222222;
		font-size: 13px;
		line-height: 20px;
		font-weight: 600;
		color: $white;
		border-radius: 15px 0px 0px 15px;
	}

	.ratio-text {
		position: absolute;
		inset-block-start: 55px;
		inset-inline-end: 5px;
		font-size: 13px;
	}
}

.dot-label {
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 100%;
	margin-inline-end: 5px;
}

.header-dropdown-list .ps__thumb-y {
	opacity: 0;
}

.page-header.main-pageheader {
	margin-block-end: 0;
}

.page-rightheader .breadcrumb-item {
	a {
		color: $color;
	}

	+ .breadcrumb-item {
		padding-inline-start: 0;

		&::before {
			content: "\e933";
			font-family: feather !important;
			color: $color;
			opacity: inherit;
		}
	}
}

.custom-btns .btn {
	border: 1px solid $black-1;
	text-align: center;
	background: 0 0;
	font-size: 20px;
	color: rgba(255, 255, 255, 0.9);
	margin: 0 5px;
	border-radius: 50%;
	background: $black-1;
	padding: 0.5rem;
	width: 3rem;
	height: 3rem;
	line-height: 2rem;
}

.widget-user-image {
	height: 100px;
	width: 100px;
	clear: both;
	margin: 0 auto;
	display: block;
	border-radius: 100%;
	box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
	-moz-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
	-webkit-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
	-ms-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
	position: relative;
}

.header .nav-link.icon i {
	color: #5c678f;
}

.mail-inbox .list-group-item {
	border: 0 !important;
}

.user-details {
	.user-icon {
		background: $white;
		color: #5c678f;
		width: 2.5rem;
		height: 2.5rem;
		line-height: 2.5rem;
		text-align: center;
		border-radius: 100%;
		font-size: 18px;
		border-radius: 50px;
		box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
		border: 1px solid $border;
		vertical-align: middle;
		margin-inline-end: 10px;
		display: inline-block;
	}

	span.h6 {
		vertical-align: middle;
	}
}

.item7-card-desc .fe {
	color: #5c678f;
}

.nav-link.icon .header-icon {
	font-size: 25px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	width: 36px;
	height: 36px;
	background: transparent;
	padding: 9px;
}

.profile-dropdown {
	img {
		width: 40px;
		height: 40px;
		box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
		border: 2px solid #f0f0f2;
	}

	.nav-link {
		padding-inline-start: 5px;
		min-width: 2.5rem;
	}
}

.hor-header {
	.search-element {
		margin-inline-start: 1rem;
	}

	border-block-end: 1px solid $border;
}

@media (min-width: 992px) {
	.horizontalMenucontainer .header.top-header {
		box-shadow: none;
	}
}

*::-ms-backdrop,
.btn-icon.btn-svg .svg-icon {
	height: 16px;
}

*::-ms-backdrop,
.item7-card-img {
	height: 100%;
}

*::-ms-backdrop,
.main-content {
	height: 100%;
}

*::-ms-backdrop,
.media-body.time-title1 {
	display: block !important;
}

*::-ms-backdrop,
.media .media-body {
	display: table-cell;
}

*::-ms-backdrop,
.message-feed.right .media-body {
	display: table-cell;
}

*::-ms-backdrop,
.message-feed.media .media-body {
	display: block;
}

*::-ms-backdrop,
.main-chat-body .media .media-body {
	display: -ms-flexbox;
}

*::-ms-backdrop,
.main-chat-list .media-body {
	display: block;
}

*::-ms-backdrop,
.card-img-top {
	height: 250px;
}

*::-ms-backdrop,
.image10 {
	height: 250px;
}

*::-ms-backdrop,
.card-image1 {
	height: 250px;
}

*::-ms-backdrop,
.sweet-alert .sa-info.sa-icon::after {
	margin-inline-start: 37px !important;
	inset-block-start: 17px !important;
}

*::-ms-backdrop,
.main-contact-info-header .media-body {
	display: block;
}

*::-ms-backdrop,
.main-contact-info-body .media-body {
	display: -ms-flexbox;
}

*::-ms-backdrop,
#media3 .media-body,
#media1 .media-body {
	display: block;
}

*::-ms-backdrop,
.page-single {
	display: block !important;
}

*::-ms-backdrop,
.page,
.page-content {
	display: flex !important;
}

*::-ms-backdrop,
.page-content img {
	min-width: 50%;
}

*::-ms-backdrop,
.chat-conatct-list .option-dots {
	padding: 2px 12px !important;
}

.sidenav-toggled .header.py-4 .header-brand .header-brand-img.desktop-lgo {
	display: flex !important;
	margin: 0;
}

*::-ms-backdrop,
.chat-conatct-list .media {
	overflow: visible;
}

.dash1 {
	color: $white !important;
	content: "";
	position: absolute;
	width: 100%;
	background-image: linear-gradient(to right, $primary, $primary-06);
}

.dash2 {
	color: $white !important;
	content: "";
	position: absolute;
	width: 100%;
	background-image: linear-gradient(to right, #1d976c, #2fd38a);
}

.dash3 {
	color: $white !important;
	content: "";
	position: absolute;
	width: 100%;
	background-image: linear-gradient(to right, #fa5420, #f6a800);
}

.dash4 {
	color: $white !important;
	content: "";
	position: absolute;
	width: 100%;
	background-image: linear-gradient(to right, #5b73e8, #44c4fa);
}

.activity-timeline {
	border: 1px solid transparent;
	width: 36px;
	height: 36px;
	text-align: center;
	display: inline-block;
	font-size: 14px;
	line-height: 36px;
	border-radius: 50%;
	position: absolute;
	background-color: #f0f3fa;
}

.activity-timeline-content {
	margin-inline-start: 52px;
}

.activity-timeline-content span a:hover {
	text-decoration: underline;
}

ul.recent-activity {
	position: relative;
}

.recent-activity li {
	padding: 0rem 1.5rem 0rem 1.5rem;
}

.header .main-header-end .nav-link.icon {
	padding: 2.2rem 0.75rem !important;
	border-inline-end: 1px solid $border;
	margin: 0px;
}

.header .profile-dropdown {
	padding: 0.95rem;
	border-inline-end: 1px solid $border !important;
	border-inline-end: 0px;

	.nav-link {
		padding: 0;
	}
}

#fullscreen-button {
	border-inline-start: 1px solid $border;
}

@media (min-width: 992px) {
	.navbar-toggler {
		display: none;
	}
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.125rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
}

.navbar-toggler-icon {
	color: $primary;
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}

.demo-icon {
	border-inline-end: 0px !important;
}

@media (max-width: 568px) {
	.header-brand-img {
		height: 2rem;
		inset-block-start: 22px;
	}

	.dark-mode .header-brand-img.dark-logo {
		margin-block-start: 0px !important;
	}
}

@media (max-width: 991.98px) {
	.header-brand-img {
		height: 2.5rem;
		inset-block-start: 15px;
	}

	.dark-mode .header-brand-img.dark-logo {
		margin-block-start: 0px !important;
	}
	.navbar-collapse.responsive-navbar {
		.header-search .btn {
			position: absolute;
			inset-inline-end: 0;
			inset-block-start: 4px;
		}
	}
}

@media (max-width: 991.98px) {
	.register-2 {
		.header-brand-img {
			display: block !important;
		}
	}

	.navbar-toggler.navresponsive-toggler {
		padding: 0px !important;

		&:focus {
			box-shadow: none;
		}

		i {
			margin-block-start: 10px !important;
		}
	}

	.demo-icon .header-icon {
		margin-block-start: 16px !important;
	}

	.responsive-navbar .dropdown-menu.show {
		padding: 0.5rem 0rem !important;
	}

	.app-header .form-control:focus {
		border-color: #dfdfef;
	}

	.responsive-navbar .collapsing .dropdown-menu {
		display: none !important;
	}

	.responsive-navbar .collapse.show .dropdown-menu.show {
		display: block !important;
	}

	.header .main-header-end .nav-link.icon {
		padding: 1.8rem 0.3rem !important;
	}

	.header .profile-dropdown {
		padding: 0.7rem 0.5rem 0.7rem 0.5rem;
		border-inline-end: 1px solid $border !important;
	}

	.header-message,
	.header-notify,
	.profile-dropdown,
	.search-element {
		display: none !important;
	}

	.app-header .header-brand .header-brand-img,
	.mobile-logo .dark-logo {
		display: none;
	}

	.desktop-lgo {
		display: block !important;
		margin: 0 auto;
		text-align: center;
		inset-inline-start: 0;
		inset-inline-end: 0;

		position: absolute;
	}

	.app-sidebar__toggle {
		border-inline-end: 0px !important;
	}

	#fullscreen-button {
		display: none;
	}

	.app-header {
		padding-block-end: 0px !important;
	}

	.nav-link.icon .header-icon {
		font-size: 25px;
		line-height: 30px;
		text-align: center;
		vertical-align: middle;
		width: 36px;
		height: 36px;
		background: transparent;
		padding: 8px;
		margin-block-start: 4px;
	}

	.app-header .header-brand {
		margin-inline-end: 0;
	}

	.page-header {
		margin-block-start: 10px !important;
	}

	.app-sidebar__toggle {
		margin: 0px;
		padding: 1rem 0.75rem !important;
		font-size: 1.2rem;
		border-inline-end: 1px solid $border;
		position: relative;
	}

	.pulse {
		display: block;
		position: absolute;
		inset-block-start: 18px;
		inset-inline-end: 17px;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background: #f72d66;
		cursor: pointer;
		animation: pulse 2s infinite;
	}

	.side-badge {
		inset-block-start: 13px !important;
	}

	.app-header .header-brand {
		padding-block-start: 12px;
	}

	.header-notify .show.dropdown-menu {
		inset-block-start: 45px;
	}

	.app-header {
		z-index: 9999;
	}

	.dropdown-menu.show {
		inset-block-start: 59px;
		inset-inline-start: 0px;
		border-end-end-radius: 8px !important;
		border-end-start-radius: 8px !important;
		border: 1px solid $border;
		box-shadow: -8px 12px 18px 0 rgba(15, 34, 58, 0.1);
		padding: 0px !important;
	}

	.dropdown-menu-arrow.dropdown-menu-start:before {
		display: none !important;
	}

	.dropdown-menu-arrow.dropdown-menu-start:after {
		display: none !important;
	}

	.close-btn {
		position: absolute;
		inset-inline-end: 25px;
		font-size: 20px;
		inset-block-start: 12px;
		color: #728096;
	}
}

@media (min-width: 992px) {
	.search-element {
		margin-inline-start: 10px;
		inset-block-start: 14px;
	}
}

.widget-user-image img {
	box-shadow: 0 0 0 4px #eeeef3 !important;
	border: 3px solid #f5f6f8 !important;
}

.zoom img:hover {
	transform: scale(0.96) !important;
	transition: 0.3s ease;
}

.no-border input[type="text"] {
	background-color: none;
	outline: 0;
	font-size: 14px;
	width: 150px;
	height: 30px;
	color: $primary;
}

.shopping-carttable .input-group .input-group-btn:first-child {
	border: 1px solid $border;
	border-inline-end: 0;
	height: 37px;
	border-radius: 7px 0 0 7px;
}

.shopping-carttable .input-group .input-group-btn:last-child {
	border: 1px solid $border;
	border-inline-start: 0;
	height: 37px;
	border-radius: 0 7px 7px 0;
}

.register-3 {
	background-image: url(../images/media/51.jpg) !important;
	background-size: cover !important;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	position: relative;

	hr {
		border-block-start: 1px solid $white-3 !important;
	}

	.hrregister3 {
		margin-block-end: 0.5rem !important;
		margin-block-start: 0.5rem !important;
		background-color: transparent;
	}

	.form-control {
		background-color: $black-1;
		border: 1px solid rgba($white, 0.05);
		color: $white;
		border-inline-start: 0;
	}

	.form-control:focus {
		border-color: rgba($white, 0.05);
		color: $white;
		background-color: $black-1;
	}

	.input-group-text {
		background-color: $black-1;
		border-color: $white-1;
		color: $white-6;
	}

	.input-text1 {
		margin-inline-start: 0 !important;
	}

	input {
		&::placeholder {
			color: $white-5;
		}
	}

	.custom-control-label {
		&:before {
			border: 1px solid $white-1;
			background-color: rgba(0, 0, 0, 0.15);
			color: $white-5;
		}
		&:focus {
			border-color: $white-1;
		}
	}

	.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
		border: 1px solid $white-1;
		background-color: $black-2;
	}

	.btn-list .btn {
		background-color: $white-1;
		color: rgba(255, 255, 255, 0.82);
		border: 1px solid $white-1;
	}
}

.register-2 {
    background: url(../images/media/50.jpg) !important;
	background-size: cover !important;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;

	&:before {
		width: 100%;
		height: 100%;
		content: "";
		position: absolute;
		background-color: $primary-09 !important;
		inset-inline-start: 0;
		inset-inline-end: 0;
		inset-block-start: 0;
		inset-block-end: 0;
	}

	.form-control:focus {
		border-color: $border !important;
	}

	hr.divider:after {
		content: "OR";
		display: block;
		position: relative;
		inset-block-start: -1.5em;
		font-size: 11px;
		padding: 0 0.25em;
		background: $primary;
		color: $white;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		z-index: 9;
		inset-inline-start: 0;
		inset-inline-end: 0;
		line-height: 30px;
		margin: 0 auto;
		font-weight: 500;
		border: 1px solid rgb(206, 205, 220);
		text-align: center;
	}

	.divider {
		border-block-start: 1px solid $white-3;
	}
}

.comming {
    background-image: url(../images/media/50.jpg) !important;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;

	&:before {
		width: 100%;
		height: 100%;
		content: "";
		position: absolute;
		background-color: rgba(102, 77, 201, 0.8) !important;
		inset-inline-start: 0;
		inset-inline-end: 0;
		inset-block-start: 0;
		inset-block-end: 0;
	}
}

.register1 {
	position: relative;

	.form-control:focus {
		border-color: $border !important;
	}

	.custom-content .text-white-50 {
		color: rgba(255, 255, 255, 09) !important;
	}
}

.register-1 {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 999;
	border-start-start-radius: 7px;
    border-start-end-radius: 0px;
    border-end-end-radius: 0px;
    border-end-start-radius: 7px;
	overflow: hidden;
}

.register-1:before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(102, 77, 201, 0.85);
	inset-inline-start: 0;
	inset-inline-end: 0;
	inset-block-start: 0;
	inset-block-end: 0;
}

.register-1 .custom-content {
	position: relative;
}

.construction {
	background-image: url(../images/media/32.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	position: relative;
	z-index: 999;
}

.construction:before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(#2c3e50, #bdc3c7);
	opacity: 0.5;
	inset-inline-start: 0;
	inset-inline-end: 0;
	inset-block-start: 0;
	inset-block-end: 0;
}

.construction .custom-content {
	position: relative;
}

.construction .countdown {
	li:nth-child(1) {
		background-image: linear-gradient(to right, $primary, #876eef);
	}

	li:nth-child(2) {
		background-image: linear-gradient(to right, #1d976c, #47b182);
	}

	li:nth-child(3) {
		background-image: linear-gradient(to right, #fa5420, #ea7f5d);
	}

	li:nth-child(4) {
		background-image: linear-gradient(to right, #5b73e8, #43a0c7);
	}
}

.bg-contacthead {
	border-radius: 0 8px 0 0;
	background-image: url(../images/media/35.jpg);
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
}

.bg-contacthead:before {
	content: "";
	background:  linear-gradient(to right top, $primary, $primary-09, $primary-09, $primary, $primary-09);
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	inset-inline-start: 0px;
	inset-block-start: 0px;
	opacity: 0.7;
	border-radius: 0 8px 0 0 !important;
}

.main-contact-info-header .media {
	position: relative;
}

.bg-gradient {
	background: url(../images/media/34.jpg) !important;
	position: relative;
	background-position: center;
	background-size: cover;
}

.bg-gradient {
	&:before {
		content: "";
		position: absolute;
		background: linear-gradient(to right top, $primary, $primary-09, $primary-09, $primary, $primary-09) !important;
		width: 100%;
		height: 100%;
		inset-block-start: 0;
		inset-inline-start: 0;
		opacity: 0.8;
		border-radius: 0.3rem;
		border-end-start-radius: 0px;
		border-end-end-radius: 0px;
	}

	p,
	small {
		position: relative;
	}

	.option-dots {
		position: relative;
		color: $white !important;
	}
}

.app-header .container-fluid {
	width: 100%;
}

@media (max-width: 1440px) and (min-width: 1024px) {
	.recent-activity {
		height: 420px !important;
		overflow: auto;
	}
}

.table-inbox tr:last-child {
	border-block-end-color: $border !important;
}

.register-icons .btn:hover {
	.fa {
		color: rgb(255, 255, 255) !important;
	}
}

.dark-mode {
	@media (min-width: 992px) {
		.sidenav-toggled .app-sidebar__logo {
			padding: 13px 9px !important;
		}
	}
}

.btn-close {
	font-size: 20px;
	background-image: none !important;
	margin-block-start: -4px;
}

.dark-layout {
	display: none;
}

@media (min-width: 992px) {
	.theme-layout {
		border-inline-end: 0px !important;
	}

	.dark-mode .theme-layout {
		border-inline-end: 0px !important;
	}
}

.btn-primary-color:focus {
	box-shadow: none !important;
}

@media (max-width: 480px) {
	.responsive-navbar .badge {
		margin: 0px;
	}
	.notification:before, .notification .notification-time, .notification .notification-icon {
		display: none;
	}
	.notification .notification-body{
		margin-inline-start: 0 !important;
		margin-inline-end: 0 !important;
	}
	.fc-scrollgrid-sync-inner, .fc .fc-daygrid-day-number {
		font-size: 0.8em !important;
	}
	.notification-time-date {
		display: block !important;
	}
	.notify-time {
		display: none;
	}
	.profile-cover, .search-tabs {
		.tabs-menu1 ul li {
			margin: 10px;
			a {
			padding-block: 5px;
			}
		}
	}
	.nav-justified .nav-item {
		flex-grow: 0;
	}
}

@media (min-width: 768px) {
	#checkoutsteps .checkoutline {
		height: 3px;
		background-color: #eaedf7;
		-webkit-transform: translateY(1.7rem);
		transform: translateY(1.7rem);
		margin: 0 11%;
		position: relative;
		z-index: 0;
		inset-block-start: 28px;
	}
	
	.vertical-tabs {
		border-inline-start: 0 !important
	}
}

@media (max-width: 767px) {
	.vertical-tabs {
		border-block-start: 0 !important;
	}
}

.vertical-tabs {
	&.tabs-menu-body {
		border-radius: 5px 0 5px 5px;
	}
}

#checkoutsteps ul {
	list-style: none !important;
	padding: 0;
	margin: 0;
	display: flex;
}

#checkoutsteps .steps ul[role="tablist"] li {
	display: flex;
	z-index: 0;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	flex-basis: auto;
	font-weight: 500;
	font-size: 1.1rem;
}

#checkoutsteps > .steps a {
	display: block;
	width: auto;
	background-color: transparent;
	color: #2d2c40;
	font-size: 16px;
	font-weight: 400;
	padding: 14px 0;
	text-decoration: none;
	border-radius: 30px;
	cursor: default;
	text-align: center;
	outline: none;
}

.wizard > .steps > ul li .current-info {
	display: none;
}

#checkoutsteps > .steps .current a .number {
	background: $primary;
	color: #ffffff;
}

#checkoutsteps .item .thumb {
	display: inline-flex;
	width: 90px;
	height: 90px;
	justify-content: center;
	align-items: center;
	border: 1px solid #f2f2f2;
}

#checkoutsteps .item .left {
	display: flex;
	align-items: center;
}

#checkoutsteps .item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-block-end: 30px;
	border-block-end: 1px solid #e6e6e6;
	margin-block-end: 30px;
}

#checkoutsteps .item .purchase {
	text-decoration: none;
}

#checkoutsteps .item .purchase {
	display: inline-block;
	margin-inline-start: 21px;
}

#checkoutsteps .total {
	float:$float-right;
	color: $primary;
}

#checkoutsteps > .steps {
	position: relative;
	display: block;
	width: 100%;
	border-radius: 3px;
	padding: 0;
}

#checkoutsteps.wizard {
	border-radius: 10px;
}

.wizard > .content > .title {
	font-size: 18px;
	color: #8f9cc0;
	font-weight: 700;
	margin-block-end: 5px;
	display: none;
}

.wizard > .content > .body {
	float: none;
	position: static;
	width: auto;
	height: auto;
}

.wizard > .content > .title.current {
	display: none !important;
}

.card-pay .tabs-menu.nav li {
	border-inline-end: 1px solid $border;

	&:last-child {
		border-inline-end: 0px;
	}
}

@media (min-width: 320px) and (max-width: 480px) {
	.wizard>.steps>ul {
		display: block !important;
	}

	.wizard>.steps>ul li {
		margin-inline-start: 0 !important;
		margin-inline-end: 0 !important;
		margin-block-end: 3px !important;
	}

	.wizard>.steps a {
		justify-content: start !important;
	}

	#checkoutsteps .checkoutline {
		display: none;
	}
}

.card-pay {
	.tabs-menu li {
		width: 33.3%;
		display: block;
	}
}

.card-pay .tabs-menu li a.active {
	background: $primary;
	color: #ffffff;
}

.card-pay .tabs-menu li a {
	padding: 0.7rem 1rem;
	display: block;
	text-align: center;
}

.card-pay .tabs-menu {
	margin-block-end: 25px;
	border-radius: 5px;
	overflow: hidden;
}

@media (min-width: 768px) {
	#checkoutsteps > .steps .number {
		background-color: #eaedf7;
		color: #9197ab;
		font-size: 20px;
		font-weight: 600;
		width: 45px;
		height: 45px;
		border-radius: 50%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin: 15px auto;
	}
}

@media (max-width: 991px) {
	#checkoutsteps > .steps .number {
		background-color: #eaedf7;
		color: #9197ab;
		font-size: 15px;
		font-weight: 600;
		width: 35px;
		height: 35px;
		border-radius: 50%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin: 15px auto;
	}
}

@media (max-width: 991px) {
	#checkoutsteps > .steps a {
		font-size: 12px !important;
	}
}

@media (max-width: 991px) {
	#checkoutsteps .checkoutline {
		height: 3px;
		background-color: #eaedf7;
		-webkit-transform: translateY(1.7rem);
		transform: translateY(1.7rem);
		margin: 0 11%;
		position: relative;
		z-index: 0;
		inset-block-start: 20px;
	}
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
	padding: 15px;
}

.header .profile-dropdown .dropdown-menu {
	min-width: 10rem;
}


.apexcharts-xaxis text,
.apexcharts-yaxis text {
	fill: #8897a5;
	font-size: 12px;
	font-family: "Poppins", sans-serif;
}

.Recent-transactions-img {
	width: 37px;
	height: 37px;
	text-align: center;
	justify-content: center;
	line-height: 37px;
	margin-inline-end: 12px;
}

@media (min-width: 992px) {
	.theme-layout {
		border-inline-start: 1px solid $border;
	}
}

.page-rightheader .btn-pill {
	border-radius: 0.25rem;
}

.tabs-menu .shop-des-tabs li .active {
	color: $primary !important;
	border: 1px solid $primary;
}

.user-contact-list {
	.option-dots:focus {
		background-color: #f0f0f2;

		i {
			color: $primary;
		}
	}

	.dropdown-menu {
		border-radius: 0.25rem;
	}

	.card-header.bg-gradient {
		border-radius: 0.3rem !important;
		border-end-end-radius: 0px;
		border-end-start-radius: 0px;
	}
}

.media {
	margin-block-start: 0px !important;
}

.calendar-image {
	margin-block-start: 2.5rem;
}

.dataTables_length .form-select {
	border: 1px solid $border;
	color: #495047;
}

table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable td:first-child {
	border-inline-start-width: 0px !important;
}

#delete-datatable {
	tr {
		cursor: pointer;

		&.selected {
			background: #f3f6f8;
		}
	}
}

.progress-bar-custom {
	background-color: #6563ff;
}

.ql-bubble .ql-tooltip {
	background-color: $white !important;
}

.ql-bubble .ql-toolbar button.ql-active {
    color: $dark-color;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
	border-block-end: 6px solid $white !important;
}

#validationCustom04 {
	border: 1px solid $border;
}

hr:not([size]) {
	height: 0px !important;
}

.example {
	.btn:hover {
		.badge {
			background-color: white !important;
			color: #495047;
		}
	}
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 38px !important;
}

.multi-wrapper .item-1:hover {
	color: $primary !important;
}

@media (max-width: 991px) {
	.invoicedetailspage {
		margin-block-start: 10px;
	}
}

@media (max-width: 500px) {
	.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
		margin-block-start: 5px;
	}
}

.purchase {
	color: #495047 !important;
}

.register-3 {
	&:before {
		width: 100%;
		height: 100%;
		content: "";
		position: absolute;
		background-color: rgba(102, 77, 201, 0.1) !important;
		inset-inline-start: 0;
		inset-inline-end: 0;
		inset-block-start: 0;
		inset-block-end: 0;
	}
}

@media (max-width: 991px) {
	.register1 .bg-white {
		border-start-start-radius: 7px !important;
		border-end-start-radius: 7px !important;
	}
}

@media (max-width: 991px) {
	.register-3 {
		.header-brand-img {
			display: block !important;
		}
	}
}

#left-defaults,
#right-defaults,
#left-events {
	touch-action: none;
}

#spark1,
#spark2,
#spark3,
#spark4 {
	.apexcharts-tooltip .apexcharts-marker,
	.apexcharts-area-series .apexcharts-area,
	.apexcharts-line {
		pointer-events: none;
		fill: transparent;
	}
}

.legend {
	width: 8px;
	height: 8px;
	display: block;
	border-radius: 2px;
	margin-inline-end: 10px;
	margin-block-start: 6px;
}

.morris-legends {
	margin-block-end: 0;
	list-style: none;
	text-align: center;
}

.bg-primary-light-1 {
	background-color: #8976d5;
}

.bg-primary-light-2 {
	background-color: #ab9de1;
}
.bg-secondary-light-1 {
	background-color: #657ced;
}

.bg-secondary-light-2 {
	background-color: #92a3f5;
}

.bg-success-light-1 {
	background-color: #5dd59f;
}

.bg-success-light-2 {
	background-color: #85e0b7;
}

.bg-warning-light-1 {
	background-color: #ffbb33;
}

.bg-warning-light-2 {
	background-color: #ffcc66;
}

.bg-danger-light-1 {
	background-color: #f37272;
}

.bg-danger-light-2 {
	background-color: #f7a1a1;
}

.btn-animation .btn-loaders:after {
	content: "";
	-webkit-animation: loader 500ms infinite linear;
	animation: loader 500ms infinite linear;
	border: 2px solid $white;
	border-radius: 50%;
	border-inline-end-color: transparent !important;
	border-block-start-color: transparent !important;
	display: block;
	height: 1.4em;
	width: 1.4em;
	position: absolute;
	inset-inline-end: 20px !important;
	inset-block-start: calc(50% - (1.4em / 2));
	-webkit-transform-origin: center;
	transform-origin: center;
	position: absolute !important;
}

.btn-animation .loading {
	margin-inline-end: 20px;
}

.btn-animation .btn-loaders {
	color: $white !important;
	pointer-events: none;
	position: relative;
	padding-inline-end: 46px;
}

.pos-static {
	position: static;
}

#tabs-style4 {
	.tabs-menu ul li a {
		margin: 3px 15px;
	}
	.nav.panel-tabs{
		justify-content: center;
	}
}

@media (max-width: 991px) {
	.register-icons {
		margin-block-end: 20px;
	}
}

@media (max-width: 500px) {
	.btn-close {
		margin-block-start: 6px;
	}
}

@media (max-width: 480px) {
	.badge {
		margin: 0 !important;
	}
	.intl-tel-input input {
		width: 180px !important;
	}
}

.nav-link {
	color: $primary;
}

@media (max-width: 480px) {
	.tabs-menu ul li {
		margin: 0px;
	}

	#tabs-style4 .tabs-menu ul li a {
		margin: 3px 0px 3px 0px;
	}

	.pro-details li {
		width: 100%;
		margin-block-end: 1.5rem;
	}
	.multi-wrapper .item-group-1 .group-label {
		line-height: 25px !important;
		height: 60px !important;
	}
	.multi-wrapper .item-1 {
		height: 48px !important;
		line-height: 18px !important;
	}
	.ui-datepicker-multi .ui-datepicker-group, .ui-datepicker-multi .ui-datepicker-group table {
		width: 100% !important;
	}
}

@media (max-width: 568px) {
	.card-pay .tabs-menu li {
		width: 100%;
	}

	.construction .display-3 {
		font-size: 2.5rem;
	}

	.pro-user-username {
		margin-block-start: 1.5rem;
	}
}

.widget-user-image1 {
	.avatar {
		width: 9rem;
		height: 9rem;
	}
}

@media (max-width: 1025px) {
	.pro-user-username {
		margin-block-start: 1.5rem;
	}
}

@media (max-width: 327px) {
	.profile-share button.pull-right {
		float: none;
	}

	.comming {
		.display-2 {
			font-size: 2.5rem;
		}
	}
}

@media (max-width: 380px) {
	#checkoutsteps .actions.clearfix ul {
		display: initial;
		text-align: center;
		li{
			margin-block: 0.25rem;
		}
	}
	.iti {
		width: 170px !important;
	}
	
	.country-text {
		display: none;
	}

	.header-avatar1 .country {
		margin-block-end: 0 !important;
	}
}

@media (max-width: 991px) {
	.main-profile-contact-list {
		.media {
			margin-block-start: 15px !important;
		}
	}
}

#spark1,
#spark2,
#spark3,
#spark4 {
	.apexcharts-canvas {
		width: auto !important;
	}
}

.apexcharts-tooltip.light {
	border: 1px solid transparent !important;
	background: rgba(255, 255, 255, 0.1) !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
	color: $black;
}

.apexcharts-tooltip.light .apexcharts-tooltip-title {
	background: rgba(255, 255, 255, 0.1) !important;
	border-block-end: 1px solid transparent !important;
}

.apexcharts-yaxis-texts-g {
	color: $white !important;
}

.country {
	width: 1.1rem !important;
	height: 0.8rem !important;
	line-height: 1.4rem !important;
}

.country-selector {
	padding: 0.3rem;
	border-inline-end: 1px solid $border !important;
}

.country-selector .nav-link {
	color: #495046;

	&:hover {
		color: #495046 !important;
	}
}

@media (min-width: 991.98px) and (max-width: 1400px) {
	.hor-header .header-search {
		min-width: 14rem !important;
		border: 1px solid $border !important;
	}

}

@media (max-width: 1400px) {
	.wizard {
		> .steps > ul li {
		  .title {
			display: none;
		  }
		}
	}
}

@media (max-width: 1279px) and (min-width: 992px) {
	#smartwizard-3 .nav-tabs .nav-link {
		padding: 1rem 1.6rem;
	}
}

@media (max-width: 992.98px) {
	.hor-header .header-search .btn {
		inset-block-start: 5px !important;
	}
}

@media (min-width: 992px) {
	.hor-header.header.top-header {
		padding-inline-start: 0px !important;
	}
}

.demo-icon .switcher-icon {
	fill: $white !important;
	color: $white !important;
}

.toast {
	max-width: 100%;
	width: 300px;
	overflow: hidden;
	font-size: 0.875rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid $black-1;
	box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1) !important;
	backdrop-filter: blur(10px);
	opacity: 0;
}

.toast-header {
	display: flex;
	align-items: center;
	padding: 0.55rem 0.75rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-block-end: 1px solid $border;
	border-start-start-radius: 0;
	border-start-start-radius: 0;
}

.toast-header .btn-close {
	font-weight: 500;
	margin-block-start: 0px;
	margin-inline-end: 0px;
	font-size: 20px;
}

.toast-body {
	padding: 1rem;
}

.toast-header.bg-primary {
	color: $white !important;
}

.toast-header h6 {
	margin-block-start: 2px;
}

.demo-static-toast .toast {
	opacity: 1;
}

.toast.show {
	display: block;
}

.toast:not(:last-child) {
	margin-block-end: 0.75rem;
}

.toast-main {
	padding: 1rem;
	border: 1px solid #f0f2f8;
	font-size: 0.9375rem;
	border-radius: 11px;
}

.toast-main {
	.r-10 {
		inset-inline-end: 10px !important;
	}

	.t-10 {
		inset-block-start: 10px !important;
	}
}

.file-images-noraml {
	width: 100px;
	height: 100px;
}

.file-images-md {
	width: 150px;
	height: 150px;
}

.file-images-lg {
	width: 200px;
	height: 200px;
}

.file-image-1 {
	display: inline-table;
	margin: 4px;
	position: relative;
	border: 1px solid #e9edf4;
	border-radius: 5px;
	margin-block-end: 20px;
}

.file-image .icons li {
	display: block;
	margin: 5px;
}

.file-image .icons {
	padding: 0;
	margin: 0;
	list-style: none;
	opacity: 0;
	inset-inline-end: 22px;
	inset-block-start: 14px;
	text-align: center;
	position: absolute;
	inset-block-end: -3px;
	z-index: 1;
	transition: all 0.5s ease 0s;
	text-align: right;
}

.file-name {
	position: absolute;
	inset-block-end: 20px;
	inset-inline-start: 0;
	inset-inline-end: 0;
	color: $white;
	font-size: 16px;
	text-align: center;
	font-weight: 500;
}

.file-image .icons .btn {
	font-size: 15px;
	line-height: 35px;
	text-align: center;
	height: 35px;
	width: 35px;
	margin: 2px 7px;
	padding: 0;
	border-radius: 50px;
	display: block;
	position: relative;
	transition: all 0.3s ease-in-out;
	min-width: inherit;
}

.file-image .icons .btn {
	color: $white;
}

.file-image:hover .icons {
	opacity: 1;
	inset-block-end: 33px;
}

.file-image-1 .file-name-1 {
	position: absolute;
	inset-inline-end: 0;
	inset-inline-start: 0;
	inset-block-end: -22px;
	color: #524d4d;
	font-size: 12px;
	text-align: center;
	font-weight: 500;
}

.file-image-1:hover .icons {
	opacity: 1;
	inset-block-end: 33px;
}

.file-image-1 .icons {
	padding: 0;
	margin: 0;
	list-style: none;
	opacity: 0;
	inset-inline-end: 7px;
	inset-block-start: 6px;
	text-align: center;
	position: absolute;
	inset-block-end: 0;
	z-index: 1;
	transition: all 0.5s ease 0s;
	text-align: right;
}

.file-image-1 .icons .btn {
	font-size: 12px;
	line-height: 26px;
	text-align: center;
	height: 25px;
	width: 25px;
	margin: 4px 1px;
	padding: 0;
	border-radius: 50px;
	display: block;
	position: relative;
	transition: all 0.3s ease-in-out;
	color: $white;
	min-width: inherit;
}

.notification {
	list-style-type: none;
	padding: 0;
	position: relative;
}

.notification > li {
	position: relative;
	min-height: 50px;
	padding: 15px 0;
}

.notification .notification-time {
	position: absolute;
	inset-inline-start: -7px;
	width: 18%;
	text-align: end;
	inset-block-start: 30px;
}

.notification .notification-time .date {
	line-height: 16px;
	font-size: 13px;
	margin-block-end: 4px;
	color: #8f8fb1;
}

.notification .notification-time .date,
.notification .notification-time .time {
	display: block;
	font-weight: 500;
}

.notification .notification-time .time {
	line-height: 24px;
	font-size: 21px;
	color: #8f8fb1;
}

.notification .notification-time .date,
.notification .notification-time .time {
	display: block;
	font-weight: 500;
}

.notification .notification-icon {
	inset-inline-start: 15%;
	position: absolute;
	width: 10%;
	text-align: center;
	inset-block-start: 41px;
}

.notification .notification-icon a {
	text-decoration: none;
	width: 18px;
	height: 18px;
	display: inline-block;
	border-radius: 50%;
	background: $white;
	line-height: 10px;
	color: $white;
	font-size: 14px;
	border: 3px solid $primary;
	transition: border-color 0.2s linear;
}

.notification-time-date {
	color: #8f8fb1;
	font-size: 16px;
	font-weight: 500;
	display: none;
}

.notification .notification-body {
	box-shadow: 0px 8px 16px rgb(0 0 0 / 3%);
	margin-inline-start: 25%;
	margin-inline-end: 18%;
	background: $white;
	position: relative;
	padding: 14px 20px;
	border-radius: 6px;
}

.notification:before {
	content: "";
	position: absolute;
	inset-block-start: 15px;
	inset-block-end: 15px;
	width: 3px;
	background: rgba(108, 95, 252, 0.2);
	inset-inline-start: 20%;
	margin-inline-start: -1px;
}

.notify-time {
	position: absolute;
	inset-block-start: auto;
	inset-block-end: auto;
	inset-inline-start: auto;
	inset-inline-end: 25px;
}

.product-label {
	margin-block-end: 0.5rem;
	padding: 0.4em 0.6em;
	font-size: 75%;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 7px;
	background: rgba(178, 177, 183, 0.1);
	float:$float-right;
}

.list-group-item a {
	color: #282f53;
}

.media-body {
	display: block;
}

.media-body {
	zoom: 1;
	overflow: hidden;
	width: auto;
	flex: 1;
}

#statistics svg,
.apexcharts-canvas {
	width: 100% !important;
}

.file-attach {
	.btn-close {
		margin-block-start: 0;
	}
}

select:disabled {
	opacity: 0;
}

.login-page .demo-icon {
	position: absolute;
	inset-inline-start: auto;
	inset-inline-end: 60px;
	inset-block-start: 60px;
	z-index: 1;
}

.login-page .demo-icon svg {
	color: $white !important;
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
	background: $black-1;
	border-radius: 50px;
	padding: 0px;
}

.login-page {
	.desktop-lgo {
		display: block;
	}
	.dark-logo {
		display: none;
	}
}
.user-details {
	li {
		display: flex;
		align-items: center;
		word-wrap: break-word!important;
		word-break: break-word!important;
		span {
			margin-block-end: 0;
		}
	}
}

.onoffswitch2-checkbox:checked + .onoffswitch2-label {
    background-color: $primary;
}
.onoffswitch2-checkbox:checked + .onoffswitch-label2, .onoffswitch2-checkbox:checked + .onoffswitch2-label:before {
   border-color: $primary;
}
.pull-right {
    float: $float-right !important;
}

.todo-list2 {
	.rounded-pill {
		padding-block-start: 0.15em;
	}
}

.filelist-img {
	width: 100px;
	height: 100px;
}

.input-bg-picker {
	padding: 0;
}

.main-content.hor-content {
	min-height: calc(100vh - 50px) !important;
}

.todo-list2,.e-panel {
	.option-dots {
		background: $background;
		font-size: 16px;
		width: 2rem;
		height: 2rem;
		line-height: 2rem;
	}
}

.login-page {
	&.color-header, &.gradient-header, &.dark-header, &.light-header {
		.form-control::placeholder {
			color: #b3bdca !important;
		}
	}
}


.input-group.input-text1>:last-child {
    margin-inline-start: 0 !important;
}


.alert-light .alert-link {
	color: #686868;
  }
  
  .btn-outline-dark.focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
  }
  
  .dropstart .dropdown-toggle::before {
	border-block-start: 0.3em solid transparent;
	border-inline-end: 0.3em solid;
	border-block-end: 0.3em solid transparent;
  }
  
  .dropdown-item {
	.bs-popover-end .popover-arrow::after,
	.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
	  border-inline-end-color: $white;
	}
  }
[hidden] {
	display: none !important;
}

a.bg-blue-dark:focus {
	background-color: #2b4f80 !important;
}

.page-rightheader {
	.btn {
		margin-inline-end: 0.25rem;
	}
}

.country-text {
	margin-inline-start: 0.25rem;
}


@media (min-width: 992px) and (max-width: 1108px) {
	.country-selector {
		.country-text {
			display: none;
		}
	}
}

@media (min-width: 992px) and (max-width: 1050px) {
	.country-selector {
		.nav-link {
			min-width: 3rem;
		}
	}
}

/*---- Custom-styles End------*/