/* ----- Sidemenu------*/.app-sidebar {
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05) !important;
}



@media (min-width: 992px) {
	.app.sidenav-toggled {
		.app-content {
			margin-inline-start: 0;
		}

		.app-sidebar {
			inset-inline-start: 250px;
		}

		.app-sidebar__overlay {
			visibility: hidden;
		}
	}

	.app-sidebar__user {
		margin-block-start: 74px;
	}
}

@media (max-width: 991px) {
	.side-menu {
		margin-block-start: 0px !important;
	}

	.app {
		overflow-x: hidden;

		.app-sidebar {
			inset-inline-start: -265px;
			margin-block-start: 71px !important;
		}

		.app-sidebar__overlay {
			visibility: hidden;
		}

		&.sidenav-toggled {
			.app-content {
				margin-inline-start: 0;
			}

			.app-sidebar {
				inset-inline-start: 0;
			}

			.app-sidebar__overlay {
				visibility: visible;
			}
		}

	}

	.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
		line-height: 1;
	}
}


.app-sidebar .ps__thumb-y {
	inset-inline-end: 0;
}

.app-content {
	margin-block-start: 50px !important;
	margin-block-end: 0 !important;
	-webkit-transition: margin-inline-start 0.3s ease;
	-o-transition: margin-inline-start 0.3s ease;
	transition: margin-inline-start 0.3s ease;
	overflow: hidden;

	.side-app {
		padding-inline: 1.5rem;
		padding-block-start: 25px;
		padding-block-end: 1.5rem;
	}
}

.footer .container {
	width: 100%;
	padding-inline-end: 0.75rem;
	padding-inline-start: 0.75rem;
	margin-inline-end: auto;
	margin-inline-start: auto;
}

@media (min-width: 992px) {
	.app-content {
		margin-inline-start: 250px;
		padding-block-end: 1rem;
	}
}

@media (max-width: 991px) {
	.app-content {
		margin-block-start: 50px;
		min-width: 100%;
	}
}


@media print {
	.app-content {
		margin: 0;
		padding: 0;
		background-color: $white;
	}
}

.light-text {
	font-weight: 300 !important;
}

.semibold-text {
	font-weight: 600 !important;
}

.line-head {
	padding-block-end: 10px;
	border-block-end: 1px solid #ddd;
}

/*----- Componant: Top Navigation Bar ----- */

.app-header {
	position: fixed;
	inset-inline-start: 0;
	inset-inline-end: 0;
	inset-block-start: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 999999;
	box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
	transition: padding-inline-start 0.3s ease;
	border-block-end: 1px solid $border !important;
}

@media (min-width: 992px) {
	.app-header {
		padding-inline-start: 250px;
		padding-block-end: 0px;
	}

	.sidebar-mini.sidenav-toggled .app-header {
		padding-inline-start: 70px;
	}
}

@media print {
	.app-header {
		display: none;
	}
}

.app-header__logo {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	color: $white;
	text-align: center;
	font-family: 'Niconne';
	padding: 0 15px;
	font-size: 26px;
	font-weight: 400;
	line-height: 50px;

	&:focus,
	&:hover {
		text-decoration: none;
	}
}

@media (min-width: 992px) {
	.app-header__logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		display: block;
		width: 250px;
	}
}

.app-sidebar__toggle {
	a {
		color: #b6c1d9;
	}

	.header-icon {
		line-height: 42px;
		text-align: center;
		vertical-align: middle;
		width: 38px;
		height: 38px;
		padding: 8px;
	}

	padding: .85rem 1rem;
	font-size: 1.2rem;
	border-inline-end: 1px solid $border;
	position: relative;
	margin-inline-start: -12px;

	&:focus,
	&:hover {
		text-decoration: none;
	}
}



@media (max-width: 991px) {
	.app-sidebar__toggle {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
}

.app-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-block-end: 0;
	-webkit-box-pack: right;
	-ms-flex-pack: right;
	justify-content: flex-right;
}

@media (min-width: 992px) {
	.app-nav {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
	}
}

.app-nav__item {
	display: block;
	padding: 15px;
	line-height: 20px;
	color: #a8a8a8;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;

	&:hover,
	&:focus {
		background: $black-1;
		color: #f6f6f6;
	}
}

.app-search {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	align-self: center;
	margin-inline-end: 15px;
	padding: 10px 0;
}

@media (max-width: 480px) {
	.app-search {
		display: none;
	}
}

.app-search__input {
	border: 0;
	padding: 5px 10px;
	padding-inline-end: 30px;
	border-radius: 2px;
	background-color: rgba(255, 255, 255, 0.8);
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;

	&::-webkit-input-placeholder,
	&:-ms-input-placeholder,
	&::-ms-input-placeholder,
	&::placeholder {
		color: rgba(0, 0, 0, 0.4);
	}
}

.app-search__button {
	position: absolute;
	inset-inline-end: 0;
	inset-block-start: 10px;
	inset-block-end: 10px;
	padding: 0 10px;
	border: 0;
	color: rgba(0, 0, 0, 0.8);
	background: none;
	cursor: pointer;
}

.app-notification {
	min-width: 270px;
}

.app-notification__title {
	padding: 8px 20px;
	text-align: center;
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
}

.app-notification__footer {
	padding: 8px 20px;
	text-align: center;
	background-color: #eee;
}

.app-notification__content {
	max-height: 220px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background: $black-2;
	}
}

.app-notification__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 8px 20px;
	color: inherit;
	border-block-end: 1px solid #ddd;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;

	&:focus,
	&:hover {
		color: inherit;
		text-decoration: none;
		background-color: $border;
	}
}

.app-notification__message,
.app-notification__meta {
	margin-block-end: 0;
}

.app-notification__icon {
	padding-inline-end: 10px;
}

.app-notification__message {
	line-height: 1.2;
}

.app-sidebar {
	position: fixed;
	inset-block-start: 0;
	inset-block-end: 0;
	inset-inline-start: 0;
	margin-block-start: 0px;
	width: 250px;
	max-height: 100%;
	z-index: 999999;
	background: $white;
	border-inline-end: 1px solid $border;
	box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
	-webkit-transition: left 0.3s ease, width 0.3s ease;
	transition: left 0.3s ease, width 0.3s ease;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background: $black-2;
	}
}

@media print {
	.app-sidebar {
		display: none;
	}
}

@media (max-width: 991px) {
	.app-sidebar__overlay {
		position: fixed;
		inset-block-start: 0;
		inset-inline-start: 0;
		inset-block-end: 0;
		inset-inline-end: 0;
		z-index: 9;
	}
}

.app-sidebar__user {
	.dropdown-menu {
		inset-block-start: 10px !important;
	}

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: $color;
	width: 100%;
	padding: 20px;
	display: inline-block;
	border-block-end: 1px solid $border;
}

.sidenav-toggled .app-sidebar__user .user-pic {
	margin: 0px 0px 0px 0;
}

.app-sidebar__user .user-pic {
	margin-block-end: 12px;
}

.app-sidebar__user-avatar {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-inline-end: 15px;
}

.app-sidebar__user-name {
	&.text-sm {
		font-size: 12px;
		font-weight: 400;
	}

	font-size: 17px;
	line-height: 1.3;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-block-end: 0;
	overflow: hidden;
	font-weight: 600;
	font-size: 15px;
	margin-block-start: 5px !important;
}

.app-sidebar__user-designation {
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-block-end: 0;
	overflow: hidden;
	font-weight: 600;
	font-size: 15px;
	margin-block-start: 5px !important;
}

.side-menu {
	margin-block-end: 0;
	padding-block-end: 40px;
	margin-block-start: 90px;
}

.side-menu__item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-block: 3px;
	padding-inline-start: 10px;
	padding-inline-end: 10px;
	font-size: 20px;
	font-weight: 400;
	color: $color;
	border-radius: 0.25rem;
	margin-inline-start: 10px;
	margin-inline-end: 10px;
	margin-block-end: 3px;

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
		background: $background;
		color: $color;

		.side-menu__icon {
			fill: $primary  !important;
		}
	}

	.fe-chevron-right {
		font-size: 0.95rem;
	}
}

.sidenav-toggled {
	.side-menu__item:focus {
		background: transparent !important;

		.side-menu__icon {
			fill: $color;
		}
	}
}

.sidenav-toggled1 {
	.side-menu__item:focus {
		background: #F3F6F8 !important;

		.side-menu__icon {
			fill: $white;
		}
	}
}

@media (min-width: 992px) {
	.app.sidebar-mini.sidenav-toggled .side-menu__label {
		display: none !important;
		position: relative;
		padding: 0;
		min-width: 100%;
		margin: 0;
		inset-inline-start: 0;
		opacity: 1;
		background: transparent;
		font-size: 14px;
		box-shadow: none;
	}
}

.slide-item {

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.slide.is-expanded a.slide-item {
	-webkit-transition: all 0.3s;
	transition: all 0.3s;

	&:hover {
		margin-inline-start: 5px;
		text-decoration: none;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
	}
}

.side-menu .side-menu__icon {
	font-size: 1.24rem;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	width: 36px;
	height: 36px;
	padding: 8px;
	margin-inline-end: 0.5rem;

}

.side-menu__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 25px;
}

.side-menu__label {
	white-space: nowrap;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: .6px;
	opacity: 1;
	height: auto;
	transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.slide.is-expanded {
	.slide-menu {
		display: block;
	}
	
	.angle {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}

.slide-menu {
	display: none;
	padding: 0;
	font-size: .8rem !important;
}

.slide-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-block-start: 10px;
	padding-inline-end: 14px;
	padding-block-end: 10px;
	padding-inline-start: 44px;
	font-size: 13px;
	color: $color;

	.icon {
		margin-inline-end: 5px;
	}
}

.angle {
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	font-size: 12px;
}

@media (min-width: 992px) {
	.sidebar-mini.sidenav-toggled {

		.app-sidebar__user-name,
		.app-sidebar__user-designation,
		.angle,
		.app-sidebar__user-name,
		.user-notification,
		.app-sidebar__user-name,
		.user-info {
			display: none;
		}

		.sidebar-mini.sidenav-toggled.user-notification::before {
			background: transparent;
			display: none;
		}

		.sidebar-navs {
			display: none;
		}

		.app-sidebar__user {
			inset-block-start: .5rem;
			inset-inline-end: 30px !important;
			margin-block-start: 74px;
		}

		.app-sidebar__user-avatar {
			width: 25px;
			height: 25px;
		}

		.side-menu li .side-menu__item.active:before {
			display: none;
		}

		.app-sidebar__user {
			padding: 4px 0px 0px 0;
			margin-block-end: 0px;
		}

		.profile-img {
			inset-block-start: 0px;
			inset-inline-end: 19px;
		}

		.app-content {
			margin-inline-start: 70px;
		}

		.app-sidebar {
			inset-inline-start: 0;
			width: 71px;
			overflow: hidden;
			-webkit-transition: left 0.3s ease, width 0.3s ease;
			transition: left 0.3s ease, width 0.3s ease;
			max-height: 100%;
		}

		&.sidenav-toggled1 .app-sidebar {
			overflow: visible;
		}

		.side-menu__item {
			overflow: hidden;

			&:hover {
				overflow: visible;

				.side-menu__label {
					opacity: 1;
				}

				+.slide-menu {
					visibility: visible;
				}
			}
		}

		.side-menu__label {
			display: block;
			position: absolute;
			inset-block-start: 0;
			inset-inline-start: 50px;
			min-width: 180px;
			padding-block-start: 12px;
			padding-inline-end: 5px;
			padding-block-end: 12px;
			padding-inline-start: 20px;
			margin-inline-start: -3px;
			line-height: 1;
			opacity: 0;
			background: $white;
			border-start-end-radius: 4px;
			border-end-end-radius: 4px;
			-webkit-box-shadow: 0px 8px 17px $black-2;
			box-shadow: 0px 8px 17px $black-2;
		}

		.slide {
			&:hover {
				.side-menu__label {
					opacity: 1;
				}

				.slide-menu {
					max-height: 100%;
					opacity: 1;
					visibility: visible;
					z-index: 10;
				}
			}

			.side-menu__label {
				border-end-end-radius: 0;
			}
		}

		.slide-menu {
			position: absolute;
			inset-inline-start: 0;
			min-width: 180px;
			opacity: 0;
			border-end-end-radius: 4px;
			z-index: 9;
			visibility: hidden;
			-webkit-transition: visibility 0.3s ease;
			-o-transition: visibility 0.3s ease;
			transition: visibility 0.3s ease;
		}
	}

	.app.sidebar-mini.sidenav-toggled {
		.side-menu__item {
			display: block;
			padding: 10px 0;
			margin: 0 auto;
			text-align: center;
			border-inline-start: 0;
			border-radius: 0;
		}

		.side-menu_label {
			display: block;
			font-size: 12px;
		}

		.side-menu__label {
			display: block;
			position: relative;
			padding: 0;
			min-width: 100%;
			margin: 0;
			inset-inline-start: 0;
			opacity: 1;
			background: transparent;
			font-size: 14px;
			box-shadow: none;
		}
	}

	.sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
		margin: 0 auto;
	}

	.app.sidebar-mini.sidenav-toggled .nav-badge {
		position: absolute;
		inset-block-start: 8px;
		inset-inline-end: 28px;
		padding: 0.2rem 0.4rem;
		font-size: 11px;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 {
		.app-sidebar {
			width: 250px;
			-webkit-transition: left 0.3s ease, width 0.3s ease;
			transition: left 0.3s ease, width 0.3s ease;
			max-height: 100%;

			.app-sidebar__user {
				.dropdown-menu {
					inset-block-start: 10px !important;
				}

				display: inline-block;
				align-items: center;
				width: 100%;
				padding: 20px;
				display: inline-block;
				border-block-end: 1px solid $border;
			}
		}

		.sidebar-navs {
			display: block;
		}

		.app-sidebar {
			.app-sidebar__user-avatar {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 auto;
				flex: 0 0 auto;
				margin-inline-end: 15px;
				display: inline-block;
			}

			.app-sidebar__user-name {
				&.text-sm {
					font-size: 12px;
					font-weight: 400;
					display: inline-block;
					width: 100%;
					height: auto;
					margin: 0 !important;
				}

				font-size: 17px;
				display: inline-block;
				line-height: 1.3;
				white-space: nowrap;
				overflow: hidden;
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
				margin-block-end: 0;
				overflow: hidden;
				font-weight: 600;
				color: #e5e9ec;
				font-size: 15px;
				margin-block-start: 5px !important;
				display: inline-block;
			}
		}
	}

	.app-sidebar__user-designation {
		white-space: nowrap;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		margin-block-end: 0;
		overflow: hidden;
		font-weight: 600;
		color: #e5e9ec;
		font-size: 15px;
		margin-block-start: 5px !important;
		display: inline-block;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
		.side-menu {
			margin-block-end: 0;
			padding-block-end: 40px;
		}

		.side-menu__item {
			position: relative;
			display: flex !important;
			display: -webkit-box;
			display: -ms-flexbox;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			line-height: 1.40625rem;
			vertical-align: middle;
			font-size: 20px;
			font-weight: 400;
			-webkit-transition: border-start-color 0.3s ease, background-color 0.3s ease;
			-o-transition: border-start-color 0.3s ease, background-color 0.3s ease;
			transition: border-start-color 0.3s ease, background-color 0.3s ease;
			padding-block-start: 2px;
			padding-inline-end: 22px;
			padding-block-end: 2px;
			padding-inline-start: 5px;
			border-radius: 0.25rem;
			margin-block-start: 0px;
			margin-inline-end: 10px;
			margin-block-end: 3px;
			margin-inline-start: 10px;
		}

		.slide-item.active {
			text-decoration: none;
			color: $color;
		}
	}

	.slide-item {

		&:hover,
		&:focus {
			text-decoration: none;
			color: $primary;
		}
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item.active {
		text-decoration: none;
	}

	.slide-item {
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
		.slide.is-expanded a {
			text-decoration: none;
		}

		.side-menu .side-menu__icon {
			margin-inline-end: 0px;
		}

		.side-menu__label {
			display: inline-flex !important;
			font-size: 14px;
			position: unset;
			min-width: inherit;
		}

		.side-menu__icon {
			font-size: 25px;
			line-height: 30px;
			text-align: center;
			vertical-align: middle;
			margin-inline-end: 7px;
			width: 36px;
			height: 36px;
			background: transparent;
			padding: 8px !important;
			margin-inline-end: 0.5rem;
		}

		.side-menu__label {
			white-space: nowrap;
			-webkit-box-flex: 1;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			position: unset;
			display: inline-block;
			font-size: 14px;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
		}

		.slide.is-expanded {
			.slide-menu {
				display: block;
			}

			.angle {
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
			}
		}

		.angle {
			display: inline-block;
		}

		.slide-menu {
			display: none;
			padding: 0;
			font-size: .8rem !important;
			box-shadow: none;
			position: unset;
			inset-block-start: inherit;
			min-width: 0;
			width: 100%;
			opacity: inherit;
			visibility: inherit;
		}

		.slide-item {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			padding-block-start: 10px !important;
			padding-inline-end: 14px !important;
			padding-block-end: 10px !important;
			padding-inline-start: 42px !important;
			font-size: 13px;

			.icon {
				margin-inline-end: 5px;
			}
		}

		.angle {
			-webkit-transform-origin: center;
			-ms-transform-origin: center;
			transform-origin: center;
			-webkit-transition: -webkit-transform 0.3s ease;
			transition: -webkit-transform 0.3s ease;
			-o-transition: transform 0.3s ease;
			transition: transform 0.3s ease;
			transition: transform 0.3s ease, -webkit-transform 0.3s ease;
			font-size: 0.95;
		}

		.app-sidebar__user img {
			margin-block-start: 8px;
			width: 4rem !important;
			height: 4rem !important;
			line-height: 4rem;
			font-size: 1.75rem;
			margin-block-end: 0.25rem !important;
		}

		.user-info {
			display: inline-block;
		}
	}

	.sidenav-toggled.sidenav-toggled1 .app-sidebar__user .user-pic {
		margin-block-start: 0px !important;
		margin-inline-end: 0px !important;
		margin-block-end: 12px !important;
		margin-inline-start: 0px !important;
		
	}
}

.app.sidebar-mini {
	&.sidenav-toggled .side-menu .side-menu__icon {
		position: relative;
	}

	.side-menu_label {
		display: none;
	}
}

.dropdown-menu {
	border-radius: 0;

	&.dropdown-menu-end {
		inset-inline-start: auto;
	}
}

.dropdown-item {

	.fa,
	.icon {
		vertical-align: middle;
	}
}

.app-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: $white;
	margin: -30px -30px 30px;
	padding: 20px 30px;
	-webkit-box-shadow: 0 1px 2px $black-1;
	box-shadow: 0 1px 2px $black-1;

	h1 {
		margin: 0;
		font-size: 24px;
		font-weight: 400;
	}

	p {
		margin-block-end: 0;
		font-style: italic;
	}
}

@media print {
	.app-title {
		display: none;
	}
}

@media (max-width: 480px) {
	.app-title {
		margin: -15px -15px 15px;
		padding: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: left;
		-ms-flex-align: left;
		align-items: flex-left;
	}
}

@media (max-width: 480px) {
	.app-title p {
		display: none;
	}
}

.app-breadcrumb {
	margin-block-end: 0;
	text-align: right;
	font-weight: 500;
	font-size: 13px;
	text-transform: capitalize;
	padding: 0;
	text-align: start;
	padding: 0;
	background-color: transparent;
}

@media (max-width: 480px) {
	.app-breadcrumb {
		margin-block-start: 10px;
	}
}

@media print {
	.tile {
		border: 1px solid #ddd;
	}
}

.sidenav-toggled .app-sidebar__user .avatar-md {
	line-height: 2rem;
	font-size: 1rem;
}

.app-sidebar {
	.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background: rgba(0, 0, 0, 0.05);
	}

	.mCSB_scrollTools {

		.mCSB_dragger .mCSB_dragger_bar,
		.mCSB_draggerRail {
			background: $white-2;
		}
	}
}

.slide-menu a:before {
	content: "\ebaa";
	margin-inline-end: 8px;
	font-family: 'boxicons' !important;
	position: relative;
	font-size: 7px;
}

.side-side-menu__label {
	flex: 1 1 auto;
}

.slide-menu a.active::before, .slide-item a:hover::before, .slide-item a:focus::before {
	color: $primary !important;
	opacity: 1 !important;
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
	.nav-badge {
		position: absolute;
		inset-block-start: 5px;
		inset-inline-start: 57px;
		display: block !important;
		padding: 3px 5px !important;
	}

	.nav-badge1 {
		display: none;
	}
}

.nav-badge {
	border-radius: 30px;
	padding: 0.4em 0.6em;
	font-size: 12px;
}


@media (max-width: 991px) {
	.app-header .header-brand-img.mobile-logo {
		margin-inline-start: 0px;
		margin-block-start: 16px;
	}

	.main-content .side-app {
		padding-block-start: 25px;
		padding-inline: 1rem;
		padding-block-end: 0;
	}

	.app-sidebar {
		margin-block-start: 70px;
	}
}

@media (min-width: 992px) {
	.footer {
		padding: 1.25rem 1.25rem;
	}

	.footer.footer {
		padding-block: 1.25rem;
		padding-inline-end: 1.25rem;
		padding-inline-start: 250px;
	}

	.sidenav-toggled footer.footer {
		padding-block-start: 1.25rem;
		padding-inline-end: 1.25rem;
		padding-block-end: 1.25rem;
		padding-inline-start: 70px;
	}
}

@media (max-width: 991px) and (min-width: 481px) {
	.header-brand {
		margin-inline-start: 0;
		min-width: auto !important;
	}
}

@media (max-width: 991px) {
	.app-sidebar__logo {
		display: none;
	}

}

@media (min-width: 992px) {
	.app-header .header-brand {
		display: none;
	}

	.header.top-header {
		padding-inline-start: 250px;
	}

	.sidenav-toggled {
		.header.top-header {
			padding-inline-start: 70px;
		}

		.app-sidebar__logo {
			padding: 11px 12px;
			width: 70px;
			border-inline-end: 0px !important;
		}

		&.sidenav-toggled1 {
			.app-sidebar__logo {
				border-inline-end: 1px solid $border !important;
			}
		}
	}
}

.app-sidebar__logo {
	padding: 11px 20px;
	border-block-end: 1px solid $border;
	border-inline-end: 1px solid $border;
	text-align: center;
	width: 250px;
	z-index: 999999;
	position: fixed;
	background: $white;
	-webkit-transition: left 0.3s ease, width 0.3s ease;
	-o-transition: left 0.3s ease, width 0.3s ease;
	transition: left 0.3s ease, width 0.3s ease;
}

.close-toggle {
	display: none;
}

.app-sidebar__toggle a i {
	line-height: 1.5;
	margin-block-start: 0;
}

@media (min-width: 992px) {
	.header-brand-img {

		&.dark-logo,
		&.darkmobile-logo,
		&.mobile-logo {
			display: none;
		}
	}

	.sidenav-toggled {
		.header-brand-img {
			&.desktop-lgo {
				display: none;
			}

			&.mobile-logo {
				display: flex;
				margin-block-start: 5px;
				margin: 0 auto;
			}
		}

		&.sidenav-toggled1 .header-brand-img {
			&.mobile-logo {
				display: none;
			}

			&.desktop-lgo {
				display: flex;
				margin: 0 auto;
			}
		}
	}

	.app-sidebar {
		&.app-sidebar3 {
			inset-block-start: 74px;
		}

		&.app-sidebar2 {
			box-shadow: none;
			z-index: 9999;
			height: 73px;
		}
	}

	.sidenav-toggled {
		.app-sidebar-help .help-dropdown {
			display: none;
		}

		&.sidenav-toggled1 .app-sidebar-help .help-dropdown {
			display: flex;
		}

		.app-sidebar-help,
		.app-sidebar .side-item.side-item-category,
		.side-badge {
			display: none;
		}

		&.sidenav-toggled1 .app-sidebar-help {
			width: 250px;
		}
	}

	.app.sidebar-mini.sidenav-toggled {
		.side-menu .side-menu__icon {
			margin-inline-end: 0;
		}

		.slide a.side-menu__item.active:after {
			inset-block-start: 20px;
		}
	}

	.sidenav-toggled.sidenav-toggled1 {

		.app-sidebar-help,
		.app-sidebar .side-item.side-item-category,
		.side-badge {
			display: block;
		}

		.app-sidebar__logo {
			width: 250px;
			border-inline-end: 1px solid $border;
		}
	}
}

.app-sidebar.app-sidebar3 {
	padding-block-end: 70px;
}

.app-sidebar-help {
	position: fixed;
	inset-block-end: 0;
	border-block-start: 1px solid $border;
	background: $white;
	z-index: 11;
	width: 250px;

	.header-icon {
		width: 30px;
		height: 30px;
	}
}

.sub-slide-item {
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	list-style: none;
	padding: 0 0 0 25px !important;
	padding-inline-start: 70px !important;
	height: 28px !important;
	padding-block-start: 19px !important;
	padding-inline-end: 0px !important;
	padding-block-end: 19px !important;
   	padding-inline-start: 60px !important;
	font-size: 12px;
	color: $color;
}

.sub-slide-menu {
	display: none;
	padding: 0;
	font-size: .8rem !important;
	padding-inline-start: 0;
	list-style: none;
}

.slide-menu .sub-slide.active.is-expanded {
	background: rgba(255, 255, 255, 0.09);
}

.sidebar-mini .sub-slide.is-expanded .sub-slide-menu {
	li:last-child {
		padding-block-end: 10px;
	}

	display: block;
}

.sub-side-menu__item {
	padding-inline-start: 44px !important;
	padding-inline-end: 33px !important;
	display: flex;
	align-items: center;
	padding-block: 12px;
	font-size: 13px;
	color: $color;
}

.sub-side-menu__label {
	flex: 1 1 auto;
}

.slide a.sub-side-menu__item {
	-webkit-transition: all 0.3s;
	transition: all 0.3s;

	&:hover {
		margin-inline-start: 5px;
		text-decoration: none;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
	}
}

.sub-angle {
	float:$float-right;
	line-height: 40px;
	-webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.help-dropdown {
	color: $color  !important;
}

.slide.is-expanded a.sub-side-menu__item {
	position: relative;
}

.app-sidebar__user .user-pic img {
	box-shadow: 0 0 0 4px #eeeef3;
	border: 3px solid $background;
	width: 58px;
	height: 58px;
	margin-block-start: 8px;

	&:after {
		content: "";
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: #8760fb;
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
		z-index: -1;
	}
}

.sidemenu-footer {
	.icon {
		margin: 5px;
		padding: 7px 14px;
		text-align: center;
		position: relative;
	}

	.header-icons {
		width: 24px;
		height: 24px;
		color: $color;
		fill: $color;
	}
}

.tooltip {
	z-index: 999 !important;
}

.profile-status {
	content: '';
	position: absolute;
	inset-block-end: 0;
	inset-inline-end: 80px;
	width: 12px;
	height: 12px;
	border-radius: 50px;
	inset-block-start: 51px;
	border: 2px solid $white;
}

.app-sidebar .side-item.side-item-category {
	color: #9993b5;
	font-size: 10px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: .5px;
	margin-block-start: 15px !important;
	margin-inline-end: 0px !important;
	margin-block-end: 15px !important;
	margin-inline-start: 0px !important;
	padding-block-start: 0px !important;
	padding-inline-end: 20px !important;
	padding-block-end: 0px !important;
	padding-inline-start: 20px !important;

	&:not(:first-child) {
		margin-block-start: 25px;
		margin-block-end: 15px;
	}
}

.app-sidebar__user .user-info .user-name {
	margin-block-end: 2px;
	font-size: 15px;
}

.profile-dropdown {
	.nav-link {
		padding-inline-start: 5px;
	}

	img {
		width: 35px;
		height: 35px;
		box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
		border: 2px solid $background;
	}
}

.user-pro-body .dot-label {
	width: 6px;
	height: 6px;
}

.sidebar-navs a {
	background: $white;
	color: #68798b !important;

	padding: 1rem !important;

}

.app-sidebar .sidebar-navs {
	padding-block-start: 10px !important;
	padding-inline-end: 0px !important;
	padding-block-end: 0px !important;
	padding-inline-start: 0px !important;

}

.sidebar-navs {
	.nav li:last-child a {
		margin-inline-end: 0 !important;
	}

	.header-icons {
		color: #5c678f;
		fill: #5c678f;
		font-size: 20px;
		vertical-align: middle;
	}
}

.slide.active.is-expanded .side-menu__item {
	text-decoration: none;
	color: $color;

	border-radius: 50px;
}

.app.sidebar-mini.sidenav-toggled .slide.active.is-expanded .side-menu__item {
	border-radius: 0;
}

@media (min-width: 991px) {
	.sidebar-mini.sidenav-toggled .app-sidebar {
		max-height: 100%;
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
		max-height: 100%;
	}
}

/*--- sub-slide-menu ---*/
.sub-slide-item2 {
	height: 28px !important;
	padding-block-start: 19px !important;
	padding-inline-end: 0px !important;
	padding-block-end: 19px !important;
    padding-inline-start: 60px !important;
	font-size: 12px;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	list-style: none;
}

.sub-slide-menu2 .sub-slide-item2 {
	height: 30px !important;
	padding-block-start: 19px !important;
	padding-inline-end: 0px !important;
	padding-block-end: 19px !important;
    padding-inline-start: 85px !important;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	list-style: none;
}

.sub-slide-menu2 {
	display: none;
}

.sub-slide2.is-expanded .sub-slide-menu2 {
	display: block;
}

.sub-angle2 {
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	position: absolute;
	inset-inline-end: 33px;
	inset-block-start: 13px;
}

.sub-slide2.is-expanded .sub-angle2 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.sub-slide.is-expanded .sub-angle {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	font-size: 12px;
}

.side-menu__item .side-badge {
	padding: .25em .4em !important;
	font-size: 12px;
	font-weight: 500;
	border-radius: 3px;
	line-height: 1;
	color: $white;
}

.side-menu__icon {
	color: #555b6d;
	fill: #555b6d;
}

.sidemneu-icon2 {
	fill: #8e98db;
}

.side-menu-label1 {
	display: none;
}

// --! Sidemenu LTR styles --//  

// -- Closed menu --//

.closed-menu {
	@media (min-width: 992px) {
		&.app.sidenav-toggled .app-sidebar {
			display: none !important;
		}

		&.app.sidenav-toggled {
			.app-content {
				margin-inline-start: 0px !important;
			}

			.main-header1 {
				padding-inline-start: 0px !important;
			}
		}
		&.sidebar-mini.sidenav-toggled .app-header {
			padding-inline-start: 0;
		}
	}
}

// -- !Closed menu --//

// -- Hover submenu --//

.hover-submenu {
	@media (min-width: 992px) {
		&.sidenav-toggled .app-sidebar__logo {
			width: 109px;
		}

		&.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
			border-inline-end: 0 !important;
		}

		&.sidebar-mini.sidenav-toggled .slide-menu {
			transition: none !important;
		}

		.slide a.sub-side-menu__item:hover {
			transition: none !important;
			margin-inline-start: 0 !important;
		}

		.slide a.sub-side-menu__item {
			transition: none !important;

		}

		&.slide-menu li {
			position: relative;
		}

		&.sidebar-mini.sidenav-toggled .app-header {
			padding-inline-start: 110px;
		}

		&.sidenav-toggled-open .slide-menu {
			border-radius: 0px 7px 7px 0px !important;
		}

		.slide-item {
			padding-block-start: 8px !important;
			padding-inline-end: 18px !important;
			padding-block-end: 8px !important;
			padding-inline-start: 43px !important;
		}

		.slide-menu {
			border-radius: 0px 7px 7px 0px;
		}

		&.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
			position: absolute;
			inset-inline-start: 108px;
			min-width: 180px;
			opacity: 0;
			z-index: 9999;
			visibility: hidden;
			inset-block-start: 0;
			display: block;
			max-height: inherit;
		}

		.app-sidebar .slide {
			position: relative;
			display: block;
		}

		&.app.sidenav-toggled .side-menu__item {
			padding-block-start: 8px;
			padding-inline-end: 0px;
			padding-block-end: 9px;
			padding-inline-start: 0;
		}

		&.app.sidenav-toggled1 {
			.ps>.ps__rail-y {
				display: none !important;
			}

			.slide-menu {
				padding-inline-start: 0 !important;
				padding: 10px 0px !important;
				position: absolute;
				background: $white;
				opacity: 1;
				visibility: visible;
				z-index: 999;
				width: 200px;
				inset-inline-start: 110px;
				border: 1px solid $border;
				transition: none !important;
				display: block !important;
				box-shadow: 8px 8px 17px $black;
				inset-block-start: 0;
			}

			.slide:hover .slide-menu {
				visibility: visible;
				display: block !important;
				height: auto !important;
			}

			.app-sidebar {
				overflow: visible !important;
				position: absolute !important;
			}

			.side-menu__label {
				white-space: nowrap;
				display: block;
				position: relative;
				padding: 0;
				margin: 0;
				inset-inline-start: 0;
				color: #7b8191;
				opacity: 1;
				background: transparent;
				font-size: 12px;
				box-shadow: none;
			}

			.side-menu .side-menu__icon {
				margin-block-end: 5px !important;
			}

			.side-menu .slide .side-menu__item.active {
				border-inline-end: 0 !important;
				padding: 10px;
			}

			.main-sidebar-header {

				.desktop-logo,
				.desktop-dark,
				.mobile-dark {
					display: none !important;
				}

				.mobile-logo {
					display: block !important;
				}
			}

			.side-item.side-item-category {
				display: none !important;
			}

			.side-menu__icon {
				margin-inline-end: 0;
				margin-inline-start: 0;
				margin: 0 auto !important;
			}

			.side-menu .slide .side-menu__item.active {
				display: block !important;
				margin: 0 auto !important;
				padding-block-start: 8px !important;
				padding-inline-end: 0px !important;
				padding-block-end: 9px !important;
				padding-inline-start: 0px !important;
				border-radius: 0;
				text-align: center !important;
			}

			.side-menu .slide .side-menu__item {
				display: block !important;
				margin: 0 auto !important;
				padding-block-start: 8px !important;
				padding-inline-end: 0px !important;
				padding-block-end: 9px !important;
				padding-inline-start: 0px !important;
				border-radius: 0;
				text-align: center !important;

				i {
					display: none !important;
				}
			}
		}

		&.app.sidenav-toggled {
			.main-content {
				min-height: 1300px;
			}

			.side-menu__label {
				font-size: 11px !important;
			}

			.app-sidebar {
				position: absolute !important;
			}

			.side-menu .side-menu__icon {
				margin-block-end: 5px;	
			}

			.side-menu__label {
				display: block !important;
			}

			.app-sidebar__toggle .close-toggle {
				display: block;
			}

			.main-header {
				padding-inline-start: 110px;
			}

			.main-sidebar-header {
				width: 110px !important;
			}

			.app-content {
				margin-inline-start: 110px;
			}

			.app-sidebar {
				inset-inline-start: 0;
				width: 110px !important;
			}

			.slide.is-expanded .slide-menu {
				display: none;
			}
		}

		.side-badge {
			display: none;
		}

		.side-header {
			width: 240px;
			inset-inline-start: 0;
			inset-inline-end: 0;
			inset-block-start: 0;
			position: fixed;
			z-index: 1;
		}

		.slide-item:hover,
		.slide-item:focus {
			color: $primary;
		}
	}

	.sub-slide-menu .sub-side-menu__item:before {
		inset-inline-start: 25px !important;
		font-size: 9px;
	}

	@media (min-width: 992px) {
		&.app.sidebar-mini {
			&.sidenav-toggled .logo-icon.mobile-logo {
				margin: 0 auto;
				text-align: center;
			}

			&.sidenav-toggled-open .avatar-xl {
				width: 55px !important;
				height: 55px !important;
			}

			&.sidenav-toggled.sidenav-toggled-open .profile-status {
				inset-inline-end: 22px;
				inset-block-start: 57px;
			}

			&.sidenav-toggled-open {
				.app-sidebar__user .user-info {
					display: none !important;
				}

				#bg-side-text,
				.main-sidebar-header .logo-icon.icon-dark {
					display: none !important;
				}
			}
		}

		&.sidebar-mini.sidenav-toggled .slide:hover .slide-menu {
			max-height: inherit;
			opacity: 1;
			visibility: visible;
			z-index: 10;
		}

		&.sidebar-mini.sidenav-toggled .slide-menu {
			position: absolute;
			inset-inline-start: 89px;
			min-width: 180px;
			transition: none !important;
			inset-block-start: 0;
			background: $white;
			border-radius: 3px;
			display: block;
		}

		&.app.sidenav-toggled1 {
			.header-brand-img.desktop-lgo {
				display: none;
			}

			.header-brand-img.mobile-logo {
				display: block;
			}

			&.sidenav-toggled .side-menu__label {
				display: block !important;
			}

			&.sidenav-toggled .slide:hover .slide-menu {
				max-height: inherit;
				opacity: 1;
				visibility: visible;
				z-index: 10;
			}

			&.sidenav-toggled .slide-menu {
				position: absolute;
				inset-inline-start: 109px;
				min-width: 180px;
				visibility: hidden;
				transition: none !important;
				inset-block-start: 0;
				background: $white;
				border-radius: 3px;
				display: block;
				opacity: 0;
			}

			&.sidenav-toggled .side-badge {
				display: none;
			}

			.app-sidebar .slide-item {
				padding-block-start: 10px !important;
				padding-inline-end: 14px !important;
				padding-block-end: 10px !important;
				padding-inline-start: 25px !important;
			}

			.app-sidebar .sub-side-menu__item {
				padding-inline-start: 24px !important;
			}

			.app-sidebar .sub-slide-item {
				padding-inline-start: 35px !important;
			}

			.app-sidebar .sub-slide-item2 {
				padding-inline-start: 35px !important;
			}

			.app-sidebar .sub-slide-item2 .sub-angle2 {
				inset-block-start: 12px !important;
			}

			.app-sidebar .side-menu .sub-slide2.sub-slide-menu2 .sub-slide-item2 {
				padding-inline-start: 50px !important;
			}
		}
	}
}

// -- !Hover submenu --//

// -- Hover submenu 1 --//

.hover-submenu1 {
	@media (min-width: 992px) {
		&.sidenav-toggled .app-sidebar__logo {
			width: 69px;
		}

		&.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
			border-inline-end: 0 !important;
		}

		&.sidebar-mini.sidenav-toggled .slide-menu {
			transition: none !important;
		}

		.slide .slide-menu .sub-slide a.sub-side-menu__item:hover {
			transition: none !important;
			margin-inline-start: 0 !important;
		}

		.slide a.sub-side-menu__item {
			transition: none !important;

		}

		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item {
			transition: none !important;
		}

		&.sidenav-toggled1 .slide-menu {
			border-radius: 0px 7px 7px 0px !important;
		}

		.slide-menu .side-menu__label1 a:before {
			display: none;
		}

		.slide-menu {
			border-radius: 0px 7px 7px 0px;
		}

		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .slide .slide-menu {
			position: absolute;
			inset-inline-start: 69px;
			min-width: 190px;
			opacity: 0;
			z-index: 9999;
			visibility: hidden;
			inset-block-start: 0;
			display: block;
			max-height: inherit;
		}

		.app-sidebar .slide {
			position: relative;
			display: block;
		}

		&.app.sidenav-toggled .side-menu__item {
			padding-block-start: 8px !important;
			padding-inline-end: 0px !important;
			padding-block-end: 9px !important;
			padding-inline-start: 0px !important;
		}

		&.app.sidenav-toggled1 {
			.ps>.ps__rail-y {
				display: none !important;
			}

			.slide-menu {
				padding-inline-start: 0 !important;
				padding: 10px 0px !important;
				position: absolute;
				background: $white;
				opacity: 1;
				visibility: visible;
				z-index: 999;
				width: 200px;
				inset-inline-start: 110px;
				border: 1px solid $border;
				transition: none !important;
				display: block !important;
				box-shadow: 8px 8px 17px $black;
				inset-block-start: 0;
			}

			.slide:hover .slide-menu {
				visibility: visible;
				display: block !important;
				height: auto !important;
			}

			.app-sidebar {
				overflow: visible !important;
				position: absolute !important;
			}

			.side-menu__label {
				white-space: nowrap;
				display: block;
				position: relative;
				padding: 0;
				margin: 0;
				inset-inline-start: 0;
				color: #7b8191;
				opacity: 1;
				background: transparent;
				font-size: 12px;
				box-shadow: none;
			}

			.side-menu .side-menu__icon {
				margin-block-end: 5px !important;
			}

			.side-menu .slide .side-menu__item.active {
				border-inline-end: 0 !important;
				padding: 10px;
			}

			.main-sidebar-header {

				.desktop-logo,
				.desktop-dark,
				.mobile-dark {
					display: none !important;
				}

				.mobile-logo {
					display: block !important;
				}
			}

			.side-item.side-item-category {
				display: none !important;
			}

			.side-menu__icon {
				margin-inline-end: 0;
				margin-inline-start: 0;
				margin: 0 auto !important;
			}

			.side-menu .slide .side-menu__item.active {
				display: block;
				margin: 0 auto !important;
				padding-block-start: 8px !important;
				padding-inline-end: 0px !important;
				padding-block-end: 9px !important;
				padding-inline-start: 0px !important;
				border-radius: 0;
				text-align: center !important;
			}

			.side-menu .slide .side-menu__item {
				display: block;
				margin: 0 auto !important;
				padding-block-start: 8px !important;
				padding-inline-end: 0px !important;
				padding-block-end: 9px !important;
				padding-inline-start: 0px !important;
				border-radius: 0;
				text-align: center !important;

				i {
					display: none !important;
				}
			}

			.side-menu__label1 {
				display: block !important;
				font-size: 15px;
				font-weight: 500;
				border-block-end: 1px solid $border;
				padding: 10px 20px !important;
				margin-block-end: 6px;
			}
		}

		&.app.sidenav-toggled {
			.main-content {
				min-height: 1300px;
			}

			.side-menu__label {
				font-size: 11px !important;
			}

			.app-sidebar {
				position: absolute !important;
			}

			.side-menu .side-menu__icon {
				margin-block-end: 5px;
			}

			.side-menu__label {
				display: none !important;
			}

			.app-sidebar__toggle .close-toggle {
				display: block;
			}

			.main-header {
				padding-inline-start: 110px;
			}

			.main-sidebar-header {
				width: 110px !important;
			}

			.app-content {
				margin-inline-start: 70px;
			}

			.app-sidebar {
				inset-inline-start: 0;
				width: 70px !important;
			}

			.slide.is-expanded .slide-menu {
				display: none;
			}
		}

		.side-badge {
			display: none;
		}

		.side-header {
			width: 240px;
			inset-inline-start: 0;
			inset-inline-end: 0;
			inset-block-start: 0;
			position: fixed;
			z-index: 1;
		}

		.slide-item:hover,
		.slide-item:focus {
			color: $primary;
		}
	}

	.sub-slide-menu .sub-side-menu__item:before {
		inset-inline-start: 25px !important;
		font-size: 9px;
	}

	@media (min-width: 992px) {
		&.app.sidebar-mini {
			&.sidenav-toggled .logo-icon.mobile-logo {
				margin: 0 auto;
				text-align: center;
			}

			&.sidenav-toggled1 .avatar-xl {
				width: 55px !important;
				height: 55px !important;
			}

			&.sidenav-toggled.sidenav-toggled1 .profile-status {
				inset-inline-end: 22px;
				inset-block-start: 57px;
			}

			&.sidenav-toggled1 {
				.app-sidebar__user .user-info {
					display: none !important;
				}

				#bg-side-text,
				.main-sidebar-header .logo-icon.icon-dark {
					display: none !important;
				}
			}
		}

		&.sidebar-mini.sidenav-toggled .slide:hover .slide-menu {
			max-height: inherit;
			opacity: 1;
			visibility: visible;
			z-index: 10;
		}

		&.sidebar-mini.sidenav-toggled .slide-menu {
			position: absolute;
			inset-inline-start: 89px;
			min-width: 180px;
			transition: none !important;
			inset-block-start: 0;
			background: $white;
			border-radius: 3px;
			display: block;
		}

		&.app.sidenav-toggled1 {
			.header-brand-img.desktop-lgo {
				display: none;
			}

			.header-brand-img.mobile-logo {
				display: block;
			}

			&.sidenav-toggled .side-menu__label {
				display: none !important;
			}

			&.sidenav-toggled .slide:hover .slide-menu {
				max-height: inherit;
				opacity: 1;
				visibility: visible;
				z-index: 10;
			}

			&.sidenav-toggled .slide-menu {
				position: absolute;
				inset-inline-start: 109px;
				min-width: 180px;
				visibility: hidden;
				transition: none !important;
				inset-block-start: 0;
				background: $white;
				border-radius: 3px !important;
				display: block;
				opacity: 0;
			}

			&.sidenav-toggled .side-badge {
				display: none;
			}

			.app-sidebar .slide-item {
				padding-block-start: 10px !important;
				padding-inline-end: 14px !important;
				padding-block-end: 10px !important;
				padding-inline-start: 25px !important;
			}

			.app-sidebar .sub-side-menu__item {
				padding-inline-start: 24px !important;
			}

			.app-sidebar .sub-slide-item {
				padding-inline-start: 35px !important;
			}

			.app-sidebar .sub-slide-item2 {
				padding-inline-start: 35px !important;
			}

			.app-sidebar .sub-slide-item2 .sub-angle2 {
				inset-block-start: 12px !important;
			}

			.app-sidebar .side-menu .sub-slide2.sub-slide-menu2 .sub-slide-item2 {
				padding-inline-start: 50px !important;
			}
		}

		&.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 {
			display: block;
			font-size: 16px;
			font-weight: 700;
			border-block-end: 1px solid #e9edf4;
			padding: 10px 25px !important;
			margin-block-end: 6px;
		}

		.app-sidebar .side-menu-label1 a {
			color: $primary;
		}
	}
}

// -- Hover submenu 1 --//

@media (min-width: 992px) {

	.hover-submenu1.bg-img1,
	.hover-submenu1.bg-img2,
	.hover-submenu1.bg-img3,
	.hover-submenu1.bg-img {
		&.dark-mode .side-header {
			background: transparent !important;
			border-block-end: 0;
			border-inline-end: 0px;
		}
	}
}

@media (min-width:992px) {

	.bg-img1,
	.bg-img2,
	.bg-img3,
	.bg-img4 {
		&.dark-mode.sidebar-mini.sidenav-toggled.hover-submenu1 {
			.side-header {
				background-color: $dark-body;
			}

			@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
				.side-header {
					background-color: transparent;
					backdrop-filter: blur(10px);
					-webkit-backdrop-filter: blur(10px);
				}
			}
		}
	}
}



.header-brand-img {
	margin: 0 auto !important;
}


// -- Icon overlay --//
.icon-overlay {
	@media (min-width: 992px) {
		&.sidenav-toggled1 .app-sidebar .slide .sub-slide a.sub-side-menu__item {
			padding-inline-start: 55px;
		}

		.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item {
			display: flex;
			align-items: center;
			padding-block-start: 10px !important;
			padding-inline-end: 14px !important;
			padding-block-end: 10px !important;
			padding-inline-start: 43px !important;
			font-size: 13px;
		}

		&.sidenav-toggled1.sidenav-toggled.app-sidebar .slide .sub-slide .slide-menu .sub-slide-menu a.sub-slide-item {
				padding-block-start: 19px !important;
				padding-inline-end: 0px !important;
				padding-block-end: 19px !important;
				padding-inline-start: 65px !important;
		}
	}
}

// -- Icons overlay --//

// -- Icon text--//
.icontext-menu {

	@media (min-width: 992px) {
		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
			width: 250px !important;
			text-align: inherit;
		}

		&.sidebar-mini.sidenav-toggled .slide:hover .slide-menu {
			visibility: hidden;
		}

		&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide.is-expanded .slide-menu {
			visibility: visible;
		}
	}

	&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu__item {
		display: flex;
		padding-block-start: 3px !important;
		padding-inline-end: 22px !important;
		padding-block-end: 3px !important;
		padding-inline-start: 5px !important;
		text-align: inherit !important;
	}

	&.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
		width: 250px !important;
		border-inline-end: 1px solid $border;
	}

	&.sidebar-mini .side-menu .side-menu__icon {
		width: 2.5rem !important;
	}

	&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .side-menu__label {
		display: block !important;
		font-size: 14px !important;
	}

	&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu .side-menu__icon {
		margin-inline-end: 0.5rem;
	}

	&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item {
		padding-block-start: 10px !important;
		padding-inline-end: 14px !important;
		padding-block-end: 10px !important;
		padding-inline-start: 43px !important;
	}

	&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .sub-slide2 .sub-slide-item2 {
		padding-block-start: 19px !important;
		padding-inline-end: 0px !important;
		padding-block-end: 19px !important;
		padding-inline-start: 60px !important;
	}
}

// -- !Icon text --//


/*--- Only Demo Purpose scss Icon-Text----*/
@media (min-width: 992px) {
	.icontext-menu {
		&.sidebar-mini.sidenav-toggled .app-sidebar {
			width: 120px;
		}

		&.app.sidebar-mini.sidenav-toggled .side-header {
			width: 120px !important;
		}

		&.sidebar-mini.sidenav-toggled .app-content {
			margin-inline-start: 120px;
		}

		&.app.sidebar-mini.sidenav-toggled {
			.side-menu__item {
				padding: 12px 5px;
				text-align: center;
				display: block;
			}

			.side-menu__label {
				display: block !important;
				font-size: 12px;
				padding-block-start: 0px;
				margin-block-start: 0;
				padding: 0 !important;
			}
		}

		&.sidebar-mini.sidenav-toggled {
			.app-header.header {
				padding-inline-start: 120px;
			}

			.side-menu .side-menu__icon:after {
				inset-block-start: 6px;
			}

			&.sidenav-toggled-open {
				.app-sidebar {
					width: 270px;
				}

				.side-header {
					width: 270px !important;
				}

				.side-menu__item {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					padding: 10px 20px;
					text-align: inherit;
				}

				.side-menu__label {
					display: block !important;
					margin-block-start: 3px;
					font-size: 15px;
				}

				.side-menu .side-menu__icon:after {
					inset-block-start: 12px;
				}
			}
		}

		&.sidenav-toggled .app-sidebar__logo {
			width: 119px;
		}
	}
}

@media (min-width: 992px) {
	.layout-boxed.closed-menu.sidenav-toggled {

		.app-sidebar,
		.app-sidebar__logo {
			width: 0px !important;
			display: none !important;
		}
	}
}

.icontext-menu .app-sidebar__logo {
	padding: 11px 20px !important;
}

@media (min-width: 992px) {
	.icontext-menu.sidenav-toggled.sidenav-toggled1 .header-brand-img.desktop-lgo {
		margin-block-start: 0px !important;
	}

	.rtl.layout-boxed.icontext-menu.sidebar-mini.sidenav-toggled {
		.app-sidebar {
			inset-inline-end: auto !important;
		}

		&.light-header .app-sidebar__toggle {
			border-inline-start: 1px solid $border !important;
		}

		&.color-header .app-sidebar__toggle,
		&.gradient-header .app-sidebar__toggle {
			border-inline-start: 1px solid #7d67d4 !important;
		}

		&.dark-header .app-sidebar__toggle {
			border-inline-start: 1px solid #32394e !important;
		}

		&.dark-mode {
			&.light-header .app-sidebar__toggle {
				border-inline-start: 1px solid $border !important;
			}

			&.color-header .app-sidebar__toggle,
			&.gradient-header .app-sidebar__toggle {
				border-inline-start: 1px solid #7d67d4 !important;
			}

			&.dark-header .app-sidebar__toggle {
				border-inline-start: 1px solid #32394e !important;
			}
		}
	}
}

.sidebar-mini .side-menu {
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
}

.tab-content-double{
	display: none !important;
}

.tab-content-show{
	display: block !important;
}

.side-menu-label1 {
	a:before {
		display: none;
	}
}

.horizontal-switcher {
  display: none;
}

/* ----- Sidemenu End------*/