/*------ Dark-styles------*/

body.dark-mode {
  background-color: $dark-body;
  color: $dark-color;
}

body.dark-mode * {
  &::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-thumb {
    background: $dark-bg;
  }

  &::-webkit-scrollbar-track {
    background: $dark-bg;
  }
}
.dark-mode {

  .slide.is-expanded .slide-menu li a {
    &:hover,
    &:focus {
      color: $white;
    }
  }

  .page-link {
    border: 1px solid $border-dark;
    color: $white;
    background-color: $dark-theme;
    &:focus {
      color: $primary;
    }
  }

  .table-primary, .table-success, .table-warning, .table-danger, .table-info {
    th, td, thead th, tbody+tbody {
      color: $white !important;
    }
  }

  .page-item {
    &.active .page-link {
      color: $white;

      &:hover {
        color: $white-8 !important;
        background-color: $dark-theme;
      }
    }

    &.disabled .page-link {
      background-color: $dark-theme;
      border-color: $border-dark;
      color: $white-3;
    }
  }

  .panel-default > .panel-heading {
    background-color: $dark-theme;
  }

  .accordionjs .acc_section .acc_head {
    background-color: $dark-bg;
  }

  .accordionjs .acc_section {
    border: 1px solid $border-dark;
  }

  .accordionjs .acc_section.acc_active > .acc_head {
    border-block-end: 1px solid $border-dark;
  }

  .panel-default {
    > .panel-heading + .panel-collapse > .panel-body {
      background-color: $dark-bg;
      color: $dark-color!important;
    }

    .list-group-item.active {
      color: $dark-color;
      background-color: $dark-bg;
      border-color: $border-dark !important;
    }
  }

  .panel.panel-primary .list-group-item.active {
    color: $white !important;
    background-color: $primary;
    border-color: $primary;
      &:hover {
        color: $white !important;
        background-color: $primary;
        border-color: $primary;
      }
  }

  .panel-success .list-group-item.active {
    color: $white;
    background-color: #38cb89;
    border-color: #38cb89;

    &:hover {
      color: $white !important;
      background-color: #38cb89;
      border-color: #38cb89;
    }
  }

  .panel-info .list-group-item.active {
    color: $white;
    background-color: #3e80eb;
    border-color: #3e80eb;

    &:hover {
      color: $white !important;
      background-color: #3e80eb;
      border-color: #3e80eb;
    }
  }

  .panel-warning .list-group-item.active {
    color: $white;
    background-color: #ffab00;
    border-color: #ffab00;

    &:hover {
      color: $white !important;
      background-color: #ffab00;
      border-color: #ffab00;
    }
  }

  .panel-danger .list-group-item.active {
    color: $white;
    background-color: #ef4b4b;
    border-color: #ef4b4b;

    &:hover {
      color: $white !important;
      background-color: #ef4b4b;
      border-color: #ef4b4b;
    }
  }

  .panel-default .list-group-item.active {
    &:hover,
    &:focus {
      color: $dark-color;
      background-color: $border-dark;
      border-color: #f0f0f2;
    }
  }

  .panel-title a.accordion-toggle {
    &:before,
    &.collapsed:before {
      color: $white;
    }
  }

  .acc-header a {
    background-color: $border-dark;
    border-color: $border-dark;
  }

  .acc-body {
    border-color: $border-dark;
    background-color: $dark-bg;
  }

  #multiCollapseExample1,
  #multiCollapseExample2 {
    background-color: $dark-bg;
  }

  .acc_content {
    background-color: $dark-bg;
  }

  .alert-success {
    color: $white;
    background-color: #38cb89;
    border-color: #38cb89;

    hr {
      border-block-start-color: #47d394;
    }

    .alert-link {
      color: #172e00;
    }
  }

  .alert-info {
    color: $white;
    background-color: #3e80eb;
    border-color: #3e80eb;

    hr {
      border-block-start-color: #6e9fed;
    }

    .alert-link {
      color: #193c56;
    }
  }

  .alert-warning {
    color: $white;
    background-color: #f3a403;
    border-color: #f3a403;

    hr {
      border-block-start-color: #f3c464;
    }

    .alert-link {
      color: #4d3f05;
    }
  }

  .alert-danger {
    color: $white;
    background-color: #ef4b4b;
    border-color: #ef4b4b;

    hr {
      border-block-start-color: #ef7272;
    }

    .alert-link {
      color: #3f0a09;
    }
  }

  .alert-light {
    color: #818182;
    background-color: #f2f2f3;
    border-color: #f2f2f3;

    hr {
      border-block-start-color: #ececf6;
    }
  }

  .alert-light-success {
    color: #38cb89;
    background-color: #cbf6e2;
    border-color: #cbf6e2;
  }

  .alert-light-danger {
    color: #ef4b4b;
    background-color: #fac8c8;
    border-color: #fac8c8;
  }

  .alert-light-warning {
    color: #ffab00;
    background-color: #fee8bb;
    border-color: #fee8bb;
  }

  .alert-light-info {
    color: #3e80eb;
    background-color: #ccd6f8;
    border-color: #ccd6f8;
  }
  
  .alert .alert-link {
    color: $black;
  }

  .avatar {
    color: $white;
  }

  .avatar-status {
    border: 2px solid $white;
    background: #868e96;
  }

  .avatar-list-stacked .avatar {
    box-shadow: 0 0 0 2px $dark-bg;
  }

  .badge-default {
    background: $border-dark;
    color: $white;
  }

  .shape {
    border-color: rgba(255, 255, 255, 0) #d9534f rgba(255, 255, 255, 0) transparent
  }

  .offer {
    background: $dark-bg;
    border: 1px solid $border-dark;
  }

  .offer-danger {
    border-color: #dc0441;

    .shape {
      border-color: transparent #dc0441 transparent transparent;
    }
  }

  .example .btn:hover .badge {
    background-color: $white-1 !important;
    color: $white-8;
  }

  .badge-gradient-primary {
    color: $white;
    background: linear-gradient(to left, #705ec8 0%, $primary 100%);
  }

  .badge-gradient-success {
    background-image: linear-gradient(to left, rgb(101, 179, 110) 0%, #38cb89 100%);
    color: $white;
  }

  .badge-gradient-secondary {
    background-image: linear-gradient(to left, #b4bdeb 0%, $secondary 100%);
    color: $white;
  }

  .badge-gradient-warning {
    background-image: linear-gradient(to left, #fdc99b 0%, #ffab00 100%);
    color: $white;
  }

  .badge-gradient-info {
    color: $white;
    background-image: linear-gradient(to left, #94b7f0 0%, #3e80eb 100%);
  }

  .badge-gradient-danger {
    color: $white;
    background-image: linear-gradient(to left, #f89191 0%, #ef4b4b 100%);
  }

  .offer-success {
    border-color: #2dce89;
  }

  .offer-primary {
    border-color: $primary;
    .shape {
      border-color: transparent $primary transparent transparent;
    }
  }

  .bg-success-light {
    background-color: rgba(29, 171, 45, 0.15) !important;
    color: #36b37e;
  }

  .bg-warning-light {
    background-color: rgba(255, 171, 0, 0.15) !important;
    color: #ffab00;
  }

  .bg-danger-light {
    color: #f94859;
    background-color: rgba(250, 92, 124, 0.15);
  }

  .bg-info-light {
    color: #0a7ffb;
    background-color: rgba(3, 85, 208, 0.15);
  }

  .bg-pink-light {
    color: #ec82ef;
    background-color: rgba(236, 130, 239, 0.15);
  }

  .bg-cyan-light {
    color: #116e7c;
    background-color: rgba(17, 110, 124, 0.15);
  }

  .bg-purple-light {
    color: #aa4cf2;
    background-color: rgba(170, 76, 242, 0.15);
  }

  .offer-success .shape {
    border-color: transparent #2dce89 transparent transparent;
  }

  .offer-default {
    border-color: #6b6f80;

    .shape {
      border-color: transparent #6b6f80 transparent transparent;
    }
  }

  .offer-info {
    border-color: #45aaf2;

    .shape {
      border-color: transparent #45aaf2 transparent transparent;
    }
  }

  .offer-warning {
    border-color: #ecb403;

    .shape {
      border-color: transparent #ecb403 transparent transparent;
    }
  }

  .shape-text {
    color: $white;
  }

  .bg-gradient-success {
    background-image: linear-gradient(to left, #43c47a 0%, #10ae23 100%);
    color: $white;
  }

  .bg-gradient-warning {
    background-image: linear-gradient(to left, #f8be8d 0%, #ff9a44 100%);
    color: $white;
  }

  .bg-gradient-danger {
    color: $white;
    background-image: linear-gradient(to left, #ff90a0 0%, #fc6076 100%);
  }

  .bg-gradient-info {
    color: $white;
    background-image: linear-gradient(to left, #58ebbb 0%, #0fd796 100%);
  }

  .card-header-pills.nav-pills .nav-link {
    background: transparent;
  }
  #checkoutsteps>.steps a {
    color: $dark-color;
  }

  #checkoutsteps .item .thumb {
    border: 0;
  }

  ::-webkit-input-placeholder {
    color: $white-4;
  }

  .breadcrumb-item {
    + .breadcrumb-item::before {
      color: #cfd1e1;
    }

    &.active {
      color: $dark-color;
    }
  }

  .breadcrumb-item1 {
    &.active {
      color: $dark-color;
    }
  }

  .breadcrumb {
    background: transparent;
  }

  .btn {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:focus,
    &.focus {
      box-shadow: none;
    }
  }

  .btn-success {
    color: $white !important;
    background-color: #38cb89;
    border-color: #38cb89;
    box-shadow: 0 0px 10px -5px rgba(56, 203, 137, 0.5);

    &:hover {
      color: $white !important;
      background-color: #42c48a !important;
      border-color: #42c48a !important;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(56, 203, 137, 0.1);
      background-color: #42c48a !important;
      border-color: #42c48a !important;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #42c48a;
      border-color: #42c48a;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #42c48a;
        border-color: #42c48a;
      }
    }
  }

  .show > .btn-success.dropdown-toggle {
    color: $white;
    background-color: #42c48a;
    border-color: #42c48a;
  }

  .btn-success:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(56, 203, 137, 0.1);
    }
  }

  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(56, 203, 137, 0.1);
  }

  .ql-bubble .ql-tooltip {
    background-color: $dark-theme !important;
  }

  .ql-bubble .ql-toolbar button {
    color: white;
  }

  .btn-info {
    color: $white !important;
    background-color: #3e80eb;
    border-color: #3e80eb;
    box-shadow: 0 0px 10px -5px rgba(91, 127, 255, 0.1);

    &:hover {
      color: $white !important;
      background-color: #3e80eb;
      border-color: #3e80eb;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
      background-color: #3e80eb;
      border-color: #3e80eb;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #3e80eb;
      border-color: #3e80eb;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #3e80eb;
        border-color: #3e80eb;
      }
    }
  }

  .show > .btn-info.dropdown-toggle {
    color: $white;
    background-color: #3e80eb;
    border-color: #3e80eb;
  }

  .btn-info:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }
  }

  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
  }

  .btn-warning {
    color: $white !important;
    background-color: #ffab00;
    border-color: #ffab00;
    box-shadow: 0 0px 10px -5px rgba(255, 171, 0, 0.5);

    &:hover {
      color: $white !important;
      background-color: #feb521;
      border-color: #feb521;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(255, 171, 0, 0.1);
      background-color: #feb521;
      border-color: #feb521;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #feb521;
      border-color: #feb521;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #f3a403;
        border-color: #feb521;
      }
    }
  }

  .show > .btn-warning.dropdown-toggle {
    color: $white;
    background-color: #f3a403;
    border-color: #feb521;
  }

  .btn-warning:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(255, 171, 0, 0.1);
    }
  }

  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(255, 171, 0, 0.1);
  }

  .btn-danger {
    color: $white !important;
    background-color: #ef4b4b;
    border-color: #ef4b4b;
    box-shadow: 0 0px 10px -5px rgba(239, 75, 75, 0.44);

    &:hover {
      color: $white !important;
      background-color: #ef4b4b;
      border-color: #ef4b4b;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(239, 75, 75, 0.1);
      background-color: #ef4b4b;
      border-color: #ef4b4b;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #ef4b4b;
      border-color: #ef4b4b;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #ef4b4b;
        border-color: #ef4b4b;
      }
    }
  }

  .show > .btn-danger.dropdown-toggle {
    color: $white;
    background-color: #ef4b4b;
    border-color: #ef4b4b;
  }

  .btn-danger:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(239, 75, 75, 0.1);
    }
  }

  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(239, 75, 75, 0.1);
  }

  .btn-light {
    color: $dark-color;
    background-color: $border-dark;
    border-color: $border-dark;
    box-shadow: none;

    &:hover {
      color: $dark-color;
      background-color: $border-dark;
      border-color: $border-dark;
    }

    &:focus,
    &.focus {
      box-shadow: none;
      background-color: $border-dark;
      border-color: $border-dark;
    }

    &.disabled,
    &:disabled {
      background-color: $black-1;
      border-color: $white-1 !important;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-color;
        background-color: $black-1;
        border-color: $white-1;
      }
    }
  }

  .show > .btn-light.dropdown-toggle {
    color: $white;
    background-color: $white-1;
    border-color: #f0f0f2;
  }

  .btn-light:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: none;
    }
  }

  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-dark {
    color: $white;
    background-color: #343a40;
    border-color: #343a40;

    &:hover {
      color: $white;
      background-color: #23272b;
      border-color: #1d2124;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #343a40;
      border-color: #343a40;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #1d2124;
        border-color: #171a1d;
      }
    }
  }

  .show > .btn-dark.dropdown-toggle {
    color: $white;
    background-color: #1d2124;
    border-color: #171a1d;
  }

  .btn-dark:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
    }
  }

  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
  }

  .btn-outline-primary {
    color: $primary;
    background-color: transparent;
    border-color: $primary;

    &:hover {
      color: $white !important;
      background-color: $primary;
      border-color: $primary;
    }

    &.disabled,
    &:disabled {
      color: $primary;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white !important;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }

  .btn-outline-secondary {
    color: $secondary;
    background-color: transparent;
    background-image: none;
    border-color: $secondary;
    box-shadow: 0 0px 10px -5px rgba($secondary, 0.5);
    &:hover {
      color: white !important;
      background-color: $secondary;
      border-color: $secondary;
    }
  }

  .btn-outline-success {
    color: #38cb89;
    background-color: transparent;
    border-color: #38cb89;
    box-shadow: 0 0px 10px -5px rgba(56, 203, 137, 0.5);

    &:hover {
      color: $white !important;
      background-color: #38cb89;
      border-color: #38cb89;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
    }

    &.disabled,
    &:disabled {
      color: #38cb89;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white !important;
        background-color: #38cb89;
        border-color: #38cb89;
      }
    }
  }

  .show > .btn-outline-success.dropdown-toggle {
    color: $white !important;
    background-color: #38cb89;
    border-color: #38cb89;
  }

  .btn-outline-success:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
    }
  }

  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
  }

  .btn-outline-info {
    color: #3e80eb;
    background-color: transparent;
    background-image: none;
    border-color: #3e80eb;
    box-shadow: 0 0px 10px -5px rgba(91, 127, 255, 0.5);

    &:hover {
      color: $white !important;
      background-color: #3e80eb;
      border-color: #3e80eb;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }

    &.disabled,
    &:disabled {
      color: #3e80eb;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #3e80eb;
        border-color: #3e80eb;
      }
    }
  }

  .show > .btn-outline-info.dropdown-toggle {
    color: $white;
    background-color: #3e80eb;
    border-color: #3e80eb;
  }

  .btn-outline-info:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }
  }

  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
  }

  .btn-outline-warning {
    color: #ffab00;
    background-color: transparent;
    border-color: #ffab00;
    box-shadow: 0 0px 10px -5px rgba(255, 171, 0, 0.5);

    &:hover {
      color: $white !important;
      background-color: #ffab00;
      border-color: #ffab00;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
    }

    &.disabled,
    &:disabled {
      color: #ffab00;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #ffab00;
        border-color: #ffab00;
      }
    }
  }

  .show > .btn-outline-warning.dropdown-toggle {
    color: $white;
    background-color: #ffab00;
    border-color: #ffab00;
  }

  .btn-outline-warning:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
    }
  }

  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
  }

  .btn-outline-danger {
    color: #dc0441;
    background-color: transparent;
    border-color: #dc0441;
    box-shadow: 0 0px 10px -5px rgba(220, 4, 65, 0.5);

    &:hover {
      color: $white !important;
      background-color: #dc0441;
      border-color: #dc0441;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
    }

    &.disabled,
    &:disabled {
      color: #dc0441;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #dc0441;
        border-color: #dc0441;
      }
    }
  }

  .show > .btn-outline-danger.dropdown-toggle {
    color: $white;
    background-color: #dc0441;
    border-color: #dc0441;
  }

  .btn-outline-danger:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
    }
  }

  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
  }

  .btn-outline-light {
    color: $dark-color;
    background-color: transparent;
    border-color: $border-dark;
    box-shadow: 0 0px 10px -5px #e8ebf21e;

    &:hover {
      color: $dark-color;
      background-color: $dark-bg;
      border-color: $dark-bg;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(22, 22, 22, 0.1);
    }

    &.disabled,
    &:disabled {
      color: $dark-color;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $dark-color;
        background-color: $dark-bg;
        border-color: $dark-bg;
      }
    }
  }

  .show > .btn-outline-light.dropdown-toggle {
    color: $white;
    background-color: #f0f0f2;
    border-color: #f0f0f2;
  }

  .btn-outline-light:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
    }
  }

  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
  }

    
  .btn-outline-dark {
    &.disabled,
    &:disabled {
      color: #343a40;
      background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #343a40;
        border-color: #343a40;
      }
    }
  }

  .show > .btn-outline-dark.dropdown-toggle {
    color: $white;
    background-color: #343a40;
    border-color: #343a40;
  }

  .btn-outline-dark:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
    }
  }

  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
  }

  .btn-link {
    font-weight: 400;
    background-color: transparent;
    color: $primary;

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }

    &:focus,
    &.focus {
      border-color: transparent;
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      color: #868e96;
    }
  }

  .collapse:not(.show) {
    background: transparent;
  }

  .btn-facebook {
    color: $white;
    background-color: #3b5998;
    border-color: #3b5998;

    &:hover {
      color: $white;
      background-color: #30497c;
      border-color: #2d4373;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #3b5998;
      border-color: #3b5998;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #2d4373;
        border-color: #293e6a;
      }
    }
  }

  .show > .btn-facebook.dropdown-toggle {
    color: $white;
    background-color: #2d4373;
    border-color: #293e6a;
  }

  .btn-facebook:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
    }
  }

  .show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
  }

  .btn-twitter {
    color: $white;
    background-color: #1da1f2;
    border-color: #1da1f2;

    &:hover {
      color: $white;
      background-color: #0d8ddc;
      border-color: #0c85d0;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #1da1f2;
      border-color: #1da1f2;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #0c85d0;
        border-color: #0b7ec4;
      }
    }
  }

  .show > .btn-twitter.dropdown-toggle {
    color: $white;
    background-color: #0c85d0;
    border-color: #0b7ec4;
  }

  .btn-twitter:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
    }
  }

  .show > .btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
  }

  .btn-google {
    color: $white;
    background-color: #dc4e41;
    border-color: #dc4e41;

    &:hover {
      color: $white;
      background-color: #d03526;
      border-color: #c63224;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #dc4e41;
      border-color: #dc4e41;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #c63224;
        border-color: #bb2f22;
      }
    }
  }

  .show > .btn-google.dropdown-toggle {
    color: $white;
    background-color: #c63224;
    border-color: #bb2f22;
  }

  .btn-google:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
    }
  }

  .show > .btn-google.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
  }

  .btn-youtube {
    color: $white;
    background-color: #f00;
    border-color: #f00;

    &:hover {
      color: $white;
      background-color: #d90000;
      border-color: #cc0000;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #f00;
      border-color: #f00;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #cc0000;
        border-color: #bf0000;
      }
    }
  }

  .show > .btn-youtube.dropdown-toggle {
    color: $white;
    background-color: #cc0000;
    border-color: #bf0000;
  }

  .btn-youtube:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
    }
  }

  .show > .btn-youtube.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
  }

  .btn-vimeo {
    color: $white;
    background-color: #1ab7ea;
    border-color: #1ab7ea;

    &:hover {
      color: $white;
      background-color: #139ecb;
      border-color: #1295bf;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #1ab7ea;
      border-color: #1ab7ea;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #1295bf;
        border-color: #108cb4;
      }
    }
  }

  .show > .btn-vimeo.dropdown-toggle {
    color: $white;
    background-color: #1295bf;
    border-color: #108cb4;
  }

  .btn-vimeo:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
    }
  }

  .dark-mode {
    .show > .btn-vimeo.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
    }

    .btn-dribbble {
      color: $white;
      background-color: #ea4c89;
      border-color: #ea4c89;
    }
  }

  .btn-dribbble {
    &:hover {
      color: $white;
      background-color: #e62a72;
      border-color: #e51e6b;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #ea4c89;
      border-color: #ea4c89;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #e51e6b;
        border-color: #dc1a65;
      }
    }
  }

  .show > .btn-dribbble.dropdown-toggle {
    color: $white;
    background-color: #e51e6b;
    border-color: #dc1a65;
  }

  .btn-dribbble:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
    }
  }

  .show > .btn-dribbble.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
  }

  .btn-github {
    color: $white;
    background-color: #181717;
    border-color: #181717;

    &:hover {
      color: $white;
      background-color: #040404;
      border-color: black;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #181717;
      border-color: #181717;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: black;
        border-color: black;
      }
    }
  }

  .show > .btn-github.dropdown-toggle {
    color: $white;
    background-color: black;
    border-color: black;
  }

  .btn-github:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
    }
  }

  .show > .btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
  }

  .btn-instagram {
    color: $white;
    background-color: #e4405f;
    border-color: #e4405f;

    &:hover {
      color: $white;
      background-color: #de1f44;
      border-color: #d31e40;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #e4405f;
      border-color: #e4405f;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #d31e40;
        border-color: #c81c3d;
      }
    }
  }

  .show > .btn-instagram.dropdown-toggle {
    color: $white;
    background-color: #d31e40;
    border-color: #c81c3d;
  }

  .btn-instagram:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
    }
  }

  .show > .btn-instagram.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
  }

  .btn-pinterest {
    color: $white;
    background-color: #bd081c;
    border-color: #bd081c;

    &:hover {
      color: $white;
      background-color: #980617;
      border-color: #8c0615;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #bd081c;
      border-color: #bd081c;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #8c0615;
        border-color: #800513;
      }
    }
  }

  .show > .btn-pinterest.dropdown-toggle {
    color: $white;
    background-color: #8c0615;
    border-color: #800513;
  }

  .btn-pinterest:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
    }
  }

  .show > .btn-pinterest.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
  }

  .btn-vk {
    color: $white;
    background-color: #6383a8;
    border-color: #6383a8;

    &:hover {
      color: $white;
      background-color: #527093;
      border-color: #4d6a8b;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #6383a8;
      border-color: #6383a8;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #4d6a8b;
        border-color: #496482;
      }
    }
  }

  .show > .btn-vk.dropdown-toggle {
    color: $white;
    background-color: #4d6a8b;
    border-color: #496482;
  }

  .btn-vk:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
    }
  }

  .show > .btn-vk.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
  }

  .btn-rss {
    color: $white;
    background-color: #ffa500;
    border-color: #ffa500;

    &:hover {
      color: $white;
      background-color: #d98c00;
      border-color: #cc8400;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #ffa500;
      border-color: #ffa500;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #cc8400;
        border-color: #bf7c00;
      }
    }
  }

  .show > .btn-rss.dropdown-toggle {
    color: $white;
    background-color: #cc8400;
    border-color: #bf7c00;
  }

  .btn-rss:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
    }
  }

  .show > .btn-rss.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
  }

  .btn-flickr {
    color: $white;
    background-color: #0063dc;
    border-color: #0063dc;

    &:hover {
      color: $white;
      background-color: #0052b6;
      border-color: #004ca9;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #0063dc;
      border-color: #0063dc;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #004ca9;
        border-color: #00469c;
      }
    }
  }

  .show > .btn-flickr.dropdown-toggle {
    color: $white;
    background-color: #004ca9;
    border-color: #00469c;
  }

  .btn-flickr:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
    }
  }

  .show > .btn-flickr.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
  }

  .btn-blue {
    color: $white;
    background-color: #083dc1;
    border-color: #083dc1;

    &:hover {
      color: $white !important;
      background-color: #1747bd;
      border-color: #1747bd;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #1747bd;
      border-color: #1747bd;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #1747bd;
        border-color: #1747bd;
      }
    }
  }

  .show > .btn-blue.dropdown-toggle {
    color: $white;
    background-color: #1747bd;
    border-color: #1747bd;
  }

  .btn-blue:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
    }
  }

  .show > .btn-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
  }

  .btn-indigo {
    color: $white;
    background-color: #6574cd;
    border-color: #6574cd;

    &:hover {
      color: $white !important;
      background-color: #485ac4;
      border-color: #3f51c1;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #6574cd;
      border-color: #6574cd;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #3f51c1;
        border-color: #3b4db7;
      }
    }
  }

  .show > .btn-indigo.dropdown-toggle {
    color: $white;
    background-color: #3f51c1;
    border-color: #3b4db7;
  }

  .btn-indigo:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
    }
  }

  .show > .btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
  }

  .btn-purple {
    color: $white;
    background-color: #aa4cf2;
    border-color: #aa4cf2;

    &:hover {
      color: $white !important;
      background-color: #923ce6;
      border-color: #8c31e4;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #aa4cf2;
      border-color: #aa4cf2;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #8c31e4;
        border-color: #8526e3;
      }
    }
  }

  .show > .btn-purple.dropdown-toggle {
    color: $white;
    background-color: #8c31e4;
    border-color: #8526e3;
  }

  .btn-purple:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
    }
  }

  .show > .btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
  }

  .btn-pink {
    color: $white;
    background-color: #ec82ef;
    border-color: #ec82ef;

    &:focus,
    &.focus {
      box-shadow: none;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #ec82ef;
      border-color: #ec82ef;
    }
  }

  .show > .btn-pink.dropdown-toggle {
    color: $white;
    background-color: #ec82ef;
    border-color: #ec82ef;
  }

  .btn-pink:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: none;
    }
  }

  .show > .btn-pink.dropdown-toggle:focus {
    box-shadow: none;
  }

  .btn-red {
    color: $white;
    background-color: #dc0441;
    border-color: #dc0441;

    &:hover {
      color: $white;
      background-color: #ac1b1a;
      border-color: #a11918;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #dc0441;
      border-color: #dc0441;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #a11918;
        border-color: #961717;
      }
    }
  }

  .show > .btn-red.dropdown-toggle {
    color: $white;
    background-color: #a11918;
    border-color: #961717;
  }

  .btn-red:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
    }
  }

  .show > .btn-red.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
  }

  .btn-orange {
    color: $white;
    background-color: #fc7303;
    border-color: #fc7303;

    &:hover {
      color: $white;
      background-color: #fd811e;
      border-color: #fc7a12;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #fc7303;
      border-color: #fc7303;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #fc7a12;
        border-color: #fc7305;
      }
    }
  }

  .dark-mode .show > .btn-orange.dropdown-toggle {
    color: $white;
    background-color: #fc7a12;
    border-color: #fc7305;
  }

  .btn-orange:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
    }
  }

  .show > .btn-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
  }

  .btn-yellow {
    color: $white;
    background-color: #ffab00;
    border-color: #ffab00;

    &:hover {
      color: $white;
      background-color: #cea70c;
      border-color: #c29d0b;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #ffab00;
      border-color: #ffab00;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #c29d0b;
        border-color: #b6940b;
      }
    }
  }

  .show > .btn-yellow.dropdown-toggle {
    color: $white;
    background-color: #c29d0b;
    border-color: #b6940b;
  }

  .btn-yellow:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
    }
  }

  .show > .btn-yellow.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
  }

  .btn-green {
    color: $white;
    background-color: #38cb89;
    border-color: #38cb89;

    &:hover {
      color: $white;
      background-color: #15ca7c;
      border-color: #15ca7c;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #38cb89;
      border-color: #38cb89;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #15ca7c;
        border-color: #3e7a00;
      }
    }
  }

  .show > .btn-green.dropdown-toggle {
    color: $white;
    background-color: #15ca7c;
    border-color: #3e7a00;
  }

  .btn-green:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
    }
  }

  .show > .btn-green.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
  }

  .btn-teal {
    color: $white;
    background-color: #2bcbba;
    border-color: #2bcbba;

    &:hover {
      color: $white;
      background-color: #24ab9d;
      border-color: #22a193;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #2bcbba;
      border-color: #2bcbba;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #22a193;
        border-color: #20968a;
      }
    }
  }

  .show > .btn-teal.dropdown-toggle {
    color: $white;
    background-color: #22a193;
    border-color: #20968a;
  }

  .btn-teal:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
    }
  }

  .show > .btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
  }

  .btn-cyan {
    color: $white;
    background-color: #116e7c;
    border-color: #116e7c;

    &:hover {
      color: $white;
      background-color: #138496;
      border-color: #117a8b;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #116e7c;
      border-color: #116e7c;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #117a8b;
        border-color: #10707f;
      }
    }
  }

  .show > .btn-cyan.dropdown-toggle {
    color: $white;
    background-color: #117a8b;
    border-color: #10707f;
  }

  .btn-cyan:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
    }
  }

  .show > .btn-cyan.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
  }

  .btn-white {
    background-color: $white;
    background-image: none;
    border-color: #e7e9f1;
    color: $dark-color;

    &:hover {
      background-color: $white;
      border-color: #e7e9f1;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px $white-5;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: $white;
      border-color: $white;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #f0f0f2;
        border-color: #e7e9f1;
      }
    }
  }

  .show > .btn-white.dropdown-toggle {
    color: $white;
    background-color: #f0f0f2;
    border-color: #e7e9f1;
  }

  .btn-white:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px $white-5;
    }
  }

  .show > .btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px $white-5;
  }

  .btn-gray {
    color: $white;
    background-color: #868e96;
    border-color: #868e96;

    &:hover {
      color: $white;
      background-color: #727b84;
      border-color: #6c757d;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #868e96;
      border-color: #868e96;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #6c757d;
        border-color: #666e76;
      }
    }
  }

  .show > .btn-gray.dropdown-toggle {
    color: $white;
    background-color: #6c757d;
    border-color: #666e76;
  }

  .btn-gray:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
    }
  }

  .show > .btn-gray.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
  }

  .btn-gray-dark {
    color: $white;
    background-color: #343a40;
    border-color: #343a40;

    &:hover {
      color: $white;
      background-color: #23272b;
      border-color: #1d2124;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #343a40;
      border-color: #343a40;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #1d2124;
        border-color: #171a1d;
      }
    }
  }

  .show > .btn-gray-dark.dropdown-toggle {
    color: $white;
    background-color: #1d2124;
    border-color: #171a1d;
  }

  .btn-gray-dark:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
    }
  }

  .show > .btn-gray-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
  }

  .btn-azure {
    color: $white;
    background-color: #3e80eb;
    border-color: #3e80eb;

    &:hover {
      color: $white !important;
      background-color: #219af0;
      border-color: #1594ef;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #3e80eb;
      border-color: #3e80eb;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #1594ef;
        border-color: #108ee7;
      }
    }
  }

  .show > .btn-azure.dropdown-toggle {
    color: $white;
    background-color: #1594ef;
    border-color: #108ee7;
  }

  .btn-azure:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.5);
    }
  }

  .show > .btn-azure.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.5);
  }

  .btn-lime {
    color: $white;
    background-color: #7bd235;
    border-color: #7bd235;

    &:hover {
      color: $white;
      background-color: #69b829;
      border-color: #63ad27;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: #7bd235;
      border-color: #7bd235;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $white;
        background-color: #63ad27;
        border-color: #5da324;
      }
    }
  }

  .show > .btn-lime.dropdown-toggle {
    color: $white;
    background-color: #63ad27;
    border-color: #5da324;
  }

  .btn-lime:not(:disabled):not(.disabled) {
    &:active:focus,
    &.active:focus {
      box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
    }
  }

  .show > .btn-lime.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
  }

  .btn-loading {
    color: transparent !important;

    &.btn-secondary:after {
      border-color: $white;
    }
  }

  .btn-primary .svg-icon,
  .btn-secondary .svg-icon,
  .btn-success .svg-icon,
  .btn-info .svg-icon,
  .btn-warning .svg-icon,
  .btn-danger .svg-icon,
  .btn-dark .svg-icon {
    fill: $white-6;
  }

  .carousel-control-prev,
  .carousel-control-next {
    color: $white;
    text-align: center;
    opacity: 0.5;
  }

  .carousel-control-prev {
    &:hover,
    &:focus {
      color: $white;
      opacity: 0.9;
    }
  }

  .carousel-control-next {
    &:hover,
    &:focus {
      color: $white;
      opacity: 0.9;
    }
  }

  .carousel-indicators,
  .carousel-indicators1,
  .carousel-indicators2,
  .carousel-indicators3,
  .carousel-indicators4,
  .carousel-indicators5 {
    button {
      background-color: $white-5;
    }

    .active {
      background-color: $white;
    }
  }

  .carousel-caption {
    color: $white;
  }

  .carousel-item-background {
    background: $black-5;
  }

  .dropdown-toggle::after {
    border-inline-end: 0.3em solid transparent;
    border-inline-start: 0.3em solid transparent;
  }

  .dropdown-menu {
    background-color: $dark-theme;
    box-shadow: 0px 16px 18px $dark-bg;
    border: 1px solid $border-dark;
  }

  .dropup .dropdown-toggle::after {
    border-inline-end: 0.3em solid transparent;
    border-block-end: 0.3em solid;
    border-inline-start: 0.3em solid transparent;
  }

  .dropend .dropdown-toggle::after {
    border-block-start: 0.3em solid transparent;
    border-block-end: 0.3em solid transparent;
    border-inline-start: 0.3em solid;
  }
  
  .header .dropdown-menu {
    &[data-popper-placement^="top"],
    &[data-popper-placement^="end"],
    &[data-popper-placement^="bottom"],
    &[data-popper-placement^="start"] {
      border: 1px solid $border-dark;
      box-shadow: -8px 12px 18px 0 rgba(15, 34, 58, 0.5);
    }
  }

  .dropdown-divider {
    border-block-start: 1px solid $border-dark;
  }

  .notifyimg {
    color: $white;
  }

  .dropdown-item {
    color: #212529;
    background-color: transparent;
  }

  .dropdown-item {
    &:hover,
    &:focus {
      background-color: $border-dark;
    }

    &.disabled,
    &:disabled {
      color: $white;
      background-color: transparent;
    }
  }

  .dropdown-menu-arrow {
    &.dropdown-menu-end {
      &:before {
        border-block-end: 10px solid $border-dark;
        border-inline-start: 10px solid transparent;
        border-inline-end: 10px solid transparent;
      }

      &:after {
        border-block-end: 10px solid $dark-bg;
        border-inline-start: 10px solid transparent;
        border-inline-end: 10px solid transparent;
      }
    }

    &.dropdown-menu-start {
      &:before {
        border-block-end: 10px solid $border-dark;
        border-inline-start: 10px solid transparent;
        border-inline-end: 10px solid transparent;
      }

      &:after {
        border-block-end: 9px solid $white;
        border-inline-start: 9px solid transparent;
        border-inline-end: 9px solid transparent;
      }
    }
  }

  .dropdown-header {
    color: #868e96;
  }

  .btn.dropdown-toggle ~ .dropdown-menu,
  ul.dropdown-menu li.dropdown ul.dropdown-menu {
    background-color: $dark-theme !important;
    border: 0 solid $border-dark !important;
    color: white;
    box-shadow: -8px 12px 18px 0 $black-1;
  }

  .dropdown-menu {
    > li > a {
      color: $white;

      &:hover,
      &:focus {
        color: $white;
        background-color: $border-dark;
      }
    }

    .divider {
      background: $border-dark;
    }

    .dropdown-plus-title {
      color: $white !important;
      border-color: $border-dark !important;
    }
  }

  .btn.dropdown-toggle {
    &.btn-success ~ .dropdown-menu .dropdown-plus-title {
      border-color: #38cb89 !important;
    }

    &.btn-info ~ .dropdown-menu .dropdown-plus-title {
      border-color: #3e80eb !important;
    }

    &.btn-warning ~ .dropdown-menu .dropdown-plus-title {
      border-color: #ffab00 !important;
    }

    &.btn-danger ~ .dropdown-menu .dropdown-plus-title {
      border-color: #ef4b4b !important;
    }
  }

  #sidebar .dropdown-menu {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .dropdown-icon {
    color: #3e4358;
  }

  .dropdown-header {
    border-block-end: 1px solid $border-dark;
    color: $dark-color;
  }

  .dropdown-toggle.split-dropdown {
    border-inline-start: 1px solid $black-2;
  }

  .list-group-item-action {
    width: 100%;
    color: $dark-color;

    &:hover,
    &:focus,
    &:active {
      color: $dark-color;
    }
  }

  .list-group-item {
    background-color: $dark-theme;
    color: $dark-color;
    border-color: $border-dark !important;
  }

  .listorder,
  .listorder1,
  .listunorder,
  .listunorder1 {
    background-color: $dark-theme;
    border: 1px solid $border-dark;
  }

  .list-group-item {
    &.disabled,
    &:disabled {
      color: $white-3;
      h5 {
        color: $white-3;
      }
    }

    &.active {
      background-color: $border-dark;
      border-color: $border-dark;
      color: white;
    }
  }

  .list-group-item+.list-group-item.active {
    margin-block-start: 0;
  }

  .list-group-item-success {
    color: #316100;
    background-color: #d2ecb8;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #316100;
        background-color: $dark-bg;
      }

      &.active {
        color: $white;
        background-color: $dark-bg;
        border-color: #316100;
      }
    }
  }

  .list-group-item-info {
    color: #24587e;
    background-color: #cbe7fb;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #24587e;
        background-color: #b3dcf9;
      }

      &.active {
        color: $white;
        background-color: #24587e;
        border-color: #24587e;
      }
    }
  }

  .list-group-item-warning {
    color: #7d6608;
    background-color: #fbeebc;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #7d6608;
        background-color: #fae8a4;
      }

      &.active {
        color: $white;
        background-color: #7d6608;
        border-color: #7d6608;
      }
    }
  }

  .list-group-item-danger {
    color: #6b1110;
    background-color: #f1c1c0;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #6b1110;
        background-color: #ecacab;
      }

      &.active {
        color: $white;
        background-color: #6b1110;
        border-color: #6b1110;
      }
    }
  }

  .list-group-item-action {
    &:hover,
    &:focus {
      background-color: $border-dark;
    }
}

  .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #818182;
        background-color: #ececf6;
      }

      &.active {
        color: $white;
        background-color: #818182;
        border-color: #818182;
      }
    }
  }

  .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;

    &.list-group-item-action {
      &:hover,
      &:focus {
        color: #1b1e21;
        background-color: #b9bbbe;
      }

      &.active {
        color: $white;
        background-color: #1b1e21;
        border-color: #1b1e21;
      }
    }
  }

  .btn-close {
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  .list-group-transparent .list-group-item.active {
    background: #f0f0f2;
  }

  .modal-content {
    background-color: $dark-theme;
    border: 1px solid $border-dark;
  }

  .modal-backdrop {
    background-color: $black;

    &.fade {
      opacity: 0;
    }

    &.show {
      opacity: 0.5;
    }
  }

  .modal-header {
    border-block-end: 1px solid $border-dark;
  }

  .modal-footer {
    border-block-start: 1px solid $border-dark;
  }

  .modal {
    &.effect-scale,
    &.effect-slide-in-right,
    &.effect-slide-in-bottom {
      .modal-dialog {
        opacity: 0;
      }

      &.show .modal-dialog {
        opacity: 1;
      }
    }

    &.effect-newspaper {
      .modal-dialog {
        opacity: 0;
      }

      &.show .modal-dialog {
        transform: scale(1) rotate(0deg);
        opacity: 1;
      }
    }

    &.effect-fall,
    &.effect-flip-horizontal,
    &.effect-flip-vertical,
    &.effect-super-scaled {
      .modal-dialog {
        opacity: 0;
      }

      &.show .modal-dialog {
        opacity: 1;
      }
    }

    &.effect-sign .modal-dialog {
      opacity: 1;
    }

    &.effect-rotate-bottom,
    &.effect-rotate-left {
      .modal-dialog {
        opacity: 0;
      }

      &.show .modal-dialog {
        opacity: 1;
      }
    }

    &.effect-just-me {
      .modal-dialog {
        opacity: 0;
      }

      .modal-content {
        background-color: $dark-bg;
        border: 1px solid $border-dark;
      }

      .btn-close {
        color: $white;
      }

      .modal-header {
        background-color: transparent;
        border-block-end-color: $border-dark;
      }
    }
  }

  .modal.effect-just-me {
    .modal-body {
      color: $white-8;

      h6 {
        color: $white;
      }
    }

    .modal-footer {
      background-color: transparent;
      border-block-start-color: $border-dark;
    }

    &.show {
      ~ .modal-backdrop {
        opacity: 0.96;
      }

      .modal-dialog {
        opacity: 1;
      }
    }
  }

  .nav-link.disabled {
    color: #a8b4c1;
  }

  .nav-tabs .nav-link:hover {
    border: 0;
  }
  
  .nav-item1 {
    &:hover:not(.disabled),
    &.active {
      color: $white;
    }

    .nav-link.disabled {
      color: #8e9cad;
    }
  }

  .nav-tabs {
    border-block-end: 1px solid $border-dark;
    color: $black;
  }

  .user-button {
    border: 1px solid $border-dark;
    color: $white !important;
  }

  .nav-tabs {

    .nav-link {
      border: 1px solid transparent;

      &:hover:not(.disabled) {
        color: $white;
      }
    }
  }

  .expanel-default {
    border: #ddd !important;
  }

  .expanel-success {
    border: #2dce89 !important;
  }

  .expanel-danger {
    border: #dc0441 !important;
  }

  .expanel-success > .expanel-heading {
    color: $white !important;
    background-color: #2dce89 !important;
    border-color: #2dce89 !important;
  }

  .expanel-danger > .expanel-heading {
    color: $white !important;
    background-color: #dc0441 !important;
    border-color: #dc0441 !important;
  }

  .expanel-title {
    color: inherit !important;
  }

  .expanel {
    background-color: $dark-bg !important;
    border: 1px solid $border-dark !important;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) !important;
  }

  .expanel-default > .expanel-heading {
    background-color: $white-1 !important;
    border-color: $border-dark !important;
  }

  .expanel-heading {
    border-block-end-color: $border-dark;
    border-color: $border-dark;
  }

  .expanel-footer {
    background-color: $white-1 !important;
    border-block-start-color: $border-dark !important;
  }

  .progress {
    background-color: $white-2;
  }

  .progress-bar {
    color: $white;
    background-color: transparent;
  }

  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }

  .progress-bar-indeterminate {
    &:after,
    &:before {
      background-color: inherit;
    }
  }

  .tag {
    color: $dark-color;
    background-color: $border-dark;
  }

  a.tag:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
  }

  .tag-addon {
    color: inherit;
    background: rgba(0, 0, 0, 0.06);
  }

  a.tag-addon:hover {
    background: rgba(0, 0, 0, 0.16);
    color: inherit;
  }

  .tag-blue {
    background-color: #083dc1;
    color: $white;
  }

  .tag-indigo {
    background-color: #6574cd;
    color: $white;
  }

  .tag-purple {
    background-color: #aa4cf2;
    color: $white;
  }

  .tag-pink {
    background-color: #ec82ef;
    color: $white;
  }

  .tag-red {
    background-color: #dc0441;
    color: $white;
  }

  .tag-orange {
    background-color: #fc7303;
    color: $white;
  }

  .tag-yellow {
    background-color: #ecb403;
    color: $white;
  }

  .tag-green {
    background-color: #2dce89;
    color: $white;
  }

  .tag-teal {
    background-color: #2bcbba;
    color: $white;
  }

  .tag-cyan {
    background-color: #116e7c;
    color: $white;
  }

  .tag-gray {
    background-color: #868e96;
    color: $white;
  }

  .tag-gray-dark {
    background-color: #343a40;
    color: $white;
  }

  .tag-azure {
    background-color: #45aaf2;
    color: $white;
  }

  .tag-lime {
    background-color: #7bd235;
    color: $white;
  }

  .tag-success {
    background-color: #2dce89;
    color: $white;
  }

  .tag-info {
    background-color: #45aaf2;
    color: $white;
  }

  .tag-warning {
    background-color: #ecb403;
    color: $white;
  }

  .tag-danger {
    background-color: #dc0441;
    color: $white;
  }

  .tag-light {
    background-color: #f8f9fa;
    color: $white;
  }

  .tag-dark {
    background-color: #343a40;
    color: $white;
  }

  .thumbnail {
    background-color: $white;
    border: 1px solid $border-dark;
  }

  .video-list-thumbs {
    > li > a {
      color: #6b6f80;
      border: 1px solid $border-dark;
    }

    .fa {
      text-shadow: 0 1px 3px $black-5;
    }

    > li > a:hover .fa {
      color: $white;
      opacity: 1;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
    }

    .duration {
      background-color: rgba(0, 0, 0, 0.4);
      color: $white;
    }

    > li > a:hover .duration {
      background-color: $black;
    }
  }

  .tooltip {
    opacity: 1;
  }

  .tooltip-inner {
    color: $white;
    background-color: $black;
  }

  .tooltip {
    &.bs-tether-element-attached-bottom .tooltip-inner::before,
    &.tooltip-top .tooltip-inner::before {
      border-block-start-color: $black;
    }

    &.bs-tether-element-attached-left .tooltip-inner::before,
    &.tooltip-right .tooltip-inner::before {
      border-inline-end-color: $black;
    }

    &.bs-tether-element-attached-top .tooltip-inner::before,
    &.tooltip-bottom .tooltip-inner::before {
      border-block-end-color: $black;
    }

    &.bs-tether-element-attached-right .tooltip-inner::before,
    &.tooltip-left .tooltip-inner::before {
      border-inline-start-color: $black;
    }
  }

  .tooltip-inner::before {
    border-color: transparent;
  }

  .tooltip {
    &.show {
      opacity: 0.9;
    }

    .tooltip-arrow::before {
      border-color: transparent;
    }
  }

  .bs-tooltip-top .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-block-start-color: $black;
  }

  .bs-tooltip-end .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    border-inline-end-color: $black;
  }

  .bs-tooltip-bottom .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    border-block-end-color: $black;
  }

  .bs-tooltip-start .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    border-inline-start-color: $black;
  }

  .bs-popover-top .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before {
    inset-block-end: 0;
    border-block-start-color: $border-dark;
  }

  .bs-popover-top .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
    border-block-start-color: $white;
  }

  .bs-popover-end .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
    border-inline-end-color: $border-dark;
  }

  .bs-popover-end .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
    border-inline-end-color: $white;
  }

  .bs-popover-bottom .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
    inset-block-start: 0;
    border-block-end-color: $border-dark;
  }

  .bs-popover-bottom .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
    border-block-end-color: $white;
  }

  .bs-popover-bottom .popover-header::before,
  .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    border-block-end: 1px solid $white;
  }

  .bs-popover-start .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
    border-inline-start-color: $border-dark;
  }

  .bs-popover-start .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
    border-inline-start-color: $white;
  }

  .popover-body {
    color: $dark-color;
    background-color: transparent;
  }

  .dropdown-item {
    color: $dark-color;

    .tooltip {
      opacity: 0;
    }

    .tooltip-inner {
      color: $white;
      background-color: $black;
    }

    .tooltip {
      &.bs-tether-element-attached-bottom .tooltip-inner::before,
      &.tooltip-top .tooltip-inner::before {
        border-block-start-color: $black;
      }

      &.bs-tether-element-attached-left .tooltip-inner::before,
      &.tooltip-right .tooltip-inner::before {
        border-inline-end-color: $black;
      }

      &.bs-tether-element-attached-top .tooltip-inner::before,
      &.tooltip-bottom .tooltip-inner::before {
        border-block-end-color: $black;
      }

      &.bs-tether-element-attached-right .tooltip-inner::before,
      &.tooltip-left .tooltip-inner::before {
        border-inline-start-color: $black;
      }
    }

    .popinfo {
      color: #88c8f7;
    }

    .popsuccess {
      color: #bfff80;
    }

    .popdanger {
      color: #ea6262;
    }

    .popwarning {
      color: #f7db6e;
    }

    .tooltip {
      &.show {
        opacity: 0.9;
      }

      .tooltip-arrow::before {
        border-color: transparent;
      }
    }

    .bs-tooltip-top .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      border-block-start-color: $black;
    }

    .bs-tooltip-end .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      border-inline-end-color: $black;
    }

    .bs-tooltip-bottom .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      border-block-end-color: $black;
    }

    .bs-tooltip-start .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      border-inline-start-color: $black;
    }

    .bs-popover-top .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before {
      border-block-start-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-top .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
      border-block-start-color: $white;
    }

    .bs-popover-end .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
      inset-inline-start: 0;
      border-inline-end-color: $border-dark;
    }
  }

  @media (max-width: 991px) {
    .richText .richText-toolbar ul li a {
      border: rgba(0, 40, 100, 0.12) solid 1px;
    }
  }
  
  .dropdown-item {
    .bs-popover-bottom .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
      border-block-end-color: $border-dark;
    }

    .bs-popover-bottom .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
      border-block-end-color: $white;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
      border-block-end: 1px solid $white;
    }

    .bs-popover-start .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
      border-inline-start-color: $border-dark;
    }

    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
      border-inline-start-color: $white;
    }

    .popover-body {
      color: #6e7687;
    }
  }

  .dropdown-item {
    .jqstooltip {
      background: #333 !important;
    }

    .tooltip-static-demo {
      background-color: $border-dark;
    }

    .popover-static-demo {
      background-color: #f0f0f2;
      border: 1px solid $border-dark;
    }

    .popover {
      -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
      border: 1px solid $border-dark;
    }

    .bs-popover-top > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
      border-block-start-color: $border-dark;
    }

    .bs-popover-top > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
      border-block-start-color: $white;
    }

    .bs-popover-end > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
      border-inline-end-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-end > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
      border-inline-end-color: $white;
    }

    .bs-popover-bottom > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
      border-block-end-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-bottom > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
      border-block-end-color: $white;
    }

    .bs-popover-start > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
      border-inline-start-color: $border-dark;
    }

    .bs-popover-start > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
      border-inline-start-color: $white;
    }

    .popover-header {
      color: $dark-color;
      border-color: $border-dark;
      background-color: $dark-bg;
      border-block-end: 1px solid $border-dark;
    }

    .popover-head-primary .popover-body,
    .popover-head-secondary .popover-body {
      border: 1px solid rgba(20, 17, 45, 0.2);
    }

    .popover-primary .popover-header,
    .popover-secondary .popover-header {
      background-color: transparent;
      color: $white;
    }

    .popover-primary .popover-body,
    .popover-secondary .popover-body {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .jqstooltip {
    background: #333 !important;
  }

  .tooltip-static-demo {
    background-color: $border-dark;
  }

  .popover {
    -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
    background-color: $dark-theme;
    border: 1px solid $border-dark;

    .popover-arrow {
      &::before,
      &::after {
        border-color: transparent;
      }
    }
  }

  .bs-popover-top > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-block-start-color: $dark-theme;
  }

  .bs-popover-end > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-inline-end-color: $dark-theme;
  }

  .bs-popover-bottom > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    border-block-end-color: $border-dark;
  }

  .bs-popover-bottom > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-block-end-color: $dark-theme;
  }

  .bs-popover-start > .popover-arrow::before,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    border-inline-start-color: $border-dark;
  }

  .bs-popover-start > .popover-arrow::after,
  .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-inline-start-color: $dark-theme;
  }

  .popover-header {
    color: $dark-color;
    border-color: $border-dark;
    background-color: $dark-theme;
  }

  .popover-head-primary .popover-body,
  .popover-head-secondary .popover-body {
    border: 1px solid rgba(20, 17, 45, 0.2);
  }

  .popover-primary,
  .popover-secondary {
    .popover-header {
      background-color: transparent;
      color: $white;
    }

    .popover-body {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .heading-inverse {
    background-color: $dark-bg;
    color: $white;
  }

  .fc-view {
    &.fc-listMonth-view .fc-list-heading td,
    &.fc-listWeek-view .fc-list-heading td {
      background-color: transparent;
    }

    &.fc-listMonth-view .fc-list-heading-main,
    &.fc-listWeek-view .fc-list-heading-main {
      color: #737688;
    }

    &.fc-listMonth-view .fc-list-heading-main span:last-child,
    &.fc-listWeek-view .fc-list-heading-main span:last-child {
      color: $white;
    }

    &.fc-listMonth-view .fc-list-heading-main.now,
    &.fc-listWeek-view .fc-list-heading-main.now,
    &.fc-listMonth-view .fc-list-heading-main.now span:last-child,
    &.fc-listWeek-view .fc-list-heading-main.now span:last-child {
      color: #4c84ff;
    }

    &.fc-listMonth-view .fc-list-item,
    &.fc-listWeek-view .fc-list-item {
      border-inline-start: 4px solid transparent;
      background-color: $white;
    }

    &.fc-listMonth-view .fc-list-item > td.fc-list-item-time,
    &.fc-listWeek-view .fc-list-item > td.fc-list-item-time {
      border-block-start: 1px solid $border-dark;
      border-inline-end: 1px solid $border-dark;
      color: $white !important;
    }

    &.fc-listMonth-view .fc-list-item > td.fc-list-item-title,
    &.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
      border-block-end: 1px solid #e9edf4;
      border-inline-end: 1px solid #e9edf4;
    }

    &.fc-listMonth-view .fc-list-item-title a,
    &.fc-listWeek-view .fc-list-item-title a {
      color: #14112d;
    }
  }

  td.fc-today {
    background-color: $dark-theme;
  }

  #messages-main {
    .ms-user {
      background: $white;
      border: 1px solid $border-dark;
    }

    #ms-compose {
      box-shadow: 0 0 4px $dark-bg, 0 4px 8px $dark-bg;
    }
  }

  .message-feed {
    &:not(.right) .mf-content {
      background: $border-dark;
      color: white;
    }

    &.right .mf-content {
      color: $white;
    }
  }

  .mf-date {
    color: $dark-color;
  }

  .msb-reply {
    background: $dark-bg;
    border-color: $border-dark;
  }

  .msb-reply button {
    color: $dark-color;
    box-shadow: 0px 2px 3px $dark-bg;
    border: 1px solid $border-dark;

    &:hover {
      background: $dark-bg;
    }
  }

  .actions > li > a {
    box-shadow: 0px 2px 3px $dark-bg;
    border: 1px solid $border-dark;
  }

  .message-feed.right {
    text-align: right;
  }

  .ms-body {
    background: $dark-bg;
  }

  .chat-body-style:after {
    background: $white-2;
  }

  .chat-conatct-list {
    .option-dots {
      border-color: $border-dark;
    }
    }

  .border-bottom {
    border-block-end-color: $border-dark !important;
  }

  .border-light {
    border-color: $border-dark !important;
  }

  .user-contact-list .card-header.bg-gradient {
    border: 0 !important;
  }

  .ms-menu .tab-menu-heading {
    background: $dark-theme;
    border-block-end: 1px solid $border-dark;

    .tabs-menu1 ul li a {
      border: 0px solid $border-dark;
    }
  }

  .action-header {
    background: #f3f4f7;
    border-block-end: 1px solid $border-dark;
    background: #e3e6ef;
  }

  .actions:not(.a-alt) > li > a > i {
    color: $dark-color;
  }

  .modal-content .actions > li > a {
    color: $white;

    &:hover {
      background: $black-1;
    }
  }

  .ah-actions .option-dots {
    color: $dark-color;
  }

  .user-contact-list .option-dots:focus i {
    color: $dark-color;
  }

  .modal-content .actions > li > a {
    box-shadow: 0px 2px 3px $dark-bg;
    border: 1px solid $border-dark;
  }

  .ms-menu .tab-menu-heading .tabs-menu1 ul li .active {
    border-block-end: 2px solid $primary;
  }

  .main-chat-contacts-wrapper {
    border-block-end: 1px solid $border-dark;
  }

  .main-chat-contacts-more {
    color: $dark-color;
  }

  .main-chat-list .media {
    border-block-end: 1px solid $border-dark;

    + .media::before {
      border-block-start: 1px solid #e9edf4;
    }

    &.new {
      .media-contact-name span:first-child {
        color: $dark-color;
      }

      .media-body p {
        color: #808494;
      }
    }

    &:hover,
    &:focus {
      background-color: $dark-bg;
      border-block-start-color: $border-dark;
      border-block-end-color: $border-dark;
    }

    &.selected {
      background-color: $border-dark;
      border-block-start-color: $border-dark;
      border-block-end-color: $border-dark;

      &:first-child {
        border-block-start-color: transparent;
      }
    }
  }

  .main-chat-list {
    .media.selected .media-body p {
      color: #808494;
    }

    .main-img-user span {
      color: $white;
      background-color: #ff5c77;
      box-shadow: 0 0 0 2px $white;
    }

    .media-body p {
      color: $dark-color;
    }

    .media-contact-name span {
      &:first-child {
        color: $dark-color;
      }

      &:last-child {
        color: $dark-color;
      }
    }
  }

  .main-chat-header {
    border-block-end: 1px solid $border-dark;

    .nav-link {
      color: $dark-color;
    }
  }

  .main-chat-msg-name {
    h6 {
      color: $dark-color;
    }

    small {
      color: #808494;
    }
  }

  .main-chat-body .media-body > div span {
    color: $dark-color;
  }

  .main-chat-time span {
    background: #e3e2e5;
  }

  .main-msg-wrapper {
    background-color: $border-dark;
    color: white;
  }

  .main-chat-footer {
    border-block-start: 1px solid $border-dark;
    background-color: $dark-bg;

    .nav-link {
      color: $dark-color;
    }
  }

  .main-img-user.online:before {
    background: #2dce89;
  }

  .main-contact-label::after {
    border-block-end: 1px solid #e9edf4;
  }

  .main-contact-item {
    border: 1px solid transparent;

    + .main-contact-item {
      border-block-start-color: transparent;

      &::before {
        border-block-start: 1px solid $border-dark;
      }
    }

    &:hover,
    &:focus,
    &.selected {
      background-color: $border-dark;
      border-block-start-color: $border-dark;
      border-block-end-color: $border-dark;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: $border-dark;
  }

  .main-contact-body {
    h6 {
      color: $dark-color;
      font-weight: 600;
      margin-block-end: 2px;
    }

    span {
      color: $dark-color;
    }
  }

  .main-contact-info-header {
    .main-img-user a {
      &:hover,
      &:focus {
        fill: $white-5;
        color: $white;
      }
    }

    .media-body {
      h4 {
        color: $dark-color;
      }

      .nav-link {
        color: $dark-color;

        i.typcn {
          line-height: 0.9;
        }
      }
    }
  }

  .main-contact-info-body {
    .media + .media::before {
      border-block-start: 1px solid #e9edf4;
    }

    .media-body {
      label {
        color: $dark-color;
      }

      span {
        color: $dark-color;
      }
    }

    .media::before {
      border-block-start: 1px solid #e9edf4;
    }
  }

  .range {
    background-color: whitesmoke;
    -webkit-box-shadow: inset 0 1px 2px $black-1;
    box-shadow: inset 0 1px 2px $black-1;

    input[type="range"] {
      background-color: transparent;

      &::-webkit-slider-thumb,
      &::-moz-slider-thumb {
        background-color: #999999;
      }
    }

    output {
      color: white;
      background-color: #999999;
    }

    &.range-success {
      input[type="range"] {
        &::-webkit-slider-thumb,
        &::-moz-slider-thumb {
          background-color: #2dce89;
        }
      }

      output {
        background-color: #2dce89;
      }

      input[type="range"] {
        outline-color: #2dce89;
      }
    }

    &.range-info {
      input[type="range"] {
        &::-webkit-slider-thumb,
        &::-moz-slider-thumb {
          background-color: #45aaf2;
        }
      }

      output {
        background-color: #45aaf2;
      }

      input[type="range"] {
        outline-color: #45aaf2;
      }
    }

    &.range-warning {
      input[type="range"] {
        &::-webkit-slider-thumb,
        &::-moz-slider-thumb {
          background-color: #ecb403;
        }
      }

      output {
        background-color: #ecb403;
      }

      input[type="range"] {
        outline-color: #ecb403;
      }
    }

    &.range-danger {
      input[type="range"] {
        &::-webkit-slider-thumb,
        &::-moz-slider-thumb {
          background-color: #dc0441;
        }
      }

      output {
        background-color: #dc0441;
      }

      input[type="range"] {
        outline-color: #dc0441;
      }
    }
  }

  .rating-stars {
    .rating-stars-container .rating-star {
      color: #f0f0f2;

      &.sm {
        color: 1px solid $border-dark;
      }

      &.is--active,
      &.is--hover {
        color: #f1c40f;
      }

      &.is--no-hover {
        color: #3e4b5b;
      }

      &.is--active .fa-heart,
      &.is--hover .fa-heart {
        color: #ef4b4b;
      }

      .fa-heart .is--no-hover {
        color: #3e4b5b;
      }
    }

    input {
      color: $white;
      background-color: $white;
      border: 1px solid $border-dark;
    }
  }
  
  @media (min-width: 992px) {
    #messages-main .ms-menu {
      border-inline-end: 1px solid $border-dark;
    }
  }

  .header {
    background: $dark-theme;
  }

  .footer {
    background: $dark-theme;
    border-block-start: 1px solid $border-dark;
    padding: 1.25rem 1.25rem;
  }

  footer.footer {
    background: $dark-theme;
    box-shadow: 16px 0px 16px rgb(24 23 38 / 6%);
    border-block-start: 1px solid $border-dark;
  }

  .footer .social ul li a {
    color: #abb8c3;
  }

  #popup {
    background: #fe6b1f;
  }

  .card-blog-overlay .card-header {
    border-block-end: 1px solid $white-2;
  }

  .card-blog-overlay1 .card-footer,
  .card-blog-overlay2 .card-footer {
    border-block-start: 1px solid $white-2;
  }

  .card-blog-overlay:before,
  .card-blog-overlay1:before,
  .card-blog-overlay2:before {
    background: $black-5;
  }
  
  .breadcrumb-item a, .breadcrumb-item1 a {
      color: $primary;
  }

  .header .form-inline {
    .form-control {
      border: 0px solid $dark-color !important;
      background: transparent;

      &::-webkit-input-placeholder {
        opacity: 0.6;
      }
    }

    .btn {
      border: 1px solid transparent;
      background: transparent !important;
      color: $white;

      i {
        color: #818c9b;
      }
    }
  }

  .icons-list-item {
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.5);
    border: 1px solid $border-dark;
    color: $dark-color;
  }

  .link-overlay:hover .link-overlay-bg {
    opacity: 1;
  }

  .link-overlay-bg {
    background: rgba(70, 127, 207, 0.8);
    color: $white;
    opacity: 0;
  }

  @media (max-width: 991.98px) and (min-width: 576px) {
    .header {
      .form-inline .form-control::-webkit-input-placeholder {
        color: $dark-color;
      }
    }
  }

  @media (max-width: 1024px) {
    &body {
      &.search-show:before,
      &.sidebar-show:before {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  @media (max-width: 991px) {
    .hor-header {
      box-shadow: 16px 0px 16px rgba(24, 23, 38, 0.06);
    }
  }

  @media (max-width: 575.98px) {
      .header .form-inline .search-element .form-control {
        background: $white;
      }
  
      .form-control.header-search {
        color: $dark-color;
      }
  
      .header {
        .form-inline {
          .btn {
            color: #46494a !important;
          }
  
          .form-control::-webkit-input-placeholder {
            color: $dark-color !important;
          }
        }
      }
  }

  
  .richText {
    border-color: $border-dark;
    background-color: $dark-bg;

    .richText-toolbar {
      border-block-end: $border-dark solid 1px;
      border-block-start: 1px solid $border-dark;
      background-color: $dark-bg;

      ul li a {
        border-inline-end-color: $border-dark !important;
      }
    }
  }

  .fc button {
    background: $primary;
    color: #efefef;
  }

  .fc-event {
    background-color: $primary-02;
  }

  .fc-toolbar {
    .fc-state-active,
    .ui-state-active {
      color: $white;
    }
  }

  #sidebar ul li.active > a:hover {
    background: #6d4dbb;
    color: $white;
  }

  #sidebar {
    ul li a {
      color: #384056;
    }

    li a[aria-expanded="true"]:hover {
      color: $white !important;
    }
  }

  .tab_wrapper {
    > ul {
      border-block-end: 1px solid $border-dark;

      li {
        border: 1px solid $border-dark;
        border-block-start: 1px solid $border-dark;
      }
    }

    &.right_side {
      .content_wrapper {
        border: 1px solid $border-dark;
      }

      > ul {
        border-block-end: 1px solid $border-dark;

        li {
          &.active {
            border-color: 1px solid $border-dark;
          }

          &:after {
            background: 1px solid $border-dark;
          }
        }
      }
    }
  }

  .search svg {
    stroke: $white;
  }

  .pulse {
    background: #f72d66;
    box-shadow: 0 0 0 rgba(247, 45, 102, 0.9);
  }

  .activity {
    border-inline-start: 3px solid $border-dark;
  }

  [class*="js-conveyor-"] ul li {
    border: 1px solid #efefef;
    background: $white;
    box-shadow: 0 0px 10px -5px rgba(68, 84, 195, 0.5);
  }

  .latest-timeline ul.timeline {
    &:before {
      border-inline-start: 1px double $border-dark;
    }

    > li {
      &:before {
        border: 3px solid #1753fc;
      }

      &:first-child:before {
        border: 2px double #0dd157;
      }

      &:nth-child(2):before {
        border: 2px solid #2d9eef;
      }

      &:nth-child(3):before {
        border: 2px solid #f35e90;
      }

      &:nth-child(4):before {
        border: 2px solid #fc7303;
      }

      &:nth-child(5):before {
        border: 2px solid #05a5ba;
      }

      &:nth-child(6):before {
        border: 2px solid #920bfa;
      }

      &:last-child:before {
        border: 2px solid #ec2d38;
      }
    }
  }

  div[data-acc-step]:not(.open) {
    background: $dark-bg;

    h5 {
      color: #8e9cad;
    }

    .bg-primary {
      background: #afb4d8;
    }
  }

  .sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
    background: transparent;
  }

  .sw-theme-dots>ul.step-anchor>li>a {
    border: none;
  }

  .sw-theme-dots>ul.step-anchor>li.done>a {
    color: $dark-color;
  }

  .sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
    color: $primary;
  }

  .apexcharts-tooltip.light {
    background: rgba(50, 50, 50, 0.7);
    box-shadow: 0 0px 17px -5px rgba(68, 84, 195, 0.5);
    border: 1px solid $border-dark;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    border-color: $border-dark;
    background: $dark-theme;
    color: $dark-color;
      .apexcharts-tooltip-title {
        background: $dark-theme;
        border-block-end-color: $border-dark;
    }
  }

  .apexcharts-tooltip.light .apexcharts-tooltip-title {
    background: $white-3;
    border-block-end: 0px solid $border-dark;
  }

  .chart-dropshadow-primary {
    filter: drop-shadow((-6px) 12px 4px rgba(68, 84, 195, 0.2));
  }

  .icon-dropshadow {
    filter: drop-shadow(0px 10px 4px #e5e9f2);
  }

  .icon-dropshadow-info {
    filter: drop-shadow(0px 4px 4px rgba(69, 170, 242, 0.3));
  }

  .icon-dropshadow-success {
    filter: drop-shadow(0px 4px 4px rgba(45, 206, 137, 0.3));
  }

  .icon-dropshadow-danger {
    filter: drop-shadow(0px 4px 4px rgba(255, 91, 81, 0.3));
  }

  .icon-dropshadow-warning {
    filter: drop-shadow(0px 4px 4px rgba(236, 180, 3, 0.3));
  }

  table.table-bordered.dataTable td:first-child {
    border-inline-start: 0px solid $border-dark !important;
  }

  .side-menu__icon {
    color: $dark-color;
    fill: $dark-color !important;
  }

  .svg-icon {
    color: $dark-color;
    fill: $dark-color;
  }

  .header-icon {
    color: $dark-color;
    fill: $dark-color;
  }

  .search-element .search-icon {
    fill: $dark-color !important;
  }

  .card-custom-icon.text-success {
    fill: #2dce89;
  }

  .page-rightheader .breadcrumb {
    background: transparent;
  }

  .under-countdown .countdown {
    color: $white;
  }

  table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > {
    td:first-child:before,
    th:first-child:before {
      color: white;
      border: 2px solid #4757c4;
    }
  }

  .timelineleft {
    &:before {
      background: rgba(152, 166, 173, 0.3);
    }

    > {
      .timeleft-label > span {
        background-color: $white;
        color: $white;
      }

      li > {
        .timelineleft-item {
          -webkit-box-shadow: 0 1px 1px $black-1;
          box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
          background: $dark-theme;
          color: $dark-color;
          border: 1px solid $border-dark;

          > .timelineleft-header {
            color: $dark-color;
            border-block-end: 1px solid $border-dark;

            &.no-border {
              border-block-end: 0px solid #ebeaf1;
            }
          }
        }

        .fa,
        .glyphicon,
        .ion {
          color: $white;
          background: #d2d6de;
        }
      }
    }
  }

  .custom-switch-indicator-lg,
  .custom-switch-indicator-xl {
    background: #f5f6fb;
    border: 1px solid #ebeaf1;

    &:before {
      background: $dark-bg;
      box-shadow: 0 1px 2px 0 $black-5;
    }
  }

  .main-proifle {
    background: $dark-theme;
    border-block-start: 1px solid $border-dark;
  }

  .profile-share {
    background: $dark-theme;

    a {
      background: $dark-theme;
      color: $dark-color;
      box-shadow: 0px 2px 3px $black-1;
      border: 1px solid $border-dark;
    }
  }

  .main-proifle .profile-icon,
  .profile-footer .new,
  .pro-option {
    background: $border-dark;
    color: $white-8;
    box-shadow: 0px 2px 3px $black-1;
    border: 1px solid $border-dark;
  }

  .profile-edit textarea {
    background: $dark-bg;

    &:focus {
      background: $border-dark;
      border: 1px solid $border-dark;
    }
  }

  .fill-white {
    fill: transparent;
  }

  .footer .social .social-icon {
    border-color: $border-dark;
    background: $dark-theme;
    box-shadow: 0px 2px 3px $dark-bg;
    border-color: $border-dark;
    color: $primary;
  }

  .richText {
    .richText-undo,
    .richText-redo {
      border-inline-end: $border-dark solid 1px !important;
    }
  }

  .page-leftheader .breadcrumb-item {
    + .breadcrumb-item::before {
      color: $dark-color;
    }

    a {
      color: $dark-color;
    }
  }

  .dash1-card .ratio {
    background-color: #222222;
    color: $white;
    border-radius: 15px 0px 0px 15px;
  }

  .header-dropdown-list .ps__thumb-y {
    opacity: 0;
  }

  .page-rightheader .breadcrumb-item {
    a,
    + .breadcrumb-item::before {
      color: $dark-color;
    }
  }

  .custom-btns .btn {
    border: 1px solid $black-1;
    color: rgba(255, 255, 255, 0.9);
    background: $black-1;
  }

  .widget-user-image {
    box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
    -moz-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
    -webkit-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
    -ms-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
  }

  .header .nav-link.icon i {
    color: $dark-color;
  }

  .user-details .user-icon {
    background: $white;
    color: $dark-color;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    border: 1px solid $border-dark;
  }

  .item7-card-desc .fe {
    color: $dark-color;
  }

  .nav-link.icon .header-icon {
    background: transparent;
  }

  .profile-dropdown img {
    box-shadow: 0px 2px 3px $dark-bg;
    border: 2px solid $border-dark;
  }

  .hor-header {
    border-block-end: 1px solid $border-dark;
  }
  
  .dash1 {
    color: $white !important;
  }

  .dash2 {
    color: $white !important;
    background-image: linear-gradient(to right, #1d976c, #2fd38a);
  }

  .dash3 {
    color: $white !important;
    background-image: linear-gradient(to right, #fa5420, #f6a800);
  }

  .dash4 {
    color: $white !important;
    background-image: linear-gradient(to right, $secondary, #44c4fa);
  }

  .activity-timeline {
    background-color: $black-1;
  }

  .apexcharts-legend-text {
    color: $white !important;
  }

  .header .main-header-end .nav-link.icon {
    border-inline-end: 1px solid $border-dark;
  }

  #fullscreen-button {
    border-inline-start: 1px solid $border-dark !important;
  }

  .navbar-toggler {
    color: $white;
  }

  .navbar-toggler-icon {
    color: $dark-color;
  }

  .widget-user-image img {
    box-shadow: 0 0 0 4px #484e69 !important;
    border: 3px solid $border-dark !important;
  }

  .no-border input[type="text"] {
    color: $dark-color;
  }

  .shopping-carttable .input-group .input-group-btn {
    &:first-child,
    &:last-child {
      border: 1px solid #ced4da;
    }
  }

  .shopping-carttable {
    .form-control {
      background-color: transparent;
    }
  }

  &.register-3 {
    hr {
      border-block-start: 1px solid $white-1 !important;
    }

    .form-control {
      background-color: transparent;
      &:focus {
        background-color: transparent;
        border-color: $border-dark;
      }
    }

    .input-group-text {
      background-color: transparent;
      color: $white-5;
      border-color: $border-dark;
    }

    input::placeholder {
      color: $white-5;
    }

    .custom-control-label:before {
      border: 1px solid $border-dark;
      background-color: rgba(0, 0, 0, 0.15);
      color: $white-5;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
      border: 1px solid $border-dark;
      background-color: $black-2;
    }

    .btn-list .btn {
      background-color: $border-dark;
      color: rgba(255, 255, 255, 0.82);
      border: 1px solid $border-dark;
    }
  }

  &.register-2 {
    .form-control {
      background-color: transparent;
      border-inline-start: 0;
      &:focus {
        border-color: $border-dark !important;
        background-color: transparent;
      }
    }

    .input-group-text {
      background-color: transparent;
    }

    hr.divider:after {
      color: $dark-color;
      border: 1px solid rgba(240, 239, 241, 0.9);
    }

    .divider {
      border-block-start: 1px solid $white-3;
    }
  }

  .register1 {

    .form-control:focus {
      border-color: #ced4da !important;
    }

    .custom-content .text-white-50 {
      color: white !important;
    }
  }

  .construction {
    &:before {
      background: linear-gradient(#2c3e50, #bdc3c7);
    }

    .countdown li {
      &:nth-child(1) {
        background-image: linear-gradient(to right, $primary, #876eef);
      }

      &:nth-child(2) {
        background-image: linear-gradient(to right, #1d976c, #47b182);
      }

      &:nth-child(3) {
        background-image: linear-gradient(to right, #fa5420, #ea7f5d);
      }

      &:nth-child(4) {
        background-image: linear-gradient(to right, $secondary, #43a0c7);
      }
    }
  }

  .btn-icon:hover .btn-inner-icon {
    color: $dark-color;
  }

  .bg-contacthead:before {
    background:  linear-gradient(to right top, $primary, $primary-09, $primary-09, $primary, $primary-09);
  }

  .bg-gradient {
    &:before {
      background: linear-gradient(to right top, $primary, $primary-09, $primary-09, $primary, $primary-09) !important;
    }

    .option-dots {
      color: $white !important;
    }
  }

  .table-inbox tr:last-child {
    border-block-end-color: $border-dark !important;
  }

  .register-icons .btn:hover .fa {
    color: white !important;
  }

  .table-inbox tr {
    border-block-end: 1px solid $border-dark;

    td {
      .fa-star {
        color: $white-2;

        &.inbox-lefted,
        &:hover {
          color: $white-2;
        }
      }

      .fa-bookmark {
        color: $white-2;
      }

      &.view-message i {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        border: 1px solid $border-dark;
        color: $dark-color;
      }
    }

    &.unread td {
      background: $dark-bg;
      border-block-end: $border-dark 1px solid;
    }
  }

  .mail-option {
    .chk-all,
    .btn-group a.btn {
      border: 1px solid $border-dark;
    }
  }

  .inbox-pagination a.np-btn {
    border: 1px solid $border-dark;
  }

  .inbox-body .modal .modal-body {
    input,
    textarea {
      border: 1px solid #e6e6e6;
    }
  }

  .email-icon {
    box-shadow: 0px 2px 3px $dark-bg;
    border: 1px solid $border-dark;
    color: $dark-color;

    i {
      color: $dark-color;
    }
  }

  .card .list-group.mail-inbox-elements {
    border-block-end: 1px solid $border-dark !important;
  }

  .circles {
    background-color: $dark-color;
  }

  @media (max-width: 576px) {
      .nav-link.icon .header-icon {
      background: transparent;
    }

    .pulse {
      background: #f72d66;
    }
  }

  @media (max-width: 991px) {
    .app-sidebar__toggle {
      border-inline-end: 1px solid $border-dark;
    }

    .pulse {
      background: #f72d66;
    }

    .dropdown-menu.show {
      inset-block-start: 48px;
      box-shadow: -8px 12px 18px 0 rgba(15, 34, 58, 0.1);
    }

    .close-btn {
      color: $dark-color;
    }

    &.register-3 .header-brand-img {
      &.dark-logo {
        display: none !important;
      }
    }
  }

  
  .table {
    color: $dark-color;

    th {
      border-block-start: 1px solid $border-dark;
    }
  }

  .text-wrap table {
    th,
    td {
      border-block-start: 1px solid $border-dark;
    }
  }

  .table td {
    border-block-start: 1px solid $border-dark;
  }

  .border {
    border: 1px solid $border-dark;
  }

  .table thead th,
  .text-wrap table thead th {
    border-block-end: 1px solid $border-dark;
  }

  .table thead th {
    border-block-start: 0;
  }

  .table tbody + tbody,
  .text-wrap table tbody + tbody {
    border-block-start: 2px solid #dee2e6;
  }

  .table .table,
  .text-wrap table .table,
  .table .text-wrap table {
    background-color: $dark-bg;
  }

  .text-wrap {
    .table table,
    table table {
      background-color: #f0f0f2;
    }
  }

  .table-borderless {
    th, td, thead th, tbody+tbody {
      border: 0;
    }
  }

  .table-bordered,
  .text-wrap table,
  .table-bordered th,
  .text-wrap table th,
  .table-bordered td,
  .text-wrap table td {
    border: 1px solid $border-dark;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: $dark-theme;
  }
  .table-striped>tbody>tr:nth-of-type(odd)>* {
    color: $dark-color;
  }

  .table-hover tbody tr:hover {
    background-color: $dark-bg !important;
  }
  .table-hover>tbody>tr:hover>* {
    color: $dark-color;
  }

  .table-success {

    > {
      th,
      td {
        background-color: #38cb89;
      }
    }
  }

  .table-hover .table-success:hover {
    background-color: #c5e7a4;

    > {
      td,
      th {
        background-color: #c5e7a4;
      }
    }
  }

  .table-info {

    > {
      th,
      td {
        background-color: #cbe7fb;
      }
    }
  }

  .table-hover .table-info:hover {
    background-color: #b3dcf9;

    > {
      td,
      th {
        background-color: #b3dcf9;
      }
    }
  }

  .table-warning {

    > {
      th,
      td {
        background-color: #fbeebc;
      }
    }
  }

  .table-hover .table-warning:hover {
    background-color: #fae8a4;

    > {
      td,
      th {
        background-color: #fae8a4;
      }
    }
  }

  .table-hover .table-danger:hover {
    background-color: #ecacab;

    > {
      td,
      th {
        background-color: #ecacab;
      }
    }
  }

  .table-light {
    background-color: #fdfdfe;

    > {
      th,
      td {
        background-color: #fdfdfe;
      }
    }
  }

  .table-hover .table-light:hover {
    background-color: #ececf6;

    > {
      td,
      th {
        background-color: #ececf6;
      }
    }
  }

  .table-dark {
    background-color: #c6c8ca;

    > {
      th,
      td {
        background-color: #c6c8ca;
      }
    }
  }

  .table-hover .table-dark:hover {
    background-color: #b9bbbe;

    > {
      td,
      th {
        background-color: #b9bbbe;
      }
    }
  }

  .table-active {
    background-color: rgba(0, 0, 0, 0.04);

    > {
      th,
      td {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.04);

    > {
      td,
      th {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .table .thead-dark th,
  .text-wrap table .thead-dark th {
    color: #f0f0f2;
    background-color: #212529;
    border-color: #32383e;
  }

  .table .thead-light th,
  .text-wrap table .thead-light th {
    color: $white;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  .table-dark {
    color: #f0f0f2;
    background-color: #212529;

    th,
    td,
    thead th {
      border-color: #32383e;
    }

    &.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &.table-hover tbody tr:hover {
      background-color: rgba(255, 255, 255, 0.075);
    }
  }

  .table th,
  .text-wrap table th {
    color: $white;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: $dark-bg;
  }

  .table-header:hover {
    color: $white !important;
  }

  .ql-snow .ql-stroke {
    stroke: $white;
  }

  .table > :not(:last-child) > :last-child > * {
    border-block-end-color: $border-dark;
  }

  .jvectormap-tip {
    background: #212529;
    color: white;
  }

  .jvectormap-zoomin,
  .jvectormap-zoomout,
  .jvectormap-goback {
    background: $dark-bg;
  }

  .form-help {
    color: $dark-color;
    background: $dark-bg;

    &:hover,
    &[aria-describedby] {
      background: $black-1;
      color: $white;
    }
  }

  .chart-circle-value small {
    color: #9aa0ac;
  }

  #back-to-top {
    color: $white;
  }

  .tabs-menu ul li {
    .active {
      color: $primary !important;
    }
  }

  .tabs-menu-body {
    border: 1px solid $border-dark;
  }

  .item-card .cardtitle a {
    color: #8e9cad;
  }

  .features span {
    color: #43414e;
  }

  .receipts-inline-table .tabs-menu1 ul li .active {
    background: $dark-bg
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }
  
  a {
    color: $dark-color;
    background-color: transparent;
    &:hover {
      color: $white;
    }
  }

  caption {
    color: #505662;
  }

  template {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: inherit;
  }

  hr {
    border-block-start: 1px solid $border-dark;
  }

  mark,
  .mark {
    background-color: #fcf8e3;
  }

  #sidebar .list-unstyled li {
    border-block-end: 1px solid rgba(255, 255, 255, 0.03);
  }

  .blockquote-footer {
    color: $white-7;
  }

  a > code {
    color: inherit;
  }

  kbd {
    color: $white;
    background-color: #343a40;
  }

  #global-loader {
    background: $dark-bg;
  }

  .loader {
    border: 2px solid $white;
    border-block-start: 2px solid #000000;
    opacity: 0;

    &.animate {
      opacity: 1;
    }
  }

  h2.animate {
    opacity: 1;
  }

  .custom-card {
    &.blue {
      color: #1687ed;
      background: #ebf5fd;
      border: 1px solid #5dacf2;

      .loader {
        border-color: #99d0ff;
        border-block-start-color: #1687ed;
      }
    }

    &.red {
      color: #ff0000;
      background: #ffe6e6;
      border: 1px solid #ff8080;

      .loader {
        border-color: #ff9696;
        border-block-start-color: #ff0000;
      }
    }

    &.green {
      color: #007e08;
      background: #cbffce;
      border: 1px solid #00cb0d;

      .loader {
        border-color: #75cc7d;
        border-block-start-color: #007e08;
      }
    }
  }
  div {
    &.dt-button-info {
      background-color: $dark-theme;
      border-color: $border-dark;
      box-shadow: none;
  
      h2 {
        border-block-end-color: $border-dark;
        background-color: $dark-theme;
      }
    }
  }

  .file-manger.list-group-transparent {
    .list-group-item {
      
      &.active {
        background: transparent;
      }
      &:hover {
        color: $white-6;
      }
    }
  }

  .profile-footer {
    background: $dark-bg;
  }

  .option-dots {
    color: $dark-color;

    &:hover,
    &:focus,
    &[aria-expanded="true"] {
      background: $dark-bg;
    }
  }

  .ms-body .action-header {
    background: $dark-theme;
  }

  hr.divider:after {
    color: #f0f0f2;
    border: 1px solid rgba(240, 239, 241, 0.1);
  }

  .custom-progress {
    background-color: #e8ebf2;

    &::-webkit-progress-bar {
      background-color: #e8ebf2;
    }

    &::-webkit-progress-value,
    &::-moz-progress-bar,
    &::-ms-fill {
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
    }
  }

  .multi-wrapper .header {
    background: $white-1 !important;
  }

  .card-pay .tabs-menu li a {
    background: $border-dark;
    border-inline-end: 0px solid $border-dark;

    &.active {
      color: $white;
      background: $primary;
      &:hover {
        color: $white;
      }
    }
    &:hover {
      color: $primary;
    }
  }

  .card-pay .tabs-menu.nav li {
    border-inline-end: 0px solid $border-dark;
  
    &:last-child {
      border-inline-end: 0px;
    }
  }

  .header.bg-primary .form-control.header-search.bg-transparent.border-transparent {
    color: $white;
  }

  .error-text {
    text-shadow: 7px 3px 3px $black-3;
  }

  .error-text-color {
    color: #9a8adb;
  }

  .card-custom-icon {
    color: #e2e2e9;
  }

  .custom-control-input {
    opacity: 0;

    &:active ~ .custom-control-label::before {
      color: $white;
      background-color: #d4e1f4;
    }

    &:disabled ~ .custom-control-label {
      color: #868e96;

      &::before {
        background-color: $dark-bg;
      }
    }
  }

  .custom-control-label::before {
    background-color: #dee2e6;
  }

  .custom-checkbox .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      background-color: $dark-bg;
      opacity: 0.5;
    }

    &:indeterminate ~ .custom-control-label::before {
      background-color: rgba(70, 127, 207, 0.5);
    }
  }

  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #4454c3;
    opacity: 0.5;
  }

  .custom-select {
    color: $dark-color;
    background: $dark-bg;
    border: 1px solid $border-dark;

    &:focus::-ms-value {
      color: $white;
      background-color: $white;
    }

    &:disabled {
      color: #868e96;
      background-color: #e9ecef;
    }
  }

  .custom-control-label:before {
    border: 1px solid $border-dark;
    background-color: $dark-bg;
  }

  .nav-tabs .nav-link {
    color: $white;
  }
  
  .input-group-btn > .btn {
    border-color: rgba(0, 40, 100, 0.12);
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: $white;
    background-color: $border-dark;
    border-color: $border-dark;
  }

  .ui-datepicker {
    background-color: $dark-theme;
    border: 1px solid $border-dark;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.5);

    .ui-datepicker-header {
      color: #473b52;
      background-color: transparent;

      .ui-datepicker-next,
      .ui-datepicker-prev {
        text-indent: -99999px;
        color: #6c757d;
      }
    }
  }

  .dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before {
    color: #343a40;
  }

  .ui-datepicker {
    .ui-datepicker-header {
      .ui-datepicker-next:focus::before {
        color: #343a40;
      }

      .ui-datepicker-prev {
        &:hover::before,
        &:focus::before {
          color: #343a40;
        }
      }

      .ui-datepicker-next-hover,
      .ui-datepicker-prev-hover {
        color: $white;
        background-color: transparent;
      }
    }
  }

  .custom-checkbox:before {
    background-color: transparent;
  }

  .select2-container--default {
    .select2-selection--single {
      background-color: $dark-bg;
      border: 1px solid $border-dark !important;
    }

    &.select2-container--focus .select2-selection--multiple {
      color: #eff6f6;
      background-color: $dark-bg;
      border-color: $border-dark;
    }

    .select2-selection--multiple {
      background-color: $dark-bg;
      border: 1px solid $border-dark !important;
      cursor: text;
    }

    .select2-search--dropdown .select2-search__field {
      border: 1px solid $border-dark !important;
    }

    .select2-selection--multiple .select2-selection__choice {
      background-color: $dark-bg !important;
      border: 1px solid $border-dark !important;
    }
  }

  .form-control {
    &::-ms-expand {
      background-color: transparent;
    }

    &:focus {
      color: $dark-color;
      background-color: $dark-bg;
      border-color: $primary;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: #b3bdca;
      opacity: 1;
    }
  }

  .search-input {
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: #b3bdca;
      opacity: 1;
    }
  }

  input {
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: #b3bdca;
      opacity: 1;
    }
  }

  .form-control {
    &:disabled,
    &[readonly] {
      background-color: $white-1;
    }
  }

  .select2-container--default .select2-selection--single .select2-selection__rrightered {
    color: #444;
  }

  select.form-control:focus::-ms-value {
    color: $white;
    background-color: $dark-bg;
  }

  .custom-control-input:disabled ~ .custom-control-label {
    color: $white-7;
  }

  .valid-feedback {
    color: #2dce89;
  }

  .valid-tooltip {
    color: $white;
    background-color: rgba(94, 186, 0, 0.8);
  }

  .was-validated .form-control:valid,
  .form-control.is-valid,
  .was-validated .custom-select:valid,
  .custom-select.is-valid {
    border-color: #2dce89;
  }

  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus,
  .was-validated .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #2dce89;
    box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label,
  .custom-control-input.is-valid ~ .custom-control-label {
    color: #2dce89;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label::before,
  .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #9eff3b;
  }

  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
  .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #78ed00;
  }

  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
  .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(94, 186, 0, 0.25);
  }

  .invalid-feedback {
    color: #dc0441;
  }

  .invalid-tooltip {
    color: $white;
    background-color: rgba(205, 32, 31, 0.8);
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  .was-validated .custom-select:invalid,
  .custom-select.is-invalid {
    border-color: #dc0441;
  }

  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus,
  .was-validated .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc0441;
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
  }

  .was-validated .custom-control-input:invalid ~ .custom-control-label,
  .custom-control-input.is-invalid ~ .custom-control-label,
  .was-validated .custom-control-input:invalid ~ .custom-control-label,
  .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc0441;
  }

  .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
  .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ec8080;
  }

  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
  .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #e23e3d;
  }

  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
  .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(205, 32, 31, 0.25);
  }

  .wizard {
    border: 1px solid $border-dark;
    background-color: $dark-bg;

    > {
      .steps {
        a {
          color: $white-8;

          &:hover,
          &:active {
            color: $white;
          }

          .number,
          &:hover .number,
          &:active .number {
            color: $dark-color;
            background-color: $border-dark;
          }
        }

        .disabled a {
          color: $white-7;

          &:hover,
          &:active {
            color: $white-7;
          }
        }

        .current a {
          .title,
          &:hover .title,
          &:active .title {
            display: inline-block;
          }
        }

        .current a {
          color: $primary;
          .number,
          &:hover .number,
          &:active .number {
            background-color: $primary;

          }
        }

        .done a {
          color: $primary-06;

          &:hover,
          &:active {
            color: $primary-06;
          }

          .number,
          &:hover .number,
          &:active .number {
            background: $primary-06;
            color: $white;
          }
        }
      }

      .content {
        border-block-start-color: $border-dark;
        border-block-end-color: $border-dark;

        > {
          .title {
            color: $dark-color;
          }

          .body input.parsley-error {
            border-color: #ff5c77;
          }
        }
      }

      .actions {
        > ul > li:last-child a {
          background-color: #2dce89;
        }

        a {
          color: $white;

          &:hover,
          &:active {
            color: $white;
          }
        }

        .disabled a {
          background-color: $secondary;
          color: $white;

          &:hover,
          &:active {
            background-color: $secondary;
            color: $white;
          }
        }
      }
    }

    .price {
      background-color: transparent !important
    }
  }

  .input-group-addon {
    color: #b7bec5;
    border: 1px solid $border-dark;
  }

  .input-group-text {
    color: $dark-color;
    background-color: $dark-bg;
    border: 1px solid $border-dark;
  }

  .selectgroup-button {
    border: 1px solid $border-dark;
    color: #9aa0ac;
  }

  .selectgroup-input:checked + .selectgroup-button {
    background: $dark-bg;
  }

  .custom-switch-indicator {
    background: $dark-bg;
    border: 1px solid $border-dark;

    &:before {
      background: $white-8;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .custom-switch-input:checked ~ .custom-switch-indicator {
    background: $primary;

    &:before {
      background: $white-8;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .custom-switch-indicator-lg {
    background: $dark-bg;

    &:before {
      background: $white-8;
      box-shadow: 0 1px 2px 0 $black-5;
    }
  }

  .custom-switch-indicator-xl {
    background: $dark-bg;
    border: 1px solid $border-dark;

    &:before {
      background: $white-8;
      box-shadow: 0 1px 2px 0 $black-5;
    }
  }

  .custom-switch-description {
    color: $white-7;
  }

  .custom-switch-input:checked ~ .custom-switch-description {
    color: $white;
  }

  .material-switch > {
    label {
      &::before {
        background: $black-3;
        box-shadow: inset 0px 0px 10px $dark-bg;
      }

      &::after {
        background: $white-7;
        border: 1px solid $border-dark;
        box-shadow: 0px 0px 5px $dark-bg;
      }
    }

    input[type="checkbox"]:checked + label::before {
      opacity: 0.5;
    }
  }

  .label-default {
    background: #f3f4f7;
  }

  .label-success {
    background: #2dce89;
  }

  .label-danger {
    background: #dc0441;
  }

  .label-warning {
    background: #ecb403;
  }

  .label-info {
    background: #45aaf2;
  }

  .colorinput-input {
    opacity: 0;
  }

  .colorinput-color {
    border: 1px solid $border-dark;
    color: $white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .colorinput-input {
    &:checked ~ .colorinput-color:before {
      opacity: 1;
    }

    &:focus ~ .colorinput-color {
      border-color: #467fcf;
      box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
    }
  }

  .selectize-control.plugin-drag_drop {
    &.multi > .selectize-input > div.ui-sortable-placeholder {
      background: #f2f2f2 !important;
      background: rgba(0, 0, 0, 0.06) !important;
      box-shadow: inset 0 0 12px 4px $white;
    }

    .ui-sortable-helper {
      box-shadow: 0 2px 5px $black-2;
    }
  }

  .selectize-dropdown-header {
    border-block-end: 1px solid #d0d0d0;
    background: #f8f8f8;
  }

  .selectize-dropdown-header-close {
    color: $white;

    &:hover {
      color: $black;
    }
  }

  .selectize-dropdown.plugin-optgroup_columns .optgroup {
    border-inline-end: 1px solid #f2f2f2;
  }

  .selectize-control.plugin-remove_button {
    [data-value] {
      .remove {
        border-inline-start: 1px solid #d0d0d0;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }

      &.active .remove {
        border-inline-start-color: #cacaca;
      }
    }

    .disabled [data-value] .remove {
      border-inline-start-color: $white;
    }
  }

  .selectize-dropdown {
    color: $white;
  }

  .selectize-input {
    color: $white;
    background: $white;

    input {
      color: $white;
    }
  }

  .selectize-control.single .selectize-input.input-active {
    background: $white;
  }

  .selectize-input {
    border: 1px solid $border-dark;
    transition: 0.3s border-color, 0.3s box-shadow;
  }

  .selectize-control.multi .selectize-input.has-items {
    padding: 7px 0.75rem 4px 7px;
  }

  .selectize-input {
    &.full {
      background-color: $white;
    }

    &.focus {
      border-color: #467fcf;
      box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    &.dropdown-active {
      border-radius: 3px 3px 0 0;
    }
  }

  .selectize-control.multi .selectize-input {
    > div {
      background: #e9ecef;
      color: $white;
      border: 0 solid rgba(0, 40, 100, 0.12);

      &.active {
        background: #e8e8e8;
        color: #303030;
        border: 0 solid #cacaca;
      }
    }

    &.disabled > div {
      color: #7d7d7d;
      background: $white;
      border: 0 solid $white;

      &.active {
        color: #7d7d7d;
        background: $white;
        border: 0 solid $white;
      }
    }
  }

  .selectize-input.dropdown-active::before {
    background: #f0f0f0;
  }

  .dark-mode .selectize-dropdown {
    border: 1px solid $border-dark;
    background: $white;
  }

  .selectize-dropdown {
    [data-selectable] .highlight {
      background: rgba(125, 168, 208, 0.2);
    }

    .optgroup-header {
      color: $white;
      background: $white;
    }

    .active {
      background-color: #f1f4f8;
      color: #467fcf;

      &.create {
        color: $white;
      }
    }

    .create {
      color: rgba(48, 48, 48, 0.5);
    }
  }

  .selectize-control .selectize-input.disabled {
    opacity: 0.5;
    background-color: #fafafa;
  }

  .selectize-dropdown .image img,
  .selectize-input .image img {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  }

  .bg-success {
    background-color: #38cb89 !important;
  }

  a.bg-success {
    &:hover,
    &:focus {
      background-color: #15ca7c !important;
    }
  }

  button.bg-success {
    &:hover,
    &:focus {
      background-color: #15ca7c !important;
    }
  }

  .bg-info {
    background-color: #3e80eb !important;
  }

  a.bg-info {
    &:hover,
    &:focus {
      background-color: #1594ef !important;
    }
  }

  button.bg-info {
    &:hover,
    &:focus {
      background-color: #1594ef !important;
    }
  }

  .bg-warning {
    background-color: #ffab00 !important;
  }

  a.bg-warning {
    &:hover,
    &:focus {
      background-color: #c29d0b !important;
    }
  }

  button.bg-warning {
    &:hover,
    &:focus {
      background-color: #c29d0b !important;
    }
  }

  .bg-danger {
    background-color: #ef4b4b !important;
  }

  a.bg-danger {
    &:hover,
    &:focus {
      background-color: #a11918 !important;
    }
  }

  button.bg-danger {
    &:hover,
    &:focus {
      background-color: #a11918 !important;
    }
  }

  .bg-light-transparent {
    background-color: #f7f7f7 !important;
  }

  .bg-light {
    background-color: $dark-bg !important;
  }

  a.bg-light {
    &:hover,
    &:focus {
      background-color: $black-1 !important;
    }
  }

  button.bg-light {
    &:hover,
    &:focus {
      background-color: #2f3550 !important;
    }
  }

  .bg-light2 {
    background-color: $white-75 !important;
  }

  a.bg-light2 {
    &:hover,
    &:focus {
      background-color: #20223c66 !important;
    }
  }

  button.bg-light2 {
    &:hover,
    &:focus {
      background-color: #20223c66 !important;
    }
  }

  .bg-light3 {
    background-color: $border-dark !important;
  }

  .bg-light4 {
    background-color: #f7f7f7 !important;
  }

  .bg-dark {
    background-color: #343a40 !important;
  }

  a.bg-dark {
    &:hover,
    &:focus {
      background-color: #1d2124 !important;
    }
  }

  button.bg-dark {
    &:hover,
    &:focus {
      background-color: #1d2124 !important;
    }
  }

  .bg-white {
    background-color: $white-2 !important;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  .bg-gradient-success {
    background-image: linear-gradient(to left, #43c47a 0%, #10ae23 100%);
  }

  .bg-gradient-info {
    background-image: linear-gradient(to left, #8caef3 0%, #3e80eb 100%);
  }

  .bg-gradient-warning {
    background-image: linear-gradient(to left, #f8be8d 0%, #ff9a44 100%);
  }

  .bg-gradient-teal {
    background-image: linear-gradient(to left, #60c1db 0%, #0698a7 100%);
  }

  .bg-gradient-blue {
    background-image: linear-gradient(to left, #07aedb 0%, #055ee9 100%);
  }

  .bg-gradient-danger {
    background-image: linear-gradient(to left, #ff90a0 0%, #fc6076 100%);
  }

  .bg-gray-100 {
    background-color: $white-1;
  }

  .bg-gray-200 {
    background-color: $white-2;
  }

  .bg-gray-300 {
    background-color: $white-3;
  }

  .bg-gray-400 {
    background-color: $white-4;
  }

  .bg-gray-500 {
    background-color: #272e42;
  }

  .bg-gray-600 {
    background-color: #1f263c;
  }

  .bg-gray-700 {
    background-color: #192138;
  }

  .bg-gray-800 {
    background-color: #151c31;
  }

  .bg-gray-900 {
    background-color: #11182b;
  }

  .bg-white-1 {
    background-color: $border-dark;
  }

  .bg-white-2 {
    background-color: $white-2;
  }

  .bg-white-3 {
    background-color: $white-3;
  }

  .bg-white-4 {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .bg-white-5 {
    background-color: $white-5;
  }

  .bg-white-6 {
    background-color: $white-6;
  }

  .bg-white-7 {
    background-color: $white-7;
  }

  .bg-white-8 {
    background-color: $white-8;
  }

  .bg-white-9 {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .bg-black-1 {
    background-color: $black-1;
  }

  .bg-black-2 {
    background-color: $black-2;
  }

  .bg-black-3 {
    background-color: $black-3;
  }

  .bg-black-4 {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .bg-black-5 {
    background-color: $black-5;
  }

  .bg-black-6 {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .bg-black-7 {
    background-color: $black-7;
  }

  .bg-black-8 {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .bg-black-9 {
    background-color: $black-9;
  }

  .border {
    border-color: $border-dark !important;
  }

  .border-block-start {
    border-block-start: 1px solid $border-dark !important;
  }

  .border-end {
    border-inline-end: 1px solid $border-dark !important;
  }

  .border-block-end {
    border-block-end: 1px solid $border-dark !important;
  }

  .border-start {
    border-inline-start: 1px solid $border-dark !important;
  }

  .border-block-start-bottom {
    border-block-start: 1px solid $border-dark !important;
    border-block-end: 1px solid $border-dark !important;
  }

  .border-start-right {
    border-inline-end: 1px solid $border-dark !important;
    border-inline-start: 1px solid $border-dark !important;
  }

  .border-block-start-bottom-0 {
    border-block-start: 0px solid $border-dark !important;
    border-block-end: 0px solid $border-dark !important;
  }

  .border-start-right-0 {
    border-inline-end: 0px solid $border-dark !important;
    border-inline-start: 0px solid $border-dark !important;
  }

  .border-primary {
    border: 1px solid $primary !important;
  }

  .border-secondary {
    border: 1px solid $secondary !important;
  }

  .border-success {
    border: 1px solid #38cb89 !important;
  }

  .border-info {
    border: 1px solid #3e80eb !important;
  }

  .border-warning {
    border: 1px solid #ffab00 !important;
  }

  .border-danger {
    border: 1px solid #ef4b4b !important;
  }

  .border-teal {
    border: 1px solid #2bcbba !important;
  }

  .border-transparent {
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
  }

  .border-dark-transparent {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
  }

  .border-light2 {
    border: 1px solid $border-dark !important;
  }

  .border-white {
    border: 1px solid $white !important;
  }

  .dark-mode .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }

  .text-white {
    color: #f0f0f0 !important;
  }

  .text-success {
    color: #38cb89 !important;
  }

  a.text-success {
    &:hover,
    &:focus {
      color: #15ca7c !important;
    }
  }

  .text-info {
    color: #3e80eb !important;
  }

  a.text-info {
    &:hover,
    &:focus {
      color: #25a1c0 !important;
    }
  }

  .text-warning {
    color: #ffab00 !important;
  }

  a.text-warning {
    &:hover,
    &:focus {
      color: #c29d0b;
    }
  }

  .text-danger {
    color: #ef4b4b !important;
  }

  a.text-danger {
    &:hover,
    &:focus {
      color: #df0812 !important;
    }
  }

  .text-light {
    color: #f8f9fa !important;
  }

  a.text-light {
    &:hover,
    &:focus {
      color: #dae0e5 !important;
    }
  }

  .text-dark {
    color: $white !important;
  }

  a.text-dark {
    &:hover,
    &:focus {
      color: #384b6e !important;
    }
  }

  .text-body {
    color: $white !important;
  }

  .text-muted {
    color: rgba($dark-color, 0.65) !important;
  }

  .datepicker-dropdown.datepicker-orient-top:before {
    border-block-start: 7px solid $border-dark;
  }

  .datepicker-dropdown:after {
    border-block-end-color: $border-dark;
  }
  .datepicker-dropdown:before {
    border-block-end-color: $dark-theme
  }

  .icon-muted {
    color: #e2e2e9 !important;
  }

  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .text-white-80 {
    color: $white-8 !important;
  }

  .text-hide {
    background-color: transparent;
  }

  blockquote {
    color: $white-7;
    border-inline-start: 2px solid $border-dark;
  }

  code {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid $border-dark;
  }

  pre {
    color: #3e80eb;
    background-color: #f8fafc;
  }

  .section-nav {
    background-color: #f8f9fa;
    border: 1px solid $border-dark;
  }

  .bg-blue-lightest {
    background-color: #edf2fa !important;
  }

  a.bg-blue-lightest {
    &:hover,
    &:focus {
      background-color: #c5d5ef !important;
    }
  }

  button.bg-blue-lightest {
    &:hover,
    &:focus {
      background-color: #c5d5ef !important;
    }
  }

  .bg-blue-lighter {
    background-color: #c8d9f1 !important;
  }

  a.bg-blue-lighter {
    &:hover,
    &:focus {
      background-color: #9fbde7 !important;
    }
  }

  button.bg-blue-lighter {
    &:hover,
    &:focus {
      background-color: #9fbde7 !important;
    }
  }

  .bg-blue-light {
    background-color: #7ea5dd !important;
  }

  a.bg-blue-light {
    &:hover,
    &:focus {
      background-color: #5689d2 !important;
    }
  }

  button.bg-blue-light {
    &:hover,
    &:focus {
      background-color: #5689d2 !important;
    }
  }

  .bg-blue-dark {
    background-color: #3866a6 !important;
  }

  @media (min-width: 992px) {
    #sticky {
      color: $white;
    }
  }
    
  @media print {
    pre,
    blockquote {
      border: 1px solid #adb5bd;
    }

    .badge {
      border: 1px solid $black;
    }

    .table td,
    .text-wrap table td,
    .table th,
    .text-wrap table th {
      background-color: $dark-color !important;
    }

    .table-bordered th,
    .text-wrap table th,
    .table-bordered td,
    .text-wrap table td {
      border: 1px solid $border-dark !important;
    }
  }

  
  button.bg-blue-dark {
    &:hover,
    &:focus {
      background-color: #2b4f80 !important;
    }
  }

  .bg-blue-darker {
    background-color: #1c3353 !important;
  }

  a.bg-blue-darker {
    &:hover,
    &:focus {
      background-color: #0f1c2d !important;
    }
  }

  button.bg-blue-darker {
    &:hover,
    &:focus {
      background-color: #0f1c2d !important;
    }
  }

  .bg-blue-darkest {
    background-color: #0e1929 !important;
  }

  a.bg-blue-darkest {
    &:hover,
    &:focus {
      background-color: #010203 !important;
    }
  }

  button.bg-blue-darkest {
    &:hover,
    &:focus {
      background-color: #010203 !important;
    }
  }

  .bg-purssianblue {
    background-color: #362f71;
  }

  a.bg-purssianblue-lightest {
    &:hover,
    &:focus {
      background-color: #3f3688 !important;
    }
  }

  button.bg-purssianblue-lightest {
    &:hover,
    &:focus {
      background-color: #3f3688 !important;
    }
  }

  .bg-indigo-lightest {
    background-color: #f0f1fa !important;
  }

  a.bg-indigo-lightest {
    &:hover,
    &:focus {
      background-color: #cacded !important;
    }
  }

  button.bg-indigo-lightest {
    &:hover,
    &:focus {
      background-color: #cacded !important;
    }
  }

  .bg-indigo-lighter {
    background-color: #d1d5f0 !important;
  }

  a.bg-indigo-lighter {
    &:hover,
    &:focus {
      background-color: #abb2e3 !important;
    }
  }

  button.bg-indigo-lighter {
    &:hover,
    &:focus {
      background-color: #abb2e3 !important;
    }
  }

  .bg-indigo-light {
    background-color: #939edc !important;
  }

  a.bg-indigo-light {
    &:hover,
    &:focus {
      background-color: #6c7bd0 !important;
    }
  }

  button.bg-indigo-light {
    &:hover,
    &:focus {
      background-color: #6c7bd0 !important;
    }
  }

  .bg-indigo-dark {
    background-color: #515da4 !important;
  }

  a.bg-indigo-dark {
    &:hover,
    &:focus {
      background-color: #404a82 !important;
    }
  }

  button.bg-indigo-dark {
    &:hover,
    &:focus {
      background-color: #404a82 !important;
    }
  }

  .bg-indigo-darker {
    background-color: #282e52 !important;
  }

  a.bg-indigo-darker {
    &:hover,
    &:focus {
      background-color: #171b30 !important;
    }
  }

  button.bg-indigo-darker {
    &:hover,
    &:focus {
      background-color: #171b30 !important;
    }
  }

  .bg-indigo-darkest {
    background-color: #141729 !important;
  }

  a.bg-indigo-darkest {
    &:hover,
    &:focus {
      background-color: #030407 !important;
    }
  }

  button.bg-indigo-darkest {
    &:hover,
    &:focus {
      background-color: #030407 !important;
    }
  }

  .bg-purple-lightest {
    background-color: #f6effd !important;
  }

  a.bg-purple-lightest {
    &:hover,
    &:focus {
      background-color: #ddc2f7 !important;
    }
  }

  button.bg-purple-lightest {
    &:hover,
    &:focus {
      background-color: #ddc2f7 !important;
    }
  }

  .bg-purple-lighter {
    background-color: #e4cff9 !important;
  }

  a.bg-purple-lighter {
    &:hover,
    &:focus {
      background-color: #cba2f3 !important;
    }
  }

  button.bg-purple-lighter {
    &:hover,
    &:focus {
      background-color: #cba2f3 !important;
    }
  }

  .bg-purple-light {
    background-color: #c08ef0 !important;
  }

  a.bg-purple-light {
    &:hover,
    &:focus {
      background-color: #a761ea !important;
    }
  }

  button.bg-purple-light {
    &:hover,
    &:focus {
      background-color: #a761ea !important;
    }
  }

  .bg-purple-dark {
    background-color: #844bbb !important;
  }

  a.bg-purple-dark {
    &:hover,
    &:focus {
      background-color: #6a3a99 !important;
    }
  }

  button.bg-purple-dark {
    &:hover,
    &:focus {
      background-color: #6a3a99 !important;
    }
  }

  .bg-purple-darker {
    background-color: #42265e !important;
  }

  a.bg-purple-darker {
    &:hover,
    &:focus {
      background-color: #29173a !important;
    }
  }

  button.bg-purple-darker {
    &:hover,
    &:focus {
      background-color: #29173a !important;
    }
  }

  .bg-purple-darkest {
    background-color: #21132f !important;
  }

  a.bg-purple-darkest {
    &:hover,
    &:focus {
      background-color: #08040b !important;
    }
  }

  button.bg-purple-darkest {
    &:hover,
    &:focus {
      background-color: #08040b !important;
    }
  }

  .bg-pink-lightest {
    background-color: #fef0f5 !important;
  }

  a.bg-pink-lightest {
    &:hover,
    &:focus {
      background-color: #fbc0d5 !important;
    }
  }

  button.bg-pink-lightest {
    &:hover,
    &:focus {
      background-color: #fbc0d5 !important;
    }
  }

  .bg-pink-lighter {
    background-color: #fcd3e1 !important;
  }

  a.bg-pink-lighter {
    &:hover,
    &:focus {
      background-color: #f9a3c0 !important;
    }
  }

  button.bg-pink-lighter {
    &:hover,
    &:focus {
      background-color: #f9a3c0 !important;
    }
  }

  .bg-pink-light {
    background-color: #f999b9 !important;
  }

  a.bg-pink-light {
    &:hover,
    &:focus {
      background-color: #f66998 !important;
    }
  }

  button.bg-pink-light {
    &:hover,
    &:focus {
      background-color: #f66998 !important;
    }
  }

  .bg-pink-dark {
    background-color: #c5577c !important;
  }

  a.bg-pink-dark {
    &:hover,
    &:focus {
      background-color: #ad3c62 !important;
    }
  }

  button.bg-pink-dark {
    &:hover,
    &:focus {
      background-color: #ad3c62 !important;
    }
  }

  .bg-pink-darker {
    background-color: #622c3e !important;
  }

  a.bg-pink-darker {
    &:hover,
    &:focus {
      background-color: #3f1c28 !important;
    }
  }

  button.bg-pink-darker {
    &:hover,
    &:focus {
      background-color: #3f1c28 !important;
    }
  }

  .bg-pink-darkest {
    background-color: #31161f !important;
  }

  a.bg-pink-darkest {
    &:hover,
    &:focus {
      background-color: #0e0609 !important;
    }
  }

  button.bg-pink-darkest {
    &:hover,
    &:focus {
      background-color: #0e0609 !important;
    }
  }

  .bg-red-lightest {
    background-color: #fae9e9 !important;
  }

  a.bg-red-lightest {
    &:hover,
    &:focus {
      background-color: #f1bfbf !important;
    }
  }

  button.bg-red-lightest {
    &:hover,
    &:focus {
      background-color: #f1bfbf !important;
    }
  }

  .bg-red-lighter {
    background-color: #f0bcbc !important;
  }

  a.bg-red-lighter {
    &:hover,
    &:focus {
      background-color: #e79292 !important;
    }
  }

  button.bg-red-lighter {
    &:hover,
    &:focus {
      background-color: #e79292 !important;
    }
  }

  .bg-red-light {
    background-color: #dc6362 !important;
  }

  a.bg-red-light {
    &:hover,
    &:focus {
      background-color: #d33a38 !important;
    }
  }

  button.bg-red-light {
    &:hover,
    &:focus {
      background-color: #d33a38 !important;
    }
  }

  .bg-red-dark {
    background-color: #a41a19 !important;
  }

  a.bg-red-dark {
    &:hover,
    &:focus {
      background-color: #781312 !important;
    }
  }

  button.bg-red-dark {
    &:hover,
    &:focus {
      background-color: #781312 !important;
    }
  }

  .bg-red-darker {
    background-color: #520d0c !important;
  }

  a.bg-red-darker {
    &:hover,
    &:focus {
      background-color: #260605 !important;
    }
  }

  button.bg-red-darker {
    &:hover,
    &:focus {
      background-color: #260605 !important;
    }
  }

  .bg-red-darkest {
    background-color: #290606 !important;
  }

  a.bg-red-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  button.bg-red-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  .bg-orange-lightest {
    background-color: $white !important;
  }

  a.bg-orange-lightest {
    &:hover,
    &:focus {
      background-color: peachpuff !important;
    }
  }

  button.bg-orange-lightest {
    &:hover,
    &:focus {
      background-color: peachpuff !important;
    }
  }

  .bg-orange-lighter {
    background-color: #fee0c7 !important;
  }

  a.bg-orange-lighter {
    &:hover,
    &:focus {
      background-color: #fdc495 !important;
    }
  }

  button.bg-orange-lighter {
    &:hover,
    &:focus {
      background-color: #fdc495 !important;
    }
  }

  .bg-orange-light {
    background-color: #feb67c !important;
  }

  a.bg-orange-light {
    &:hover,
    &:focus {
      background-color: #fe9a49 !important;
    }
  }

  button.bg-orange-light {
    &:hover,
    &:focus {
      background-color: #fe9a49 !important;
    }
  }

  .bg-orange-dark {
    background-color: #ca7836 !important;
  }

  a.bg-orange-dark {
    &:hover,
    &:focus {
      background-color: #a2602b !important;
    }
  }

  button.bg-orange-dark {
    &:hover,
    &:focus {
      background-color: #a2602b !important;
    }
  }

  .bg-orange-darker {
    background-color: #653c1b !important;
  }

  a.bg-orange-darker {
    &:hover,
    &:focus {
      background-color: #3d2410 !important;
    }
  }

  button.bg-orange-darker {
    &:hover,
    &:focus {
      background-color: #3d2410 !important;
    }
  }

  .bg-orange-darkest {
    background-color: #331e0e !important;
  }

  a.bg-orange-darkest {
    &:hover,
    &:focus {
      background-color: #0b0603 !important;
    }
  }

  button.bg-orange-darkest {
    &:hover,
    &:focus {
      background-color: #0b0603 !important;
    }
  }

  .bg-yellow-lightest {
    background-color: #fef9e7 !important;
  }

  a.bg-yellow-lightest {
    &:hover,
    &:focus {
      background-color: #fcedb6 !important;
    }
  }

  button.bg-yellow-lightest {
    &:hover,
    &:focus {
      background-color: #fcedb6 !important;
    }
  }

  .bg-yellow-lighter {
    background-color: #fbedb7 !important;
  }

  a.bg-yellow-lighter {
    &:hover,
    &:focus {
      background-color: #f8e187 !important;
    }
  }

  button.bg-yellow-lighter {
    &:hover,
    &:focus {
      background-color: #f8e187 !important;
    }
  }

  .bg-yellow-light {
    background-color: #f5d657 !important;
  }

  a.bg-yellow-light {
    &:hover,
    &:focus {
      background-color: #f2ca27 !important;
    }
  }

  button.bg-yellow-light {
    &:hover,
    &:focus {
      background-color: #f2ca27 !important;
    }
  }

  .bg-yellow-dark {
    background-color: #c19d0c !important;
  }

  a.bg-yellow-dark {
    &:hover,
    &:focus {
      background-color: #917609 !important;
    }
  }

  button.bg-yellow-dark {
    &:hover,
    &:focus {
      background-color: #917609 !important;
    }
  }

  .bg-yellow-darker {
    background-color: #604e06 !important;
  }

  a.bg-yellow-darker {
    &:hover,
    &:focus {
      background-color: #302703 !important;
    }
  }

  button.bg-yellow-darker {
    &:hover,
    &:focus {
      background-color: #302703 !important;
    }
  }

  .bg-yellow-darkest {
    background-color: #302703 !important;
  }

  a.bg-yellow-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  button.bg-yellow-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  .bg-green-lightest {
    background-color: #eff8e6 !important;
  }

  a.bg-green-lightest {
    &:hover,
    &:focus {
      background-color: #d6edbe !important;
    }
  }

  button.bg-green-lightest {
    &:hover,
    &:focus {
      background-color: #d6edbe !important;
    }
  }

  .bg-green-lighter {
    background-color: #cfeab3 !important;
  }

  a.bg-green-lighter {
    &:hover,
    &:focus {
      background-color: #b6df8b !important;
    }
  }

  button.bg-green-lighter {
    &:hover,
    &:focus {
      background-color: #b6df8b !important;
    }
  }

  .bg-green-light {
    background-color: #8ecf4d !important;
  }

  a.bg-green-light {
    &:hover,
    &:focus {
      background-color: #75b831 !important;
    }
  }

  button.bg-green-light {
    &:hover,
    &:focus {
      background-color: #75b831 !important;
    }
  }

  .bg-green-dark {
    background-color: #4b9500 !important;
  }

  a.bg-green-dark {
    &:hover,
    &:focus {
      background-color: #316200 !important;
    }
  }

  button.bg-green-dark {
    &:hover,
    &:focus {
      background-color: #316200 !important;
    }
  }

  .bg-green-darker {
    background-color: #264a00 !important;
  }

  a.bg-green-darker {
    &:hover,
    &:focus {
      background-color: #0c1700 !important;
    }
  }

  button.bg-green-darker {
    &:hover,
    &:focus {
      background-color: #0c1700 !important;
    }
  }

  .bg-green-darkest {
    background-color: #132500 !important;
  }

  a.bg-green-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  button.bg-green-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  .bg-teal-lightest {
    background-color: #eafaf8 !important;
  }

  a.bg-teal-lightest {
    &:hover,
    &:focus {
      background-color: #c1f0ea !important;
    }
  }

  button.bg-teal-lightest {
    &:hover,
    &:focus {
      background-color: #c1f0ea !important;
    }
  }

  .bg-teal-lighter {
    background-color: #bfefea !important;
  }

  a.bg-teal-lighter {
    &:hover,
    &:focus {
      background-color: #96e5dd !important;
    }
  }

  button.bg-teal-lighter {
    &:hover,
    &:focus {
      background-color: #96e5dd !important;
    }
  }

  .bg-teal-light {
    background-color: #6bdbcf !important;
  }

  a.bg-teal-light {
    &:hover,
    &:focus {
      background-color: #42d1c2 !important;
    }
  }

  button.bg-teal-light {
    &:hover,
    &:focus {
      background-color: #42d1c2 !important;
    }
  }

  .bg-teal-dark {
    background-color: #22a295 !important;
  }

  a.bg-teal-dark {
    &:hover,
    &:focus {
      background-color: #19786e !important;
    }
  }

  button.bg-teal-dark {
    &:hover,
    &:focus {
      background-color: #19786e !important;
    }
  }

  .bg-teal-darker {
    background-color: #11514a !important;
  }

  a.bg-teal-darker {
    &:hover,
    &:focus {
      background-color: #082723 !important;
    }
  }

  button.bg-teal-darker {
    &:hover,
    &:focus {
      background-color: #082723 !important;
    }
  }

  .bg-teal-darkest {
    background-color: #092925 !important;
  }

  a.bg-teal-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  button.bg-teal-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  .bg-cyan-lightest {
    background-color: #e8f6f8 !important;
  }

  a.bg-cyan-lightest {
    &:hover,
    &:focus {
      background-color: #c1e7ec !important;
    }
  }

  button.bg-cyan-lightest {
    &:hover,
    &:focus {
      background-color: #c1e7ec !important;
    }
  }

  .bg-cyan-lighter {
    background-color: #b9e3ea !important;
  }

  a.bg-cyan-lighter {
    &:hover,
    &:focus {
      background-color: #92d3de !important;
    }
  }

  button.bg-cyan-lighter {
    &:hover,
    &:focus {
      background-color: #92d3de !important;
    }
  }

  .bg-cyan-light {
    background-color: #5dbecd !important;
  }

  a.bg-cyan-light {
    &:hover,
    &:focus {
      background-color: #3aabbd !important;
    }
  }

  button.bg-cyan-light {
    &:hover,
    &:focus {
      background-color: #3aabbd !important;
    }
  }

  .bg-cyan-dark {
    background-color: #128293 !important;
  }

  a.bg-cyan-dark {
    &:hover,
    &:focus {
      background-color: #0c5a66 !important;
    }
  }

  button.bg-cyan-dark {
    &:hover,
    &:focus {
      background-color: #0c5a66 !important;
    }
  }

  .bg-cyan-darker {
    background-color: #09414a !important;
  }

  a.bg-cyan-darker {
    &:hover,
    &:focus {
      background-color: #03191d !important;
    }
  }

  button.bg-cyan-darker {
    &:hover,
    &:focus {
      background-color: #03191d !important;
    }
  }

  .bg-cyan-darkest {
    background-color: #052025 !important;
  }

  a.bg-cyan-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  button.bg-cyan-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  .bg-white-lightest {
    background-color: white !important;
  }

  a.bg-white-lightest {
    &:hover,
    &:focus {
      background-color: #e6e5e5 !important;
    }
  }

  button.bg-white-lightest {
    &:hover,
    &:focus {
      background-color: #e6e5e5 !important;
    }
  }

  .bg-white-lighter {
    background-color: white !important;
  }

  a.bg-white-lighter {
    &:hover,
    &:focus {
      background-color: #e6e5e5 !important;
    }
  }

  button.bg-white-lighter {
    &:hover,
    &:focus {
      background-color: #e6e5e5 !important;
    }
  }

  .bg-white-light {
    background-color: white !important;
  }

  a.bg-white-light {
    &:hover,
    &:focus {
      background-color: #e6e5e5 !important;
    }
  }

  button.bg-white-light {
    &:hover,
    &:focus {
      background-color: #e6e5e5 !important;
    }
  }

  .bg-white-dark {
    background-color: #cccccc !important;
  }

  a.bg-white-dark {
    &:hover,
    &:focus {
      background-color: #b3b2b2 !important;
    }
  }

  button.bg-white-dark {
    &:hover,
    &:focus {
      background-color: #b3b2b2 !important;
    }
  }

  .bg-white-darker {
    background-color: #666666 !important;
  }

  a.bg-white-darker {
    &:hover,
    &:focus {
      background-color: #4d4c4c !important;
    }
  }

  button.bg-white-darker {
    &:hover,
    &:focus {
      background-color: #4d4c4c !important;
    }
  }

  .bg-white-darkest {
    background-color: #333333 !important;
  }

  a.bg-white-darkest {
    &:hover,
    &:focus {
      background-color: #1a1919 !important;
    }
  }

  button.bg-white-darkest {
    &:hover,
    &:focus {
      background-color: #1a1919 !important;
    }
  }

  .bg-gray-lightest {
    background-color: #f3f4f5 !important;
  }

  a.bg-gray-lightest {
    &:hover,
    &:focus {
      background-color: #d7dbde !important;
    }
  }

  button.bg-gray-lightest {
    &:hover,
    &:focus {
      background-color: #d7dbde !important;
    }
  }

  .bg-gray-lighter {
    background-color: #dbdde0 !important;
  }

  a.bg-gray-lighter {
    &:hover,
    &:focus {
      background-color: #c0c3c8 !important;
    }
  }

  button.bg-gray-lighter {
    &:hover,
    &:focus {
      background-color: #c0c3c8 !important;
    }
  }

  .bg-gray-light {
    background-color: #aab0b6 !important;
  }

  a.bg-gray-light {
    &:hover,
    &:focus {
      background-color: #8f979e !important;
    }
  }

  button.bg-gray-light {
    &:hover,
    &:focus {
      background-color: #8f979e !important;
    }
  }

  .bg-gray-dark {
    background-color: #6b7278 !important;
    background-color: #343a40 !important;
  }

  a.bg-gray-dark {
    &:hover,
    &:focus {
      background-color: #53585d !important;
      background-color: #1d2124 !important;
    }
  }

  button.bg-gray-dark {
    &:hover,
    &:focus {
      background-color: #53585d !important;
      background-color: #1d2124 !important;
    }
  }

  .bg-gray-darker {
    background-color: #36393c !important;
  }

  a.bg-gray-darker {
    &:hover,
    &:focus {
      background-color: #1e2021 !important;
    }
  }

  button.bg-gray-darker {
    &:hover,
    &:focus {
      background-color: #1e2021 !important;
    }
  }

  .bg-gray-darkest {
    background-color: #1b1c1e !important;
  }

  a.bg-gray-darkest {
    &:hover,
    &:focus {
      background-color: #030303 !important;
    }
  }

  button.bg-gray-darkest {
    &:hover,
    &:focus {
      background-color: #030303 !important;
    }
  }

  .bg-gray-dark-lightest {
    background-color: #ebebec !important;
  }

  a.bg-gray-dark-lightest {
    &:hover,
    &:focus {
      background-color: #d1d1d3 !important;
    }
  }

  button.bg-gray-dark-lightest {
    &:hover,
    &:focus {
      background-color: #d1d1d3 !important;
    }
  }

  .bg-gray-dark-lighter {
    background-color: #c2c4c6 !important;
  }

  a.bg-gray-dark-lighter {
    &:hover,
    &:focus {
      background-color: #a8abad !important;
    }
  }

  button.bg-gray-dark-lighter {
    &:hover,
    &:focus {
      background-color: #a8abad !important;
    }
  }

  .bg-gray-dark-light {
    background-color: #717579 !important;
  }

  a.bg-gray-dark-light {
    &:hover,
    &:focus {
      background-color: #585c5f !important;
    }
  }

  button.bg-gray-dark-light {
    &:hover,
    &:focus {
      background-color: #585c5f !important;
    }
  }

  .bg-gray-dark-dark {
    background-color: #2a2e33 !important;
  }

  a.bg-gray-dark-dark {
    &:hover,
    &:focus {
      background-color: #131517 !important;
    }
  }

  button.bg-gray-dark-dark {
    &:hover,
    &:focus {
      background-color: #131517 !important;
    }
  }

  .bg-gray-dark-darker {
    background-color: #15171a !important;
  }

  a.bg-gray-dark-darker {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  button.bg-gray-dark-darker {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  .bg-gray-dark-darkest {
    background-color: #0a0c0d !important;
  }

  a.bg-gray-dark-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  button.bg-gray-dark-darkest {
    &:hover,
    &:focus {
      background-color: black !important;
    }
  }

  .bg-azure-lightest {
    background-color: #ecf7fe !important;
  }

  a.bg-azure-lightest {
    &:hover,
    &:focus {
      background-color: #bce3fb !important;
    }
  }

  button.bg-azure-lightest {
    &:hover,
    &:focus {
      background-color: #bce3fb !important;
    }
  }

  .bg-azure-lighter {
    background-color: #c7e6fb !important;
  }

  a.bg-azure-lighter {
    &:hover,
    &:focus {
      background-color: #97d1f8 !important;
    }
  }

  button.bg-azure-lighter {
    &:hover,
    &:focus {
      background-color: #97d1f8 !important;
    }
  }

  .bg-azure-light {
    background-color: #7dc4f6 !important;
  }

  a.bg-azure-light {
    &:hover,
    &:focus {
      background-color: #4daef3 !important;
    }
  }

  button.bg-azure-light {
    &:hover,
    &:focus {
      background-color: #4daef3 !important;
    }
  }

  .bg-azure-dark {
    background-color: #3788c2 !important;
  }

  a.bg-azure-dark {
    &:hover,
    &:focus {
      background-color: #2c6c9a !important;
    }
  }

  button.bg-azure-dark {
    &:hover,
    &:focus {
      background-color: #2c6c9a !important;
    }
  }

  .bg-azure-darker {
    background-color: #1c4461 !important;
  }

  a.bg-azure-darker {
    &:hover,
    &:focus {
      background-color: #112839 !important;
    }
  }

  button.bg-azure-darker {
    &:hover,
    &:focus {
      background-color: #112839 !important;
    }
  }

  .bg-azure-darkest {
    background-color: #0e2230 !important;
  }

  a.bg-azure-darkest {
    &:hover,
    &:focus {
      background-color: #020609 !important;
    }
  }

  button.bg-azure-darkest {
    &:hover,
    &:focus {
      background-color: #020609 !important;
    }
  }

  .bg-lime-lightest {
    background-color: #f2fbeb !important;
  }

  a.bg-lime-lightest {
    &:hover,
    &:focus {
      background-color: #d6f3c1 !important;
    }
  }

  button.bg-lime-lightest {
    &:hover,
    &:focus {
      background-color: #d6f3c1 !important;
    }
  }

  .bg-lime-lighter {
    background-color: #d7f2c2 !important;
  }

  a.bg-lime-lighter {
    &:hover,
    &:focus {
      background-color: #bbe998 !important;
    }
  }

  button.bg-lime-lighter {
    &:hover,
    &:focus {
      background-color: #bbe998 !important;
    }
  }

  .bg-lime-light {
    background-color: #a3e072 !important;
  }

  a.bg-lime-light {
    &:hover,
    &:focus {
      background-color: #88d748 !important;
    }
  }

  button.bg-lime-light {
    &:hover,
    &:focus {
      background-color: #88d748 !important;
    }
  }

  .bg-lime-dark {
    background-color: #62a82a !important;
  }

  a.bg-lime-dark {
    &:hover,
    &:focus {
      background-color: #4a7f20 !important;
    }
  }

  button.bg-lime-dark {
    &:hover,
    &:focus {
      background-color: #4a7f20 !important;
    }
  }

  .bg-lime-darker {
    background-color: #315415 !important;
  }

  a.bg-lime-darker {
    &:hover,
    &:focus {
      background-color: #192b0b !important;
    }
  }

  button.bg-lime-darker {
    &:hover,
    &:focus {
      background-color: #192b0b !important;
    }
  }

  .bg-lime-darkest {
    background-color: #192a0b !important;
  }

  a.bg-lime-darkest {
    &:hover,
    &:focus {
      background-color: #010200 !important;
    }
  }

  button.bg-lime-darkest {
    &:hover,
    &:focus {
      background-color: #010200 !important;
    }
  }

  .text-inherit {
    color: inherit !important;
  }

  .text-default {
    color: $dark-color !important;
  }

  .text-muted-dark {
    color: #6e7687 !important;
  }

  .bg-blue {
    background-color: #083dc1 !important;
    color: $white !important;
  }

  a.bg-blue {
    &:hover,
    &:focus {
      background-color: #1747bd !important;
    }
  }

  button.bg-blue {
    &:hover,
    &:focus {
      background-color: #1747bd !important;
    }
  }

  .text-blue {
    color: #083dc1 !important;
  }

  .bg-indigo {
    background-color: #5323a7 !important;
    color: $white !important;
  }

  a.bg-indigo {
    &:hover,
    &:focus {
      background-color: #3f51c1 !important;
    }
  }

  button.bg-indigo {
    &:hover,
    &:focus {
      background-color: #3f51c1 !important;
    }
  }

  .text-indigo {
    color: #5323a7 !important;
  }

  .bg-purple {
    background-color: #920bfa !important;
    color: $white !important;
  }

  a.bg-purple {
    &:hover,
    &:focus {
      background-color: #8c31e4 !important;
    }
  }

  button.bg-purple {
    &:hover,
    &:focus {
      background-color: #8c31e4 !important;
    }
  }

  .text-purple {
    color: #920bfa !important;
  }

  .text-lightpink-red {
    color: #ff7088 !important;
  }

  .text-lightgreen {
    color: #26eda2 !important;
  }

  .bg-pink {
    background-color: #bb08c0 !important;
    color: $white !important;
  }

  .bg-darkpink {
    background-color: #14a485 !important;
    color: $white !important;
  }

  a.bg-pink {
    &:hover,
    &:focus {
      background-color: #f33d7a !important;
    }
  }

  button.bg-pink {
    &:hover,
    &:focus {
      background-color: #f33d7a !important;
    }
  }

  .text-pink {
    color: #bb08c0 !important;
  }

  .bg-red {
    background-color: #dc0441 !important;
    color: $white !important;
  }

  a.bg-red {
    &:hover,
    &:focus {
      background-color: #a11918 !important;
    }
  }

  button.bg-red {
    &:hover,
    &:focus {
      background-color: #a11918 !important;
    }
  }

  .text-red {
    color: #dc0441 !important;
  }

  .bg-orange {
    background-color: #fc7303 !important;
    color: $white !important;
  }

  a.bg-orange {
    &:hover,
    &:focus {
      background-color: #fc7a12 !important;
    }
  }

  button.bg-orange {
    &:hover,
    &:focus {
      background-color: #fc7a12 !important;
    }
  }

  .text-orange {
    color: #fc7303 !important;
  }

  .bg-yellow {
    background-color: #ecb403 !important;
    color: $white !important;
  }

  a.bg-yellow {
    &:hover,
    &:focus {
      background-color: #c29d0b !important;
    }
  }

  button.bg-yellow {
    &:hover,
    &:focus {
      background-color: #c29d0b !important;
    }
  }

  .text-yellow {
    color: #ecb403 !important;
  }

  .bg-green {
    background-color: #2dce89 !important;
    color: $white !important;
  }

  a.bg-green {
    &:hover,
    &:focus {
      background-color: #15ca7c !important;
    }
  }

  button.bg-green {
    &:hover,
    &:focus {
      background-color: #15ca7c !important;
    }
  }

  .text-green {
    color: #2dce89 !important;
  }

  .bg-teal {
    background-color: #06c0d9 !important;
  }

  a.bg-teal {
    &:hover,
    &:focus {
      background-color: #22a193 !important;
    }
  }

  button.bg-teal {
    &:hover,
    &:focus {
      background-color: #22a193 !important;
    }
  }

  .text-teal {
    color: #06c0d9 !important;
  }

  .bg-cyan {
    background-color: #116e7c !important;
    color: $white !important;
  }

  a.bg-cyan {
    &:hover,
    &:focus {
      background-color: #117a8b !important;
    }
  }

  button.bg-cyan {
    &:hover,
    &:focus {
      background-color: #117a8b !important;
    }
  }

  .text-cyan {
    color: #116e7c !important;
  }

  a.bg-white {
    &:hover,
    &:focus {
      background-color: #f3f4f5 !important;
    }
  }

  button.bg-white {
    &:hover,
    &:focus {
      background-color: #f3f4f5 !important;
    }
  }

  .text-white {
    color: $white !important;
  }

  .bg-gray {
    background-color: #868e96 !important;
  }

  a.bg-gray {
    &:hover,
    &:focus {
      background-color: #6c757d !important;
    }
  }

  button.bg-gray {
    &:hover,
    &:focus {
      background-color: #6c757d !important;
    }
  }

  .text-gray {
    color: #969696 !important;
  }

  .bg-lightpink-red {
    color: #ff7088 !important;
  }

  .text-gray-dark {
    color: #343a40 !important;
  }

  .bg-azure {
    background-color: #45aaf2 !important;
  }

  a.bg-azure {
    &:hover,
    &:focus {
      background-color: #1594ef !important;
    }
  }

  button.bg-azure {
    &:hover,
    &:focus {
      background-color: #1594ef !important;
    }
  }

  .text-azure {
    color: #45aaf2 !important;
  }

  .bg-lime {
    background-color: #1e74a0 !important;
  }

  a.bg-lime {
    &:hover,
    &:focus {
      background-color: #63ad27 !important;
    }
  }

  button.bg-lime {
    &:hover,
    &:focus {
      background-color: #63ad27 !important;
    }
  }

  .text-lime {
    color: #1e74a0 !important;
  }

  .icon {
    color: $white !important;

    i {
      color: $white-7;
    }
  }

  a.icon:hover {
    color: $white !important;
  }

  .shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .shadow2 {
    box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0), 10px 10px 15px -5px #b0b8d6 !important;
  }

  .bg-primary-transparent {
    background-color: $primary-02 !important;
  }

  .bg-secondary-transparent {
    background-color: rgba(91, 115, 232, 0.2) !important;
    color: rgb(91, 115, 232) !important;
  }

  .bg-pink-transparent {
    background-color: rgba(236, 130, 239, 0.2) !important;
    color: rgb(236, 130, 239) !important;
  }

  .bg-cyan-transparent {
    background-color: rgba(17, 110, 124, 0.2) !important;
    color: rgb(17, 110, 124) !important;
  }

  .bg-danger-transparent {
    background-color: rgba(239, 75, 75, 0.2) !important;
    color: rgb(239, 75, 75) !important;
  }

  .bg-warning-transparent {
    background-color: rgba(255, 171, 0, 0.2) !important;
    color: rgb(255, 171, 0) !important;
  }

  .bg-info-transparent {
    background-color: rgba(62, 128, 235, 0.2) !important;
    color: rgb(62, 128, 235) !important;
  }

  .bg-success-transparent {
    background-color: rgba(56, 203, 137, 0.2) !important;
    color: rgb(56, 203, 137) !important;
  }

  .bg-teal-transparent {
    background-color: rgba(43, 203, 186, 0.2) !important;
    color: rgb(43, 203, 186) !important;
  }

  .bg-dark-transparent {
    background-color: #3c4151 !important;
    color: #f6f6f6 !important;
  }

  .fc-event .bg-dark {
    background-color: #f6f6f6 !important;
  }

  .bg-success-transparent-2 {
    background-color: rgba(56, 203, 137, 0.15) !important;
  }

  .bg-info-transparent-2 {
    background-color: rgba(34, 133, 204, 0.15) !important;
  }

  .bg-warning-transparent-2 {
    background-color: rgba(252, 191, 9, 0.15) !important;
  }

  .bg-danger-transparent-2 {
    background-color: rgba(250, 96, 96, 0.15) !important;
  }

  .bg-facebook {
    background: #2b4170 !important;
  }

  .bg-google {
    background: linear-gradient(to bottom right, #e64522, #c33219) !important;
    color: $white;
  }

  .bg-dribbble {
    background: linear-gradient(to bottom right, #ea4c89, #c93764) !important;
  }

  .card {
    background-color: $dark-theme;
    border-color: $border-dark;
    box-shadow: 0 2px 4px $dark-bg;
  }

  .card-footer {
    background-color: $dark-theme;
    border-block-start-color: $border-dark;
    color: $white-8;
  }

  .card-header {
    background: transparent;
    border-block-end-color: $border-dark;
  }

  .reg {
    color: #2e1170;
  }

  .card-title small,
  .card-subtitle {
    color: #9aa0ac;
  }

  .card-body + .card-table {
    border-block-start-color: $border-dark;
  }

  .card-options {
    color: #9aa0ac;

    a {
      &:not(.btn) {
        color: $dark-color;
      }

      &.option-dots {
        color: $dark-color;
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        border-color: $border-dark;

        &:hover {
          color: $dark-color !important;
          background: $dark-bg;
        }
      }
    }
  }

  .card-status {
    background: rgba(0, 40, 100, 0.12);
  }

  .header-search .btn {
    color: #8499c4;
  }

  .hor-header {
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  }

  .horizontalMenu {
    html,
    body,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $dark-color;
    }

    color: $white;

    > .horizontalMenu-list > li {
      > a {
        color: $dark-color;

        .arrow:after {
          border-inline-start: 4px solid rgba(0, 0, 0, 0);
          border-inline-end: 4px solid rgba(0, 0, 0, 0);
          border-block-start: 4px solid;
        }
      }

      &.rightmenu > .topmenusearch {
        input {
          color: #333;
          background-color: #efefef;

          &::placeholder {
            color: #a9a9a9;
          }

          &:focus {
            color: #333;

            ~ {
              .btnstyle .fa {
                color: $black;
                opacity: 1;
              }

              .searchicon {
                opacity: 1;
                color: $white;
              }
            }
          }
        }

        .btnstyle {
          color: #333;
          background-color: transparent;

          &:hover .fa {
            opacity: 1;
          }
        }
      }

      > {
        ul.sub-menu {
          background-color: $dark-bg;
          box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.2);

          > li > {
            a {
              color: $dark-color;
            }

            ul.sub-menu {
              background-color: $dark-bg;
              box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.2);

              > li > {
                a {
                  color: $dark-color;
                }

                ul.sub-menu {
                  background-color: $dark-bg;
                  box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.2);

                  > li > a {
                    color: $dark-color;
                  }
                }
              }
            }
          }
        }

        .horizontal-megamenu {
          color: $dark-color;

          .megamenu-content {
            border: 1px solid $border-dark;
            background-color: $dark-bg;
            box-shadow: 0px 5px 25px rgba(0, 1, 2, 0.2);
          }

          .title,
          .wsmwnutxt {
            color: $dark-color;
          }

          .link-list li a {
            color: $dark-color;
          }

          li a:hover {
            background: transparent;
          }

          .show-grid div {
            background-color: #dbdbdb;
            border: 1px solid #e7e7e7;
            color: #6a6a6a;
          }

          .menu_form {
            input[type="text"],
            textarea {
              border: 1px solid #e2e2e2;
              color: $black;
            }

            input {
              &[type="submit"],
              &[type="button"] {
                background-color: #e1e1e1;
              }
            }
          }

          .carousel-inner .item img {
            width: 100%;
          }

          .carousel-caption {
            background-color: $black-7;
          }

          .typography-text {
            p,
            ul li a {
              color: #656565;
            }
          }
        }
      }
    }
  }

  .horizontal-overlapbg {
    opacity: 0;
  }

  .sticky.sticky-pin .horizontal-main {
    box-shadow: -6px 5px 8px 0 rgba(24, 23, 38, 0.06);
  }

  .horizontal-main.hor-menu {
    background: $dark-bg;
    border-block-end: 1px solid transparent;
    box-shadow: 5px 7px 26px -5px rgba(24, 23, 38, 0.06);
  }

  .hor-menu .horizontalMenu {
    > .horizontalMenu-list > li > {
      .horizontal-megamenu .link-list li a {
        color: $dark-color;
      }

      a {
        &.active,
        &:hover {
          color: $white;
          background: rgba($dark-color, 0.11) !important;
        }
      }
    }

    a {
      &.active .hor-icon,
      &:hover .hor-icon {
        fill: white;
      }
    }
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after {
    color: $dark-color;
    opacity: 0.4;
  }

  .sub-menu li:before {
    color: #aab2bf;
  }

  .hor-icon {
    color: $dark-color;
    fill: $dark-color;
  }

  .demo-gallery {
    > ul > li a {
      border: 3px solid $white;

      &:hover .demo-gallery-poster > img {
        opacity: 1;
      }

      .demo-gallery-poster {
        background-color: $black-1;

        > img {
          opacity: 0;
        }
      }

      &:hover .demo-gallery-poster {
        background-color: $black-5;
      }
    }

    .justified-gallery > a {
      &:hover .demo-gallery-poster > img {
        opacity: 1;
      }

      .demo-gallery-poster {
        background-color: $black-1;
      }

      &:hover .demo-gallery-poster {
        background-color: $black-5;
      }
    }

    &.dark > ul > li a {
      border: 3px solid #04070a;
    }

    a {
      border: 1px solid transparent;

      &:hover {
        border: 1px solid transparent;
        background: transparent;
      }
    }
  }

  .price {
    color: $white;
    background-color: $dark-theme !important;
    border-radius: 5px;
  }

  .dark-panel-header {
    .lead {
      background-color: $dark-bg !important;
    }
  }

  .pricing {
    color: $white;
    background-color: $dark-theme;
    border-radius: 0px;
  }

  .pricing {
    .list-unstyled li {
      border-block-end: 1px solid $border-dark;
    }
  }

  .pricing-tabs {
    .pri-tabs-heading2 {
      ul.nav-price li a.active {
        background: $primary;
      }
    }
  }

  .plan-card {
    .plan-icon {
      color: $white;
      border: 4px solid rgba(242, 246, 249, 0.55);
    }

    .plan-features p {
      border-block-end: 1px solid $border-dark;

      &:first-child {
        border-block-start: 1px solid $border-dark;
      }
    }
  }

  .panel-heading {
    background: $dark-theme;
    border-radius: 0px;
  }

  .price .list-group-item {
    border-block-end: 1px solid rgba(250, 250, 250, 0.5);
  }

  .panel.price {
    border: 1px solid $border-dark;
  }

  .price {
    .panel-footer,
    &.panel-color > .panel-body {
      background-color: $dark-theme;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .horizontalMenu {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;

      > .horizontalMenu-list {
        background: $dark-bg;

        > li {
          background-color: transparent;

          > a {
            color: $dark-color;
            border-block-end-color: transparent;
          }

          a.menuhomeicon {
            border-block-start: solid 1px $black-5;
          }

          > {
            a > .fa {
              color: $white;
            }

            ul.sub-menu {
              background-color: $dark-bg;

              > li > a {
                color: $dark-color;

                &:hover {
                  background-color: transparent;
                  color: $dark-color;
                }
              }

              li:hover > a {
                color: $dark-color;
              }
            }
          }
        }
      }
    }

    .horizontalMenu > .horizontalMenu-list > li > {
      ul.sub-menu > li > ul.sub-menu > li {
        > a {
          &:hover {
            background-color: transparent;
            color: #576482;
          }

          &.active {
            color: $black;
          }
        }

        &:hover > a {
          color: $black;
        }

        > ul.sub-menu > li > a {
          &:hover {
            background-color: transparent;
            color: #576482;
          }

          &.active {
            color: $black;
          }
        }
      }

      .horizontal-megamenu {
        color: #576482;
        border-block-end: 1px solid rgba(0, 0, 0, 0.13);

        .title {
          color: #576482;
        }

        > ul > li > a {
          background-color: #e7e7e7;
          color: #576482;

          &:hover {
            background-color: $black;
          }
        }

        ul li.title {
          color: #576482;
          background-color: transparent;
        }
      }
    }

    .horizontal-header {
      border-block-end: 1px solid $black-1;
      border-block-start: 1px solid $black-1;
    }

    .callusbtn {
      color: #a9a9a9;

      &:hover .fa {
        color: #a9a9a9;
      }
    }

    .animated-arrow span {
      background: $dark-color;

      &:before,
      &:after {
        background: $dark-color;
      }
    }

    .active .animated-arrow span {
      background-color: transparent;
    }

    .horizontal-overlapbg {
      background-color: rgba(0, 0, 0, 0.45);
    }

    .active .horizontalMenu .horizontal-overlapbg {
      opacity: 1;
    }

    .horizontalMenu > .horizontalMenu-list > li > {
      .horizontalMenu-click > i {
        color: $dark-color;
      }

      ul.sub-menu > li {
        .horizontalMenu-click02 > i {
          color: $dark-color;
          opacity: 0.4;
        }

        > ul.sub-menu {
          background: transparent;
        }
      }

      .horizontal-megamenu .link-list li a {
        color: $white-6;
      }
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
      color: $dark-color;
    }
  }

  @media (min-width: 992px) {
    .horizontalMenu > .horizontalMenu-list > li {
      > ul.sub-menu {
        opacity: 0;
        border: 1px solid $border-dark;
      }
  
      &:hover > {
        ul.sub-menu,
        .horizontal-megamenu {
          opacity: 1;
        }
      }
  
      > {
        ul.sub-menu > li {
          > ul.sub-menu {
            opacity: 0;
            border: 1px solid $border-dark;
          }
  
          &:hover > ul.sub-menu {
            opacity: 1;
          }
  
          > ul.sub-menu > li {
            > ul.sub-menu {
              opacity: 0;
              border: 1px solid $border-dark;
            }
  
            &:hover > ul.sub-menu {
              opacity: 1;
            }
          }
        }
  
        .horizontal-megamenu {
          opacity: 0;
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    ul.sub-menu {
      background-color: transparent !important;
    }

    .responsive-navbar.navbar .navbar-collapse {
      background: $dark-theme;
      border-block-end: 1px solid $border-dark;
      border-block-start: 1px solid $border-dark;
      box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    }
  }

  @media (min-width: 991px) {
    .hor-header {
      .form-inline .btn {
        background-color: $primary !important;
        opacity: 1;
  
        .search-icon {
          fill: white !important;
        }
      }
  
      &.header .form-inline .form-control.header-search {
        border: 1px solid $border-dark !important;
      }
    }
  }

  .panel-footer {
    background-color: #f7f7f7;
    border-block-start: 1px solid $border-dark;
  }

  
  .profile-dropdown {
    margin-block-start: 1px;
    border-inline-end: 1px solid $border-dark !important;
  }

  .light-layout {
    display: none;
  }

  .dark-layout {
    display: block;
  }

  .invoicelist {
    .sub-panel-heading .tabs-menu ul li a {
      border: 0;
    }
  }

  .panel.price .btn {
    box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
  }

  .purchase {
    color: $dark-color !important;
  }

  .pricing-tabs {
    ul.nav-price {
      border: 1px solid $border-dark;
      background: $dark-theme;

      li a {
        background: $dark-theme;

        &.active {
          color: $white;
          background: $primary;
        }
      }
    }

    .pri-tabs-heading2 ul.nav-price {
      border: 1px solid $border-dark;
      background: $dark-theme;

      li a {
        background: $dark-theme;
      }
    }
  }

  .profile-cover .cover {
    border: 10px solid $white;

    .inner-cover img {
      border: 1px solid transparent;
    }

    ul.cover-menu {
      background: none repeat scroll 0% 0% rgba(0, 0, 0, 0.24);

      li {
        &:hover {
          background-color: rgba(0, 0, 0, 0.44);
        }

        &.active {
          background-color: rgba(0, 0, 0, 0.64);
        }

        a {
          color: $white;
        }
      }
    }
  }
  
  @media (min-width: 992px) {
    .plan-card.active {
      box-shadow: none !important;
    }

    .panel.price.active {
      box-shadow: 0px 10px 10px 20px rgba(35, 40, 60, 0.9), 10px 10px 15px -5px rgba(14, 20, 44, 0.16);
    }
  }

  &.register1 .form-control {
    color: $dark-color;
    background-color: transparent;
    border-inline-start: 0;
  }
  
  &.register1 .form-control:focus {
    border-color: $border-dark !important;
  }
  
  #checkoutsteps .item {
    border-block-end: 1px solid $border-dark;
  }

  #checkoutsteps > .steps .number {
    background-color: $dark-theme;
  }

  #checkoutsteps .checkoutline {
    background-color: $dark-bg;
  }

  .side-menu__item.active .side-menu__label,
  .side-menu__item:hover .side-menu__label,
  .side-menu__item:focus .side-menu__label {
    color: $white !important;
  }

  .side-menu__item.active .side-menu__icon,
  .side-menu__item:hover .side-menu__icon,
  .side-menu__item:focus .side-menu__icon {
    fill: $white !important;
  }

  .side-menu__item.active .angle,
  .side-menu__item:hover .angle,
  .side-menu__item:focus .angle {
    color: $white !important;
  }

  .side-menu__item.active,
  .side-menu__item:hover,
  .side-menu__item:focus {
    text-decoration: none;
    background: rgba($dark-color, 0.11);
    color: $white !important;
  }

  &.sidenav-toggled {
    .side-menu__item:focus {
      background: transparent !important;

      .side-menu__icon {
        fill: $white;
      }
    }
  }

  &.sidenav-toggled1 {
    .side-menu__item:focus {
      background: rgba($dark-color, 0.11) !important;

      .side-menu__icon {
        fill: $white;
      }
    }
  }

  @media (max-width: 991px) {
    .horizontalMenu-list > li:hover {
      background-color: rgba($dark-color, 0.11);
      color: $white !important;
    }
  }

  .panel-footer {
    border-inline-start: 0px solid $border;
    border-inline-end: 0px solid $border;
  }

  .theme-white .cookie-popup-learn-more {
    color: $dark-color;
    background: $dark-bg;
  }

  .hor-header .header-brand {
    .desktop-lgo {
      display: none;
    }

    .dark-logo {
      display: block;
    }
  }

  @media (min-width: 992px) {
    .sidenav-toggled .app-sidebar__logo {
      padding: 13px 9px !important;
    }
  }

  .fc-daygrid-day-frame {
    background-color: $dark-bg;
  }

  .form-check-input {
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
    &:focus {
      box-shadow: none;
    }
   }

  .plan-card .plan-price {
    border-block-start: 1px solid $border-dark;
  }

  .form-select {
      color: $dark-color;
      border-color: $border-dark;
      background-color: $dark-theme;
      &:focus {
        border-color: $border-dark;
        box-shadow: none;
    }
    
    &:focus::-ms-value {
        color: $dark-color;
        background-color: $dark-theme;
    }
    
    &:disabled {
        color: $white-1;
        background-color: $dark-theme;
    }
  }

  .tree ul li:before {
    border-block-start: 2px solid $border-dark;
  }

  .tree ul:before {
    border-inline-start: 2px solid $border-dark;
  }

  .tree li.branch li {
    background: $dark-theme;
  }

  .tree li a {
    color: $dark-color;
  }

  .tree li.branch {
    background: $dark-theme;
  }

  .tree li {
    color: $dark-color;
    border: 1px solid $border-dark;
  }

  .ql-toolbar.ql-snow .ql-picker-label {
    border-inline-end: $border-dark solid 1px !important;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: $border-dark;
    background-color: $dark-theme;
  }

  .ql-snow .ql-tooltip {
    background-color: $dark-theme;
    border: 1px solid $border-dark;
    color: $white;
    box-shadow: 0px 0px 5px $dark-bg;
  }

  .ql-container.ql-snow {
    border: 1px solid $border-dark;
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $primary;
    background: $border-dark;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    border-inline-end: $border-dark solid 1px;
    color: white;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid $border-dark;
    background: $dark-bg;
    color: $white;
  }

  .richText .richText-toolbar ul li a:hover {
    background-color: $border-dark;
  }

  .richText .richText-editor {
    background-color: $dark-bg;
    border-inline-start: $border-dark solid 1px;
  }

  .sw-theme-dots > ul.step-anchor > li > a:before {
    background-color: $border-dark;
  }

  .sw-theme-dots > ul.step-anchor:before {
    background-color: $border-dark;
  }

  .sw-theme-dots .sw-toolbar {
    background: $dark-theme;
  }

  .sw-theme-dots .step-content {
    background-color: $dark-theme;
  }

  .sw-theme-dots > ul.step-anchor {
    background: $dark-theme;
    border: 0px solid $border-dark !important;
  }

  .multi-wrapper .item-1 {
    color: $dark-color;
  }

  .multi-wrapper .selected-wrapper {
    background: $dark-bg;
  }

  .multi-wrapper .search-input {
    border-block-end: 1px solid $border-dark;
    background: $dark-bg;
    color: $dark-color;
  }

  .multi-wrapper {
    border: 1px solid $border-dark;
  }

  .multi-wrapper .non-selected-wrapper {
    background: $dark-bg;
    border-inline-end: 1px solid $border-dark;
  }

  .btn-select-arrow {
    color: $dark-color;
    background: $dark-bg;
    border: 1px solid $border-dark;
  }

  .transfer-double-selected-list-search-input {
    color: $dark-color !important;
    border: 1px solid $border-dark;
    background: $dark-bg;
  }

  .transfer-double-content-param {
    border-block-end: 1px solid $border-dark;
  }

  .transfer-double-content-right {
    border: 1px solid $border-dark;
  }

  .ms-search input {
    border: 1px solid $border-dark;
    background: $dark-bg;
    color: $dark-color;
  }

  .checkbox-group label:before {
    background: $border-dark;
    border: 1px solid $border-dark;
  }

  .ql-snow .ql-tooltip input[type=text] {
    border-color: $border-dark;
    background: $dark-bg;
    color: $dark-color;
  }

  .transfer-double-list-footer {
    border-block-start: 1px solid $border-dark;
  }

  .transfer-double-content-tabs {
    border-block-end: 1px solid $border-dark;
  }

  .transfer-double-content-tabs .tab-item-name.tab-active {
    background: $dark-bg;
  }

  .transfer-double-list-search-input {
    border: 1px solid $border-dark;
    color: $dark-color;
    background-color: $dark-bg;
  }

  .transfer-double-content-left {
    border: 1px solid $border-dark;
  }

  .transfer-double {
    background-color: $dark-bg;
  }

  .intl-tel-input .flag-dropdown .country-list .divider {
    border-block-end: 1px solid $border-dark;
  }

  .intl-tel-input .flag-dropdown .country-list .country .dial-code {
    color: $dark-color;
  }

  .intl-tel-input .flag-dropdown .country-list .country.highlight {
    background-color: $border-dark;
  }

  .intl-tel-input .flag-dropdown .country-list {
    background-color: $dark-theme;
  }

  .intl-tel-input .flag-dropdown .selected-flag .down-arrow {
    border-block-start: 4px solid $dark-color;
  }

  .intl-tel-input .flag-dropdown .selected-flag {
    background: $dark-bg;
  }

  .intl-tel-input .flag-dropdown .selected-flag:hover {
    background-color: $dark-bg !important;
  }

  .intl-tel-input input {
    border: 1px solid $border-dark;
    background: $dark-bg;
    color: $dark-color;
  }

  .SumoSelect > .optWrapper.multiple > .options li.opt span i,
  .SumoSelect .select-all > span i {
    border: 1px solid $border-dark;
    background-color: $dark-theme;
  }
  .SumoSelect>.optWrapper.multiple>.options li.opt.selected span i {
    border-color: $border-dark;
  }

  .SumoSelect.open .search-txt {
    background: transparent;
    color: $dark-color;
  }

  .SumoSelect > .optWrapper > .options li.opt {
    border-block-end: 1px solid $border-dark;
  }

  .SumoSelect > .optWrapper > .options li.opt:hover {
    background-color: $dark-bg;
  }

  .SumoSelect .select-all {
    border-block-end: 1px solid $border-dark;
    background-color: #1d202b;
  }

  .SumoSelect.open > .optWrapper {
    box-shadow: 0px 16px 18px $dark-bg;
    background: $dark-theme;
    border: 1px solid $border-dark;
  }

  .SumoSelect .select-all.partial>span i {
    background-color: $dark-theme;
  }

  .SumoSelect.disabled > .CaptionCont {
    border-color: $dark-theme;
    background: $black-1;
    border-radius: 0;
  }
  .SumoSelect.disabled .form-control {
    color: transparent;
  }

  .SumoSelect > .CaptionCont {
    border: 1px solid $border-dark;
    color: $dark-color;
    background-color: $dark-bg;
  }

  .ms-choice.disabled {
    background-color: $black-1;
    color: $dark-color;
    border: 1px solid $border-dark;
  }

  .ms-drop .custom-control-label::before {
    background-color: $dark-theme;
  }

  .ms-drop {
    background: $dark-theme;
    color: $dark-color;
    border: 1px solid $border-dark;
  }

  .ms-drop.bottom {
    box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.5);
  }

  .ms-choice {
    background-color: $dark-bg;
    border: 1px solid $border-dark;
  }

  table.dataTable>tbody>tr.child ul.dtr-details>li {
    border-block-end-color: $border-dark;
  }

  .datepicker table tr td span.old,
  .datepicker table tr td span.new {
    color: #7f88a5;
  }

  .datepicker .datepicker-switch {
    color: $primary !important;
  }

  .datepicker td {
    color: $dark-color;
  }

  .datepicker th {
    color: $white;
  }

  .datepicker-dropdown.datepicker-orient-top:after {
    border-block-start: 6px solid $dark-bg;
  }

  .sp-picker-container {
    border-inline-start: solid 1px $border-dark;
  }

  .sp-container {
    background-color: $dark-bg !important;
    border: solid 1px $border-dark !important;
    box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.5) !important;
  }

  .sp-replacer {
    border: solid 1px $border-dark;
    background: $dark-bg;
    color: $dark-color;
  }

  .sp-replacer:hover,
  .sp-replacer.sp-active {
    border-color: $dark-bg;
    color: $dark-color;
    border: solid 1px $border-dark;
  }

  .ui-timepicker-list li {
    color: $dark-color !important;
  }

  .ui-timepicker-wrapper {
    background: $dark-theme;
    border: 1px solid $border-dark;
    color: $dark-color !important;
  }

  .select2-results {
    background-color: $dark-bg;
  }

  .select2-dropdown {
    background-color: $dark-theme;
    border: 1px solid $border-dark;
  }

  .ff_fileupload_wrap .ff_fileupload_dropzone:hover,
  .ff_fileupload_wrap .ff_fileupload_dropzone:focus,
  .ff_fileupload_wrap .ff_fileupload_dropzone:active {
    opacity: 1;
    background-color: $dark-bg !important;
    border-color: $border-dark;
  }

  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button:hover {
    background-color: $black-1;
  }

  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary {
    color: $white-4;
  }

  .ff_fileupload_wrap .ff_fileupload_dropzone {
    background-color: $dark-bg;
    border: 2px dashed $border-dark;
  }

  .dropify-wrapper .dropify-preview {
    background-color: $dark-theme;
  }

  .dropify-wrapper {
    background-color: $dark-theme;
    border: 1px solid $border-dark;
  }

  .dropify-wrapper:hover {
    background-image: linear-gradient(
      -45deg,
      $dark-bg 25%,
      transparent 25%,
      transparent 50%,
      $dark-bg 50%,
      $dark-bg 75%,
      transparent 75%,
      transparent
    ) !important;
  }

  .table-success {

    th,
    td {
      border-block-start: none;
    }
  }

  .table-danger {
    --bs-table-bg: #e87b85;

    th,
    td {
      border-block-start: none;
    }
  }

  .table-inbox {
    border: 1px solid $border-dark;
  }

  .sweet-alert {
    background-color: $dark-theme;
  }

  .sweet-alert h2 {
    color: $white;
  }

  .irs-line-left {
    background-color: $border-dark;
  }

  .irs--flat .irs-line,
  .irs--flat .irs-grid-pol {
    background-color: $white-2;
  }

  .irs--flat .irs-grid-text {
    color: $dark-color;
  }

  .irs--flat .irs-min, .irs--flat .irs-max {
    color: $dark-color;
    background-color: $white-1;
  }

  .irs-line-mid {
    background-color: $border-dark;
  }

  .irs-line-right {
    background-color: $border-dark;
  }

  .irs-min,
  .irs-max {
    background: $border-dark;
    color: $white;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $dark-color;
  }

  .card-body + .card-body {
    border-block-start: 1px solid $border-dark;
  }

  .border-cyan {
    border-color: #116e7c !important;
  }

  .theme-white .cookie-popup-inner {
    border: 1px solid $border-dark;
  }

  .theme-white .cookie-popup {
    background-color: $dark-theme;
    color: $dark-color;
  }

  .cookie-popup.position-topleft {
    box-shadow: 5px 7px 26px -5px $black-1;
  }

  .fc .fc-non-business {
    background: none;
  }

  .fc-theme-standard .fc-scrollgrid {
    border: 1px solid $border-dark;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border-color: $border-dark;
  }

  .fc .fc-daygrid-day-number {
    color: $dark-color;
  }

  .main-contact-label::after {
    content: none;
  }

  .main-chat-body {
    background-color: $dark-bg;
  }

  .main-chat-list .media.selected .media-contact-name span:first-child {
    color: $dark-color;
  }

  .msb-reply textarea {
    color: #d1d2d5;
  }

  .chat-body-style {
    background-color: $dark-theme;
  }

  .tab_wrapper > ul li {
    background: $dark-bg;
    border-block-end: 0 !important;
  }

  .tab_wrapper .content_wrapper {
    border-color: $border-dark;
  }

  .popover-static-demo {
    background-color: $dark-bg;
    border: 1px solid $border-dark;
  }

  .nav-pills .nav-link {
    background: $white-1;
    color: $dark-color;
    &.active{
      background: $primary;
    }
  }

  .nav1 {
    background: $dark-bg;
  }

  .app-sidebar {
    background: $dark-theme !important;
    border-inline-end: 1px solid $border-dark;
    border-block-start: none;
  }

  .app-sidebar .side-item.side-item-category {
    color: $dark-color;
  }

  .app-sidebar__toggle {
    border-inline-end: 1px solid $border-dark;
  }

  .app-header {
    border-block-end: 1px solid $border-dark !important;
  }

  .app-sidebar__logo {
    border-block-end: 1px solid $border-dark;
    border-inline-end: 1px solid $border-dark;
    background: $dark-theme;
  }

  @media (min-width: 992px) {
    &.sidenav-toggled1 .app-sidebar__logo .header-brand-img {
      &.dark-logo {
        margin-block-start: 4px;
      }
    }

    .app-sidebar__logo .header-brand-img {
      &.dark-logo {
        display: block !important;
      }

      &.desktop-lgo {
        display: none;
      }
    }

    &.sidenav-toggled .app-sidebar__logo .header-brand-img {
      &.dark-logo,
      &.mobile-logo {
        display: none !important;
      }

      &.darkmobile-logo {
        display: block;
      }
    }

    &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo .header-brand-img {
      &.dark-logo {
        display: block !important;
      }

      &.desktop-lgo,
      &.darkmobile-logo {
        display: none;
      }
    }

    @media (min-width: 992px) {
      &.sidenav-toggled {
        &.sidenav-toggled1 .app-sidebar__logo {
          border-inline-end: 1px solid $border-dark;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .app-header .form-control:focus {
      border: 1px solid $border-dark !important;
    }
    
    .header-brand-img {
      &.dark-logo {
        display: block !important;
        margin: 0 auto;
        text-align: center;
        inset-inline-start: 0;
        inset-inline-end: 0;
        position: absolute;
      }

      &.desktop-lgo {
        display: none !important;
      }
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: $dark-color;
  }

  .breadcrumb-item span {
    color: $dark-color;
  }

  .dropdown-menu {
    color: $dark-color;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .form-inline .btn:focus {
    box-shadow: none;
  }

  .apexcharts-canvas text {
    fill: $dark-color;
  }

  #statistics {
    .apexcharts-canvas line {
      stroke: #a7a7a721;
    }
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-style: none;
  }

  .table td {
    color: $dark-color;
  }

  .dataTables_wrapper .form-control {
    border-color: $border-dark;
  }

  .form-control {
    border-color: $border-dark;
    background-color: $dark-bg;
    color: $dark-color;
  }

  .btn-close {
    background-image: none !important;
  }

  &::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-thumb {
    background: #000 !important;
  }

  @media (min-width: 992px) {
    &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
      border-inline-end-color: $border-dark !important;
    }
  }

  
  .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a:hover,
  .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a.active {
    color: $white;
  }

  .hor-menu .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover,
  .hor-menu .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
    color: $white;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li h3 {
    color: rgba($dark-color, 0.65);
  }

  @media (min-width: 992px) {
    .theme-layout {
      border-inline-start: 1px solid $border-dark !important;
    }
  }

  .multi-wrapper .non-selected-wrapper {
    background: $dark-bg;
  }

  
  .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
    background: transparent;
    color: $dark-color;
  }
  .richText .richText-editor table td {
    border-color: $border-dark;
  }

  .richText .richText-form input[type="text"],
  .richText .richText-form input[type="file"],
  .richText .richText-form input[type="number"],
  .richText .richText-form select {
    background-color: $dark-theme;
    color: $dark-color;
    border-color: $border-dark;
  }

  .richText .richText-initial {
    background-color: $dark-theme
  }

  .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown {
    background-color: $dark-theme;
    border: $border-dark solid 1px;
  }

  .progress-bar-custom {
    background-color: #6563ff;
  }

  .slide-menu a.active {
    color: $white !important;
  }

  .slide-menu a.active::before, .slide-item a:hover::before, .slide-item a:focus::before {
    color: $white !important;
  }

  .ps__thumb-y {
    background-color: #3c4151;
  }

  .fc-daygrid-day-frame {
    background-color: $dark-bg !important;
  }

  .price .text-muted {
    color: #adb1c5 !important;
  }

  .wizard.vertical > .actions {
    border-inline-start-color: $border-dark;
  }

  .wizard.vertical > .content {
    border-inline-start-color: $border-dark;
  }

  .todo-list2,.e-panel {
    .option-dots {
      background: $dark-bg;
    }
  }

  .tab_wrapper > ul li.active:after {
    content: "";
    background: $dark-bg;
  }

  .main-chat-contacts-more {
    background-color: $dark-bg;
  }

  @media (min-width: 992px) {
    .main-chat-footer .form-control {
      padding-inline-start: 10px;
    }
  }

  .irs-modern .irs-slider {
    background-color: $dark-bg;
  }

  .irs-outline .irs-slider {
    background-color: $dark-bg;
  }

  .table-success td,
  .table-success th {
    color: $dark-bg;
  }

  .table-danger td,
  .table-danger th {
    color: $dark-bg;
  }

  .card-header h3 {
    color: $white-8 !important;
  }

  .mini:hover {
    color: $white;
  }

  .panel-group .panel {
    box-shadow: none;
    border: 1px solid $border-dark;
  }

  .np-btn {
    color: $white;
  }

  .table-inbox tr.unread td {
    font-weight: 400;
    color: $white;
  }

  .form-control::placeholder {
    opacity: 0.5;
  }

  .custom-control-input:focus {
    border: 1px solid red;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .modal-bg-color {
    .bg-light {
      background-color: $dark-bg !important;
    }
  }

  .sub-panel-heading .tabs-menu ul li a.active {
    background: $dark-bg;
  }

  .label-primary {
    background-color: $primary;
  }

  .bootstrap-tagsinput input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: $white !important;
    padding: 0 6px;
    margin: 0;
    border-radius: 3px;
    font-family: initial;
    font-size: initial;
    line-height: initial;
  }

  @media (max-width: 991px) {
    &.register1 .bg-dark {
      border-start-start-radius: 7px !important;
      border-end-start-radius: 7px !important;
    }
  }

  .legend {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 2px;
    margin-inline-end: 10px;
    margin-block-start: 6px;
  }

  .morris-legends {
    margin-block-end: 0;
    list-style: none;
    padding-inline-start: 10px;
    text-align: center;
  }

  .bg-primary-light-1 {
    background-color: #8976d5;
  }

  .bg-primary-light-2 {
    background-color: #ab9de1;
  }

  .bg-success-light-1 {
    background-color: #5dd59f;
  }

  .bg-success-light-2 {
    background-color: #85e0b7;
  }

  .bg-warning-light-1 {
    background-color: #ffbb33;
  }

  .bg-warning-light-2 {
    background-color: #ffcc66;
  }

  .bg-danger-light-1 {
    background-color: #f37272;
  }

  .bg-danger-light-2 {
    background-color: #f7a1a1;
  }

  &.register1 {
    .input-group-text {
      color: $dark-color !important;
      background-color: transparent;
    }
  }

  
  #delete-datatable tr.selected {
    background: rgba($dark-color, 0.11);
  }

  .country-selector {
    padding: 0.3rem;
    border-inline-end: 1px solid $border-dark !important;
  }

  .country-selector .nav-link {
    color: #d4d7e2 !important;
  }

  .SumoSelect > .optWrapper > .MultiControls {
    border-block-start: 1px solid $border-dark;
    background-color: $dark-bg;
  }

  .SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
    border-inline-end: 1px solid $border-dark !important;
  }

  .SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
    background-color: $dark-bg !important;
  }

  .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a:hover {
    background-color: $dark-bg !important;
  }

  .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a {
    border-block-end: $border-dark solid 1px !important;
  }

  @media (min-width: 576px) and (max-width: 992px) {
    .wizard.vertical > .steps .current a .title {
      display: none !important;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $dark-theme !important;
  }

  .intl-tel-input .flag-dropdown .selected-flag {
    border-color: $border-dark;
  }

  &.register1 {
    .bg-white {
      background-color: $dark-theme !important;
      border: 1px solid $border-dark;
    }

    .text-dark {
      color: #e3e6f3 !important;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: $dark-color !important;
  }

  .bg-white-50 {
    background-color: rgba(225, 225, 225, 0.2);
  }

  &.icontext-menu .sub-slide2.is-expanded .sub-angle2 {
    -webkit-transform: rotate(180deg) !important;
    -ms-transform: rotate(90deg) !important;
    transform: rotate(180deg) !important;
  }

  &.icontext-menu .sub-slide-item {
    color: #e3e6f3 !important;
  }

  &.icontext-menu .slide-item {
    color: #e3e6f3 !important;
  }

  &.icontext-menu .sub-side-menu__item {
    color: #e3e6f3 !important;
  }

  &.icontext-menu .app-sidebar__toggle {
    border-inline-start: 1px solid $border-dark !important;
  }

  &.icontext-menu .app-sidebar {
    border-inline-start: 1px solid $border-dark !important;
  }


  @media (min-width: 992px) {
    &.double-menu-tabs.app .app-sidebar,
    &.double-menu.app .app-sidebar {
      .app-sidebar__logo .mobile-logo,
      .app-sidebar__logo .header-brand-img.dark-logo {
        display: none !important;
      }

      .app-sidebar__logo {
          border-inline-end-color: $border-dark !important;
      }

      .slide-menu .tabs-menu ul li a {
        border-color: $border-dark;
      }

      .side-account li {
        a {
          color: $dark-color;
        }
      }

      .app-sidebar__logo .darkmobile-logo {
        display: block !important;
      }

      .slide-menu {
        background: $dark-theme
      }

      .slide .slide-menu.double-menu-active {
        border-inline-end-color: $border-dark;
      }
      
      .side-menu-label1 a {
        color: $dark-color !important;
      }
      .activity {
        border-inline-start-color: $white-1;
      }
      .latest-timeline .timeline:before {
        background: $white-1;
      }
    }

    &.double-menu.app,
    &.double-menu-tabs.app {
      .sub-slide a {
        color: $dark-color;
      }
    }

    &.double-menu-tabs.app {
      .slide-menu .tabs-menu ul li a {
        border-color: $border-dark;
        color: $dark-color;
      }
    }
  }

  .custom-switcher .onoffswitch2-label {
    border: 1px solid $border-dark !important;
    background-color: #343748 !important;
  }

  .custom-switcher .onoffswitch2-label:before {
    background: $primary;
    border: 1px solid $border-dark;
  }

  #chart8, #chart9 {
    .apexcharts-radialbar-track.apexcharts-track {
      path {
        stroke: $dark-bg;
      }
    }
  }

  
  .toast {
    background-color: $dark-theme;
    border: 1px solid $border-dark;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2) !important;
  }

  .toast-header {
    background-color: $dark-theme;
    border-block-end: 1px solid $border-dark;
    &.bg-primary, &.bg-success, &.bg-secondary, &.bg-warning, &.bg-info, &.bg-dark {
      .btn-close {
        color: initial;
      }
    }
  }

  .toast-main {
    border: 1px solid $border-dark;
  }

  .toast-header .btn-close {
    font-weight: 500;
    margin-block-start: 0px;
    margin-inline-end: 0px;
    font-size: 20px;
  }

  .border-top {
    border-block-start-color: $border-dark !important;
  }

  .border-top-bottom {
    border-block-start-color: $border-dark !important;
    border-block-end-color: $border-dark !important;
  }

  .demo_changer .form_holder {
    background-color: $dark-theme;
    border-inline-start: 1px solid $border-dark;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
  }

  .demo_changer h4 {
    border-block-end: 1px solid $border-dark;
    background: $white-3;
    border-block-start: 1px solid $border-dark;
    color: $white !important;
  }

  .demo_changer,
  .demo_changer p {
    color: $white-8 !important;
  }

  @media (min-width: 992px) {
    &.hover-submenu {
      &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo .header-brand-img.dark-logo {
        display: none !important;
      }

      &.sidenav-toggled .app-sidebar__logo {
        width: 110px;
        border-inline-end: 1px solid $border-dark !important;
      }

      &.sidenav-toggled .app-sidebar__logo .header-brand-img.darkmobile-logo {
        display: block;
      }

      &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
        background: $dark-theme !important;
        border: 1px solid $border-dark;
        border-inline-start: 0px;
      }

      &.app.sidenav-toggled1 .side-menu__label {
        color: $dark-color;
      }
    }
  }

  
  @media (min-width: 992px) {
    &.hover-submenu1 {
      &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo .header-brand-img.dark-logo {
        display: none !important;
      }

      .sidenav-toggled .app-sidebar__logo {
        width: 110px;
      }

      &.sidenav-toggled .app-sidebar__logo .header-brand-img.darkmobile-logo {
        display: block;
      }

      &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
        background: $dark-theme !important;
        border: 1px solid $border-dark;
        border-inline-start: 0px !important;
      }

      &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 {
        border-block-end: 1px solid $border-dark;
      }

      &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 a {
        color: $dark-color;
      }

      &.app.sidenav-toggled1 .side-menu__label {
        color: $dark-color;
      }
    }
  }

  
  @media (min-width: 992px) {
    &.horizontal .horizontal-main .slide .slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu,
    &.horizontal .horizontal-main .slide .sub-slide-menu2 {
      background-color: $dark-theme;
      border: 1px solid $border-dark;
      box-shadow: none !important;
    }

    &.horizontal.layout-boxed .main-sidemenu .slide-left,
    &.horizontal.layout-boxed .main-sidemenu .slide-right {
      background: $dark-bg;
      border: 1px solid $border-dark;
    }

    &.horizontal .main-sidemenu .slide-left,
    &.horizontal .main-sidemenu .slide-right {
      background: $dark-bg;
      border: 1px solid $border-dark;
    }

    &.horizontal .main-sidemenu .slide-right svg,
    &.horizontal .main-sidemenu .slide-left svg {
      fill: $white;
    }

    &.horizontal.layout-boxed .hor-header .demo-icon .app-sidebar {
      border-inline-end: 0px solid $border-dark !important;
    }

    &.horizontal .main-sidemenu .slide-left,
    .dark-mode.horizontal .main-sidemenu .slide-right {
      background: $dark-bg;
      border: 1px solid var(--dark-border);
    }

    &.light-header.horizontal .hor-header .form-inline .form-control {
      border: 1px solid $border !important;
    }
  }
  .owl-nav button {
    background: rgba(255, 255, 255, 0.2) !important;
    border: 1px solid $border-dark !important;
    box-shadow: 0 4px 15px $black-1;
  }
  .file-image-1 {
    border-color: $border-dark;
  }
  .file-image-1 .file-name-1 {
    color: $white-7;
  }
  #morrisDonut4 text,
  #morrisDonut3 text,
  #morrisDonut2 text,
  #morrisDonut1 text {
    fill: $white-7;
  }
  &.login-page {
    .desktop-lgo {
      display: none;
    }
    .dark-logo {
      display: block;
    }
  }
  .tab_wrapper .content_wrapper .accordian_header.active {
    border-color: $border-dark;
  }
  .tab_wrapper .content_wrapper .accordian_header .arrow {
    background: transparent;
  }
  .tab_wrapper .content_wrapper .accordian_header {
    border-block-start-color: $border-dark;
    border-block-end-color: $border-dark;
  }
  &.light-menu {
    .side-menu__item.active .angle,
    .side-menu__item:hover .angle,
    .side-menu__item:focus .angle,
    .slide-menu a.active {
      color: $primary !important;
    }
    .slide.is-expanded .slide-menu li a:hover,
    .slide.is-expanded .slide-menu li a:focus {
      color: $primary;
    }
  }
  &.login-page .demo-icon svg {
    fill: $white !important;
  }
  &.construction {
    .form-control {
      background-color: $dark-bg;
    }
  }
  .notification .notification-body {
    background: $dark-theme;
    p {
      color: $white !important;
    }
  }
  &.register1:before,
  &.register-3:before {
    background-color: $dark-body;
  }
  .select2-search__field {
    background-color: transparent;
  }
  .form-control-file::-webkit-file-upload-button {
    background-color: $border-dark;
  }

  .form-control::-webkit-file-upload-button {
    color: #dedefd;
    background-color: $border-dark;
  }

  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: $border-dark;
  }

  .form-control-file::-webkit-file-upload-button {
    background-color: $border-dark;
  }

  .form-check-input {
    background-color: $dark-theme;
    border-color: $border-dark;
  }

  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button {
    border-color: $border-dark;
    background-color: $dark-bg;
    color: $dark-color;
}

  .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input {
    background: transparent;
    color: white;
}

  &.login-page {
    .border-light {
      border-color: $border !important;
    }
    .btn-light {
      background-color: $white;
    }
    &.bg-light {
      background-color: $dark-theme !important;
    }
  }

  .onoffswitch2-label {
    border-color: $border-dark;
    background-color: $white-2;
    &:before {
      background: $white-8;
      border-color: $border-dark;
    }
  }

  .accordion-item {
    color: $dark-color;
    background-color: $dark-theme;
    border-color: $border-dark;
  }

  &.landing-page {
    &.horizontal .sticky-pin .horizontal-main {
      border-block-end-color: $border-dark;
      background-color: $dark-theme !important;
      .side-menu__item {
        color: $dark-color;
      }
    }
    &.horizontal .sticky-pin .horizontal-main {
      .logo-2 {
        display: block;
      }
      .logo-3 {
        display: none;
      }
      .side-menu__item{
        &.active, &:hover, &:focus {
          .side-menu__label, .angle {
            color: white !important;
          }
        }
      }
    }

    .card-overlap .information .card {
      border-color: $border-dark;
    }

    .contact-bg {
      .form-control {
          &:focus {
              border-color: $border-dark;
          }
      }
    }

    .aboutUs::before {
      background: $dark-bg;
    }

    .bg-section {
      background-color: $dark-bg;
    }

    .aboutUs {
      background: $dark-theme;
    }

    .accordion>.accordion-item>.accordion-header a {
      color: $dark-color;
      background: $dark-bg;
      &:hover {
        color: $primary;
      }
    }

    .accordion-flush .accordion-collapse {
      border-block-start-color: $border-dark;
    }
    
    .accordion>.accordion-item>.accordion-header [aria-expanded=true] {
      color: $primary;
    }

    .accordion-flush .accordion-item {
      &:first-child, &:not(:first-of-type) {
        border-block-start-color: $border-dark;
        border-block-end-color: $border-dark;
      }
      border-inline-end-color: $border-dark;
      border-inline-start-color: $border-dark;
      &:last-child {
        border-block-end-color: $border-dark;
      }
    }

    .pricing-icon.bg-white {
      background-color: white !important;
    }
    .app-sidebar {
      background-color: transparent !important;
    }

    .card.features.main-features {
        background-color: $dark-theme;
    }
    .logo-2 {
        display: block;
    }
    .logo-3 {
        display: none;
    }
    footer {
        border-block-start-color: $border-dark;
    }
    .bg-white {
        background-color: $dark-theme !important;
    }
    @media (min-width: 992px) {
        .app-sidebar.horizontal-main.fixed-header {
            border-block-end: 1px solid $border-dark;
            background-color: $dark-body !important;
            box-shadow: 0px 4px 16px $black-2;
        }
    }
    @media (min-width: 992px) and (max-width: 1275.98px) {
        &.horizontal .side-menu>li>a {
            color: $dark-color;
        }
    }
    @media (max-width: 991px) {
        .header.hor-header .header-brand-img.desktop-logo {
          display: none;
        }

        .header.hor-header .header-brand-img.light-logo1 {
          display: block;
        }

        .header.hor-header {
          border-block-end-color: $border-dark !important;
        }
    }
    .card {
        box-shadow: none;
    }
    .demo-footer {
        .card {
            border-color: transparent;
            background-color: transparent
        }
    }
    .top-footer img {
        border: 0;
    }
    hr {
        border-block-start-color: transparent
    }
    .bg-image-style {
        background-image: none;
    }
    .card .card {
        box-shadow: none;
    }
    @media (max-width: 992px) {
        .app-sidebar.horizontal-main {
            background-color: $dark-theme !important;
        }
    }
    .top.sticky.stickyClass {
        border-block-end: 1px solid $border-dark;
        background: $dark-theme;
    }
    #Contact {
        .card.card-shadow {
            border: 1px solid $border-dark !important;
        }
    }
    .pricing-card.card {
        background-color: $dark-theme;
    }
  }
}
/*------ Dark-styles------*/