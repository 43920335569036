/*-----Pricing-----*/

.pricing {
	color: $white;
	border-radius: 7px;
  }
  
  .pricing {
	.list-unstyled li {
	  border-block-end: 1px solid $white-1;
	  padding: 7px 0;
	}
  }
  
  .plan-card {
	.plan-icon {
	     display: inline-block;
	     font-size: 35px;
	     width: 75px;
	     height: 75px;
	     color: $white;
	     line-height: 67px;
	     overflow: hidden;
	     border: 4px solid rgba(242, 246, 249, 0.55);
	     border-radius: 50%;
	     transition: all .3s;
	}
  
	.plan-price {
	  border-block-start: 1px solid $border;
	  padding-block-start: 20px;
  
	  span {
		font-size: 15px;
		vertical-align: middle;
	  }
	}
  
	.plan-features p {
	  line-height: 40px;
	  margin-block-end: 0;
	  border-block-end: 1px solid $border;
  
	  &:first-child {
		border-block-start: 1px solid $border;
	  }
	}
  }

  .panel.price {
	-moz-transition: all .3s ease;
	transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
  
	> .panel-heading {
	  -moz-transition: all .3s ease;
	  transition: all .3s ease;
	  -o-transition: all .3s ease;
	  -webkit-transition: all .3s ease;
	}
  
	h3 {
	  margin-block-end: 0;
	  padding: 20px 0;
	}
  }
  
  .panel-heading {
	padding: 10px 15px;
	border-start-end-radius: 7px;
	border-start-end-radius: 7px;
	background: $white;
  }
  
  .price .list-group-item {
	border-block-end: 1px solid rgba(250, 250, 250, 0.5);
  }
  
  .panel.price {
	.list-group-item {
	  &:last-child {
		border-end-end-radius: 0px;
		border-end-start-radius: 0px;
	  }
  
	  &:first-child {
		border-start-end-radius: 0px;
		border-start-end-radius: 0px;
	  }
	}
  
	margin-block-end: 1.5rem;
	border: 1px solid $border;
	overflow: hidden;
	border-radius: 5px;
	overflow: hidden;
  }
  
  .price {
	.panel-footer {
	  border-block-end: 0px;
	  background-color: $white;
	}
  
	&.panel-color > .panel-body {
	  background-color: $white;
	}
  }
  
  .panel-body {
	.lead {
	  strong {
		font-size: 40px;
		margin-block-end: 0;
	  }
  
	  font-size: 20px;
	  margin-block-end: 0;
	  padding: 10px 0;
	}
  }
  
  .panel-footer {
	padding: 10px 15px;
	background-color: #f7f7f7;
	border-block-start: 1px solid $border;
	border-end-end-radius: 3px;
	border-end-start-radius: 3px;
  }
  
  .panel.price .btn {
	border: 0px;
	border-radius:25px;
  }

  @media (min-width: 992px) {
	.plan-card.active {
	  margin-block-start: -25px;
	  margin-inline-start: -20px;
	  width: 120%;
	  height: 100%;
	  z-index: 1;
	  box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0.09), 10px 10px 15px -5px rgba(176, 184, 214, 0.16) !important;
	  font-size: 16px;
	 
  
	  .plan-features p {
		line-height: 50px;
	  }
  
	  .plan-card .plan-price span {
		font-size: 18px;
	  }
  
	  .display-4 {
		font-size: 3.5rem;
	  }
	}
  
	.plan-card.active h6 {
	  font-size: 16px;
	}
  
	.panel.price.active {
	  margin-block-start: -25px;
	  margin-inline-start: -20px;
	  width: 120%;
	  height: 100%;
	  z-index: 9;
	  box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0.09), 10px 10px 15px -5px rgba(176, 184, 214, 0.08);
	  position: relative;
	  font-size: 16px;
	  border-inline-end: 1px !important;
	}
  
	.g-0 .panel.price {
	  &.active {
		.panel-body .lead strong {
		  font-size: 50px;
		}
  
		.price-title {
		  font-size: 26px;
		}
	  }
  
	  border-inline-end: 0;
  
	  &:last-child {
		border-inline-end: 1px;
	  }
	}
  }
  
  .pricing-tabs ul.nav-price {
	text-align: center;
	margin: 0 auto;
	display: inline-flex;
	margin-block-start: 30px;
	margin-block-end: 40px;
	border: 1px solid $border;
	border-radius: 5px;
	background: $white;
	overflow: hidden;
  
	li {
	  display: block;
  
	  a {
		padding: 12px 22px;
		display: block;
		background: $white;
		font-size: 16px;
		border-radius: 0px;
		transition: 0.3s;
  
		&.active {
		  color: $white;
		  transition: 0.3s;
		}
	  }
	}
  }
  
  .leading-loose li {
	padding: 5px 0;
  
	&:last-child {
	  border-block-end: 0;
	}
  }
  
  .pricing-tabs {
	.pri-tabs-heading2 ul.nav-price {
	  text-align: center;
	  margin: 0 auto;
	  display: inline-flex;
	  margin-block-start: 30px;
	  margin-block-end: 40px;
	  border: 1px solid $border;
	  border-radius: 0px;
	  background: #f3f4f7;
	  border-radius: 50px;
  
	  li {
		display: block;
  
		a {
		  padding: 12px 40px;
		  display: block;
		  background: #f3f4f7;
		  font-size: 16px;
		  border-radius: 50px;
		  transition: 0.3s;
		  font-weight: 700;
		}
	  }
	}
  
	.pri-tabs-heading3 ul.nav-price {
	  border-radius: 50px;
  
	  li {
		&:first-child a {
		  border-radius: 50px 0 0 50px;
		  border-inline-start: 0;
		}
  
		&:last-child a {
		  border-radius: 0 50px 50px 0;
		}
	  }
	}
  }

.pricing-icon {
	padding: 2px;
	border-radius: 50px;
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-block-end: 5px;
    box-shadow: 0 2px 3px $black-1;
}

.ribbon-top-left {
    inset-block-start: -5px;
    inset-inline-end: -5px;
}
.ribbon {
    width: 146px;
    height: 146px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
}
.ribbon::after, .ribbon::before {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid;
}
.ribbon-top-left span {
    inset-inline-start: -8px;
    inset-block-start: 30px;
    transform: rotate(45deg);
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 3px 0;
    color: white;
    text-shadow: 0 1px 1px $black-2;
    text-transform: capitalize;
    text-align: center;
}
.ribbon-top-left::after {
    inset-block-end: 45px;
    inset-inline-end: -5px;
}
.ribbon-top-left::after {
    border-block-start-color: transparent;
    border-inline-end-color: transparent;
}

.ribbon-top-left::before {
    border-block-start-color: transparent;
    border-inline-end-color: transparent;
    inset-block-start: -5px;
    inset-inline-start: 45px;
}

/*-----Pricing End-----*/
