/*------ Breadcrumb ------*/

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-block-end: 0;
	list-style: none;
	border-radius: 3px;
	font-weight: 400;
	background: #f0f0f2;
  }
  
  .breadcrumb-item {
	+ .breadcrumb-item {
	  &::before {
		display: inline-block;
		padding-inline-end: 0.5rem;
		padding-inline-start: 0.5rem;
		color: #cfd1e1;
		content: "/";
		opacity: 0.5;
	  }
  
	  &:hover::before {
		text-decoration: underline;
		text-decoration: none;
	  }
	}
  
	&.active {
	  color: $color;
	}
  }
  
  .breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-block-end: 1rem;
	list-style: none;
	border-radius: .25rem;
  }
  
  .breadcrumb-item1 {
	a:hover {
	  color: $primary;
	  text-decoration: underline;
	}
  
	+ .breadcrumb-item1 {
	  &::before {
		display: inline-block;
		padding-inline-end: 0.5rem;
		padding-inline-start: 0.5rem;
		content: "/";
		opacity: 0.5;
	  }
  
	  &:hover::before {
		text-decoration: underline;
		text-decoration: none;
	  }
	}
  
	&.active {
	  color: $color;
	}
  }
  
  @media (max-width: 375px) {
	.breadcrumb {
	  font-size: 12px;
	}
  
	.page-title {
	  font-size: 20px !important;
	}
  }
  /*------ Breadcrumb End ------*/