/*------ Colors------*/
a:hover {
	color: $primary;
}

.file-manger.list-group-transparent .list-group-item.active {
	color: $primary;
}

.custom-progress {

	&::-webkit-progress-value,
	&::-moz-progress-bar,
	&::-ms-fill {
		background-color: $primary;
	}
}

.card-pay .tabs-menu li a.active {
	background: $primary;
}

.page-link:hover {
	color: $primary;
}

.page-item.active .page-link {
	background-color: $primary;
	border-color: $primary;
}

.panel-default>.panel-heading {
	color: $primary;
}

.panel-primary .list-group-item.active {
	color: $white;
	background-color: $primary;
	border-color: $primary;
}

.alert-primary {
	color: #ffffff;
	background-color: $primary;
	border-color: $primary;

	hr {
		border-block-start-color: #b7cded;
	}

	.alert-link {
		color: #172b46;
	}
}

.alert-secondary {
	color: #ffffff;
	background-color: $secondary;
	border-color: $secondary;

	hr {
		border-block-start-color: $secondary;
	}

	.alert-link {
		color: #151213;
	}
}

.alert-light-primary {
	color: $primary;
	background-color: #cdc8e5;
	border-color: #cdc8e5;
}

.alert-light-secondary {
	color: $secondary;
	background-color: #bcc6f5;
	border-color: #bcc6f5;
}

.avatar {
	background: $primary no-repeat center/cover;
}

.badge-gradient-primary {
	color: $white;
	background: linear-gradient(to left, #705ec8 0%, $primary 100%);
}

.badge-gradient-success {
	background-image: linear-gradient(to left, rgb(101, 179, 110) 0%, #38cb89 100%);
	color: $white;
}

.badge-gradient-secondary {
	background-image: linear-gradient(to left, #b4bdeb 0%, #5b73e8 100%);
	color: $white;
}

.badge-gradient-warning {
	background-image: linear-gradient(to left, #fdc99b 0%, #ffab00 100%);
	color: $white;
}

.badge-gradient-info {
	color: $white;
	background-image: linear-gradient(to left, #94b7f0 0%, #3e80eb 100%);
}

.badge-gradient-danger {
	color: $white;
	background-image: linear-gradient(to left, #f89191 0%, #ef4b4b 100%);
}

.bg-primary-light {
	color: $primary;
	background-color: rgba(112, 94, 200, 0.15);
}

.bg-secondary-light {
	background-color: rgba(91, 115, 232, 0.15) !important;
	color: $secondary;
}

.offer-primary {
	border-color: $primary;

	.shape {
		border-color: transparent $primary transparent transparent;
	}
}

.bg-gradient-primary {
	color: $white;
	background: linear-gradient(to left, #be5fca 0%, $primary 100%);
}

.bg-gradient-secondary {
	background-image: linear-gradient(to left, #ed7d98 0%, $secondary 100%);
	color: $white;
}

.breadcrumb-item a,
.breadcrumb-item1 a {
	color: $primary;
}

.btn-primary {
	color: $white  !important;
	background-color: $primary !important;
	border-color: $primary;



	&:hover {
		color: $white  !important;
		background-color: $primary;
		border-color: $primary;
		opacity: 0.8;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(112, 94, 200, 0.1);
		background-color: $primary;
		border-color: $primary;
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #6958be;
		border-color: #6958be;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}
}

.show>.btn-primary.dropdown-toggle {
	color: $white;
	background-color: #6958be;
	border-color: #6958be;
}

.btn-primary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(112, 94, 200, 0.1);
	}
}

.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(112, 94, 200, 0.1);
}

.btn-secondary {
	color: $white  !important;
	background-color: $secondary !important;
	border-color: $secondary !important;
	box-shadow: 0 0px 10px -5px rgb(91, 115, 232);

	&:hover {
		color: $white  !important;
		background-color: $secondary;
		border-color: $secondary;
		opacity: 0.8;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba($secondary, 0.1);
		background-color: $secondary;
		border-color: $secondary;
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #405ce6;
			border-color: #405ce6;
		}
	}
}

.show>.btn-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-secondary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		background-color: #4964e6;
		box-shadow: 0 0 0 2px rgba(91, 115, 232, 0.1);
	}
}

.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(91, 115, 232, 0.1);
}

.btn-outline-primary {
	color: $primary;
	background-color: transparent;
	background-image: none;
	border-color: $primary;
	box-shadow: 0 0px 10px -5px rgba(112, 94, 200, 0.5);

	&:hover {
		color: $white  !important;
		background-color: $primary;
		border-color: $primary;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $primary;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}
}
.btn-outline-white {
	color: white;
	background-color: transparent;
	border-color: white;
	box-shadow: 0 0px 10px -5px $black-1;

	&:hover {
		color: black;
		background-color: white;
		border-color: white;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px $black-1;
	}
}

a.btn-outline-white {
	&:hover {
		color: $primary;
	}
}

.show>.btn-outline-primary.dropdown-toggle {
	color: $white;
	background-color: $primary;
	border-color: $primary;
}

.btn-outline-primary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(112, 94, 200, 0.1);
	}
}

.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(112, 94, 200, 0.1);
}

.btn-outline-secondary {
	color: $secondary;
	background-color: transparent;
	background-image: none;
	border-color: $secondary;
	box-shadow: 0 0px 10px -5px rgba(91, 115, 232, 0.5);

	&:hover {
		color: $white  !important;
		background-color: $secondary;
		border-color: $secondary;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(91, 115, 232, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $secondary;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}

.show>.btn-outline-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(91, 115, 232, 0.1);
	}
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(91, 115, 232, 0.1);
}

.btn-link {
	color: $primary;

	&:hover {
		color: #6958be;
	}
}

.dropdown-item {

	&:hover,
	&:focus {
		color: $primary;
	}
}

.btn.dropdown-toggle.btn-primary~.dropdown-menu .dropdown-plus-title {
	border-color: $primary  !important;
}

.list-group-item-primary {
	color: $primary;
	background-color: #cbdbf2;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: $primary;
			background-color: #b7cded;
		}

		&.active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}
}

.list-group-item-secondary {
	color: $white;
	background-color: #5b73e8;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: $white;
			background-color: #5b73e8;
		}

		&.active {
			color: $white;
			background-color: #5b73e8;
			border-color: #5b73e8;
		}
	}
}

.nav-pills {

	.nav-link.active,
	.show>.nav-link {
		color: $white;
		background-color: $primary;
	}
}

.user-button {
	background: $primary;

	&:hover {
		border: 1px solid $primary;
		background: $white;
		color: $primary  !important;
	}
}

.nav-tabs {
	.nav-item1 .nav-link {

		&:hover:not(.disabled),
		&.active {
			color: $white;
			background: $primary;
		}
	}

	.nav-link.active {
		color: $white;
		background: $primary;
	}
}

.header .nav-tabs .nav-link {

	&:hover:not(.disabled),
	&.active {
		color: $white  !important;
		background: $primary;
	}
}

.expanel-primary>.expanel-heading {
	color: $white  !important;
	background-color: $primary  !important;
	border-color: $primary  !important;
}

.expanel-secondary>.expanel-heading {
	color: $white  !important;
	background-color: #5b73e8 !important;
	border-color: #5b73e8 !important;
}

.tag-primary {
	background-color: $primary;
	color: $white;
}

.tag-secondary {
	background-color: #5b73e8;
	color: $white;
}

.pop-primary {
	color: $primary;
}

.popsecondary {
	color: #5b73e8;
}

.tooltip-primary {
	.tooltip-inner {
		background-color: $primary !important;
		color: $white;
	}

	&.bs-tooltip-top .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
		border-block-start-color: $primary !important;
	}

	&.bs-tooltip-bottom .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
		border-block-end-color: $primary !important;
	}

	&.bs-tooltip-start .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
		border-inline-start-color: $primary !important;
	}

	&.bs-tooltip-end .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
		border-inline-end-color: $primary !important;
	}
}

.tooltip-primary1 {
	.tooltip-inner {
		background-color: $primary  !important;
		color: $white;
	}

	&.bs-tooltip-top .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
		border-block-start-color: $primary !important;
	}

	&.bs-tooltip-bottom .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
		border-block-end-color: $primary !important;
	}

	&.bs-tooltip-start .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
		border-inline-start-color: $primary !important;
	}

	&.bs-tooltip-end .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
		border-inline-end-color: $primary !important;
	}
}

.tooltip-secondary {
	.tooltip-inner {
		background-color: $primary !important;
		color: $white;
	}

	&.bs-tooltip-top .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
		border-block-start-color: $primary !important;
	}

	&.bs-tooltip-bottom .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
		border-block-end-color: $primary !important;
	}

	&.bs-tooltip-start .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
		border-inline-start-color: $primary !important;
	}

	&.bs-tooltip-end .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
		border-inline-end-color: $primary !important;
	}
}

.tooltip-secondary1 {
	.tooltip-inner {
		background-color: $primary !important;
		color: $white;
	}

	&.bs-tooltip-top .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
		border-block-start-color: $primary !important;
	}

	&.bs-tooltip-bottom .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
		border-block-end-color: $primary !important;
	}

	&.bs-tooltip-start .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
		border-inline-start-color: $primary !important;
	}

	&.bs-tooltip-end .tooltip-arrow::before,
	&.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
		border-inline-end-color: $primary !important;
	}
}

.popover-head-primary {
	.popover-header {
		color: $white;
		background-color: $primary  !important;
	}

	&.bs-popover-bottom .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
		border-block-end-color: $primary;
	}
}

.popover-head-secondary {
	.popover-header {
		color: $white;
		background-color: $primary !important;
	}

	&.bs-popover-bottom .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
		border-block-end-color: $primary !important;
	}
}

.popover-primary {
	background-color: $primary  !important;

	&.bs-popover-top .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
		border-block-start-color: $primary  !important;
	}

	&.bs-popover-bottom .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
		border-block-end-color: $primary  !important;
	}

	&.bs-popover-start .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
		border-inline-start-color: $primary  !important;
	}

	&.bs-popover-end .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
		border-inline-end-color: $primary  !important;
	}
}

.popover-secondary {
	background-color: #5b73e8 !important;

	&.bs-popover-top .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
		border-block-start-color: #5b73e8 !important;
	}

	&.bs-popover-bottom .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
		border-block-end-color: #5b73e8 !important;
	}

	&.bs-popover-start .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
		border-inline-start-color: #5b73e8 !important;
	}

	&.bs-popover-end .popover-arrow::after,
	&.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
		border-inline-end-color: #5b73e8 !important;
	}
}

.dark-mode {
	.popover-primary .popover-header {
		background-color: $primary  !important;
		color: $white;
	}

	.popover-secondary .popover-header {
		background-color: #5b73e8 !important;
		color: $white;
	}
}

.message-feed.right .mf-content {
	background: $primary;

	&:before {
		border-block-end: 8px solid $primary;
	}
}

.chat .msg_cotainer_sright {
	&:after {
		border-block-end: 10px solid $primary;
	}

	background-color: $primary;
}

.action-header {
	background: $primary;
}

.main-chat-header .nav-link {

	&:hover,
	&:focus {
		color: $primary;
	}
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
	background-color: $primary;
	color: $white;

	&:before {
		border-block-end: 7px solid $primary;
	}
}

.main-contact-item.selected {
	border-inline-start-color: $primary;
}

.main-contact-info-header {
	.main-img-user a {

		&:hover,
		&:focus {
			background-color: $primary;
		}
	}

	.media-body .nav-link {

		&:hover,
		&:focus {
			color: $primary;
		}
	}
}

.main-contact-action a {

	&:hover,
	&:focus {
		color: $primary;
	}
}

.range.range-primary {
	input[type="range"] {

		&::-webkit-slider-thumb,
		&::-moz-slider-thumb {
			background-color: $primary;
		}
	}

	output {
		background-color: $primary;
	}

	input[type="range"] {
		outline-color: $primary;
	}
}

.header-bg {
	background: $primary;

	/* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #be5fca 0%, $primary 100%);

	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #be5fca 0%, $primary 100%);
}

.footer a:not(.btn) {
	color: $primary;
}

@media (max-width: 991.98px) and (min-width: 576px) {
	.search-show .search-element {
		background: $white;
	}
}

@media (max-width: 575.98px) {
	.search-show .search-element {
		background: $white;
	}
}

.form-group label.control-label {
	color: $primary;
}

.fc-event {
	background-color: rgba(102, 77, 201, .2);
}

.fc-event-resizable {
	color: $primary  !important;
}

.fc-h-event.event7 .fc-event-main {
	color: rgb(26, 22, 48);
}

.fc-toolbar {

	.fc-state-active,
	.ui-state-active {
		background: $primary;
	}
}

#sidebar ul li.active>a {
	color: $primary;
}

.accordionjs .acc_section.acc_active>.acc_head {
	background: $primary  !important;
}

.tab_wrapper {
	>ul li.active {
		border-color: $primary  !important;
		background: $primary-02 !important;
		color: $primary;
	}

	&.right_side>ul li.active:after {
		background: $primary  !important;
	}
}

.tab_wrapper>ul li {
	background: #ffffff;
}

#sidebar li a {

	&[aria-expanded="true"],
	&.active {
		background: $primary;
		color: $white;
	}
}

.sweet-alert button {
	background-color: $primary  !important;
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
	color: $primary;
}

.card-custom-icon.text-primary {
	fill: $primary;
}


table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]> {

	td:first-child:before,
	th:first-child:before {
		background: $primary;
	}
}

.footer .social .social-icon {
	color: $primary;
}

.page-leftheader .breadcrumb-item.active {
	a {
		color: $primary;
	}

	color: $primary;
}

.dot1,
.dot2,
.spinner4>div,
.sk-circle .sk-child:before {
	background: -webkit-gradient(linear, left top, right top, from($primary), to($primary));
}

.sk-cube-grid .sk-cube {
	background: $primary;
}

.sk-folding-cube .sk-cube:before {
	background-color: $primary;
}

.spinner {
	background: $primary;
}

.double-bounce1,
.double-bounce2 {
	background: $primary;
}

.lds-hourglass:after {
	border: 26px solid $primary;
	border-color: $primary transparent;
}

.table-hover .table-primary:hover {
	background-color: #f0f0f2;

	> {

		td,
		th {
			background-color: #b7cded;
		}
	}
}

.table-secondary {
	background-color: #5b73e8;

	> {

		th,
		td {
			background-color: #5b73e8;
		}
	}
}

.table-hover .table-secondary:hover {
	background-color: #cfd2d6;

	> {

		td,
		th {
			background-color: #5b73e8;
		}
	}
}

#back-to-top {
	background: $primary;

	&:hover {
		color: $white;
	}
}

.tabs-menu ul li .active {
	background-color: $primary-02;
}

.tabs-menu1 ul li .active {
	border-block-end: 3px solid $primary;
	color: $primary;
}

.sub-panel-heading .tabs-menu ul li a.active {
	color: $primary  !important;
}

.receipts-inline-table .tabs-menu1 ul li .active {
	color: $primary;
}

.custom-control-input:checked~.custom-control-label::before {
	color: $white;
	background-color: $primary;
}

.custom-checkbox .custom-control-input {
	&:checked~.custom-control-label::before {
		background-color: $primary;
		border-color: $primary;
	}

	&:indeterminate~.custom-control-label::before {
		background-color: $primary;
	}
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: $primary;
	border-color: $primary;
}

.custom-select:focus {
	border-color: $primary;
}

.ui-datepicker {
	.ui-datepicker-title {
		color: $primary;
	}
}

.form-control:focus {
	border-color: $primary;
}

.wizard> {
	.steps .current a {
		color: $primary;

		&:hover,
		&:active {
			color: $primary;
		}

		.number,
		&:hover .number,
		&:active .number {
			background-color: $primary;
			color: $white;
		}
	}

	.actions a {
		background-color: $primary;

		&:hover,
		&:active {
			background-color: $primary;
		}
	}
}

.selectgroup-input {

	&:checked+.selectgroup-button,
	&:focus+.selectgroup-button {
		border-color: $primary;
		color: $primary;
	}
}

.custom-switch-input {
	&:checked~.custom-switch-indicator {
		background: $primary;
	}

	&:focus~.custom-switch-indicator {
		border-color: $primary;
	}
}

.label-primary {
	background: $primary;
}

.bg-primary {
	background-color: $primary  !important;
}

a.bg-primary {

	&:hover,
	&:focus {
		background-color: #6958be !important;
	}
}

button.bg-primary {

	&:hover,
	&:focus {
		background-color: #6958be !important;
	}
}

.bg-secondary {
	background-color: $secondary  !important;
}

a.bg-secondary {

	&:hover,
	&:focus {
		background-color: $secondary  !important;
	}
}

button.bg-secondary {

	&:hover,
	&:focus {
		background-color: $secondary  !important;
	}
}

.bg-gradient-primary {
	background: linear-gradient(to left, #be5fca 0%, $primary 100%);
}

.border-primary {
	border-color: $primary  !important;
}

.border-secondary {
	border-color: $secondary  !important;
}

.border-danger {
	border-color: $danger  !important;
}

.border-warning {
	border-color: $warning  !important;
}

.border-cyan {
	border-color: $cyan  !important;
}

.border-pink {
	border-color: $pink  !important;
}

.border-success {
	border-color: $success  !important;
}

.border-info {
	border-color: $info  !important;
}

i.fa.round,
.list-icon span p {
	border: 1px solid $primary;
}

.text-primary {
	color: $primary  !important;
}

a.text-primary {

	&:hover,
	&:focus {
		color: $primary;
	}
}

.text-secondary {
	color: $secondary  !important;
}

a.text-secondary {

	&:hover,
	&:focus {
		color: $secondary;
	}
}

pre {
	border-inline-start: 3px solid $primary;
}

.bg-primary-transparent {
	background-color: $primary-02 !important;
	color: $primary  !important;

	&:hover {
		color: $white;
		background-color: $primary;
		border-color: $primary;
	}
}

.bg-secondary-transparent {
	background-color: rgba(91, 115, 232, 0.2) !important;
	color: $secondary  !important;

	&:hover {
		color: $white;
		background-color: #5b73e8;
		border-color: #6f85f1;
	}
}

.bg-pink-transparent {
	background-color: rgba(236, 130, 239, 0.2) !important;
	color: $pink  !important;
}

.bg-cyan-transparent {
	background-color: rgba(17, 110, 124, 0.2) !important;
	color: $cyan  !important;
}

.bg-danger-transparent {
	background-color: rgba(239, 75, 75, 0.2) !important;
	color: $danger  !important;

	&:hover {
		color: $white;
		background-color: #e82646;
		border-color: #ed314c;
	}
}

.bg-warning-transparent {
	background-color: rgba(255, 171, 0, 0.2) !important;
	color: $warning  !important;

	&:hover {
		color: $white;
		background-color: #ffab00;
		border-color: #fbac0e;
	}
}

.bg-info-transparent {
	background-color: rgba(62, 128, 235, 0.2) !important;
	color: $info  !important;

	&:hover {
		color: $white;
		background-color: #3e80eb;
		border-color: #3d81ee;
	}
}

.bg-success-transparent {
	background-color: rgba(56, 203, 137, 0.2) !important;
	color: rgb(56, 203, 137) !important;

	&:hover {
		color: $white;
		background-color: rgb(56, 203, 137);
		border-color: rgb(69, 223, 154);
	}
}

.bg-teal-transparent {
	background-color: rgba(43, 203, 186, 0.2) !important;
	color: $teal  !important;
	&:hover {
		border-color: $teal;
	}
}

.bg-dark-transparent {
	background-color: rgba(52, 58, 64, 0.2) !important;
	color: $dark  !important;
	&:hover {
		border-color: $dark;
	}
}

.bg-primary-transparent-2 {
	background-color: rgba(91, 115, 232, 0.15);
}

.bg-secondary-transparent-2 {
	background-color: rgba(102, 77, 201, 0.15);
}


.bg-primary-1 {
	background-color: $primary-09 !important;
}

.bg-primary-2 {
	background-color: $primary-08 !important;
}

.bg-primary-3 {
	background-color: $primary-07 !important;
}

.bg-primary-4 {
	background-color: $primary-06 !important;
}

.bg-danger-1 {
	background-color: #f37272 !important;
}

.bg-danger-2 {
	background-color: #f7a1a1 !important;
}

.bg-warning-1 {
	background-color: #ffbb33 !important;
}

.bg-warning-2 {
	background-color: #ffcc66 !important;
}

.bg-success-1 {
	background-color: #5dd59f !important;
}

.bg-success-2 {
	background-color: #85e0b7 !important;
}

.card-header:before {
	background-color: $primary;
}

.pricing-tabs {

	ul.nav-price li a.active,
	.pri-tabs-heading2 ul.nav-price li a.active {
		background: $primary;
	}
}

.icon-dropshadow-primary {
	filter: drop-shadow(0px 4px 4px rgba(91, 115, 232, 0.3));
}

.icon-dropshadow-secondary {
	filter: drop-shadow(0px 4px 4px rgba(102, 77, 201, 0.3));
}

.card-custom-icon.text-secondary,
.fill-secondary {
	fill: $secondary;
}

.tree li i {
	color: $primary;
}

.timeline .timeline-items .timeline-item::after {
	background: $primary;
}

li.ui-timepicker-selected {
	background: $primary !important;
	color: $white !important;
}

.ui-timepicker-list {

	li:hover,
	.ui-timepicker-selected:hover {
		background: $primary !important;
		color: $white !important;
	}
}

.SumoSelect {
	&:focus>.CaptionCont {
		border-color: $primary;
	}

	>.optWrapper.multiple {
		&.okCancelInMulti>.MultiControls>p:focus {
			border-color: $primary;
			outline: none;
			background-color: $primary;
		}

		>.options li.opt.selected span i {
			background-color: $primary;
		}
	}

	.select-all {

		&.selected>span i,
		&.partial>span i {
			background-color: $primary;
		}
	}
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $primary;
	color: white;
}

.ql-editor .ql-bg-blue {
	background-color: $primary;
}

.ql-snow {

	&.ql-toolbar button:hover,
	.ql-toolbar button:hover,
	&.ql-toolbar button:focus,
	.ql-toolbar button:focus,
	&.ql-toolbar button.ql-active,
	.ql-toolbar button.ql-active,
	&.ql-toolbar .ql-picker-label:hover,
	.ql-toolbar .ql-picker-label:hover,
	&.ql-toolbar .ql-picker-label.ql-active,
	.ql-toolbar .ql-picker-label.ql-active,
	&.ql-toolbar .ql-picker-item:hover,
	.ql-toolbar .ql-picker-item:hover,
	&.ql-toolbar .ql-picker-item.ql-selected,
	.ql-toolbar .ql-picker-item.ql-selected {
		color: $primary;
		outline: 0;
		background: $white;
	}

	&.ql-toolbar button:hover .ql-fill,
	.ql-toolbar button:hover .ql-fill,
	&.ql-toolbar button:focus .ql-fill,
	.ql-toolbar button:focus .ql-fill,
	&.ql-toolbar button.ql-active .ql-fill,
	.ql-toolbar button.ql-active .ql-fill,
	&.ql-toolbar .ql-picker-label:hover .ql-fill,
	.ql-toolbar .ql-picker-label:hover .ql-fill,
	&.ql-toolbar .ql-picker-label.ql-active .ql-fill,
	.ql-toolbar .ql-picker-label.ql-active .ql-fill,
	&.ql-toolbar .ql-picker-item:hover .ql-fill,
	.ql-toolbar .ql-picker-item:hover .ql-fill,
	&.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
	.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
	&.ql-toolbar button:hover .ql-stroke.ql-fill,
	.ql-toolbar button:hover .ql-stroke.ql-fill,
	&.ql-toolbar button:focus .ql-stroke.ql-fill,
	.ql-toolbar button:focus .ql-stroke.ql-fill,
	&.ql-toolbar button.ql-active .ql-stroke.ql-fill,
	.ql-toolbar button.ql-active .ql-stroke.ql-fill,
	&.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
	.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
	&.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
	.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
	&.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
	.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
	&.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
	.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
		fill: $primary;
	}

	&.ql-toolbar button:hover .ql-stroke,
	.ql-toolbar button:hover .ql-stroke,
	&.ql-toolbar button:focus .ql-stroke,
	.ql-toolbar button:focus .ql-stroke,
	&.ql-toolbar button.ql-active .ql-stroke,
	.ql-toolbar button.ql-active .ql-stroke,
	&.ql-toolbar .ql-picker-label:hover .ql-stroke,
	.ql-toolbar .ql-picker-label:hover .ql-stroke,
	&.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
	.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
	&.ql-toolbar .ql-picker-item:hover .ql-stroke,
	.ql-toolbar .ql-picker-item:hover .ql-stroke,
	&.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
	.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
	&.ql-toolbar button:hover .ql-stroke-miter,
	.ql-toolbar button:hover .ql-stroke-miter,
	&.ql-toolbar button:focus .ql-stroke-miter,
	.ql-toolbar button:focus .ql-stroke-miter,
	&.ql-toolbar button.ql-active .ql-stroke-miter,
	.ql-toolbar button.ql-active .ql-stroke-miter,
	&.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
	.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
	&.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
	.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
	&.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
	.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
	&.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
	.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
		stroke: $primary;
	}

	a {
		color: $primary;
	}
}

.ms-choice {
	color: $primary;
}

.checkbox-group .checkbox-normal:checked+label:before {
	background: $primary;
}

.irs-from,
.irs-to,
.irs-single {
	background-color: $primary;
}

.irs-bar,
.irs-bar-edge {
	background-image: none;
	background-color: $primary;
}

.irs-from::after,
.irs-to::after,
.irs-single::after {
	border-block-start-color: $primary;
}

.irs-slider {

	&:before,
	&:hover:before,
	&:focus:before {
		background-color: $primary;
	}
}

.irs-primary {

	.irs-bar,
	.irs-bar-edge,
	.irs-slider::before,
	.irs-from,
	.irs-to,
	.irs-single {
		background-color: $primary;
	}

	.irs-from::after,
	.irs-to::after,
	.irs-single::after {
		border-block-start-color: $primary;
	}
}

.irs-modern {
	.irs-slider {
		border: 1px solid $primary;

		&::before {
			border-inline-start: 1px solid $primary;
			border-inline-end: 1px solid $primary;
		}
	}

	&.irs-primary .irs-slider {
		border-color: $primary;

		&::before {
			border-color: $primary;
		}
	}
}

.irs-outline {
	.irs-line {
		border: 1px solid $primary;
	}

	.irs-slider {
		border: 1px solid $primary;

		&:hover::before,
		&:focus::before {
			background-color: $primary;
		}
	}

	&.irs-primary {

		.irs-line,
		.irs-slider {
			border-color: $primary;
		}

		.irs-line {

			&:hover::before,
			&:focus::before {
				background-color: $primary;
			}
		}

		.irs-slider {

			&:hover::before,
			&:focus::before {
				background-color: $primary;
			}
		}
	}
}

.cropme-slider input::-webkit-slider-thumb,
.cropme-rotation-slider input::-webkit-slider-thumb {
	background: $primary;
}

.fc button {
	color: $primary;
}

.sw-theme-dots>ul.step-anchor>li {
	>a:before {
		color: $primary;
	}

	&.active>a {
		color: $primary;

		&:after {
			background: $primary;
		}
	}
}

.theme-white .cookie-popup-accept-cookies,
.theme-light .cookie-popup-accept-cookies,
.theme-primary .cookie-popup {
	background-color: $primary;
	color: $white;
}

.daterangepicker {
	td.active {
		background-color: $primary;
		border-color: transparent;
		color: $white;

		&:hover {
			background-color: $primary;
			border-color: transparent;
			color: $white;
		}
	}

	.ranges li.active {
		background-color: $primary;
		color: $white;
	}
}

/*-- Horizontal-menu --*/

.hor-menu .horizontalMenu>.horizontalMenu-list>li> {
	ul.sub-menu>li> {

		a:hover,
		ul.sub-menu>li>a:hover {
			color: $primary;
		}
	}

	.horizontal-megamenu .link-list li a {

		&:hover,
		&.active {
			color: $primary;
		}
	}
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active,
.hor-menu .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active {
	color: $primary;
}

.sub-menu li {

	&.active:before,
	&:hover:before {
		color: $primary;
		opacity: 0.9;
	}
}

/*-- Sidemenu --*/

.app-sidebar__toggle:hover {
	color: $primary;
}

.slide-menu a.active {
	color: $primary  !important;
}

.slide-item {

	&.active,
	&:hover,
	&:focus {
		color: $primary;
	}
}

.slide.is-expanded .slide-menu li a:hover {
	color: $primary;
}

@media (min-width: 768px) {
	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
		.slide-menu a.active {
			color: $primary;
			background: transparent;
		}

		.slide-item.active {
			color: $primary;
		}
	}

	.slide-item {

		&:hover,
		&:focus {
			color: $primary;
		}
	}

	.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item.active {
		color: $primary;
		background: transparent;
	}
}

.onoffswitch-checkbox:checked+.onoffswitch-label {
	background-color: $primary;
	border-color: $primary;

	&:before {
		border-color: $primary;
	}
}

.onoffswitch2-checkbox:checked+ {
	.onoffswitch2-label {
		background-color: $primary;
	}

	.onoffswitch-label2,
	.onoffswitch2-label:before {
		border-color: $primary;
	}
}

.side-menu__item {

	&.active,
	&:hover,
	&:focus {
		.side-menu__icon {
			fill: white;
		}
	}
}

.side-menu__item.active .side-menu__label,
.side-menu__item:hover .side-menu__label,
.side-menu__item:focus .side-menu__label {
	color: $primary  !important;
}

.side-menu__item.active .angle,
.side-menu__item:hover .angle,
.side-menu__item:focus .angle {
	color: $primary  !important;
}

.text-primary-transparent {
	color: #8976d5;
}

.bg-white-50 {
	background-color: rgba(225, 225, 225, 0.2);
}

.bg-white-transparent {
	background-color: $white-1;
}

/*------ Colors End-------*/