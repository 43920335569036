/*-----Bootstrap-styles-----*/

:root {
	--primary-bg-color: #664dc9;
	--primary-bg-hover: #5135c3;
	--primary-bg-border: #664dc9;
	--primary06: rgba(102, 77, 201, 0.6);
	--primary01: rgba(102, 77, 201, 0.1);
	--primary02: rgba(102, 77, 201, 0.2);
	--primary03: rgba(102, 77, 201, 0.3);
	--primary04: rgba(102, 77, 201, 0.4);
	--primary05: rgba(102, 77, 201, 0.5);
	--primary07: rgba(102, 77, 201, 0.7);
	--primary08: rgba(102, 77, 201, 0.8);
	--primary09: rgba(102, 77, 201, 0.9);
	--dark-body: #1a1c32;
	--dark-theme: #272d48;
	--dark-bg: rgba(0, 0, 0, 0.2);
	--dark-border: rgba(255, 255, 255, 0.1);
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
		"Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--float-left: left;
	--float-right: right;
}

* {
	box-sizing: border-box;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
	width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: .81rem;
  text-align: start;
  font-weight: 500;
  background: $background;
  overflow-x: hidden;
  line-height: 1.5;
  color: $color;

}

.number-font {
	font-weight: 600 !important;
}

[tabindex="-1"]:focus {
	outline: 0 !important;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

p {
	margin-block-start: 0;
	margin-block-end: 1rem;
	font-weight: 400;
}

abbr {
	&[title],
	&[data-original-title] {
		text-decoration: underline;
		-webkit-text-decoration: underline dotted;
		text-decoration: underline dotted;
		cursor: help;
		border-block-end: 0;
	}
}

address {
	margin-block-end: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul,
dl {
	margin-block-start: 0;
	margin-block-end: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-block-end: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-block-end: 0.5rem;
	margin-inline-start: 0;
}

dfn {
	font-style: italic;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	inset-block-end: -0.25em;
}

sup {
	inset-block-start: -0.5em;
}

a {
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;

	&:hover {
		text-decoration: underline;
	}

	&:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;

		&:hover {
			color: inherit;
			text-decoration: none;
		}

		&:focus {
			color: inherit;
			text-decoration: none;
			outline: 0;
		}
	}
}

pre,
code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

pre {
	margin-block-start: 0;
	margin-block-end: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}

svg:not(:root) {
	overflow: hidden;
}

caption {
	padding-block-start: 0.75rem;
	padding-block-end: 0.75rem;
	color: #505662;
	text-align: start;
	caption-side: bottom;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
	margin-block-end: 0.5rem;
}

input {

	&[type="radio"],
	&[type="checkbox"] {
		box-sizing: border-box;
		padding: 0;
	}

	&[type="date"],
	&[type="time"],
	&[type="datetime-local"],
	&[type="month"] {
		-webkit-appearance: listbox;
		appearance: listbox;
	}
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-block-end: 0.5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

[type="number"] {

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		height: auto;
	}
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
	appearance: none;

	&::-webkit-search-cancel-button,
	&::-webkit-search-decoration {
		-webkit-appearance: none;
		appearance: none;
	}
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
	appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}

.lead {
	font-size: 1.171875rem;
	font-weight: 300;
	line-height: 1.4;
}

hr {
	margin-block-start: 1rem;
	margin-block-end: 1rem;
	border: 0;
	border-block-start: 1px solid #eaedf1;
	opacity: 1;
	background-color: transparent;
}

mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

.list-unstyled {
	padding-inline-start: 0;
	list-style: none;

	li {
		border-block-end: 1px solid #eaedf1;
	}
}

.list-inline {
	padding-inline-start: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-inline-end: 0.5rem;
	}
}

code,
kbd,
pre,
samp {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
	font-size: 85%;
	color: inherit;
	word-break: break-word;
}

a>code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 85%;
	color: $white;
	background-color: $dark;
	border-radius: 3px;

	kbd {
		padding: 0;
		font-size: 100%;
		font-weight: 700;
	}
}

pre {
	display: block;
	font-size: 85%;
	color: #212529;

	code {
		font-size: inherit;
		color: inherit;
		word-break: normal;
	}
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}

@media (max-width: 576px) {
	.container {
		padding-inline-end: 0.75rem;
		padding-inline-start: 0.75rem;
	}
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1280px) {
	.container {
		max-width: 1140px;
	}
}

.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-inline-end: -0.75rem;
	margin-inline-start: -0.75rem;
}

.fixed-top {
	position: fixed;
	inset-block-start: 0;
	inset-inline-end: 0;
	inset-inline-start: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	inset-inline-end: 0;
	inset-block-end: 0;
	inset-inline-start: 0;
	z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		inset-block-start: 0;
		z-index: 1020;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable {

	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow1 {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

.users img {
	max-width: none !important;
}

@media print {
	* {
		text-shadow: none !important;
		box-shadow: none !important;

		&::before,
		&::after {
			text-shadow: none !important;
			box-shadow: none !important;
		}
	}

	a:not(.btn) {
		text-decoration: underline;
	}

	abbr[title]::after {
		content: " (" attr(title) ")";
	}

	pre {
		white-space: pre-wrap !important;
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}

	blockquote {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	@page {
		size: a3;
	}

	body,
	.container {
		min-width: 992px !important;
	}

	.navbar {
		display: none;
	}

	.badge {
		border: 1px solid $black;
	}

	.table,
	.text-wrap table {
		border-collapse: collapse !important;
	}

	.table td,
	.text-wrap table td,
	.table th,
	.text-wrap table th {
		background-color: $white  !important;
	}

	.table-bordered th,
	.text-wrap table th,
	.table-bordered td,
	.text-wrap table td {
		border: 1px solid #eaedf1 !important;
	}
}

html {
	font-size: 16px;
	height: 100%;
	direction: ltr;
}

body {
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	-webkit-font-feature-settings: "liga" 0;
	font-feature-settings: "liga" 0;
	overflow-y: scroll;
	overflow-x: hidden !important;
	position: relative;

	* {

		&::-webkit-scrollbar-thumb {
			background: #ced4da;
		}

		&:hover::-webkit-scrollbar-thumb {
			background: #adb5bd;
		}
	}
}

@media print {
	body {
		background: none;
	}
}

a {
	-webkit-text-decoration-skip-ink: auto;
	text-decoration-skip-ink: auto;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
	color: inherit;
}

strong,
b {
	font-weight: bolder;
}

code {
	background: #f2f3f9;
	border: 1px solid #eaedf1;
	border-radius: 3px;
	padding: 3px;
}

pre code {
	padding: 0;
	border-radius: 0;
	border: none;
	background: none;
}

pre {
	color: $dark;
	padding: 1rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #f4f8fd;
	border-radius: 3px;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	text-shadow: 0 1px white;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

.section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: 0.5rem 1rem;
	border: 1px solid #eaedf1;
	border-radius: 3px;
	list-style: none;

	&:before {
		content: "Table of contents:";
		display: block;
		font-weight: 600;
	}
}

@media print {
	.container {
		max-width: none;
	}
}

.row-cards> {

	.col,
	[class*="col-"] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.row-deck> {

	.col,
	[class*="col-"] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.col .card,
	[class*="col-"] .card {
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
	}
}

.col-text {
	max-width: 48rem;
}

.col-login {
	max-width: 24rem;
}
/*-----Bootstrap-styles-----*/