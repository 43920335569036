/*----------- Skin-modes -------------*/
.color-header {
    .country-selector .nav-link {
        color: $white !important;
    }

    @media (max-width: 991px) {
        .responsive-navbar.navbar .navbar-collapse {
            background: $primary;
            border-block-end-color: $white-1;
            border-block-start-color:  $white-1;
        }
        .header-icon {
            fill: $white !important;
        }
    }

    .country-selector {
        padding: 0.3rem;
        border-inline-end: 1px solid $border-dark !important;
    }

    @media (min-width:992px) {
        .nav-link.theme-layout {
            border-inline-start: 1px solid $border-dark !important;
        }
    }

    .hor-header {
        border-block-end: 1px solid $white-1;
    }

    .profile-dropdown {
        border-inline-end: 1px solid $border-dark !important;
    }

    .collapse:not(.show) {
        display: none;
        background: transparent;
    }

    .header {
        background: $primary;
    }

    .app-sidebar__toggle {
        border-inline-end: 1px solid $border-dark;
    }

    .app-header {
        border-block-end: 1px solid $border-dark !important;
    }

    .header-icon {
        fill: $white !important;
    }

    .profile-dropdown .header-icon {
        fill: #555b6d !important;
    }

    &.dark-mode .profile-dropdown .header-icon {
        fill: $white !important;
    }

    .header .form-inline .form-control {
        background: $primary;
        color: $white;
    }

    .form-control::placeholder {
        color: $white !important;
    }

    &.dark-mode {
        .form-control::placeholder {
            color: $white !important;
            opacity: 0.5 !important;
        }
    }

    .nav-link.icon .header-icon {
        background: transparent;
    }

    .header .main-header-end .nav-link.icon {
        border-inline-end: 1px solid $border-dark;
    }

    #fullscreen-button {
        border-inline-start: 1px solid $border-dark;
    }

    .hor-header {
        .form-control {
            border: 1px solid $border-dark !important;
        }

        .search-element .btn {
            background-color: transparent !important;
        }

        @media (max-width: 991px) {
            .form-control:focus {
                border-color: $border-dark !important;
            }

            .animated-arrow span {
                background: $white !important;
            }

            .animated-arrow span:before,
            .animated-arrow span:after {
                background: $white !important;
            }
        }

        .desktop-lgo,
        .mobile-logo,
        .darkmobile-logo {
            display: none !important;
        }

        .dark-logo {
            display: block !important;
            margin: 0 auto;
        }

        .form-inline .btn .search-icon {
            fill: #ccd4e0 !important;
        }
    }

    @media (min-width: 991px) {
        .hor-header {
            .profile-dropdown img {
                box-shadow: 0 0 0 4px $white-1;
                border: 2px solid $white-1;
            }
        }

        .profile-dropdown img {
            box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
            border: 2px solid $white-1;
        }
    }

    @media (max-width: 991px) {
        .header-search {
            .form-control {
                border-color: $border !important;
            }
            .header-icon.search-icon {
                fill: #555b6d !important;
            }
        }

        &.dark-mode {
            .country-selector .nav-link {
                color: $white !important;
            }

            .country-selector {
                padding: 0.3rem;
                border-inline-end: 1px solid $border-dark !important;
            }

            .header-search .form-control {
                border-color: $white-1 !important;
            }
        }


        .demo-icon svg {
            fill: $white !important;
        }

        .header-icon.settings-icon {
            fill: $white !important;
        }

        &.dark-mode .header-icon.settings-icon {
            fill: $white !important;
        }

        &.dark-mode .profile-dropdown {
            border-inline-end: 1px solid $border-dark !important;
        }

        .hor-header {
            .navbar-toggler {
                inset-block-start: -10px;
            }

            .animated-arrow span {
                background: $white;
            }

            .animated-arrow span:before,
            .animated-arrow span:after {
                background: $white;
            }
        }

        .profile-dropdown .header-icon {
            fill: #555b6d !important;
        }

        &.dark-mode .header .main-header-end .nav-link.icon {
            border-inline-end: 1px solid $border-dark;
        }

        &.dark-mode .profile-dropdown .header-icon {
            fill: #ccd4e0 !important;
        }

        .theme-layout {
            border-inline-start: 0px !important;
        }

        .desktop-lgo,
        .darkmobile-logo,
        .mobile-logo {
            display: none !important;
        }

        .header-brand-img.dark-logo {
            display: block !important;
            margin: 0 auto;
            text-align: center;
            inset-inline-start: 0;
            inset-inline-end: 0;
            position: absolute;
        }

        .app-sidebar__toggle .header-icon {
            fill: $white !important;
        }

        .nav-link.icon .header-icon {
            background: transparent;
        }

        .nav-link.icon .header-icon {
            margin-block-start: 3px;
        }

        .hader-message {
            margin-inline-start: 0px;
        }

        &.dark-mode .header-icon {
            fill: #ccd4e0 !important;
        }
    }

    .navbar-toggler-icon {
        color: $white;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    // horizontal //
    @media (min-width: 992px) {
        &.horizontal {
            .form-control {
                border: 1px solid $border-dark !important;
            }

            &.dark-mode .hor-header .header-search {
                border: 1px solid $border-dark !important;
            }

            .hor-header .form-inline .form-control {
                padding-inline-start: 14px;
                padding-block-start: 8px;
                border-radius: 4px !important;
            }

            .hor-header {
                border-block-end: 1px solid $white-1;
            }

            .hor-header .search-element .btn {
                background-color: $black-2 !important;
            }
        }
    }
}

.dark-header {
    .country-selector .nav-link {
        color: $white !important;
    }

    .country-selector {
        padding: 0.3rem;
        border-inline-end: 1px solid $border-dark !important;
    }

    @media (min-width:992px) {
        .nav-link.theme-layout {
            border-inline-start: 1px solid $border-dark !important;
        }
    }

    .hor-header {
        border-block-end: 1px solid $white-1;
    }

    .hor-header {

        .desktop-lgo,
        .darkmobile-logo,
        .mobile-logo {
            display: none !important;
        }

        .dark-logo {
            display: block !important;
        }

        @media (max-width: 991px) {
            .animated-arrow span {
                background: $white !important;
            }

            .animated-arrow span:before,
            .animated-arrow span:after {
                background: $white !important;
            }

            .theme-layout {
                border-inline-start: 0px !important;
            }
        }
    }

    .profile-dropdown {
        border-inline-end: 1px solid $border-dark !important;
    }

    @media (min-width: 992px) {
        .hor-header .header-search {
            border: 1px solid $border-dark !important;
        }

        .hor-header .search-element .btn {
            background-color: transparent !important;

            .search-icon {
                fill: #ccd4e0 !important;
            }
        }

        .profile-dropdown img {
            box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
            border: 2px solid $border-dark;
        }
    }

    .collapse:not(.show) {
        display: none;
        background: transparent;
    }

    .header {
        background: $dark-theme;
    }

    .app-sidebar__toggle {
        border-inline-end: 1px solid $border-dark;
    }

    .app-header {
        border-block-end: 1px solid $border-dark !important;
    }

    .header-icon {
        fill: $white !important;
    }

    .profile-dropdown .header-icon {
        fill: #555b6d !important;
    }

    &.dark-mode .profile-dropdown .header-icon {
        fill: $white !important;
    }

    .header .form-inline .form-control {
        background: $dark-theme;
        color: $white;
    }

    .form-control::placeholder {
        color: $white !important;
    }

    &.dark-mode {
        .form-control::placeholder {
            color: $white !important;
            opacity: 0.5 !important;
        }
    }

    .nav-link.icon .header-icon {
        background: transparent;
    }

    .header .main-header-end .nav-link.icon {
        border-inline-end: 1px solid $border-dark;
    }

    #fullscreen-button {
        border-inline-start: 1px solid $border-dark;
    }

    @media (max-width: 991px) {

        
        .responsive-navbar.navbar .navbar-collapse {
            background: $dark-theme;
            border-block-end-color: $border-dark;
            border-block-start-color:  $border-dark;
        }
        .header-icon {
            fill: $white !important;
        }
        
        .header-search {
            .header-icon.search-icon {
                fill: #555b6d !important;
            }
        }

        .country-selector {
            padding: 0.3rem;
        }

        &.dark-mode {
            .country-selector .nav-link {
                color: $white !important;
            }

            .country-selector {
                padding: 0.3rem;
                border-inline-end: 1px solid $border-dark !important;
            }
        }

        .demo-icon svg {
            fill: $white !important;
        }

        &.dark-mode .profile-dropdown {
            border-inline-end: 1px solid $border-dark !important;
        }

        .header-icon.settings-icon {
            fill: $white !important;
        }

        .profile-dropdown img {
            box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
            border: 2px solid $border;
        }

        &.dark-mode .header .main-header-end .nav-link.icon {
            border-inline-end: 1px solid $border-dark;
        }

        &.dark-mode .profile-dropdown .header-icon {
            fill: #ccd4e0 !important;
        }

        .theme-layout {
            border-inline-start: 0px !important;
        }

        .desktop-lgo,
        .darkmobile-logo,
        .mobile-logo {
            display: none !important;
        }

        .header-brand-img.dark-logo {
            display: block !important;
            margin: 0 auto;
            text-align: center;
            inset-inline-start: 0;
            inset-inline-end: 0;
            position: absolute;
        }

        .app-sidebar__toggle .header-icon {
            fill: $white !important;
        }

        .nav-link.icon .header-icon {
            background: transparent;
        }

        .nav-link.icon .header-icon {
            margin-block-start: 3px;
        }

        .hader-message {
            margin-inline-start: 0px;
        }

        &.dark-mode .header-icon {
            fill: #ccd4e0 !important;
        }
    }

    .navbar-toggler-icon {
        color: $white;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    // horizontal //
    @media (min-width: 992px) {
        &.horizontal {
            .form-control {
                border: 1px solid $border-dark !important;
            }

            &.dark-mode .hor-header .header-search {
                border: 1px solid $border-dark !important;
            }

            .hor-header .form-inline .form-control {
                padding-inline-start: 14px;
                padding-block-start: 8px;
                border-radius: 4px !important;
            }

            .hor-header {
                border-block-end: 1px solid $white-1;
            }

            .hor-header .search-element .btn {
                background-color: $black-2 !important;
            }
        }
    }
}

.light-header {
    .country-selector .nav-link {
        color: $color !important;
    }

    .country-selector {
        padding: 0.3rem;
        border-inline-end: 1px solid $border !important;
    }

    @media (min-width:992px) {
        .nav-link.theme-layout {
            border-inline-start: 1px solid $border !important;
        }
    }

    .hor-header {
        border-block-end: 1px solid $white-1;
    }

    &.dark-mode {
        .profile-dropdown {
            margin-block-start: 1px;
            border-inline-end: 1px solid $border !important;
        }

        &.horizontal .hor-header {
            border-block-end: 1px solid $border;
        }

        .header .main-header-end .nav-link.icon {
            border-inline-end-color: $border;
        }

        #fullscreen-button {
            border-inline-start-color: $border !important;
        }
    }

    .profile-dropdown img {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        border: 2px solid $border;
    }

    .collapse:not(.show) {
        display: none;
        background: transparent;
    }

    .app-header, .hor-header {
        background: $white;
    }

    .app-sidebar__toggle {
        border-inline-end: 1px solid $border;
    }

    .app-header {
        border-block-end: 1px solid $border !important;
    }

    .header-icon {
        fill: #555b6d !important;
    }

    .header .form-inline .form-control {
        background: $white;
        color: #8c97a9;
    }

    .form-control::placeholder {
        color: #bcc1c4 !important;
    }

    &.dark-mode {
        .form-control::placeholder {
            color: #bcc1c4 !important;
            opacity: 0.5 !important;
        }
    }

    .nav-link.icon .header-icon {
        background: transparent;
    }

    #fullscreen-button {
        border-inline-start: 1px solid $border;
    }

    &.dark-mode .search-element .search-icon {
        fill: #555b6d !important;
    }

    .hor-header {
        .form-control {
            border: 1px solid $border !important;
        }

        .search-element .btn {
            background-color: $primary  !important;
        }

        @media (max-width: 991px) {
            .form-control:focus {
                border-color: $border !important;
            }
        }

        .dark-logo,
        .mobile-logo,
        .darkmobile-logo {
            display: none !important;
        }

        .desktop-lgo {
            display: block !important;
        }

        .form-inline .btn .search-icon {
            fill: #ccd4e0 !important;
        }
    }

    @media (min-width: 991px) {
        .hor-header {
            .profile-dropdown img {
                box-shadow: 0 0 0 4px $border;
                border: 2px solid $border;
            }
        }
    }

    @media (max-width: 991px) {
        &.dark-mode {
            .country-selector {
                padding: 0.3rem;
            }

            .responsive-navbar.navbar .navbar-collapse {
                background: $white;
                border-color: $border;
            }

            .header-search .form-control {
                border-color: $border-dark !important;
            }
        }

        .header .main-header-end .nav-link.icon.demo-icon {
            border-inline-end: 0 !important;
        }

        &.dark-mode .demo-icon svg {
            fill: #555b6d !important;
        }

        .hor-header {
            .navbar-toggler {
                inset-block-start: -10px;
            }

            .animated-arrow span {
                background: $primary;
            }

            .animated-arrow span:before,
            .animated-arrow span:after {
                background: $primary;
            }
        }

        .profile-dropdown .header-icon {
            fill: #555b6d !important;
        }

        .header .main-header-end .nav-link.icon {
            border-inline-end: 1px solid $border;
        }

        .theme-layout {
            border-inline-start: 0px !important;
        }

        .dark-logo,
        .darkmobile-logo,
        .mobile-logo {
            display: none !important;
        }

        .header-brand-img.desktop-lgo {
            display: block !important;
            margin: 0 auto;
            text-align: center;
            inset-inline-start: 0;
            inset-inline-end: 0;
            position: absolute;
        }

        &.dark-mode {

            .dark-logo,
            .darkmobile-logo,
            .mobile-logo {
                display: none !important;
            }

            .header-brand-img.desktop-lgo {
                display: block !important;
                margin: 0 auto;
            }
        }

        .app-sidebar__toggle .header-icon {
            fill: #555b6d !important;
        }

        .nav-link.icon .header-icon {
            background: transparent;
        }

        .nav-link.icon .header-icon {
            margin-block-start: 3px;
        }

        .hader-message {
            margin-inline-start: 0px;
        }

        &.dark-mode {

            .app-sidebar__toggle .header-icon {
                fill: #555b6d !important;
            }
        }
    }

    .navbar-toggler-icon {
        color: $primary;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    // horizontal //
    @media (min-width: 992px) {
        &.horizontal {

            &.dark-mode .hor-header .header-search {
                border: 1px solid #ebe6f1 !important;
            }

            .hor-header .form-inline .form-control {
                padding-inline-start: 14px;
                padding-block-start: 8px;
                border-radius: 4px !important;
            }

            .hor-header {
                border-block-end: 1px solid $border;
            }

            .hor-header .search-element .btn {
                background-color: $primary  !important;
            }
        }
    }
}

.gradient-header {
    .country-selector .nav-link {
        color: $white !important;
    }

    .country-selector {
        padding: 0.3rem;
        border-inline-end: 1px solid $white-1 !important;
    }

    @media (min-width:992px) {
        .nav-link.theme-layout {
            border-inline-start: 1px solid $border-dark !important;
        }
    }

    .hor-header {
        border-block-end: 1px solid $white-1;
    }

    .hor-header {

        .desktop-lgo,
        .darkmobile-logo,
        .mobile-logo {
            display: none !important;
        }

        .dark-logo {
            display: block !important;
        }

        @media (max-width: 991px) {
            .animated-arrow span {
                background: $white !important;
            }

            .animated-arrow span:before,
            .animated-arrow span:after {
                background: $white !important;
            }

            .hor-header .header-search {
                border: 1px solid $border !important;
            }
        }
    }

    .profile-dropdown {
        border-inline-end: 1px solid $border-dark !important;
    }

    .collapse:not(.show) {
        display: none;
        background: transparent;
    }

    .header {
        background-image: $primary-gradient  !important;
    }

    .app-sidebar__toggle {
        border-inline-end: 1px solid $border-dark;
    }

    .app-header {
        border-block-end: 1px solid $border-dark !important;
    }

    .header-icon {
        fill: $white !important;
    }

    .profile-dropdown .header-icon {
        fill: #555b6d !important;
    }

    &.dark-mode .profile-dropdown .header-icon {
        fill: $white !important;
    }

    .header .form-inline .form-control {
        background: transparent;
        color: $white;
    }

    .form-control::placeholder {
        color: $white !important;
    }

    &.dark-mode {
        .form-control::placeholder {
            color: $white !important;
            opacity: 0.5 !important;
        }
    }

    .nav-link.icon .header-icon {
        background: transparent;
    }

    .header .main-header-end .nav-link.icon {
        border-inline-end: 1px solid $border-dark;
    }

    #fullscreen-button {
        border-inline-start: 1px solid $border-dark;
    }

    @media (min-width: 991px) {
        .profile-dropdown img {
            box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
            border: 2px solid $white-1;
        }

        .form-control::placeholder {
            color: $white !important;
        }

        .hor-header .search-element .btn {
            background-color: transparent !important;

            .search-icon {
                fill: #ccd4e0 !important;
            }
        }
    }

    @media (max-width: 991px) {
        .header-search {
            .header-icon.search-icon {
                fill: #555b6d !important;
            }
        }
        .responsive-navbar.navbar .navbar-collapse {
            background: $primary-gradient;
            border-block-end-color: $border-dark;
            border-block-start-color:  $border-dark;
        }
        .country-selector {
            padding: 0.3rem;
        }

        &.dark-mode {
            .country-selector .nav-link {
                color: $white !important;
            }

            .country-selector {
                padding: 0.3rem;
                border-inline-end: 1px solid $border-dark !important;
            }
        }

        .demo-icon svg {
            fill: $white !important;
        }

        .header-icon.settings-icon {
            fill: $white !important;
        }

        &.dark-mode .header-icon.settings-icon {
            fill: $white !important;
        }

        &.dark-mode .profile-dropdown {
            border-inline-end: 1px solid $border-dark !important;
        }

        &.dark-mode .header .main-header-end .nav-link.icon {
            border-inline-end: 1px solid $border-dark;
        }

        &.dark-mode .profile-dropdown .header-icon {
            fill: #ccd4e0 !important;
        }

        .theme-layout {
            border-inline-start: 0px !important;
        }

        .desktop-lgo,
        .darkmobile-logo,
        .mobile-logo {
            display: none !important;
        }

        .header-brand-img.dark-logo {
            display: block !important;
            margin: 0 auto;
            text-align: center;
            inset-inline-start: 0;
            inset-inline-end: 0;
            position: absolute;
        }

        .app-sidebar__toggle .header-icon {
            fill: $white !important;
        }

        .nav-link.icon .header-icon {
            background: transparent;
        }

        .nav-link.icon .header-icon {
            margin-block-start: 3px;
        }

        .hader-message {
            margin-inline-start: 0px;
        }

        &.dark-mode .header-icon {
            fill: #ccd4e0 !important;
        }
    }

    .navbar-toggler-icon {
        color: $white;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    // horizontal //
    @media (min-width: 992px) {
        &.horizontal {
            .form-control {
                border: 1px solid $border-dark !important;
            }

            &.dark-mode .hor-header .header-search {
                border: 1px solid $border-dark !important;
            }

            .hor-header .form-inline .form-control {
                padding-inline-start: 14px;
                padding-block-start: 8px;
                border-radius: 4px !important;
            }

            .hor-header {
                border-block-end: 1px solid $white-1;
            }

            .hor-header .search-element .btn {
                background-color: $black-2 !important;
            }
        }
    }
}


.color-menu {
    @media (min-width: 992px) {
        .slide-item:hover,
        .slide-item:focus {
            color: $white;
        }
        &.sidebar-mini .header-brand-img{
            &.desktop-lgo{
                display: none;
            }
            &.dark-logo{
                display: block;
            }
        }

        &.double-menu-tabs.app,
        &.double-menu.app {
            .slide-menu {
                background: $primary;
            }

            .sub-slide a {
                color: $dark-color;
            }
            
            .side-account li {
                a {
                  color: $dark-color;
                }
            }

            .side-menu-label1 a {
                color: $white !important;
            }

            .side-menu__item:active .side-menu__icon, .side-menu__item:hover .side-menu__icon, .side-menu__item:focus .side-menu__icon {
                fill: $white !important;
            }

            .app-sidebar__logo {
                border-inline-end-color: $white-1 !important;
            }

            .app-sidebar__logo .mobile-logo {
                display: none !important;
            }

            .app-sidebar__logo .darkmobile-logo {
                display: block !important;
            }
        }

        &.double-menu-tabs.app {
            .slide-menu .sidetab-menu .panel-tabs li a.active, 
            .slide-menu .sidetab-menu .panel-tabs li a:hover{
                border-color: $white-5;
                background: $white-1;
            }

            .slide-menu .tabs-menu ul li a {
                border-color: $white-1;
                color: $dark-color;
            }

            .latest-timeline .timeline:before {
                background: $white-1;
            }

            .tab-content .menutabs-content h5, .tab-content h5 {
                color: $white;
            }

            .list-group-item {
                background-color: transparent;
                border-color: $border-dark !important;
            }

            .tab-content-double {
                color: white;
            }

            .activity {
                border-inline-start-color: $border-dark;
            }
            
            .text-muted {
                color: $white-6 !important;
            }
        }
    }

    .sub-side-menu__label2 {
        color: $dark-color;
    }

    .sub-slide-item2 {
        color: $dark-color;
    }

    .slide-menu a.active {
        color: $white !important;
    }

    .sub-side-menu__item {
        color: $dark-color;
    }

    .sub-slide-item {
        color: $dark-color;
    }

    .slide-menu a.active::before, .slide-item a:hover::before, .slide-item a:focus::before {
        color: $white !important;
    }

    .app-sidebar {
        background: $primary  !important;
    }

    .app-sidebar__logo {
        border-block-end: 1px solid $white-1;
        border-inline-end: 1px solid $white-1;
        background: $primary;
        padding: 11px 12px;
    }

    .app-sidebar .side-item.side-item-category {
        color: $dark-color;
    }

    .app-sidebar {
        border-inline-end: 1px solid $white-1;
    }

    .slide.is-expanded .slide-menu li a:hover {
        color: $white;
    }

    .side-menu__item {

        &.active,
        &:hover,
        &:focus {
            background: #ccd4e01c;
            color: $white !important;

            .side-menu__label {
                color: $white !important;
            }

            .side-menu__icon {
                fill: $white !important;
            }

            i {
                color: $white !important;
            }
        }
    }

    .side-menu__label {
        color: #ccd4e0 !important;
    }

    .side-menu__icon {
        fill: #ccd4e0 !important;
    }

    .side-menu__item i {
        color: $dark-color;
    }

    .slide-item {
        color: $dark-color;
    }

    &.sidenav-toggled1 {
        .side-menu__item:focus {
            background: #ccd4e01c !important;
        }
    }

    @media (min-width: 992px) {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            border-inline-end: 1px solid $white-1 !important;
        }

        &.sidenav-toggled {

            .desktop-lgo,
            .dark-logo,
            .mobile-logo {
                display: none !important;
            }

            .darkmobile-logo {
                display: block !important;
                margin: 0 auto;
            }

            &.sidenav-toggled1 {

                .desktop-lgo,
                .darkmobile-logo,
                .mobile-logo {
                    display: none !important;
                }

                .dark-logo {
                    display: block !important;
                    margin: 0 auto;
                }

                .app-sidebar__logo {
                    padding: 11px 40px;
                }

                .header-brand-img.dark-logo {
                    margin-block-start: 0px;
                }
            }
        }
    }

    &.hover-submenu,
    &.hover-submenu1 {
        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background: $primary  !important;
            border: 0px solid $border;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            padding: 11px 12px;
        }

        &.app.sidenav-toggled1 .header-brand-img {

            &.dark-logo {
                display: none !important;
            }

            &.darkmobile-logo {
                display: block !important;
            }
        }

    }

    .sub-side-menu__item,
    .sub-slide-item,
    .sub-side-menu__label2 {
        color: $dark-color;
        &:hover,
        &:focus {
            color: $white
        }
    }

    &.hover-submenu,
    &.hover-submenu1 {

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-side-menu__label2,
        .sub-angle2,
        .sub-slide-item2 {

            &:hover,
            &:focus {
                color: $white
            }

        }
    }

    &.dark-mode {
        &.double-menu-tabs.app .app-sidebar, &.double-menu.app .app-sidebar {
            .slide-menu {
                background: $primary;
            }
        }
    }


    &.dark-mode.hover-submenu {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo .header-brand .header-brand-img.darkmobile-logo {
            display: block !important;
        }

        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background: $primary  !important;
        }

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-slide-item2,
        .sub-angle2,
        .sub-slide-item2 {
            color: $dark-color;

            &:hover,
            &:focus {
                color: $white;
            }

        }
    }

    &.dark-mode.hover-submenu1 {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo .header-brand .header-brand-img.darkmobile-logo {
            display: block !important;
        }

        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background: $primary  !important;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 {
            border-block-end: 1px solid $white-2;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 a {
            color: $white;
        }

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-slide-item2,
        .sub-angle2,
        .sub-slide-item2 {
            color: $dark-color;

            &:hover,
            &:focus {
                color: $white;
            }

        }
    }

    &.hover-submenu1 {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 {
            border-block-end: 1px solid #868e99;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 a {
            color: $white;
        }
    }

    // horizontal //
    @media (min-width: 992px) {

        &.horizontal .horizontal-main .slide .slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu2 {
            background-color: $primary;
            box-shadow: 0px 16px 18px rgb(135 135 182 / 10%) !important;
            border: 1px solid #e7eaf324;
        }
    }

    &.horizontal .main-sidemenu .slide-left,
    &.horizontal .main-sidemenu .slide-right {
        background: $primary;
        border-color: $white-1;
    }

    &.dark-mode.horizontal .main-sidemenu .slide-left,
    &.dark-mode.horizontal .main-sidemenu .slide-right {
        background: $primary;
        border-color: $white-1;
    }

    &.horizontal .main-sidemenu .slide-right svg,
    &.horizontal .main-sidemenu .slide-left svg {
        fill: $white;
    }

}

.dark-menu {
    @media (min-width: 992px) {

        .slide-item:hover,
        .slide-item:focus {
            color: $white;
        }
        &.sidebar-mini .header-brand-img{
            &.desktop-lgo{
                display: none;
            }
            &.dark-logo{
                display: block;
            }
        }
        &.double-menu-tabs.app .app-sidebar, 
        &.double-menu.app .app-sidebar {
            .slide-menu {
                background: $dark-theme;
            }

            .sub-slide a {
                color: $dark-color;
            }

            .slide-menu .tabs-menu ul li a {
                border-color: $border-dark;
                color: $dark-color;
            }
            .side-menu-label1 a {
                color: $dark-color !important;
            }

            .app-sidebar__logo {
                border-inline-end-color: $white-1 !important;
            }

            .app-sidebar__logo {
                .mobile-logo {
                    display: none !important;
                }
                .darkmobile-logo {
                    display: block !important;
                }
            }
        }

        &.double-menu-tabs.app {
            .menutabs-content {
                color: $dark-color;
            }
            .side-account li a {
                color: $dark-color;
            }

            .list-group-item {
                background-color: transparent;
                border-color: $border-dark !important;
            }

            .tab-content-double {
                color: white;
            }

            .activity {
                border-inline-start-color: $border-dark;
            }
            
            .text-muted {
                color: $white-6 !important;
            }

            .latest-timeline .timeline:before {
                background: $white-1;
            }
        }
        
    }

    .sub-side-menu__label2 {
        color: $dark-color;
    }

    .sub-slide-item2 {
        color: $dark-color;
    }

    .slide-menu a.active {
        color: $white !important;
    }

    .sub-side-menu__item {
        color: $dark-color;
    }

    .sub-slide-item {
        color: $dark-color;
    }

    .slide-menu a.active::before, .slide-item a:hover::before, .slide-item a:focus::before {
        color: $white !important;
    }

    .app-sidebar {
        background: $dark-theme !important;
    }

    .app-sidebar__logo {
        border-block-end: 1px solid $border-dark;
        border-inline-end: 1px solid $border-dark;
        background: $dark-theme;
        padding: 11px 12px;
    }

    .app-sidebar .side-item.side-item-category {
        color: $dark-color;
    }

    .app-sidebar {
        border-inline-end: 1px solid $border-dark;
    }

    .slide.is-expanded .slide-menu li a:hover {
        color: $white;
    }

    .side-menu__item {

        &.active,
        &:hover,
        &:focus {
            background: #ccd4e01c;
            color: $white !important;

            .side-menu__label {
                color: $white !important;
            }

            .side-menu__icon {
                fill: $white !important;
            }

            i {
                color: $white !important;
            }
        }
    }

    .side-menu__label {
        color: #ccd4e0 !important;
    }

    .side-menu__icon {
        fill: #ccd4e0 !important;
    }

    .side-menu__item i {
        color: $dark-color;
    }

    .slide-item {
        color: $dark-color;
    }

    &.sidenav-toggled1 {
        .side-menu__item:focus {
            background: #ccd4e01c !important;
        }
    }

    @media (min-width: 992px) {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            border-inline-end: 1px solid $border-dark !important;
        }

        &.sidenav-toggled {

            .desktop-lgo,
            .dark-logo,
            .mobile-logo {
                display: none !important;
            }

            .darkmobile-logo {
                display: block !important;
                margin: 0 auto;
            }

            &.sidenav-toggled1 {

                .desktop-lgo,
                .darkmobile-logo,
                .mobile-logo {
                    display: none !important;
                }

                .dark-logo {
                    display: block !important;
                    margin: 0 auto;
                }

                .app-sidebar__logo {
                    padding: 11px 40px;
                }

                .header-brand-img.dark-logo {
                    margin-block-start: 0px;
                }
            }
        }
    }

    &.hover-submenu,
    &.hover-submenu1 {
        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background: $dark-theme !important;
            border: 0px solid $border;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            padding: 11px 12px;
        }

        &.app.sidenav-toggled1 .header-brand-img {

            &.dark-logo {
                display: none !important;
            }

            &.darkmobile-logo {
                display: block !important;
            }
        }

    }

    .sub-side-menu__item,
    .sub-slide-item,
    .sub-side-menu__label2 {
        color: $dark-color;
        &:hover, &:focus, &:active {
            color: $white;
        }
    }
    
    .sub-side-menu__label2 {
        &:hover, &:focus, &:active {
            color: $white;
        }
    }

    &.hover-submenu,
    &.hover-submenu1 {

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-side-menu__label2,
        .sub-angle2,
        .sub-slide-item2 {

            &:hover,
            &:focus {
                color: $white
            }

        }
    }


    &.dark-mode.hover-submenu {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo .header-brand .header-brand-img.darkmobile-logo {
            display: block !important;
        }

        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background: $dark-theme !important;
        }

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-slide-item2,
        .sub-angle2,
        .sub-slide-item2 {
            color: $dark-color;

            &:hover,
            &:focus {
                color: $white;
            }

        }
    }

    &.dark-mode.hover-submenu {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo .header-brand .header-brand-img.darkmobile-logo {
            display: block !important;
        }

        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background: $dark-theme !important;
        }

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-slide-item2,
        .sub-angle2,
        .sub-slide-item2 {
            color: $dark-color;

            &:hover,
            &:focus {
                color: $white;
            }

        }
    }

    &.hover-submenu1 {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 {
            border-block-end: 1px solid #868e99;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 a {
            color: $white;
        }
    }

    // horizontal //
    @media (min-width: 992px) {

        &.horizontal .horizontal-main .slide .slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu2 {
            background-color: $dark-theme !important;
            box-shadow: none;
            border: 1px solid #8d8e9129;
        }
    }

    &.horizontal {
        .slide a.sub-side-menu__item:hover {
            color: $white;
        }
    }

    &.horizontal .main-sidemenu .slide-left,
    &.horizontal .main-sidemenu .slide-right {
        background: $dark-theme;
        border-color: $white-1;
    }

    &.horizontal .main-sidemenu .slide-right svg,
    &.horizontal .main-sidemenu .slide-left svg {
        fill: $white;
    }
}

.light-menu {
    &.icontext-menu {
        &.sidenav-toggled.dark-mode {
            .app-sidebar__logo {
                .header-brand-img.mobile-logo {
                    display: block !important;
                }

                .header-brand-img.desktop-lgo,
                .header-brand-img.dark-logo,
                .header-brand-img.darkmobile-logo {
                    display: none !important;
                }
            }
        }

        &.sidenav-toggled.sidenav-toggled1.dark-mode {
            .app-sidebar__logo {
                .header-brand-img.desktop-lgo {
                    display: block !important;
                }

                .header-brand-img.mobile-logo,
                .header-brand-img.dark-logo,
                .header-brand-img.darkmobile-logo {
                    display: none !important;
                }
            }
        }

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-slide-item2,
        .slide-item {
            color: $color !important;
        }

        .side-menu__icon {
            color: #555b6d !important;
            fill: #555b6d !important;
        }
    }

    .app-sidebar {
        background: $white !important;
    }

    .app-sidebar__logo {
        border-block-end: 1px solid $border;
        border-inline-end: 1px solid $border;
        background: $white;
        padding: 11px 12px;
    }

    .app-sidebar .side-item.side-item-category {
        color: $dark-color;
    }

    .app-sidebar {
        border-inline-end: 1px solid $border;
    }

    .slide.is-expanded .slide-menu li a:hover {
        color: $primary;
    }

    .side-menu__item {
        &.active,
        &:hover,
        &:focus {
            background: $background;
            color: $primary !important;

            .side-menu__label {
                color: $primary !important;
            }

            .side-menu__icon {
                fill: $primary !important;
            }

            i {
                color: $primary !important;
            }
        }
    }

    .side-menu__label {
        color: $color !important;
    }

    .side-menu__icon {
        fill: #555b6d !important;
    }

    .side-menu__item i {
        color: $color;
    }

    .slide-item {
        color: $color;
    }

    &.sidenav-toggled1 {
        .side-menu__item:focus {
            background: #ccd4e01c !important;
        }
    }

    .side-item.side-item-category {
        color: #9993b5 !important;
    }

    .text-muted {
        color: #adb1c5 !important;
    }

    &.dark-mode {

        .slide-menu a.active::before, .slide-item a:hover::before, .slide-item a:focus::before {
            color: $primary !important;
        }
    }

    @media (min-width: 992px) {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            border-inline-end: 1px solid $border !important;
        }

        &.dark-mode.double-menu-tabs.app .app-sidebar,
        &.dark-mode.double-menu.app .app-sidebar {
            .app-sidebar__logo .darkmobile-logo {
                display: none !important;
            }
            .app-sidebar__logo .mobile-logo {
                display: block !important;
            }
            .slide-menu {
                background: $white;
            }

            .side-account li a {
                color: $color;
            }
            
            .side-menu-label1 a {
                color: $color !important;
            }

            &.sidebar-mini .app-sidebar .app-sidebar__logo {
                border-inline-end: 1px solid $border !important;
            }

            .slide-menu .tabs-menu ul li a {
                border-color: $border;
                color: $color;
                &.active {
                    border-color: $primary;
                }
            }

            .list-group-item {
                background-color: white;
                border-color: $border !important;
                color: #212529;
            }
            h5 {
                color: $color;
            }

            a {
                color: $color;
            }

            .latest-timeline, .activity {
                color: #495046;
            }

            .sidebar-mini .app-sidebar__logo {
                border-inline-end: 1px solid $border !important;
            }

            .latest-timeline .timeline:before {
                background: $background;
            }

            .activity {
                border-inline-start-color: $border;
            }
        }

        &.dark-mode.double-menu-tabs, &.dark-mode.double-menu {
            &.app.sidebar-mini.light-menu .app-sidebar .app-sidebar__logo {
                border-inline-end: 1px solid $border !important;
            }
        }

        &.sidenav-toggled {
            .darkmobile-logo,
            .dark-logo,
            .desktop-lgo {
                display: none !important;
            }

            .app-sidebar__logo .header-brand-img.mobile-logo {
                display: block !important;
                margin: 0 auto;
            }

            &.sidenav-toggled1  .app-sidebar__logo .header-brand-img.mobile-logo {
                display: none !important;
            }

            &.hover-submenu.sidenav-toggled1  .app-sidebar__logo .header-brand-img.mobile-logo {
                display: block !important;
            }

            &.hover-submenu.sidenav-toggled1  .app-sidebar__logo .header-brand-img.desktop-lgo {
                display:  none !important;
            }

            &.hover-submenu1.sidenav-toggled1  .app-sidebar__logo .header-brand-img.mobile-logo {
                display: block !important;
            }

            &.hover-submenu1.sidenav-toggled1  .app-sidebar__logo .header-brand-img.desktop-lgo {
                display:  none !important;
            }

            &.sidenav-toggled1 {
                .app-sidebar__logo {
                    border-inline-end: 1px solid $border !important;
                }

                .mobile-logo,
                .darkmobile-logo,
                .dark-logo {
                    display: none !important;
                }

                .desktop-lgo {
                    display: block !important;
                    margin: 0 auto;
                }

                .app-sidebar__logo {
                    padding: 11px 12px;
                }

                .header-brand-img.dark-logo {
                    margin-block-start: 0px;
                }
            }
        }
    }

    &.dark-mode.sidenav-toggled .app-sidebar__logo {
        padding: 11px 12px;
    }

    &.dark-mode{
        .app-sidebar__logo .header-brand-img.desktop-lgo{
            display: block;
        }
         
        .sub-side-menu__label2,
        .sub-slide-item2,
        .sub-slide-item2 {
            &:hover, &:focus {
                color: $primary;
            }
        }

    }

    .sub-side-menu__item,
    .sub-slide-item,
    .sub-slide-item2 {
        color: $color;
        &:hover, &:focus, &:active {
            color: $primary;
        }
    }

    
    .sub-side-menu__label2 {
        &:hover, &:focus, &:active {
            color: $primary;
        }
    }

    &.hover-submenu,
    &.hover-submenu1 {

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-side-menu__label2 {

            &:hover,
            &:focus {
                color: $primary;
            }

        }
    }


    &.dark-mode.hover-submenu {

        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background: $white !important;
        }

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-slide-item2,
        .sub-angle2,
        .sub-slide-item2 {
            color: $color;

            &:hover,
            &:focus {
                color: $primary;
            }

        }
    }


    &.dark-mode.hover-submenu1 {

        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background: $white !important;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 {
            border-block-end: 1px solid $border;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 a {
            color: $primary;
        }

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-slide-item2,
        .sub-angle2,
        .sub-slide-item2 {
            color: $color;

            &:hover,
            &:focus {
                color: $primary;
            }

        }
    }

    // horizontal //

    &.dark-mode.horizontal .horizontal-main .slide .slide-menu,
    &.dark-mode.horizontal .horizontal-main .slide .sub-slide-menu,
    &.dark-mode.horizontal .horizontal-main .slide .sub-slide-menu2 {
        background-color: $white;
        border: 1px solid $border;
        box-shadow: none !important;
    }
    
    &.dark-mode.horizontal .horizontal-main .slide .sub-slide-menu2 .sub-slide-item2 {
        color: $color;
        &:hover, &:focus {
            color: $primary;
        }
    }

    &.dark-mode.horizontal .main-sidemenu .slide-left,
    &.dark-mode.horizontal .main-sidemenu .slide-right {
        background: $white;
        border: 1px solid #eaedf1;
    }

    &.horizontal .main-sidemenu .slide-right svg,
    &.horizontal .main-sidemenu .slide-left svg {
        fill: #7b8191;
    }

    &.dark-mode.horizontal .main-sidemenu .slide-left,
    &.dark-mode.horizontal .main-sidemenu .slide-right {
        background: $white;
        border: 1px solid #eaedf1;
    }

    &.horizontal .main-sidemenu .slide-right svg,
    &.horizontal .main-sidemenu .slide-left svg {
        fill: #7b8191;
    }

    &.horizontal.horizontal-hover .slide .slide-item {

        &:hover,
        &:focus {
            color: $primary  !important;
        }

    }

    &.horizontal-hover.horizontal .slide a.sub-side-menu__item:hover {
        color: $primary  !important;
    }

    &.horizontal-hover.dark-mode .slide-menu .sub-slide a.sub-side-menu__item:hover {
        color: $primary  !important;
    }
}

.gradient-menu {
    @media (min-width: 992px) {

        .slide-item:hover,
        .slide-item:focus {
            color: $white;
        }
        &.sidebar-mini .header-brand-img{
            &.desktop-lgo{
                display: none;
            }
            &.dark-logo{
                display: block;
            }
        }

        &.double-menu-tabs.app,
        &.double-menu.app {
            .slide-menu {
                background-image: $primary-gradient;
            }

            .sub-slide a {
                color: $dark-color;
            }

            .side-account li {
                a {
                  color: $dark-color;
                }
            }

            .side-menu-label1 a {
                color: $white !important;
            }

            .app-sidebar__logo {
                border-inline-end-color: $white-1 !important;
            }

            .side-menu__item:active .side-menu__icon, .side-menu__item:hover .side-menu__icon, .side-menu__item:focus .side-menu__icon {
                fill: $white !important;
            }
            
            .app-sidebar__logo .mobile-logo {
                display: none !important;
            }

            .app-sidebar__logo .darkmobile-logo {
                display: block !important;
            }
        }

        &.double-menu-tabs.app {
            .slide-menu .sidetab-menu .panel-tabs li a.active, 
            .slide-menu .sidetab-menu .panel-tabs li a:hover{
                border-color: $white-5;
                background: $white-1;
            }
            .slide-menu .tabs-menu ul li a {
                border-color: $white-1;
                color: $dark-color;
            }
            .latest-timeline .timeline:before {
                background: $white-1;
            }

            .tab-content .menutabs-content h5, .tab-content h5 {
                color: $white;
            }

            .list-group-item {
                background-color: transparent;
                border-color: $border-dark !important;
            }

            .tab-content-double {
                color: white;
            }

            .activity {
                border-inline-start-color: $border-dark;
            }
            
            .text-muted {
                color: $white-6 !important;
            }
        }
    }

    .sub-side-menu__label2 {
        color: $dark-color;
    }

    .sub-slide-item2 {
        color: $dark-color;
    }

    .slide-menu a.active {
        color: $white !important;
    }

    .sub-side-menu__item {
        color: $dark-color;
    }

    .sub-slide-item {
        color: $dark-color;
    }

    .slide-menu a.active::before, .slide-item a:hover::before, .slide-item a:focus::before {
        color: $white !important;
    }

    .app-sidebar {
        background-image: $primary-gradient  !important;
    }

    .app-sidebar__logo {
        border-block-end: 1px solid $white-1;
        border-inline-end: 1px solid $white-1;
        background: $primary-gradient;
        padding: 11px 12px;
    }

    .app-sidebar .side-item.side-item-category {
        color: $dark-color;
    }

    .app-sidebar {
        border-inline-end: 1px solid $white-1;
    }

    .slide.is-expanded .slide-menu li a:hover {
        color: $white;
    }

    .side-menu__item {

        &.active,
        &:hover,
        &:focus {
            background: #ccd4e01c;
            color: $white !important;

            .side-menu__label {
                color: $white !important;
            }

            .side-menu__icon {
                fill: $white !important;
            }

            i {
                color: $white !important;
            }
        }
    }

    .side-menu__label {
        color: #ccd4e0 !important;
    }

    .side-menu__icon {
        fill: #ccd4e0 !important;
    }

    .side-menu__item i {
        color: $dark-color;
    }

    .slide-item {
        color: $dark-color;
    }

    &.sidenav-toggled1 {
        .side-menu__item:focus {
            background: #ccd4e01c !important;
        }
    }

    @media (min-width: 992px) {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            border-inline-end: 1px solid $white-1 !important;
        }

        &.sidenav-toggled {

            .desktop-lgo,
            .dark-logo,
            .mobile-logo {
                display: none !important;
            }

            .darkmobile-logo {
                display: block !important;
                margin: 0 auto;
            }

            &.sidenav-toggled1 {

                .desktop-lgo,
                .darkmobile-logo,
                .mobile-logo {
                    display: none !important;
                }

                .dark-logo {
                    display: block !important;
                    margin: 0 auto;
                }

                .app-sidebar__logo {
                    padding: 11px 40px;
                }

                .header-brand-img.dark-logo {
                    margin-block-start: 0px;
                }
            }
        }
    }

    &.hover-submenu,
    &.hover-submenu1 {
        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background-image: $primary-gradient  !important;
            border: 0px solid $border;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo {
            padding: 11px 12px;
        }

        &.app.sidenav-toggled1 .header-brand-img {

            &.dark-logo {
                display: none !important;
            }

            &.darkmobile-logo {
                display: block !important;
            }
        }

    }

    .sub-side-menu__item,
    .sub-slide-item,
    .sub-side-menu__label2 {
        color: $dark-color;

        &:hover,
        &:focus {
            color: $white
        }
    }

    &.hover-submenu,
    &.hover-submenu1 {

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-side-menu__label2,
        .sub-angle2,
        .sub-slide-item2 {

            &:hover,
            &:focus {
                color: $white
            }

        }
    }

    &.dark-mode {
        &.double-menu-tabs.app .app-sidebar, &.double-menu.app .app-sidebar {
            .slide-menu {
                background: $primary-gradient;
            }
        }
    }


    &.dark-mode.hover-submenu {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar__logo .header-brand .header-brand-img.darkmobile-logo {
            display: block !important;
        }

        &.app.sidenav-toggled1.sidenav-toggled .slide:hover .slide-menu {
            background-image: $primary-gradient  !important;
        }

        .sub-side-menu__item,
        .sub-slide-item,
        .sub-slide-item2,
        .sub-angle2,
        .sub-slide-item2 {
            color: $dark-color;

            &:hover,
            &:focus {
                color: $white;
            }

        }
    }

    &.hover-submenu1 {
        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 {
            border-block-end: 1px solid #868e99;
        }

        &.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu-label1 a {
            color: $white;
        }
    }

    // gradient menu //
    @media (min-width: 992px) {

        &.horizontal .horizontal-main .slide .slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu,
        &.horizontal .horizontal-main .slide .sub-slide-menu2 {
            background-image: $primary-gradient  !important;
            box-shadow: 0px 16px 18px rgb(135 135 182 / 10%) !important;
            border: 1px solid #e7eaf324;
        }
    }

    &.horizontal .main-sidemenu .slide-left,
    &.horizontal .main-sidemenu .slide-right {
        background: $primary-gradient;
        border-color: $white-1;
    }

    &.horizontal .main-sidemenu .slide-right svg,
    &.horizontal .main-sidemenu .slide-left svg {
        fill: $white;
    }

    &.dark-mode.horizontal .main-sidemenu .slide-left,
    &.dark-mode.horizontal .main-sidemenu .slide-right {
        background: $primary;
        border-color: $white-1;
    }

}


/*--Boxed--*/
@media (max-width: 1400px) {
    .layout-boxed {
        .page {
            width: auto;
        }
    }
}

@media (min-width: 1400px) {
    body.layout-boxed {
        background: #cfd1e2;
    }

    body.layout-boxed.dark-mode {
        background: $dark-theme;
    }

    .layout-boxed.construction {
        .page {
            background-image: url(../images/media/32.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            z-index: 999;
            &::before{
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                background: linear-gradient(#2c3e50, #bdc3c7);
                opacity: 0.5;
                inset-inline-start: 0;
                inset-inline-end: 0;
                inset-block-start: 0;
                inset-block-end: 0;
            }
        }
    }

    body.layout-boxed .sticky-pin .horizontal-main {
        max-width: 1400px;
        margin: 0;
    }

    .register-2.layout-boxed .page {
	    background-color: transparent;
    }

    .dark-mode.layout-boxed.construction {
        .page {
            background-image: url(../images/media/32.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            z-index: 999;
            &::before{
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                background: linear-gradient(#2c3e50, #bdc3c7);
                opacity: 0.5;
                inset-inline-start: 0;
                inset-inline-end: 0;
                inset-block-start: 0;
                inset-block-end: 0;
            }
        }
    }

    .layout-boxed {
        .page {
            width: 1400px;
            margin: 0 auto;
            background: #f2f3f9;
            position: relative;
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex-direction: column;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            -ms-flex-pack: center;
            -webkit-box-pack: center;
            -webkit-box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
            box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
        }

        .app-header.header {
            width: 1400px !important;
            margin: 0 auto;
        }

        .app-sidebar {
            inset-inline-start: auto !important;
        }

        .side-header {
            inset-inline-start: auto !important;
            inset-inline-end: auto !important;
        }

        h3.number-font {
            font-size: 1.8rem;
        }

        .sticky-pin .horizontal-main.hor-menu {
            width: 1400px !important;
        }

        .hor-content .container,
        .hor-header .container,
        .horizontal-main.hor-menu .container {
            max-width: 95%;
        }

        .recent-activity {
            height: 430px !important;
        }
    }

    .dark-mode.layout-boxed .page {
        background: $dark-body;
        -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
    }

    .horizontal {
        @media (min-width: 1400px) {

            &.layout-boxed .hor-content .container,
            &.layout-boxed .hor-header .container,
            &.layout-boxed .horizontal-main.hor-menu .container,
            &.layout-boxed .horizontal-main .container {
                max-width: 95% !important;
            }

            &.layout-boxed.dark-mode .app-sidebar {
                border-inline-end: 0;
            }
            
            &.layout-boxed .app-sidebar.fixed-header {
                max-width: 1400px;
            }
        }
    }
}

/*--- Scrollabel-header ----*/
@media (min-width: 991px) {
    .layout-boxed.menu3.sideicon-menu.sidenav-toggled.scrollable-layout {
        .app-sidebar {
            max-height: 100%;
        }
    }

    .layout-boxed.scrollable-layout.menu1.default-menu.sidenav-toggled {
        .app-sidebar {
            max-height: 100%;
        }
    }

    .sidebar-mini.scrollable-layout.menu3.sideicon-menu.sidenav-toggled {
        .app-sidebar {
            max-height: 100%;
        }
    }

    .scrollable-layout {
        &.double-menu.app .app-sidebar, &.double-menu-tabs.app .app-sidebar {
            position: absolute !important;
        }
    }
}

.scrollable-layout {
    .app-header {
        position: absolute !important;
    }

    .app-sidebar {
        position: absolute;
    }

    .app-sidebar__logo {
        position: absolute;
    }

    .page {
        position: relative;
    }

    @media (max-width: 991px) {
        .header .responsive-navbar.navbar .navbar-collapse {
            position: absolute;
            width: 100%;
        }
        .header.hor-header{
            position: absolute;
        }
    }

    .navbar {
        position: inherit;
    }
}

.dark-mode .bg-left2,
.light-mode #background-left1,
.light-header .bg1,
.color-header .bg2,
.dark-header .bg3,
.gradient-header .bg8,
.light-menu .bg4,
.color-menu .bg5,
.dark-menu .bg6,
.gradient-menu .bg7,
.layout-fullwidth #background14,
.layout-boxed #background15,
.fixed-layout #background16,
.scrollable-layout #background17,
.dark-mode .bg-hor2,
.light-mode .bg-hor1,
.light-header .bg-hor3,
.color-header .bg-hor4,
.dark-header .bg-hor5,
.gradient-header .bg-hor10 {
    border: 3px solid #6c5ffc !important;
}

.dark-mode .bg-left2:before,
.light-mode #background-left1::before,
.dark-mode .bg-hor2:before,
.light-mode .bg-hor1:before,
.layout-fullwidth #background14:before,
.layout-boxed #background15:before,
.fixed-layout #background16:before,
.scrollable-layout #background17:before {
    content: "\2713";
    z-index: 999;
    position: absolute;
    inset-block-start: 4px;
    inset-inline-end: 21px;
    font-size: 14px;
    color: $white;
}

.dark-mode .bg-left2:after,
.light-mode #background-left1:after,
.dark-mode .bg-hor2:after,
.light-mode .bg-hor1:after,
.layout-fullwidth #background14:after,
.layout-boxed #background15:after,
.fixed-layout #background16:after,
.scrollable-layout #background17:after {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 17px;
    border-style: solid;
    border-width: 0 35px 35px 0px;
    border-color: #6c5ffc #6c5ffc transparent;
    display: block;
    position: absolute;
    inset-block-start: 4px;
    inset-inline-end: 17px;
}

.light-header .bg1:before,
.color-header .bg2:before,
.dark-header .bg3:before,
.gradient-header .bg8:before,
.light-menu .bg4:before,
.color-menu .bg5:before,
.dark-menu .bg6:before,
.gradient-menu .bg7:before,
.light-header .bg-hor3:before,
.color-header .bg-hor4:before,
.dark-header .bg-hor5:before,
.gradient-header .bg-hor10::before {
    content: "\2713";
    z-index: 999;
    position: absolute;
    inset-block-start: 4px;
    inset-inline-end: 24px;
    font-size: 14px;
    color: $white;
}

.light-header .bg1:after,
.color-header .bg2:after,
.dark-header .bg3:after,
.gradient-header .bg8:after,
.light-menu .bg4:after,
.color-menu .bg5:after,
.dark-menu .bg6:after,
.gradient-menu .bg7:after,
.light-header .bg-hor3:after,
.color-header .bg-hor4:after,
.dark-header .bg-hor5:after,
.gradient-header .bg-hor10:after {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 17px;
    border-style: solid;
    border-width: 0 35px 35px 0px;
    border-color: #6c5ffc #6c5ffc transparent;
    display: block;
    position: absolute;
    inset-block-start: 4px;
    inset-inline-end: 20px;
}



/*--- Only Demo Purpose scss Icon-Text----*/
@media (min-width: 992px) {
    .icontext-menu.sidebar-mini.sidenav-toggled .app-sidebar {
        width: 120px;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled .side-header {
        width: 120px !important;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled .app-content {
        margin-inline-start: 120px;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu__item {
        padding: 12px 5px;
        text-align: center;
        display: block;
    }

    .icontext-menu.app.sidebar-mini.sidenav-toggled .side-menu__label {
        display: block !important;
        font-size: 12px;
        padding-block-start: 0px;
        margin-block-start: 0;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled .app-header.header {
        padding-inline-start: 120px;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon:after {
        inset-block-start: 6px;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
        width: 270px;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header {
        width: 270px !important;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item {
        display: flex;
        padding: 10px 20px;
        text-align: inherit;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__label {
        display: block !important;
        margin-block-start: 3px;
        font-size: 15px;
    }

    .icontext-menu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu .side-menu__icon:after {
        inset-block-start: 12px;
    }

    .icontext-menu.sidenav-toggled .app-sidebar__logo {
        width: 119px;
    }
}

.layout-boxed.closed-menu.sidenav-toggled {
    @media (min-width: 992px) {
        .app-sidebar {
            width: 0px !important;
            display: none !important;
        }

        .app-sidebar__logo {
            width: 0px !important;
            display: none !important;
        }
    }
}

.icontext-menu .app-sidebar__logo {
    padding: 11px 20px !important;
}

@media (min-width: 992px) {
    .icontext-menu.sidenav-toggled.sidenav-toggled1 .header-brand-img.desktop-lgo {
        margin-block-start: 0px !important;
    }
}

.app.sidebar-mini.dark-mode.light-menu {
    .app-sidebar {
        .app-sidebar__logo {
            .header-brand {
                .header-brand-img.dark-logo {
                    display: none !important;
                }
            }
        }
    }
}


.light-mode {
    @media (min-width: 992px) {
        &.dark-menu.double-menu-tabs.app,
        &.dark-menu.double-menu.app {
            .app-sidebar__logo .darkmobile-logo {
                display: block !important;
            }
            .app-sidebar__logo .mobile-logo {
                display: none !important;
            }
            .slide-menu {
                background: $dark-theme;
            }
            
            .side-menu-label1 a {
                color: $white !important;
            }

            .side-menu__item:active .side-menu__icon, .side-menu__item:hover .side-menu__icon, .side-menu__item:focus .side-menu__icon {
                fill: $white !important;
            }
            .slide-menu {
                background: $dark-theme;
            }
            
        }
        &.dark-menu {
            &.double-menu-tabs.app .app-sidebar {
                .slide-menu .tabs-menu ul li a {
                    color: $dark-color;
                }

                .slide-menu .tabs-menu ul li a {
                    color: $dark-color;
                }
                .tab-pane {
                    h5 {
                        color: $white;
                    }

                    .list-group-item {
                        background-color: transparent;
                        border-color: $white-1 !important;
                        color: $dark-color;
                    }

                    .form-control {
                        color: $white;
                        background-color: transparent;
                        border-color: $white-1;
                    }
                }
                .latest-timeline {
                    color: $dark-color;
                    .timeline:before {
                        background: $white-1;
                    }
                }
                .activity {
                    border-inline-start-color: $white-1;
                }
                .activity .item-activity {
                    color: $dark-color;
                }
                .text-muted {
                    color: $white-4 !important;
                }
            }
        }
        &.color-menu, &.gradient-menu {
            &.double-menu-tabs.app .app-sidebar {
                .slide-menu .tabs-menu ul li a {
                    color: $dark-color;
                }
                .activity {
                    border-inline-start-color: $white-1;
                }
                .tab-pane {
                    h5 {
                        color: $white;
                    }

                    .list-group-item {
                        background-color: transparent;
                        border-color: $white-1 !important;
                        color: $dark-color;
                    }

                    .form-control {
                        color: $white;
                        background-color: transparent;
                        border-color: $white-1;
                    }
                }
                .latest-timeline {
                    color: $dark-color;
                }
                .activity .item-activity {
                    color: $dark-color;
                }
                .text-muted {
                    color: $white-4 !important;
                }
            }
        }
    }
}
/*----------- Skin-modes End-------------*/
