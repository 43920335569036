/*----- Input-group----*/
.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
  }
  
  .input-group-addon {
	padding: .5rem .75rem;
	margin-block-end: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25;
	color: #b7bec5;
	text-align: center;
	border: 1px solid $border;
	border-inline-end: 0;
	border-radius: .25rem 0 0 .2rem;
  }
  
  .box-shadow-0 {
	box-shadow: none;
  }
  
  .input-group > {
	.form-control, .custom-select {
	  position: relative;
	  -ms-flex: 1 1 auto;
	  flex: 1 1 auto;
	  width: 1%;
	  margin-block-end: 0;
	}
  
	.form-control:focus, .custom-select:focus {
	  z-index: 2;
	}
  
	.form-control + {
	  .form-control, .custom-select {
		margin-inline-start: -1px;
	  }
	}
  
	.custom-select + {
	  .form-control, .custom-select {
		margin-inline-start: -1px;
	  }
	}
  
	.form-control:not(:last-child), .custom-select:not(:last-child) {
	  border-start-end-radius: 0;
	  border-end-end-radius: 0;
	}
  
	.form-control:not(:first-child), .custom-select:not(:first-child) {
	  border-start-start-radius: 0;
	  border-end-start-radius: 0;
	}
  
  }
  
  .input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-block-end: 0;
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.6;
	color: #98a6be;
    text-align: center;
   	white-space: nowrap;
	background-color: white;
	border: 1px solid $border;
   	border-radius: 5px;
  
	input {
	  &[type="radio"], &[type="checkbox"] {
		margin-block-start: 0;
	  }
	}
  }

  .input-group .btn {
    z-index: 0;
}

  /*----- Input-group End----*/