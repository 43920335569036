/*------ Modal -------*/

.modal-open {
	overflow: hidden;
	padding: 0 !important;
	display: block;
	overflow: inherit;
  }

  .modal-open {
	overflow: auto !important;
	padding: 0px !important;
	.modal {
	overflow-x: hidden;
	overflow-y: auto;
	padding-inline-end: 0px !important;
	}
	}
  
  .modal {
	position: fixed;
	inset-block-start: 0;
	inset-inline-end: 0;
	inset-block-end: 0;
	inset-inline-start: 0;
	z-index: 10000;
	display: none;
	overflow: hidden;
	outline: 0;
	padding-inline-end: 0 !important;
	margin: 0 !important;
  }
  
  .modal-open, .modal-backdrop.fade.show {
	padding-inline-end: 0 !important;
  }
  
  .modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
  }
  
  .modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
  }
  
  .modal {
	&.fade .modal-dialog {
	  transition: -webkit-transform 0.3s ease-out;
	  transition: transform 0.3s ease-out;
	  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	  -webkit-transform: translate(0, -25%);
	  transform: translate(0, -25%);
	}
  
	&.show .modal-dialog {
	  -webkit-transform: translate(0, 0);
	  transform: translate(0, 0);
	}
  }
  
  @media screen and (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
	  transition: none;
	}
  }
  
  .modal-dialog-centered {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	min-height: calc(100% - (0.5rem * 2));
  }
  
  .modal-content {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $border;
	border-radius: 3px;
	outline: 0;
  }
  
  .modal-backdrop {
	position: fixed;
	inset-block-start: 0;
	inset-inline-end: 0;
	inset-block-end: 0;
	inset-inline-start: 0;
	z-index: 9999999;
	background-color: $black;
  
	&.fade {
	  opacity: 0;
	}
  
	&.show {
	  opacity: 0.5;
	}
  }
  .modal{
  z-index:99999999;
  }
  
  .modal-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: left;
	align-items: flex-left;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 1rem;
	border-block-end: 1px solid $border;
	border-start-end-radius: 3px;
	border-start-end-radius: 3px;
  
	.btn-close {
	  padding: 1rem;
	  margin-block-start: -1rem;
	  margin-inline-end: -1rem;
	  margin-block-end: -1rem;
    	 margin-inline-start: auto;
	  background-image:none !important;
	  font-size:25px;
	}
  }
  
  .modal-title {
	margin-block-end: 0;
	line-height: 1.5;
	font-size: 16px;
  }
  
  .modal-body {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1rem;
  
	p:last-child {
	  margin-block-end: 0;
	}
  }
  
  .modal-footer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: right;
	justify-content: flex-right;
	padding: 1rem;
	border-block-start: 1px solid #e9ecef;
  
	> {
	  :not(:first-child) {
		margin-inline-start: .25rem;
	  }
  
	  :not(:last-child) {
		margin-inline-end: .25rem;
	  }
	}
  }
  
  @media (min-width: 576px) {
	.modal-dialog {
	  max-width: 500px;
	  margin: 1.75rem auto;
	}
  
	.modal-dialog-centered {
	  min-height: calc(100% - (1.75rem * 2));
	}
  
	.modal-sm {
	  max-width: 300px;
	}
  }
  
  @media (min-width: 992px) {
	.modal-lg {
	  max-width: 800px;
	}
  }
  
  .modal {
	&.animated .modal-dialog {
	  transform: translate(0, 0);
	}
  
	&.effect-scale {
	  .modal-dialog {
		transform: scale(0.7);
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: scale(1);
		opacity: 1;
	  }
	}
  
	&.effect-slide-in-right {
	  .modal-dialog {
		transform: translateX(20%);
		opacity: 0;
		transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
	  }
  
	  &.show .modal-dialog {
		transform: translateX(0);
		opacity: 1;
	  }
	}
  
	&.effect-slide-in-bottom {
	  .modal-dialog {
		transform: translateY(20%);
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: translateY(0);
		opacity: 1;
	  }
	}
  
	&.effect-newspaper {
	  .modal-dialog {
		transform: scale(0) rotate(720deg);
		opacity: 0;
	  }
  
	  &.show ~ .modal-backdrop, .modal-dialog {
		transition: all 0.5s;
	  }
  
	  &.show .modal-dialog {
		transform: scale(1) rotate(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-fall {
	  -webkit-perspective: 1300px;
	  -moz-perspective: 1300px;
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: translateZ(600px) rotateX(20deg);
		opacity: 0;
	  }
  
	  &.show .modal-dialog {
		transition: all 0.3s ease-in;
		transform: translateZ(0px) rotateX(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-flip-horizontal {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: rotateY(-70deg);
		transition: all 0.3s;
		opacity: 0;
	  }
  
	  &.show .modal-dialog {
		transform: rotateY(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-flip-vertical {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: rotateX(-70deg);
		transition: all 0.3s;
		opacity: 0;
	  }
  
	  &.show .modal-dialog {
		transform: rotateX(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-super-scaled {
	  .modal-dialog {
		transform: scale(2);
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: scale(1);
		opacity: 1;
	  }
	}
  
	&.effect-sign {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: rotateX(-60deg);
		transform-origin: 50% 0;
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: rotateX(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-rotate-bottom {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: translateY(100%) rotateX(90deg);
		transform-origin: 0 100%;
		opacity: 0;
		transition: all 0.3s ease-out;
	  }
  
	  &.show .modal-dialog {
		transform: translateY(0%) rotateX(0deg);
		opacity: 1;
	  }
	}
  
	&.effect-rotate-left {
	  perspective: 1300px;
  
	  .modal-dialog {
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		transform: translateZ(100px) translateX(-30%) rotateY(90deg);
		transform-origin: 0 100%;
		opacity: 0;
		transition: all 0.3s;
	  }
  
	  &.show .modal-dialog {
		transform: translateZ(0px) translateX(0%) rotateY(0deg);
		opacity: 1;
	  }
	}
  }

 /*------ Modal End-------*/ 