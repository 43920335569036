/*------ Cards -------*/

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: white;
	background-clip: border-box;
	position: relative;
	margin-block-end: 1.5rem;
	width: 100%;
	border: 1px solid transparent;
	box-shadow: $shadow;
	border-radius: 0.30rem;

	> {
		hr {
			margin-inline-end: 0;
			margin-inline-start: 0;
		}

		.list-group {
			&:first-child .list-group-item:first-child {
				border-start-end-radius: 3px;
				border-start-end-radius: 3px;
			}

			&:last-child .list-group-item:last-child {
				border-end-end-radius: 7px;
				border-end-start-radius: 7px;
			}
		}
	}
}

.card-subtitle {
	margin-block-start: -0.75rem;
	margin-block-end: 0;
}

.card-text:last-child {
	margin-block-end: 0;
}

.card-link:hover {
	text-decoration: none;
}

.card-header {
	&:first-child {
		border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
	}

	+.list-group .list-group-item:first-child {
		border-block-start: 0;
	}
}

.card-footer {
	padding: 1.5rem 1.5rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-block-start: 1px solid $border;
	color: #6e7687;

	&:last-child {
		border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
	}
}

.card-img {
	width: 100%;
	border-radius: calc(3px - 1px);
}

.card-img-top {
	width: 100%;
	border-start-start-radius: calc(3px - 1px);
	border-start-end-radius: calc(3px - 1px);
}

.card-img-blog-details {
	width: 100%;
	border-start-start-radius: calc(6px - 1px);
	border-start-end-radius: calc(6px - 1px);
}


.card-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;

	>.card {
		margin-block-end: 0.75rem;
	}
}

@media (min-width: 576px) {

	.horizontalMenucontainer .header-brand-img {
		&.mobile-logo,
		&.darkmobile-logo,
		&.dark-logo {
			display: none;
		}
	}

	.card-group {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;

		>.card {
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			margin-block-end: 0;

			+.card {
				margin-inline-start: 0;
				border-inline-start: 0;
			}

			&:first-child {
				border-start-end-radius: 0;
				border-end-end-radius: 0;

				.card-img-top,
				.card-header {
					border-start-end-radius: 0;
				}

				.card-footer {
					border-end-end-radius: 0;
				}
			}

			&:last-child {
				border-start-end-radius: 0;
				border-end-start-radius: 0;

				.card-img-top,
				.card-header {
					border-start-end-radius: 0;
				}
				
				.card-footer {
					border-end-start-radius: 0;
				}
			}

			&:only-child {
				border-radius: 3px;

				.card-img-top,
				.card-header {
					border-start-end-radius: 3px;
					border-start-end-radius: 3px;
				}
				
				.card-footer {
					border-end-end-radius: 3px;
					border-end-start-radius: 3px;
				}
			}

			&:not(:first-child):not(:last-child):not(:only-child) {
				border-radius: 0;

				.card-img-top,
				.card-header,
				.card-footer {
					border-radius: 0;
				}
			}
		}
	}
}

@media print {
	.card {
		box-shadow: none;
		border: none;
	}

	.card-header {
		display: none;
	}

	.card-body {
		padding: 0;
	}
}

.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0;
	padding: 1.5rem 1.5rem;
	position: relative;

	+.card-body {
		border-block-start: 1px solid $border;
	}
}

.card-body> :last-child {
	margin-block-end: 0;
}

.card-footer {
	padding: 1rem 1.5rem;
	background: none;
}

.card-header {
	background: transparent;
	padding: 0.5rem 1.5rem;
	display: -ms-flexbox;
	display: flex;
	min-height: 3.5rem;
	-ms-flex-align: center;
	align-items: center;
	margin-block-end: 0;
	border-block-end: 1px solid $border;
	position: relative;

	.card-title {
		margin-block-end: 0;
	}

	&.border-0+.card-body {
		padding-block-start: 0;
	}
}

.card-title {
	line-height: 1.2;
	text-transform: capitalize;
	font-weight: 700;
	font-size: 13px;
	text-transform: uppercase;
}

.reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float:$float-right;
}

.card-title {
	a {
		color: inherit;
	}

	&:only-child {
		margin-block-end: 0;
	}

	small {
		color: #9aa0ac;
		font-size: 0.875rem;
		display: block;
		margin: -.75rem 0 1rem;
		line-height: 1.1;
		font-weight: 400;
	}
}

.card-subtitle {
	color: #9aa0ac;
	font-size: 0.875rem;
	display: block;
	margin: -.75rem 0 1rem;
	line-height: 1.1;
	font-weight: 400;
}

.card-table {
	margin-block-end: 0;

	tr {

		td:first-child,
		th:first-child {
			padding-inline-start: 1.5rem;
		}

		td:last-child,
		th:last-child {
			padding-inline-end: 1.5rem;
		}
	}
}

.card-body+.card-table {
	border-block-start: 1px solid $border;
}

.card-link+.card-link {
	margin-inline-start: 1rem;
	margin-inline-start: 1.5rem;
}

.card-header-pills {
	margin: -.75rem 0;
	margin-inline-end: -0.75rem;
	margin-inline-start: -0.75rem;
}

.card-aside {
	-ms-flex-direction: row;
	flex-direction: row;
}

.card-aside-column {
	min-width: 5rem;
	width: 20%;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	background: no-repeat center/cover;
}

.card-recent-post {
	min-width: 5rem;
	width: 37%;
	-ms-flex: 0 0 30%;
	flex: 0 0 37%;
	background: no-repeat center/cover;

	img {
		border-radius: 5px;
	}
}

.card-options {
	margin-inline-start: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-order: 100;
	order: 100;
	margin-inline-end: -.5rem;
	color: #9aa0ac;
	-ms-flex-item-align: center;
	align-self: center;

	a {
		&:not(.btn) {
			color: $color;

			&:hover {
				text-decoration: none;
			}

			i {
				font-size: 1rem;
			}
		}

		&.option-dots {
			color: #5c678f;
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			line-height: 2.8rem;
			text-align: center;
			border-radius: 100%;
			font-size: 18px;
			border-radius: 50px;
			box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
			border: 1px solid $border;
			vertical-align: middle;
			margin-inline-end: 5px;
			padding: 0;

			&:hover {
				text-decoration: none;
				color: #5c678f !important;
				background: #f0f0f2;
			}
		}
	}

	.dropdown-toggle:after {
		display: none;
	}
}

.card-collapsed {
	> :not(.card-header):not(.card-status) {
		display: none;
	}

	.card-options-collapse i:before {
		content: '\e92d';
	}
}

.card-fullscreen {
	.card-options-fullscreen i:before {
		content: '\e992';
	}

	.card-options-remove {
		display: none;
	}
}

.card-status {
	position: absolute;
	inset-block-start: -1px;
	inset-inline-start: 0px;
	inset-inline-end: 0px;
	height: 3px;
	border-radius: 7px 7px 0 0;
	background: rgba(0, 40, 100, 0.12);
}

.card-status-left {
	inset-inline-end: auto;
	inset-block-end: 0;
	height: auto;
	width: 3px;
	border-radius: 3px 0 0 3px;
}

.card-fullscreen {
	position: fixed;
	inset-block-start: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	inset-block-end: 0;
	z-index: 99999999;
	margin: 0;
}

.card-alert {
	border-radius: 0;
	margin: -1px -1px 0;
}

.card>.list-group {
	border-block-start: 0 !important;
	border-block-end: 0 !important;
}

/*------ Cards End-------*/