/*------ Tables -----*/

.table {
	color: $color;
	width: 100%;
	max-width: 100%;
	margin-block-end: 1rem;
  }
  
  .text-wrap table {
	width: 100%;
	max-width: 100%;
	margin-block-end: 1rem;
  }
  
  .table th {
	padding: 0.75rem;
	vertical-align: top;
	border-block-start: 1px solid $border;
  }
  
  .text-wrap table {
	th, td {
	  padding: 0.75rem;
	  vertical-align: top;
	  border-block-start: 1px solid $border;
	}
  }
  
  .table td {
	padding: 0.75rem;
	vertical-align: top;
	border-block-start: 1px solid $border;
  
	&:hover, &:focus {
	  outline: 0;
	  box-shadow: none;
	}
  }
  
  .border {
	vertical-align: top;
	border: 1px solid $border;
  }
  
  .table thead th, .text-wrap table thead th {
	vertical-align: bottom;
	border-block-end: 1px solid $border;
	border-block-start: 0;
	border-block-end-width: 1px;
	padding-block-start: .75rem;
	padding-block-end: .75rem;
  }
  
  .table tbody + tbody, .text-wrap table tbody + tbody {
	border-block-start: 2px solid #dee2e6;
  }
  
  .table .table, .text-wrap table .table, .table .text-wrap table {
	background-color: #f0f0f270;
  }
  
  .text-wrap {
	.table table, table table {
	  background-color: #f0f0f270;
	}
  }
  
  .table-sm {
	th, td {
	  padding: 0.3rem;
	}
  }
  
  .table-bordered, .text-wrap table, .table-bordered th, .text-wrap table th, .table-bordered td, .text-wrap table td {
	border: 1px solid $border;
  }
  
  .table-bordered thead th, .text-wrap table thead th, .table-bordered thead td, .text-wrap table thead td {
	border-block-end-width: 1px;
  }
  
  .table-borderless {
	th, td, thead th, tbody + tbody {
	  border: 0;
	}
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
	background-color: #f0f0f2;
	background: transparent;
  }
  
  .table-hover tbody tr:hover {
	background-color: rgba(240, 240, 242, 0.5);
  }
  
  .table-success {
	background-color: $success;
  
	> {
	  th, td {
		background-color: $success;
	  }
	}
  }
  
  .table-hover .table-success:hover {
	background-color: #c5e7a4;
  
	> {
	  td, th {
		background-color: #c5e7a4;
	  }
	}
  }
  
  .table-info {
	background-color: #cbe7fb;
  
	> {
	  th, td {
		background-color: #cbe7fb;
	  }
	}
  }
  
  .table-hover .table-info:hover {
	background-color: #b3dcf9;
  
	> {
	  td, th {
		background-color: #b3dcf9;
	  }
	}
  }
  
  .table-warning {
	background-color: #fbeebc;
  
	> {
	  th, td {
		background-color: #fbeebc;
	  }
	}
  }
  
  .table-hover .table-warning:hover {
	background-color: #fae8a4;
  
	> {
	  td, th {
		background-color: #fae8a4;
	  }
	}
  }
  
  .table-danger {
	background-color: $danger;
  
	> {
	  th, td {
		background-color: $danger;
	  }
	}
  }
  
  .table-hover .table-danger:hover {
	background-color: #ecacab;
  
	> {
	  td, th {
		background-color: #ecacab;
	  }
	}
  }
  
  .table-light {
	background-color: #fdfdfe;
  
	> {
	  th, td {
		background-color: #fdfdfe;
	  }
	}
  }
  
  .table-hover .table-light:hover {
	background-color: #ececf6;
  
	> {
	  td, th {
		background-color: #ececf6;
	  }
	}
  }
  
  .table-dark {
	background-color: #c6c8ca;
  
	> {
	  th, td {
		background-color: #c6c8ca;
	  }
	}
  }
  
  .table-hover .table-dark:hover {
	background-color: #b9bbbe;
  
	> {
	  td, th {
		background-color: #b9bbbe;
	  }
	}
  }
  
  .table-active {
	background-color: rgba(0, 0, 0, 0.04);
  
	> {
	  th, td {
		background-color: rgba(0, 0, 0, 0.04);
	  }
	}
  }
  
  .table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.04);
  
	> {
	  td, th {
		background-color: rgba(0, 0, 0, 0.04);
	  }
	}
  }
  
  .table .thead-dark th, .text-wrap table .thead-dark th {
	color: #f0f0f2;
	background-color: #212529;
	border-color: #32383e;
  }
  
  .table .thead-light th, .text-wrap table .thead-light th {
	color: #495057;
	background-color: #e9ecef;
	border-color: #dee2e6;
  }
  
  .table-dark {
	color: #f0f0f2;
	background-color: #212529;
  
	th, td, thead th {
	  border-color: #32383e;
	}
  
	&.table-bordered {
	  border: 0;
	}
  }
  
  .text-wrap table.table-dark {
	border: 0;
  }
  
  .table-dark {
	&.table-striped tbody tr:nth-of-type(odd) {
	  background-color: rgba(255, 255, 255, 0.05);
	}
  
	&.table-hover tbody tr:hover {
	  background-color: rgba(255, 255, 255, 0.075);
	}
  }
  
  @media (max-width: 575.98px) {
	.table-responsive-sm {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  
	.text-wrap .table-responsive-sm > table {
	  border: 0;
	}
  
	.header-brand-img.dark-logo {
	  display: none;
	}
  
	.header-brand {
	  margin-inline-end: 0;
	}
  
	.header-brand-img {
	  &.mobile-logo {
		display: block;
		margin-inline-start: 0.5rem;
	  }
  
	  &.darkmobile-logo {
		display: none;
		margin-inline-start: 0.5rem;
	  }
	}
  
	.animated-arrow.hor-toggle {
	  text-align: center;
	  height: 2.5rem;
	  width: 2.5rem;
	  font-size: 1.2rem;
	  position: relative;
	  border-radius: 3px;
	  inset-block-start: 0;
	  padding: 5px;
	}
  }
  
  @media (max-width: 767.98px) {
	.table-responsive-md {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  
	.text-wrap .table-responsive-md > table {
	  border: 0;
	}
  }
  
  @media (max-width: 991.98px) {
	.table-responsive-lg {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  
	.text-wrap .table-responsive-lg > table {
	  border: 0;
	}
  }
  
  @media (max-width: 1279.98px) {
	.table-responsive-xl {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: -ms-autohiding-scrollbar;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  
	.text-wrap .table-responsive-xl > table {
	  border: 0;
	}
  }
  
  .table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
  
	> .table-bordered {
	  border: 0;
	}
  }
  
  .text-wrap .table-responsive > table {
	border: 0;
  }
  
  .table th, .text-wrap table th {
	color: #384b6e;
	text-transform: capitalize;
	font-size: 0.81rem;
	font-weight: 400;
  }
  
  .table-md {
	th, td {
	  padding: .5rem;
	}
  }
  
  .table-vcenter {
	td, th {
	  vertical-align: middle;
	  border-block-start: 1px solid $border;
	  border-block-end: none;
	}
  }
  
  .table-center {
	td, th {
	  text-align: center;
	}
  }
  
  .table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(240, 240, 242, 0.5);
  }
  
  .table-header {
	cursor: pointer;
	transition: .3s color;
  
	&:hover {
	  color: #495057 !important;
	}
  
	&:after {
	  content: '\f0dc';
	  font-family: FontAwesome;
	  display: inline-block;
	  margin-inline-start: .5rem;
	  font-size: .75rem;
	}
  }
  .table>:not(:last-child)>:last-child>* {
    border-block-end-color: $border;
}
.table>:not(caption)>*>* {
    padding:0.75rem;
    background-color: 1px;
    box-shadow: none;
}
.table-bordered>:not(caption)>* {
	border-width: 0px 0;
}
table {
	border-collapse: collapse !important;
}
.table > {
	tbody > tr > th {
	  font-weight: 500;
	  -webkit-transition: all .3s ease;
	  transition: all .3s ease;
	}
  
	tfoot > tr > {
	  td, th {
		font-weight: 500;
		-webkit-transition: all .3s ease;
		transition: all .3s ease;
	  }
	}
  
	thead > tr > {
	  td, th {
		font-weight: 600;
		-webkit-transition: all .3s ease;
		transition: all .3s ease;
	  }
	}
  }
.table-responsive, .table {
    overflow-y: visible !important;
}

.table-primary {
	background: $primary-02;
	color: white;
	th, td, thead th, tbody+tbody {
	  border-color: $primary-02 !important;
	  color: $black !important;
	  background-color: $primary-02;
	}
	&.table> :not(:first-child) {
	  border-block-start: 1px solid $primary !important;
	}
	tbody tr {
	  background-color: transparent !important;
	}
	thead th {
	  background: $primary !important;
	  color: white !important;
	}
	&.table-bordered> :not(caption)>* {
	  border-block-end-color: $primary-02 !important;
	}
}

.table-warning {
	background: rgba($warning, 0.2);
	color: white;
	th, td, thead th, tbody+tbody {
	  border-color: rgba($warning, 0.2) !important;
	  color: $black !important;
	  background-color: rgba($warning, 0.2);
	}
	&.table> :not(:first-child) {
	  border-block-start: 1px solid $warning !important;
	}
	tbody tr {
	  background-color: transparent !important;
	}
	thead th {
	  background: $warning !important;
	  color: white !important;
	}
	&.table-bordered> :not(caption)>* {
	  border-block-end-color: rgba($warning, 0.2) !important;
	}
}

.table-success {
	background: rgba($success, 0.2);
	color: white;
	th, td, thead th, tbody+tbody {
	  border-color: rgba($success, 0.2) !important;
	  color: $black !important;
	  background-color: rgba($success, 0.2);
	}
	&.table> :not(:first-child) {
	  border-block-start: 1px solid $success !important;
	}
	tbody tr {
	  background-color: transparent !important;
	}
	thead th {
	  background: $success !important;
	  color: white !important;
	}
	&.table-bordered> :not(caption)>* {
	  border-block-end-color: rgba($success, 0.2) !important;
	}
}

.table-danger {
	background: rgba($danger, 0.2);
	color: white;
	th, td, thead th, tbody+tbody {
	  border-color: rgba($danger, 0.2) !important;
	  color: $black !important;
	  background-color: rgba($danger, 0.2);
	}
	&.table> :not(:first-child) {
	  border-block-start: 1px solid $danger !important;
	}
	tbody tr {
	  background-color: transparent !important;
	}
	thead th {
	  background: $danger !important;
	  color: white !important;
	}
	&.table-bordered> :not(caption)>* {
	  border-block-end-color: rgba($danger, 0.2) !important;
	}
}

.table-info {
	background: rgba($info, 0.2);
	color: white;
	th, td, thead th, tbody+tbody {
	  border-color: rgba($info, 0.2) !important;
	  color: $black !important;
	  background-color: rgba($info, 0.2);
	}
	&.table> :not(:first-child) {
	  border-block-start: 1px solid $info !important;
	}
	tbody tr {
	  background-color: transparent !important;
	}
	thead th {
	  background: $info !important;
	  color: white !important;
	}
	&.table-bordered> :not(caption)>* {
	  border-block-end-color: rgba($info, 0.2) !important;
	}
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    color: $default-color;
}
.table-hover tbody tr.text-danger:hover>* {
    color: $danger;
}
/*------ Tables End-----*/
