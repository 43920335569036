/*------ Carousel -------*/

.carousel {
	position: relative;
  }
  
  .carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
	border-radius:5px;
  }
  
  .carousel-item {
	position: relative;
	display: none;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	cursor:pointer;
	transition: -webkit-transform 0.6s ease;
	transition: transform 0.6s ease;
	transition: transform 0.6s ease, -webkit-transform 0.6s ease;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
  
	&.active {
	  display: block;
	}
  }
  
  @media screen and (prefers-reduced-motion: reduce) {
	.carousel-item {
	  transition: none;
	}
  }
  
  .carousel-item-next, .carousel-item-prev {
	position: absolute;
	inset-block-start: 0;
	display: block;
  }
  
  .carousel-item-next.carousel-item-start, .carousel-item-prev.carousel-item-end {
	-webkit-transform: translateX(0);
	transform: translateX(0);
  }
  
  @supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
	.carousel-item-next.carousel-item-start, .carousel-item-prev.carousel-item-end {
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  
	.carousel-item-next, .active.carousel-item-end {
	  -webkit-transform: translate3d(100%, 0, 0);
	  transform: translate3d(100%, 0, 0);
	}
  
	.carousel-item-prev, .active.carousel-item-start {
	  -webkit-transform: translate3d(-100%, 0, 0);
	  transform: translate3d(-100%, 0, 0);
	}
  }
  
  .carousel-item-next, .active.carousel-item-end {
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
  }
  
  .carousel-item-prev, .active.carousel-item-start {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
  }
  
  .carousel-control-prev, .carousel-control-next {
	position: absolute;
	inset-block-start: 0;
	inset-block-end: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 15%;
	color: $white;
	text-align: center;
	opacity: 0.5;
  }
  
  .carousel-control-prev {
	&:hover, &:focus {
	  color: $white;
	  text-decoration: none;
	  outline: 0;
	  opacity: .9;
	}
  }
  
  .carousel-control-next {
	&:hover, &:focus {
	  color: $white;
	  text-decoration: none;
	  outline: 0;
	  opacity: .9;
	}
  }
  
  .carousel-control-prev {
	inset-inline-start: 0;
  }
  
  .carousel-control-next {
	inset-inline-end: 0;
  }
  
  .carousel-control-prev-icon, .carousel-control-next-icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: transparent no-repeat center center;
	background-size: 100% 100%;
  }
  
  .carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }
  
  .carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }
  
  .carousel-indicators {
	position: absolute;
	inset-inline-end: 0;
	inset-block-end: 10px;
	inset-inline-start: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-inline-start: 0;
	margin-inline-end: 15%;
	margin-inline-start: 15%;
	list-style: none;
  
	button {
	  position: relative;
	  -ms-flex: 0 1 auto;
	  flex: 0 1 auto;
	  width: 7px;
	  height: 7px;
	  margin-inline-end: 3px;
	  margin-inline-start: 3px;
	  text-indent: -999px;
	  background-color: $white-5;
  
	  &::before {
		position: absolute;
		inset-block-start: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
  
	  &::after {
		position: absolute;
		inset-block-end: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
	}
  
	.active {
	  background-color: $white;
	}
  }
  
  .carousel-indicators1 {
	position: absolute;
	inset-inline-end: 0;
	inset-block-start: 10px;
	inset-inline-start: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-inline-start: 0;
	margin-inline-end: 15%;
	margin-inline-start: 15%;
	list-style: none;
  
	button {
	  position: relative;
	  -ms-flex: 0 1 auto;
	  flex: 0 1 auto;
	  width: 7px;
	  height: 7px;
	  margin-inline-end: 3px;
	  margin-inline-start: 3px;
	  text-indent: -999px;
	  background-color: $white-5;
	  border-radius: 50%;
  
	  &::before {
		position: absolute;
		inset-block-start: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
  
	  &::after {
		position: absolute;
		inset-block-end: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
	}
  
	.active {
	  background-color: $white;
	}
  }
  
  .carousel-indicators2 {
	position: absolute;
	inset-inline-end: 10px;
	inset-block-start: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-inline-start: 0;
	margin-block-start: 10px;
	list-style: none;
  
	button {
	  position: relative;
	  -ms-flex: 0 1 auto;
	  flex: 0 1 auto;
	  width: 7px;
	  height: 7px;
	  margin-inline-end: 3px;
	  margin-inline-start: 3px;
	  text-indent: -999px;
	  background-color: $white-5;
	  border-radius: 50%;
  
	  &::before {
		position: absolute;
		inset-block-start: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
  
	  &::after {
		position: absolute;
		inset-block-end: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
	}
  
	.active {
	  background-color: $white;
	}
  }
  
  .carousel-indicators3 {
	position: absolute;
	inset-inline-start: 10px;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-inline-start: 0;
	margin-block-start: 10px;
	list-style: none;
  
	button {
	  position: relative;
	  -ms-flex: 0 1 auto;
	  flex: 0 1 auto;
	  width: 7px;
	  height: 7px;
	  margin-inline-end: 3px;
	  margin-inline-start: 3px;
	  text-indent: -999px;
	  background-color: $white-5;
	  border-radius: 50%;
  
	  &::before {
		position: absolute;
		inset-block-start: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
  
	  &::after {
		position: absolute;
		inset-block-end: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
	}
  
	.active {
	  background-color: $white;
	}
  }
  
  .carousel-indicators4 {
	position: absolute;
	inset-inline-end: 10px;
	inset-block-end: 0;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-inline-start: 0;
	margin-block-start: 10%;
	list-style: none;
  
	button {
	  position: relative;
	  -ms-flex: 0 1 auto;
	  flex: 0 1 auto;
	  width: 7px;
	  height: 7px;
	  margin-inline-end: 3px;
	  margin-inline-start: 3px;
	  text-indent: -999px;
	  background-color: $white-5;
	  border-radius: 50%;
  
	  &::before {
		position: absolute;
		inset-block-start: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
  
	  &::after {
		position: absolute;
		inset-block-end: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
	}
  
	.active {
	  background-color: $white;
	}
  }
  
  .carousel-indicators5 {
	position: absolute;
	inset-block-end: 0;
	inset-inline-start: 10px;
	z-index: 15;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	padding-inline-start: 0;
	list-style: none;
  
	button {
	  position: relative;
	  -ms-flex: 0 1 auto;
	  flex: 0 1 auto;
	  width: 7px;
	  height: 7px;
	  margin-inline-end: 3px;
	  margin-inline-start: 3px;
	  text-indent: -999px;
	  background-color: $white-5;
	  border-radius: 50%;
  
	  &::before {
		position: absolute;
		inset-block-start: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
  
	  &::after {
		position: absolute;
		inset-block-end: -10px;
		inset-inline-start: 0;
		display: inline-block;
		width: 100%;
		height: 10px;
		content: "";
	  }
	}
  
	.active {
	  background-color: $white;
	}
  }
  
  .carousel-caption {
	position: absolute;
	inset-inline-end: 15%;
	inset-block-start: 30%;
	inset-inline-start: 15%;
	z-index: 10;
	padding-block-start: 20px;
	padding-block-end: 20px;
	color: $white;
	text-align: center;
  }
  
  .carousel-item-background {
	content: '';
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	inset-block-end: 0;
  }
  
  .carousel-top-indicators {
	inset-block-start: 15px;
  }
  
  .carousel-top-right-indicators {
	inset-block-start: 15px;
	inset-inline-end: 15px !important;
	inset-inline-start: auto;
	margin: 0 !important;
  }
  
  .carousel-top-left-indicators {
	inset-block-start: 15px;
	inset-inline-start: 15px !important;
	inset-inline-end: auto;
	margin: 0 !important;
  }
  
  .carousel-bottom-right-indicators {
	inset-block-end: 15px;
	inset-inline-end: 15px !important;
	inset-inline-start: auto;
	margin: 0 !important;
  }
  
  .carousel-bottom-left-indicators {
	inset-block-end: 15px;
	inset-inline-start: 15px !important;
	inset-inline-end: auto;
	margin: 0 !important;
  }

  /*------ Carousel End-------*/